import * as React from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  Link,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { fetchQr, setQr } from '../../store/settings/actionCreator'
import { useDispatch } from '../../store'

type Props = {
  qrCode: string | null
  onClose: any
  handle2FAInputOpen: () => void
}

export const Dialog2FAQr = ({ qrCode, handle2FAInputOpen, onClose }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(fetchQr)

    return () => {
      dispatch(setQr(null))
    }
  }, [dispatch])

  const handleQrCodeScanned = () => {
    onClose()
    handle2FAInputOpen()
    dispatch(setQr(null))
  }

  return (
    <Dialog open={true} fullWidth onClose={onClose}>
      <DialogTitle>{t('auth.2step.title')}</DialogTitle>

      <Divider variant="popup" color="primary" />
      <p>
      Dear Client, before activate Two-Factor Authentication we recommend you to check our <Link target="_blank" href="https://irbis-user-manual.espysys.com/irbis-user-manual/accessing-the-system/irbis-2fa-activation">User Guide</Link>.
      </p>
      <p>{t('settings.2factorHint')}</p>
      <p>{t('settings.2factorHint2')}</p>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <h2>{t('settings.2factorScan')}</h2>
        </Grid>
        <Grid item>
          {qrCode ? (
            <img src={qrCode} width={200} height={200} alt="Your QR code" />
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </Grid>

      <Divider variant="popup" color="primary" />

      <DialogActions>
        <Button color="primary" size="large" onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={handleQrCodeScanned}
        >
          {t('common.done')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
