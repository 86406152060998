import { useLocation } from 'react-router-dom'
import { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from '../../store'
import { getPDFData } from '../../store/searcher/actionCreator'
import { PhonePagePdf } from './components/PhonePagePdf'
import { PostPagePdf } from './components/PostPagePdf'
import { NamePagePdf } from './components/NamePagePdf'
import { DeepwebPagePdf } from './components/DeepwebPagePdf'

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export const PDFLookupPage = () => {
  const query = useQuery()

  const dispatch = useDispatch()
  const requestMonitor = query.get('id')
  const apiKey = query.get('apiKey')

  useEffect(() => {
    if (apiKey) dispatch(getPDFData(Number(requestMonitor), apiKey))
  }, [dispatch, requestMonitor, apiKey])

  const pdfData = useSelector((state) => state.searcher.pdfData)

  useEffect(() => {
    console.log('pdfData', pdfData)
  }, [pdfData])

  switch (pdfData?.type) {
    case 'phone':
      return <PhonePagePdf pdfData={pdfData} />
    case 'post':
      return <PostPagePdf pdfData={pdfData} />
    case 'name':
      return <NamePagePdf pdfData={pdfData} />
    case 'deepweb':
      return <DeepwebPagePdf pdfData={pdfData} />
    default:
      return <></>
  }
}
