import React from 'react'
import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material'
import { PhoneDataItem, SearchResult } from '../../../../store/searcher/reducer'
import { getLabel } from '../../../../component/searcher/RenderData'
import sprite_ from '../../../../sass/sprite.module.sass'
import { isHttpUrl } from '../../../../function/string'
import emptyImage from '../../../../asset/icon/defaultUser.png'

type Props = {
  el?: any
}
type objType = {
  [key: string]: object;
};

const EyesItem = (props: Props) => {
  const {el} = props
  const eyesItem = el.data[el.from]
  if (eyesItem) {
    return <>
      {
        eyesItem?.duolingo?.users?.length > 0 && <>
          <Stack direction={'row'} alignItems={'center'} sx={{padding: '20px 0'}}>
            <Box className={sprite_[`social__small_duolingo`]} sx={{marginRight: '8px'}} />
            <Typography fontSize={18} fontWeight={700} color={'#747474'} sx={{wordBreak: 'break-all'}}>Duolingo</Typography>
          </Stack>

          {
            eyesItem?.duolingo?.users?.length > 0 && eyesItem?.duolingo?.users?.map((user: any, idx: number) => <Grid container sx={{display: 'flex'}}>
              <Grid item xs={12} >
                {
                  [
                    'currentCourseId',
                    'totalXp',
                    'username',
                    'motivation',
                    'fromLanguage',
                    'learningLanguage',
                  ].map((field, i: number) => <Grid container sx={{display: 'flex'}} columnSpacing={'20px'} key={i}>
                    <Grid item xs={4}>
                      <Typography variant="text" fontSize={14}>{getLabel(field)}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {
                        (typeof user?.[field] === 'string' || typeof user?.[field] === 'number') && <Typography variant="bold" fontSize={14}>{user?.[field]}</Typography>
                      }
                    </Grid>
                  </Grid>)
                }
                <Grid container sx={{display: 'flex'}} columnSpacing={'20px'}>
                  <Grid item xs={4}>
                    <Typography variant="text" fontSize={14}>Courses</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {
                      user.courses.map((course: any, idx: number) => <>
                        {
                          [
                            'title',
                            'authorId',
                            'learningLanguage',
                            'id',
                          ].map((subField: string, ik: number) => <Grid container sx={{display: 'flex'}} columnSpacing={'20px'} key={idx}>
                            <Grid item xs={4}>
                              <Typography variant="text" fontSize={14}>{getLabel(subField)}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                              {
                                (typeof course?.[subField] === 'string' || typeof course?.[subField] === 'number') && <Typography variant="bold" fontSize={14}>{course?.[subField] }</Typography>
                              }
                            </Grid>
                          </Grid>)
                        }
                      </>)
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>)
          }
        </>
      }

      {
        eyesItem?.github?.items?.length > 0 && <>
          <Stack direction={'row'} alignItems={'center'} sx={{padding: '20px 0'}}>
            <Box className={sprite_[`social__small_github`]} sx={{marginRight: '8px'}} />
            <Typography fontSize={18} fontWeight={700} color={'#747474'} sx={{wordBreak: 'break-all'}}>Github</Typography>
          </Stack>

          {
            eyesItem?.github?.items?.map((user: any, idx: number) => <Grid container sx={{display: 'flex'}}>
              <Grid item xs={12} >
                {
                  [
                    'login',
                    'url',
                    'html_url',
                    'repos_url',
                    'followers_url',
                    'organizations_url',
                    'subscriptions_url',
                    'score',
                    'type',
                  ].map((field, i: number) => <Grid container sx={{display: 'flex'}} columnSpacing={'20px'} key={i}>
                    <Grid item xs={4}>
                      <Typography variant="text" fontSize={14}>{getLabel(field)}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {
                        (typeof user?.[field] === 'string' || typeof user?.[field] === 'number') && <Typography variant="bold" fontSize={14}>
                          {
                            isHttpUrl(user?.[field]) ? <a style={{color: '#000'}} href={user?.[field]} target={'_blank'}>{user?.[field]}</a> : user?.[field]
                          }
                        </Typography>
                      }
                    </Grid>
                  </Grid>)
                }
                <Grid container sx={{display: 'flex'}} columnSpacing={'20px'}>
                  <Grid item xs={4}>
                    <Typography variant="text" fontSize={14}>Avatar</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <img
                      src={user.avatar_url}
                      alt=""
                      style={{ objectFit: 'cover', width: '100px', height: '100px', borderRadius: '50%' }}
                      onError={(e: any) => e.target.src = emptyImage}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>)
          }
        </>
      }

      {
        Object.entries(eyesItem?.gravatar)?.length > 0 &&<>
          <Stack direction={'row'} alignItems={'center'} sx={{padding: '20px 0'}}>
            <Box className={sprite_[`social__small_gravatar`]} sx={{marginRight: '8px'}} />
            <Typography fontSize={18} fontWeight={700} color={'#747474'} sx={{wordBreak: 'break-all'}}>Gravatar</Typography>
          </Stack>

          {
            eyesItem?.gravatar?.entry?.map((user: any, idx: number) => <Grid container sx={{display: 'flex'}}>
              <Grid item xs={12} >
                {
                  [
                    'profileUrl',
                    'displayName',
                    'preferredUsername',
                  ].map((field, i: number) => <Grid container sx={{display: 'flex'}} columnSpacing={'20px'} key={i}>
                    <Grid item xs={4}>
                      <Typography variant="text" fontSize={14}>{getLabel(field)}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {
                        (typeof user?.[field] === 'string' || typeof user?.[field] === 'number') && <Typography variant="bold" fontSize={14}>
                          {
                            isHttpUrl(user?.[field]) ? <a style={{color: '#000'}} href={user?.[field]} target={'_blank'}>{user?.[field]}</a> : user?.[field]
                          }
                        </Typography>
                      }
                    </Grid>
                  </Grid>)
                }
                <Grid container sx={{display: 'flex'}} columnSpacing={'20px'}>
                  <Grid item xs={4}>
                    <Typography variant="text" fontSize={14}>Avatar</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <img
                      src={user.thumbnailUrl}
                      alt=""
                      style={{ objectFit: 'cover', width: '100px', height: '100px', borderRadius: '50%' }}
                      onError={(e: any) => e.target.src = emptyImage}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>)
          }
          {
            Object.entries(eyesItem?.gravatar).map(([title, value], i: number) =>
              title !== 'entry' && <Grid container sx={{display: 'flex'}} columnSpacing={'20px'} key={i}>
                <Grid item xs={4}>
                  <Typography variant="text" fontSize={14}>{getLabel(title)}</Typography>
                </Grid>
                <Grid item xs={8}>
                  {
                    (typeof value === 'string' || typeof value === 'number') && <Typography variant="bold" fontSize={14}>{value}</Typography>
                  }
                  {
                    (typeof value === 'object') && <Typography variant="bold" fontSize={14}>{JSON.stringify(value)?.replace(/[\[\]}{"]/g, ' ')}</Typography>
                  }
                </Grid>
              </Grid>,
            )
          }
        </>
      }

      {
        ['bitmoji', 'instagram', 'x', 'protonmail', 'imgur', 'mailru'].map((sn: string, idx: number) => Object.entries(eyesItem?.[sn])?.length > 0 && <>
          <Stack direction={'row'} alignItems={'center'} sx={{padding: '20px 0'}}>
            <Box className={sprite_[`social__small_${sn}`]} sx={{marginRight: '8px'}} />
            <Typography fontSize={18} fontWeight={700} color={'#747474'} sx={{wordBreak: 'break-all'}}>{getLabel(sn)}</Typography>
          </Stack>
          {
            Object.entries(eyesItem?.[sn]).map(([title, value], i: number) =>
              <Grid container sx={{display: 'flex'}} columnSpacing={'20px'} key={i}>
                <Grid item xs={4}>
                  <Typography variant="text" fontSize={14}>{getLabel(title)}</Typography>
                </Grid>
                <Grid item xs={8}>
                  {
                    (typeof value === 'string' || typeof value === 'number') && <Typography variant="bold" fontSize={14}>{value}</Typography>
                  }
                  {
                    (typeof value === 'object') && <Typography variant="bold" fontSize={14}>{JSON.stringify(value)?.replace(/[\[\]}{"]/g, ' ')}</Typography>
                  }
                </Grid>
              </Grid>,
            )
          }
        </>)
      }

      {/*{
        taroItem?.breach_details?.breaches && <Grid item xs={12} >
          <Grid container sx={{display: 'flex'}}>
            <Grid item xs={2}>
              <Typography variant="text" fontSize={14}>Breaches</Typography>
            </Grid>
            <Grid item xs={10}>
              {
                taroItem?.breach_details?.breaches?.map((breach: any, idx: number) => <Stack key={idx} direction={'row'}>
                    {
                      Object.entries(breach).map(([title, value]) =>
                        <Grid item xs={4}>
                          <Typography variant="text" fontSize={14} sx={{marginRight: '10px'}}>{getLabel(title)}</Typography>
                          <Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{value + ''}</Typography>
                        </Grid>,
                      )
                    }
                  </Stack>,
                )
              }
            </Grid>
          </Grid>
        </Grid>
      }
      {
        taroItem?.breach_details?.first_breach && <Grid item xs={12}>
          <Grid container sx={{display: 'flex'}}>
            <Grid item xs={2}><Typography variant="text" fontSize={14}>First Breach</Typography></Grid>
            <Grid item xs={10}><Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{taroItem?.breach_details?.first_breach}</Typography></Grid>
          </Grid>
        </Grid>
      }
      {
        taroItem?.breach_details?.number_of_breaches && <Grid item xs={12}>
          <Grid container sx={{display: 'flex'}}>
            <Grid item xs={2}><Typography variant="text" fontSize={14}>Number of breaches</Typography></Grid>
            <Grid item xs={10}><Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{taroItem?.breach_details?.number_of_breaches}</Typography></Grid>
          </Grid>
        </Grid>
      }
      {
        taroItem?.email && <Grid item xs={12}>
          <Grid container sx={{display: 'flex'}}>
            <Grid item xs={2}><Typography variant="text" fontSize={14}>Email</Typography></Grid>
            <Grid item xs={10}><Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{taroItem?.email}</Typography></Grid>
          </Grid>
        </Grid>
      }
      {
        taroItem?.history && <Grid item xs={12} >
          <Grid container sx={{display: 'flex'}}>
            <Grid item xs={2}>
              <Typography variant="text" fontSize={14}>History</Typography>
            </Grid>
            <Grid item xs={10}>
              <Grid container>
                {
                  Object.entries(taroItem?.history).map(([title, value]) =>
                    <Grid item xs={12}>
                      <Typography variant="text" fontSize={14} sx={{marginRight: '10px'}}>{getLabel(title)}</Typography>
                      <Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{value + ''}</Typography>
                    </Grid>,
                  )
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      {
        taroItem?.domain_details && <Grid item xs={12} >
          <Grid container sx={{display: 'flex'}}>
            <Grid item xs={2}>
              <Typography variant="text" fontSize={14}>Domain Details</Typography>
            </Grid>
            <Grid item xs={10}>
              <Grid container>
                {
                  Object.entries(taroItem?.domain_details).map(([title, value]) =>
                    <Grid item xs={12}>
                      <Typography variant="text" fontSize={14} sx={{marginRight: '10px'}}>{getLabel(title)}</Typography>
                      <Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{value + ''}</Typography>
                    </Grid>,
                  )
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      {
        Object.entries(taroItem?.account_details).map(([service, serviceData]: [string, any]) =>
          serviceData?.registered && Object.keys(serviceData).length > 1 &&
          <Grid item xs={12} >
            <Grid container sx={{display: 'flex'}}>
              <Grid item xs={2}>
                <Typography variant="text" fontSize={14}>{getLabel(service)}</Typography>
              </Grid>
              <Grid item xs={10}>
                <Grid container>
                  {
                    serviceData ? Object.entries(serviceData as Object)?.map(([title, value]) =>
                      <Grid item xs={12}>
                        <Typography variant="text" fontSize={14} sx={{marginRight: '10px'}}>{getLabel(title)}</Typography>
                        <Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{value + ''}</Typography>
                      </Grid>,
                    ) : null
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>,
        )
      }*/}
    </>
  }
  return null
}

export default EyesItem