import { ILookup, Source, Type } from '../searcher/reducer'
import { AdditionalLookupAction, AdditionalPackageAction, QuotaAction, TokenAction } from './actionType'

export interface AdditionalPackage {
  productId: number
  id : number
  uid: string
  title: string
  price: number
  packageBalance: number
  bonus: number
  sources: Source[]
}

export type AdditionalPackageState = {
  additionalPackage : AdditionalPackage | undefined | null
}

const initialAdditionalPackageState: AdditionalPackageState = {
  additionalPackage: undefined,
}
export function additionalPackageReducer(state = initialAdditionalPackageState, action: AdditionalPackageAction) {
  switch (action.type) {
    case 'additional-package/SET_ADDITIONAL_PACKAGE':
      return {
        ...state,
        additionalPackage: action.data,
      }
      default:
        return state
    }
}

// -------
export interface AdditionalLookup {
  id: number
  type: Type
  title: string
  price: number
  sources: Source[]
  subTitle: string
  input: string
  button: string
  color: string
}

export type AdditionalLookupState = {
  additionalLookup: AdditionalLookup | undefined | null
}

const initialAdditionalLookupState: AdditionalLookupState = {
  additionalLookup: undefined,
}

export function additionalLookupReducer(state = initialAdditionalLookupState, action: AdditionalLookupAction) {
  switch (action.type) {
    case 'additional-lookups/SET_ADDITIONAL_LOOKUP':
      return {
        ...state,
        additionalLookup: action.data,
      }
      default:
        return state
    }
}

// ---------
export interface Token {
  token: string
}

export type TokenState = {
  token: Token | undefined | null | string
}

const initialState: TokenState = {
  token: undefined,
}

export function tokenReducer(state = initialState, action: TokenAction) {
  switch (action.type) {
  case 'token/SET_TOKEN':
    return {
      ...state,
      token: action.data,
    }
    default:
      return state
  }
}

//==========
export interface Quota {
  quotaProfilerLookup : string
	quotaProfilerCrawlers : string
	quotaProfilerCases : string
	quotaProfilerMonitoring : string
	quotaProfilerPeriodic : string
	quotaWebIntLookup : string
	quotaWebIntCrawlers : string
	quotaWebIntCases : string
	quotaWebIntMonitoring : string
	quotaWebIntPeriodic : string
}

export type QuotaState = {
  quota : Quota | null
}

const initialQuotaState: QuotaState = {
  quota : null,
}

export function userQuotaReducer(state = initialQuotaState, action: QuotaAction) {
  switch (action.type) {
    case 'quota/SET_QUOTA':
      return {
        ...state,
        quota: action.data,
      }
      case 'quota/SET_QUOTA_TRIAL':
        return {
          ...state,
          quota: action.data,
        }
      default:
        return state
    }
}
