import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Circle as CircleIcon } from '@mui/icons-material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
  Box,
  Grid,
} from '@mui/material'
import { useDispatch, useSelector } from '../../store'
import onBoarding_ from '../../sass/onBoarding.module.sass'
import {
  setOnBoardingStep,
  setOnBoardingStatus,
  finishOnBoarding,
  setRulesAcknowledgementStatus,
  finishRulesAcknowledgement,
} from '../../store/settings/actionCreator'

function BackdropComponent() {
  return null
}

export function Acknowledgement() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isAgreed, setIsAgreed] = React.useState(false)
  const [isDelayed, setIsDelayed] = React.useState(true)

  React.useEffect(() => {
    const delay = setTimeout(() => {
      setIsDelayed(false)
    }, 15000)
    return () => clearTimeout(delay)
  }, [])

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAgreed(event.target.checked)
    dispatch(finishRulesAcknowledgement)

  }
  const handleClose = () => {
    if (isAgreed && !isDelayed) {
      dispatch(setOnBoardingStatus(false))
    }
  }
  return (
    <Dialog
      open={true}
      onClose={handleClose}
      BackdropComponent={BackdropComponent}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{t('rulesacknowledgement.title')}</DialogTitle>
      <Divider variant="popup" color="primary" />

      <DialogContent>
        <Stack>
          <Box>
            <Typography fontSize={16}>
              {t('rulesacknowledgement.paragraph1')}
            </Typography>
          </Box>
          <Box>
            <Typography fontSize={16}>
              {t('rulesacknowledgement.paragraph2')}
            </Typography>
          </Box>
          <Box>
            <Typography fontSize={16}>
              {t('rulesacknowledgement.paragraph3')}
            </Typography>
          </Box>
          <Box>
            <Typography fontSize={16}>
              {t('rulesacknowledgement.paragraph4')}
            </Typography>
          </Box>
          {isDelayed && (
            <Box>
              <Typography fontSize={16}>
                {t('rulesacknowledgement.delayed')}
              </Typography>
            </Box>
          )}
          {!isDelayed && (
            <Box>
              <Typography fontSize={16}>
                <input type="checkbox" required checked={isAgreed} onChange={handleCheckboxChange} />
                {'I agree'}
              </Typography>
            </Box>
          )}
        </Stack>
      </DialogContent>

      <Divider variant="popup" color="primary" />
    </Dialog>
  )
}
