import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  DialogContent,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Divider,
  Avatar,
  Link,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Popover,
  PopoverOrigin,
  Grid,
  CircularProgress, useTheme, Tooltip,
} from '@mui/material'
import * as _ from "lodash"
import sprite_ from '../../../sass/sprite.module.sass'
import { usePostObserverDialog } from '../../../style/lookup'
import { Source } from '../../../store/searcher/reducer'
import { isTextLimitPassed, parseText, truncate } from '../../../function/string'
import { search } from '../../../function/data'
import { getJSXByWord, RenderHTMLValue, RenderValue } from '../../../component/searcher/RenderData'
import { useDispatch, useSelector } from '../../../store'
import { preparePostResult } from '../../../store/searcher/service'
import { usePopover } from '../../../hook/usePopover'
import { showSnackbar } from '../../../store/common/actionCreator'
import { addLeadingZeros } from '../../../function/number'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {getLinkToProfile} from '../../../function/sn-groups'
import { SearchResult } from '../../../store/searcher/reducer'
import { Marker } from 'react-mark.js'


const postLimit = 45

const searchExcludes = ['profile_id', 'keyphrase', 'keyword']

function getAccordionStyle(idx: number, textLength: number) {
  return {
    background: idx % 2 ? '#FFF' : '#F0F2FA',
    margin: '0px !important',
    cursor: textLength > postLimit ? 'pointer' : 'auto',
  }
}

function getFilterIcon(source: Source, sourceFilter: Source[]) {
  if (sourceFilter.includes(source)) return sprite_['social__large_' + source]
  else return sprite_['social__small_' + source]
}

type PropsLinks = {
  links: any
}

export const Links = ({ links }: PropsLinks) => {
  return (
    <React.Fragment>
      <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
        {links.map((el: any, idx: number) => {
          return el.url ? (
            <Link variant="underlined" href={el.url} target="_blank" key={idx}>
              {el.name}
            </Link>
          ) : null
        })}
      </Grid>
    </React.Fragment>
  )
}

type Props = {
  apiData: SearchResult
}

export function PostCommon(props: Props) {
  const { t } = useTranslation()
  const dialogClasses = usePostObserverDialog()

  const {apiData} = props
  const data = preparePostResult(apiData)
  const theme = useTheme()

  const initState = React.useMemo(() => {
    return data.data?.map((_) => false) || []
  }, [data.data])

  const [accordionOpen, setAccordionOpen] = React.useState<boolean[]>(initState)
  const [filter, setFilter] = React.useState<Source[]>([])
  const [searchValue, setSearchValue] = React.useState('')

  const filteredData = React.useMemo(() => {
    let filtered =
      filter.length > 0
        ? data.data?.filter((el) => filter.includes(el.from))
        : data.data

    if (searchValue.length > 1) {
      filtered = filtered?.filter((row) =>
        search(searchValue, row, searchExcludes),
      )
    }

    return filtered
  }, [data.data, filter, searchValue])

  const sources = React.useMemo(() => {
    return [...new Set(data.data?.map((el) => el.from))] || []
  }, [data.data])

  const handleClickPost =
    (url: string) => (e: React.MouseEvent<HTMLSpanElement>) => {
      e.stopPropagation()

      window.open(url, '_blank')?.focus()
    }

  const handleChangeAccordion =
    (idx: number) => (event: React.SyntheticEvent, expanded: boolean) => {
      setAccordionOpen((state) => {
        const newState = [...state]
        newState[idx] = expanded
        return newState
      })
    }

  const handleSourceFilter = (source: Source) => {
    setFilter((state) => {
      const index = state.indexOf(source)
      const newState = [...state]

      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  const getProfileId = (id: string | number, source: string) => {
    if (source !== 'vk') return '@' + id
    return id
  }

  const dispatch = useDispatch()

  const fileName = React.useMemo(() => {
    if (apiData) {
      const date = new Date(String(apiData?.createdTime))
      const days = addLeadingZeros(date.getDate(), 2)
      const month = addLeadingZeros(date.getMonth() + 1, 2)
      const year = date.getFullYear()
      const type =
        apiData?.type.charAt(0).toUpperCase() + apiData?.type.slice(1)
      return `${type}_${apiData.criteria}_${days}_${month}_${year}.pdf`
    } else {
      return 'file.pdf'
    }
  }, [apiData])

  const [isPdfLoading, setIsPdfLoading] = React.useState<boolean>(false)

  const handleDownloadPdf = (id: number) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/pdf/request-monitor/download/?id=${data.id}`

    setIsPdfLoading(true)

    dispatch(
      showSnackbar('info', 'preparation of the pdf file may take some time'),
    )

    const xhr = new XMLHttpRequest()
    //set the request type to post and the destination url to '/convert'
    xhr.open('GET', url, true)
    //set the reponse type to blob since that's what we're expecting back
    xhr.responseType = 'blob'

    xhr.send()

    xhr.onload = function (e) {
      if (this.status === 200) {
        const blob = new Blob([this.response], { type: 'image/pdf' })

        const a = document.createElement('a')

        document.body.appendChild(a)

        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = fileName

        a.click()

        setIsPdfLoading(false)
        window.URL.revokeObjectURL(url)
      } else {
        dispatch(showSnackbar('error', 'Something went wrong'))
      }
    }
  }

  const handleSearchValue = _.debounce((value: string) => {
    setSearchValue(value)
  }, 1000)

  return (
    <Stack>
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
          mb={3}
        >
          <Stack direction="row" alignItems="flex-start" height={42} sx={{
            [theme.breakpoints.only("xs")]: {
              marginLeft: '0px!important',
              alignItems: 'center!important',
            },
          }}>
            <Typography fontSize={15} style={{ color: '#8B929B' }} ml={2} sx={{
              [theme.breakpoints.only("xs")]: {
                display: 'none',
              },
            }}>
              {t('searcher.name.popup.searchCriteriaUsed')}:
            </Typography>
            <Tooltip title={data.criteria}>
              <Typography
                fontSize={15}
                style={{ color:'#00D348', maxWidth:'400px', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}
                ml={1}
              >
                {data.criteria}
              </Typography>
            </Tooltip>

          </Stack>
          {/*input */}
          <OutlinedInput
            //value={searchValue}
            placeholder={t('searcher.name.popup.searchPlaceholder')}
            onChange={(e) => handleSearchValue(e.target.value)}
            sx={{
              background: 'none',
            }}
            endAdornment={
              <InputAdornment position="end">
                <Box className={sprite_.common__search} />
              </InputAdornment>
            }
            style={{ width: 192, height: 38 }}
          />
        </Stack>
      </DialogTitle>
      {/* start */}
      <DialogContent>
        {filteredData?.map((el, idx) => {
            const link = getLinkToProfile(el)
            if (link) {
              el.posted_by_profile_url = link
            }
          const links: any = [
            { name: 'image_1', url: el?.images_url[0] },
            { name: 'image_2', url: el?.images_url[1] },
            { name: 'image_3', url: el?.images_url[2] },
            { name: 'image_4', url: el?.images_url[3] },
            { name: 'image_5', url: el?.images_url[4] },
            { name: 'video_1', url: el?.videos_url[0] },
            { name: 'video_2', url: el?.videos_url[1] },
          ]

          return (
            <Accordion
              key={idx}
              square
              expanded={accordionOpen[idx]}
              onChange={handleChangeAccordion(idx)}
              sx={{ position: 'static' }}
            >
              <AccordionSummary
                style={getAccordionStyle(idx, el.text.length)}
              >
                <Stack direction="row" alignItems="center" spacing={3}>
                  <Box pl={2} sx={{
                    [theme.breakpoints.only("xs")]: {
                      display: 'none',
                    },
                    width: '45px',
                  }}>
                    <Avatar src={el.original_post_user_image} />
                  </Box>
                  <Box width={80} textAlign="center" sx={{
                    [theme.breakpoints.only("xs")]: {
                      display: 'none',
                    },
                  }}>
                    <Typography
                      variant="bold"
                      sx={{
                        overflowWrap: 'break-word',
                      }}
                    >
                      <Marker mark={searchValue}>{el.original_post_user_name}</Marker>
                    </Typography>
                  </Box>
                  <Box className={sprite_['social__small_' + el.from]} sx={{
                    [theme.breakpoints.only("xs")]: {
                      marginLeft: '0!important',
                    },
                  }} />
                  <Box
                    width={90}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      [theme.breakpoints.only("xs")]: {
                        display: 'none',
                      },
                    }}
                  >
                    {el.profile_id && (
                      <Link
                        variant="underlined"
                        href={el?.posted_by_profile_url}
                        target="_blank"
                      >
                        <Marker as='span' mark={searchValue}>{getProfileId(el?.profile_id, el?.from)}</Marker>
                      </Link>
                    )}
                  </Box>
                  <Box width={70} sx={{
                    [theme.breakpoints.only("xs")]: {
                      width: '70px',
                      lineHeight: '10px',
                      marginLeft: '5px!important',
                    },
                  }}>
                    <Typography sx={{
                      [theme.breakpoints.only("xs")]: {
                        fontSize: '10px',
                        lineHeight: '10px',
                      },
                    }}>{el.timeStamp}</Typography>
                  </Box>
                  <Box width={200} sx={{
                    [theme.breakpoints.only("xs")]: {
                      display: 'none',
                    },
                  }}>
                    {Boolean(el.text) ? (
                      <Typography
                        variant="bold"
                        color="primary"
                        onClick={
                          isTextLimitPassed(el.text, postLimit)
                            ? undefined
                            : handleClickPost(el.post_url)
                        }
                      >
                        <Marker as='span' mark={searchValue}>{truncate(parseText(el.text), postLimit)}</Marker>
                      </Typography>
                    ) : (
                      <Link
                        variant="bold"
                        color="primary"
                        href={el.post_url}
                        target="_blank"
                        sx={{wordBreak: 'break-word'}}
                      >
                        {truncate(el.post_url, postLimit)}
                      </Link>
                    )}
                  </Box>
                  <Box sx={{
                    [theme.breakpoints.not("xs")]: {
                      display: 'none',
                    },
                    [theme.breakpoints.only("xs")]: {
                      width: '40px',
                      marginLeft: '0!important',
                    },
                  }}><ArrowDropDownIcon /></Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    width={60}
                    height={26}
                    sx={{
                      padding: '0 5px',
                      [theme.breakpoints.only("xs")]: {
                        //width: '60px',
                        justifyContent: 'flex-start',
                        marginLeft: '3px!important',
                      },
                    }}
                  >
                    <Box className={sprite_.common__like} />
                    <Typography sx={{
                      [theme.breakpoints.only("xs")]: {
                        fontSize: '10px',
                      },
                    }}>{el.total_reactions_count}</Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    width={60}
                    height={26}
                    sx={{
                      padding: '0 5px',
                      [theme.breakpoints.only("xs")]: {
                        //width: '60px',
                        justifyContent: 'flex-start',
                        marginLeft: '3px!important',
                      },
                    }}
                  >
                    <Box className={sprite_.common__comment} />
                    <Typography sx={{
                      [theme.breakpoints.only("xs")]: {
                        fontSize: '10px',
                      },
                    }}>{el.comments_count}</Typography>
                  </Stack>
                </Stack>
              </AccordionSummary>
              {/*start Details */}

              <AccordionDetails
                style={getAccordionStyle(idx, el.text.length)}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  px={2}
                  sx={{
                    [theme.breakpoints.only("xs")]: {
                      flexFlow: 'column',
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  <Box width={900} sx={{
                    overflowWrap: 'break-word',
                    [theme.breakpoints.only("xs")]: {
                      width: '100%',
                    },
                  }} >
                    <Marker as={'span'} mark={searchValue}>
                      {/*<RenderValue
                        label="text"
                        value={el.text}
                        variant="inherit"
                        source={el.from}
                      />*/}
                      <RenderHTMLValue label={'text'} value={el.text} />
                      {/*{el.text}*/}
                    </Marker>
                    {/*LINKS*/}
                    <Links links={links} />
                  </Box>
                  <Box
                    className={sprite_['social__small_' + el.from]}
                    ml="auto"
                  />
                  <Link
                    href={el.post_url}
                    target="_blank"
                    variant="underlined"
                    color="primary"
                    ml={1}
                  >
                    {t('searcher.post.popup.originalPost')}
                  </Link>
                </Stack>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </DialogContent>
    </Stack>
  )
}
