import * as React from 'react'
import { useLocation } from 'react-router-dom'
import content_ from '../sass/content.module.sass'
import { useDispatch, useSelector } from '../store'
import { Header } from '../container/main/Header'
import { Menu } from '../container/main/Menu'
import { LocalStorage } from '../enum/storage'
import { getIsRtl } from '../store/common/selector'
import { Backdrop, Stack } from '@mui/material'
import { Campaign, CampaignPage } from '../store/settings/reducer'
import { PopupCampaign } from '../container/PopupCampaign'
import {
  addAuthLocalStorageItemToArray,
  getAuthLocalStorageArray,
} from '../function/storage'
import { formatDate } from '../function/date'
import { getViewResult } from '../store/searcher/selector'
import { PopupPhoneLookup } from '../container/searcher/PopupPhoneLookup'
import { PopupNameLookup } from '../container/searcher/PopupNameLookup'
import { PopupPostObserver } from '../container/searcher/PopupPostObserver'
import { PopupDeepWebLookup } from '../container/searcher/PopupDeepWebLookup'
import { toggleViewResult } from '../store/searcher/actionCreator'
import PopupCommon from '../container/searcher/PopupCommon'


type Props = {
  children: React.ReactNode,
  style?: object,
}

const mdWidth = 960

const handleOnload = (storageMenuOpen: boolean) => {
  const width = window.innerWidth
  const isMobile = width < mdWidth

  if (isMobile) return false
  else return storageMenuOpen
}

export function PrivatePage({ children, style }: Props) {
  const location = useLocation()
  const dispatch = useDispatch()

  const storageMenuValue =
    localStorage.getItem(LocalStorage.MENU_OPEN) || 'true'
  const storageMenuOpen: boolean = storageMenuValue === 'true'
  const storageSeenEveryLogin = getAuthLocalStorageArray(
    LocalStorage.CAMPAIGN_EVERY_LOGIN,
  )
  const storageSeenOnceADay = getAuthLocalStorageArray(
    LocalStorage.CAMPAIGN_ONCE_A_DAY,
  )

  const isRtl = useSelector(getIsRtl)
  const viewResult = useSelector(getViewResult)
  const { marketingCampaigns, isOnBoarding } = useSelector(
    (state) => state.settings,
  )

  const [campaignOpened, setCampaignOpened] = React.useState<Campaign | null>(
    null,
  )

  const showOnBoarding = isOnBoarding && location.pathname === '/dashboard'

  const pageCampaigns = marketingCampaigns?.filter((el) => {
    const isRouteMatch = el.pages.includes(location.pathname as CampaignPage)
    const isNotInEveryLoginSeenArchive = !storageSeenEveryLogin.includes(el.id)
    const isNotInOnceADayArchive = !storageSeenOnceADay.some(
      (el2: { id: number; date: string }) => {
        return (
          el2.id === el.id && el2.date === formatDate(new Date(), 'yyyy-MM-dd')
        )
      },
    )
    return (
      isRouteMatch && isNotInEveryLoginSeenArchive && isNotInOnceADayArchive
    )
  })
  const everyLoginCampaigns = pageCampaigns?.filter(
    (el) => el.frequency === 'every-login',
  )
  const onceADayCampaigns = pageCampaigns?.filter(
    (el) => el.frequency === 'once-a-day',
  )

  const campaigns: Campaign[] = React.useMemo(() => {
    return !everyLoginCampaigns || !onceADayCampaigns
      ? []
      : [...everyLoginCampaigns, ...onceADayCampaigns]
  }, [everyLoginCampaigns, onceADayCampaigns])

  React.useEffect(() => {
    if (campaigns) setCampaignOpened(campaigns[0])
  }, [campaigns])

  const handleResize = React.useCallback(() => {
    const width = window.innerWidth
    const isMobile = width < mdWidth

    setIsMobile(isMobile)

    if (isMobile) setNavbarOpen(false)
    else setNavbarOpen(storageMenuOpen)
  }, [storageMenuOpen])

  const [navbarOpen, setNavbarOpen] = React.useState<boolean>(
    handleOnload(storageMenuOpen),
  )
  const [isMobile, setIsMobile] = React.useState<boolean>()

  React.useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  const handleNavbarOpen = (status: boolean) => {
    setNavbarOpen(status)

    dispatch({ type: 'common/TOGGLE_MENU_ANIMATION' })

    setTimeout(() => {
      dispatch({ type: 'common/TOGGLE_MENU_ANIMATION' })
    }, 300)

    localStorage.setItem(LocalStorage.MENU_OPEN, String(status))
  }

  let contentClassName = navbarOpen ? content_.root_narrow : content_.root_wide
  contentClassName = isMobile ? content_.root_mobile : contentClassName
  contentClassName = `${contentClassName} ${
    isRtl ? content_.root_rtl : content_.root_ltr
  }`

  function handleCampaignArchive(campaign: Campaign) {
    if (campaign.frequency === 'every-login')
      addAuthLocalStorageItemToArray(
        LocalStorage.CAMPAIGN_EVERY_LOGIN,
        campaign.id,
      )
    else {
      const record = {
        id: campaign.id,
        date: formatDate(new Date(), 'yyyy-MM-dd'),
      }
      addAuthLocalStorageItemToArray(LocalStorage.CAMPAIGN_ONCE_A_DAY, record)
    }

    setCampaignOpened(null)
  }

  return (
    <>
      {showOnBoarding ? (
        <Backdrop open={true} style={{ zIndex: 2 }} />
      ) : campaignOpened ? (
        <PopupCampaign
          data={campaignOpened}
          onClose={() => handleCampaignArchive(campaignOpened)}
        />
      ) : null}

      <Stack className={contentClassName}>
        <Header setNavbarOpen={handleNavbarOpen} isMobile={isMobile} />
        <Menu navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} isMobile={isMobile} />

        <Stack p={4} spacing={4} className="content" sx={style}>
          {children}
        </Stack>
      </Stack>

      {viewResult &&
        (() => {
          switch(viewResult.type) {
            case 'phone':
              //return <PopupPhoneLookup onClose={() => dispatch(toggleViewResult(null))} />
              return <PopupCommon onClose={() => dispatch(toggleViewResult(null))} isMobile={isMobile} />
            case 'name':
              //return <PopupNameLookup onClose={() => dispatch(toggleViewResult(null))} />
              return <PopupCommon onClose={() => dispatch(toggleViewResult(null))} isMobile={isMobile} />
            case 'post':
              //return <PopupPostObserver onClose={() => dispatch(toggleViewResult(null))} />
              return <PopupCommon onClose={() => dispatch(toggleViewResult(null))} isMobile={isMobile} />
            case 'deepweb':
              //return <PopupDeepWebLookup onClose={() => dispatch(toggleViewResult(null))} />
              return <PopupCommon onClose={() => dispatch(toggleViewResult(null))} isMobile={isMobile} />
            case 'phone_list':
                //return <PopupDeepWebLookup onClose={() => dispatch(toggleViewResult(null))} />
              return null
            default:
              return <PopupCommon onClose={() => dispatch(toggleViewResult(null))} isMobile={isMobile} />
              
          }
        })()
      }
    </>
  )
}
