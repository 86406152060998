import React from 'react'
import { TableBodyData, TableBodyRow } from '../../../store/common/reducer'
import { HeaderData } from '../types'

type Props = {
  headerData: HeaderData[]
  bodyData: TableBodyData | undefined
  params: Record<string, any>
  selectable?: boolean
  onSelectCallback?: (rows: TableBodyRow[]) => void
  onUnselectCallback?: (rows: TableBodyRow[]) => void
}

export function useTable({
  headerData,
  bodyData,
  params,
  selectable,
  onSelectCallback,
  onUnselectCallback,
}: Props) {
  const [sortKey, setSortKey] = React.useState<string>('')
  const [selectedRowIds, setSelectedRowIds] = React.useState<number[]>([])

  React.useEffect(() => {
    if (bodyData?.list.length === 0) setSelectedRowIds([])
  }, [bodyData])

  const cellCount = React.useMemo(() => {
    return headerData.length + (selectable ? 1 : 0)
  }, [headerData, selectable])

  const pageCount = React.useMemo(() => {
    if (bodyData) return Math.ceil((bodyData?.count || 1) / params.limit)
    else return 1
  }, [bodyData, params])

  const isPagesRowsSelected = React.useMemo(() => {
    if (bodyData === undefined) return false

    const pageIds = bodyData.list.map((el) => (el as any).id)

    if (pageIds.length === 0) return false

    return pageIds.every((el) => selectedRowIds.includes(el))
  }, [bodyData, selectedRowIds])

  const handleSelect = (row: TableBodyRow, isChecked: boolean) => {
    setSelectedRowIds((state) => {
      const index = state.indexOf((row as any).id)
      const newState = [...state]

      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, (row as any).id]
      }
    })

    if (isChecked && onSelectCallback) {
      onSelectCallback([row])
    } else if (!isChecked && onUnselectCallback) {
      onUnselectCallback([row])
    }
  }

  const handleSelectAll = () => {
    const pageRows = bodyData?.list || []
    const ids = pageRows.map((el) => (el as any).id)

    if (!isPagesRowsSelected) {
      // page's all rows selected
      setSelectedRowIds((state) => [
        ...state,
        ...ids.filter((el) => !state.includes(el)),
      ])

      if (onSelectCallback) {
        onSelectCallback(pageRows)
      }
    } else {
      // page's all rows deselected
      setSelectedRowIds((state) => [...state.filter((el) => !ids.includes(el))])

      if (onUnselectCallback) {
        onUnselectCallback(pageRows)
      }
    }
  }

  return {
    sortKey,
    setSortKey,
    selectedRowIds,
    setSelectedRowIds,

    cellCount,
    pageCount,
    isPagesRowsSelected,
    handleSelect,
    handleSelectAll,
  }
}
