import * as React from 'react'
import { t } from 'i18next'
import {
  Grid,
  Typography,
  Box,
  useTheme,
  CardHeader,
  CardContent,
  Card,
  CardMedia,
  Stack,
  Button,
  CardActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
} from '@mui/material'
import { useDispatch, useSelector } from '../store'
import { getLookups } from '../store/searcher/selector'
import {
  
  fetchLookup,
  
  
} from '../store/searcher/actionCreator'

import { PrivatePage } from '../component/PagePrivate'
import { useAuthEffect } from '../hook/useAuthEffect'
import { WebInt } from '../component/WebInt'
import { fetchAdditionalLookup, fetchAdditionaWebIntPackage, postQuota, postToken, setTrialQuota } from '../store/webint/actionCreator'
import developer_ from '../sass/developer.module.sass'

import startImg from '../../src/asset/osint/start_bg.png'
import ownPaceImg from '../../src/asset/osint/own_pace.png'
import videoImg from '../../src/asset/osint/video_bg.png'
import videoPlay from '../../src/asset/osint/video_play.png'
import circleImg from '../../src/asset/osint/circle.png'
import researcherImg from '../../src/asset/osint/researcher.png'
import investigatorImg from '../../src/asset/osint/investigator.png'
import dataAnalystImg from '../../src/asset/osint/data_analyst.png'
import profilerImg from '../../src/asset/osint/profiler.png'
import webintImg from '../../src/asset/osint/webint.png'

import profilerBullet1 from '../../src/asset/osint/profiler_bullet1.png'
import profilerBullet2 from '../../src/asset/osint/profiler_bullet2.png'
import profilerBullet3 from '../../src/asset/osint/profiler_bullet3.png'
import webintBullet1 from '../../src/asset/osint/webint_bullet1.png'
import webintBullet2 from '../../src/asset/osint/webint_bullet2.png'
import webintBullet3 from '../../src/asset/osint/webint_bullet3.png'

import treeImg from '../../src/asset/osint/tree.png'
import folderImg from '../../src/asset/osint/folder.png'

import {
  OsintBlueButton,
  OsintGreenButton,
  OsintGreenSmallButton,
  OsintInvestigationButton,
  OsintVideoPlayButton,
} from '../style/osint'
import { useHistory } from 'react-router-dom'
import { getAdditionalLookups } from '../store/webint/selector'
import { fetchProfile } from '../store/settings/actionCreator'
import { createPayProUrl } from '../function/payment'
import { visitPage } from './visit-page-loger/visit-page-loger-segment'

export function WebintAboutPage() {
  const dispatch = useDispatch()
  const theme = useTheme()
  const history = useHistory()
  const { additionalLookup }  = useSelector((state) => state.additionalLookup)
  const { token } = useSelector((state) => state.token)
  const { additionalPackage } = useSelector((state) => state.additionalPackage)
  const user = useSelector((state) => state.settings)
  const playerRef = React.useRef<HTMLVideoElement>(null)
  const [releaseVideo, setReleaseVideo] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (user.uid) {
      visitPage(user.uid, "/irbispro/about")
    }
  }, [])

  React.useEffect(() => {
     dispatch(fetchAdditionalLookup)
     dispatch(fetchAdditionaWebIntPackage)
     
     if (additionalLookup && user.uid) {
       dispatch(postToken({userId: user.uid, quotas: additionalLookup.input}))
       dispatch(postQuota({userId: user.uid}))
      }
  }, [user.uid] )

  // if (additionalLookup && user.uid) {
  // dispatch(postToken({userId: user.uid, quotas: additionalLookup.input}))
  // }
  
  const handlePlayClick = () => {
    setReleaseVideo(true)
  }

  const handleRedirectWebintOnly = () => {
    history.push({pathname: '/packages/webinthub'})
  }

  const handleGoWebint = async () => {
    //const path = process?.env?.REACT_APP_WEBINT_BEARER
    
    if (!user.additionalPackageId) {
      handleRedirectWebintOnly()
    } else {
      /*if (path && token)
        window.open( path + token, '_blank')
        window.location.reload()*/
      history.push({pathname: '/irbispro'})
    }
  }
  const handleSupportClick = () => {
    window.open( `mailto:${process.env?.REACT_APP_SUPPORT || 'support@espysys.com'}`, '_blank' )
  }
  
  return (
    <PrivatePage>
      <Grid container spacing={1} sx={{
        [theme.breakpoints.only("xs")]: {
          display: 'none',
        },
      }}>
        <Grid item xs={12}>
          <Typography variant="title">{t('webint.title')}</Typography>
        </Grid>
      </Grid>

      <Grid container columnSpacing={2} rowSpacing={2} style={{margin: 'inherit'}}>
        <Grid item xl={4} lg={6} md={12} sm={12} xs={12}>
          <Card sx={{
            position: 'relative',
            minHeight: '162px',
          }}>
            <CardMedia
              image={startImg}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                height: "100%",
                width: "100%",
              }}
            />
            <CardContent sx={{zIndex: 1, height: '100%', paddingBottom: 0}}>
              <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" height={'100%'}>
                <Stack direction="column" justifyContent="center">
                  <Typography variant="title" color={'white'} fontSize={20} sx={{lineHeight: '23px'}}>Launch Irbis Pro</Typography>
                  <Typography variant="text" color={'white'} fontSize={14} sx={{lineHeight: '16px', paddingTop: '16px'}}>Advanced centralized OSINT Investigation Platform for Top Intelligence Teams</Typography>
                </Stack>
                <Stack>
                  <OsintBlueButton variant="contained" onClick={handleGoWebint}>{user.additionalPackageId ? "START" : "Go to deal"}</OsintBlueButton>
                </Stack>
              </Stack>
            </CardContent>
            <CardActions></CardActions>
          </Card>
        </Grid>
        <Grid item xl={4} lg={6} md={12} sm={12} xs={12}>
        <Link target="_blank" href="https://irbis-user-manual.espysys.com/"  className='flex flex-row px-3 py-2'>
          <Card sx={{
            position: 'relative',
            minHeight: '162px',
          }}>
            <CardContent sx={{zIndex: 1, height: '100%', paddingBottom: 0, margin: '0 20px'}}>
              <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" height={'100%'}>
                <Stack>
                  <img src={ownPaceImg} width={'78px'} height={'80px'} alt={''} />
                </Stack>
                <Stack direction="column" justifyContent="center">
                  <Typography variant="title" color={'#214A98'} fontSize={20}>Learn at your own pace</Typography>
                  <Typography variant="text" color={'#214A98'} fontSize={14}>From dozens of sources including search engines, social media platforms, mobile applications and deep web</Typography>
                </Stack>
                
              </Stack>
            </CardContent>
            <CardActions></CardActions>
          </Card>
          </Link>
        </Grid>
        <Grid item xl={4} lg={6} md={12} sm={12} xs={12}>
        <Link href="/support"  className='flex flex-row px-3 py-2'>
          <Card sx={{
            position: 'relative',
            minHeight: '162px',
            background: '#F7FFF5',
          }}>
            <CardContent sx={{zIndex: 1, height: '100%', paddingBottom: 0, margin: '0 20px'}}>
              <Stack direction="column">
                <OsintGreenButton variant="contained" onClick={handleSupportClick}>Support</OsintGreenButton>
                <Stack direction="row" paddingTop={'15px'}>
                  <Typography marginRight={'15px'}>Send us an email to request guidance and training on how to use Irbis Pro.</Typography>
                  <OsintGreenSmallButton variant="contained" onClick={handleSupportClick}>Request</OsintGreenSmallButton>
                </Stack>
              </Stack>
            </CardContent>
            <CardActions></CardActions>
          </Card>
        </Link>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{
            position: 'relative',
            height: '500px',
          }}>
            <CardMedia
              image={videoImg}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                height: "100%",
                width: "100%",
                backgroundSize: 'contain',
                backgroundColor: '#00153D',
              }}
            />
            <CardContent sx={{
              zIndex: 1,
              height: '100%',
              width: "100%",
              paddingBottom: 0,
              position: "absolute",
              top: 0,
              right: 0,
              //backgroundColor: '#000',
            }}>
              {
                !releaseVideo && <Stack sx={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                  <OsintVideoPlayButton
                    onClick={handlePlayClick}
                  >
                    <img title={'Play'} src={videoPlay} alt={''} />
                  </OsintVideoPlayButton>
                </Stack>
              }
              {
                releaseVideo && <video
                  width={'100%'}
                  height={'100%'}
                  controls
                  autoPlay
                  ref={playerRef}
                  style={{
                    objectFit: 'contain',
                    backgroundColor: '#000',
                  }}
                >
                  <source src={'https://irbis-images.s3.eu-west-1.amazonaws.com/IrbisProProFILER4.mp4'} type="video/mp4" />
                </video>
              }
            </CardContent>
            <CardActions></CardActions>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{
            position: 'relative',
            minHeight: '400px',
          }}>
            <CardContent sx={{
              height: '100%',
              width: "100%",
              paddingBottom: 0,
            }}>
              <Stack sx={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                <Grid container spacing={1} sx={{justifyContent: 'center'}}>
                  <Grid
                    item
                    xs={4} /*lg={12} md={12} sm={12} xs={12}*/
                    sx={{position: 'relative'}}
                  >
                    <Stack
                      direction={'column'}
                      sx={{
                        textAlign: 'right',
                        position: 'absolute',
                        maxWidth: '350px',
                        right: '0',
                        top: '0',
                        [theme.breakpoints.down("lg")]: {
                          position: 'relative',
                          //maxWidth: 'inherit',
                          right: 0,
                          maxWidth: '150px',
                        },
                        /*[theme.breakpoints.down("md")]: {
                          position: 'relative',
                          //maxWidth: 'inherit',
                          right: 0,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                        },*/
                      }}
                    >
                      <Typography variant={'title'} fontSize={20} color={'#214A98'}>Real-time intelligence</Typography>
                      <Typography variant={'text'} fontSize={14}>From dozens of sources including search engines, social media platforms, mobile applications and deep web</Typography>
                    </Stack>
                    <Stack
                      direction={'column'}
                      sx={{
                        textAlign: 'right',
                        position: 'absolute',
                        maxWidth: '350px',
                        right: '50px',
                        bottom: '25px',
                        [theme.breakpoints.down("lg")]: {
                          position: 'relative',
                          right: 0,
                          bottom: 0,
                          //maxWidth: 'inherit',
                          maxWidth: '150px',
                        },
                      }}
                    >
                      <Typography variant={'title'} fontSize={20} color={'#214A98'}>OSINT automation</Typography>
                      <Typography variant={'text'} fontSize={14}>Automated collection and reporting to produce fast, accurate and actionable intelligence from dozens of sources</Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    //xl={'auto'} lg={'auto'} md={4} sm={12} xs={12}
                    //xs={4}
                    sx={{
                      width: '321px',
                      [theme.breakpoints.down("xl")]: {position: 'absolute', opacity: '0.4',top: '0'},
                      [theme.breakpoints.down("lg")]: {left: '25%'},
                      [theme.breakpoints.down("sm")]: {left: '10%'},
                    }}
                  >
                    <Stack sx={{width: '321px', height: '291px', alignItems: 'center', [theme.breakpoints.down("xl")]: {}}}>
                      <img style={{width: '100%', height: '100%'}} src={circleImg} alt={''} />
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    //xl={4} lg={12} md={12} sm={12} xs={12}
                    xs={4}
                    sx={{position: 'relative'}}
                  >
                    <Stack
                      direction={'column'}
                      sx={{
                        position: 'absolute',
                        maxWidth: '350px',
                        left: '50px',
                        top: '25px',
                        [theme.breakpoints.down("lg")]: {
                          position: 'relative',
                          left: 0,
                          top: 0,
                          //maxWidth: 'inherit',
                          maxWidth: '150px',
                        },
                      }}
                    >
                      <Typography variant={'title'} fontSize={20} color={'#214A98'}>Investigation & AI analysis</Typography>
                      <Typography variant={'text'} fontSize={14}>A full suite of tools provides everything you need to utilize the world wide web for your intelligence activities</Typography>
                    </Stack>
                    <Stack
                      direction={'column'}
                      sx={{
                        position: 'absolute',
                        maxWidth: '350px',
                        bottom: '0',
                        [theme.breakpoints.down("lg")]: {
                          position: 'relative',
                          bottom: 0,
                          //maxWidth: 'inherit',
                          maxWidth: '150px',
                        },
                      }}
                    >
                      <Typography variant={'title'} fontSize={20} color={'#214A98'}>Smooth integration</Typography>
                      <Typography variant={'text'} fontSize={14}>Intuitive user interface and smooth integration between any ESPY solution and your intelligence infrastructure</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </CardContent>
            <CardActions></CardActions>
          </Card>
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Card sx={{
            minHeight: '270px',
          }}>
            <CardContent sx={{height: '100%', padding: '40px'}}>
              <Stack direction={'column'} spacing={2} alignItems={'center'}>
                <img style={{width: '100px', height: '100px'}} src={researcherImg} alt={''} />
                <Stack alignItems={'center'}>
                  <Typography variant={'title'} fontSize={20} color={'#214A98'} sx={{textTransform: 'uppercase'}}>Researcher</Typography>
                  <Typography variant={'text'} fontSize={14} textAlign={'center'}>Collects, analyses, and provides data-driven reports</Typography>
                </Stack>
              </Stack>
            </CardContent>
            <CardActions></CardActions>
          </Card>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Card sx={{
            minHeight: '270px',
          }}>
            <CardContent sx={{height: '100%', padding: '40px'}}>
              <Stack direction={'column'} spacing={2} alignItems={'center'}>
                <img style={{width: '100px', height: '100px'}} src={investigatorImg} alt={''} />
                <Stack alignItems={'center'}>
                  <Typography variant={'title'} fontSize={20} color={'#214A98'} sx={{textTransform: 'uppercase'}}>Investigator</Typography>
                  <Typography variant={'text'} fontSize={14} textAlign={'center'}>Utilizes the data and platform functionality to solve cases</Typography>
                </Stack>
              </Stack>
            </CardContent>
            <CardActions></CardActions>
          </Card>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Card sx={{
            minHeight: '270px',
          }}>
            <CardContent sx={{height: '100%', padding: '40px'}}>
              <Stack direction={'column'} spacing={2} alignItems={'center'}>
                <img style={{width: '100px', height: '100px'}} src={dataAnalystImg} alt={''} />
                <Stack alignItems={'center'}>
                  <Typography variant={'title'} fontSize={20} color={'#214A98'} sx={{textTransform: 'uppercase'}} textAlign={'center'}>Data Analyst</Typography>
                  <Typography variant={'text'} fontSize={14} textAlign={'center'}>Inspects, transforms, and models data to discover useful information</Typography>
                </Stack>
              </Stack>
            </CardContent>
            <CardActions></CardActions>
          </Card>
        </Grid>


        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Card sx={{
            minHeight: '288px',
            background: '#09153C',
            padding: 0,
          }}>
            <CardContent sx={{height: '100%', padding: '40px'}}>
              <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <Stack>
                  <Typography variant={'title'} fontSize={32} color={'#FFFFFF'} sx={{textTransform: 'uppercase'}}>Profiler</Typography>
                  <Typography variant={'text'} fontSize={14} color={'#FFFFFF'}>Generate highly accurate and reliable profiling data, from dozens of open sources in real-time</Typography>
                  <List>
                    <ListItem color={'#FFFFFF'} sx={{padding: '0px'}}>
                      <ListItemIcon sx={{minWidth: '35px'}}>
                        <img src={profilerBullet1} alt={''} />
                      </ListItemIcon>
                      <ListItemText
                        primary="Produce detailed identity profiles"
                        primaryTypographyProps={{
                          fontSize: 14,
                          color: '#FFFFFF',
                        }}
                      />
                    </ListItem>
                    <ListItem color={'#FFFFFF'} sx={{padding: '0px'}}>
                      <ListItemIcon sx={{minWidth: '35px'}}>
                        <img src={profilerBullet2} alt={''} />
                      </ListItemIcon>
                      <ListItemText
                        primary="Track suspect activity across the web"
                        primaryTypographyProps={{
                          fontSize: 14,
                          color: '#FFFFFF',
                        }}
                      />
                    </ListItem>
                    <ListItem color={'#FFFFFF'} sx={{padding: '0px'}}>
                      <ListItemIcon sx={{minWidth: '35px'}}>
                        <img src={profilerBullet3} alt={''} />
                      </ListItemIcon>
                      <ListItemText
                        primary="Detect suspicious social connections"
                        primaryTypographyProps={{
                          fontSize: 14,
                          color: '#FFFFFF',
                        }}
                      />
                    </ListItem>
                  </List>
                </Stack>
                <img style={{}} src={profilerImg} alt={''} />
              </Stack>
            </CardContent>
            <CardActions></CardActions>
          </Card>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Card sx={{
            minHeight: '288px',
            background: '#09153C',
            padding: 0,
          }}>
            <CardContent sx={{height: '100%', padding: '40px'}}>
              <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <Stack>
                  <Typography variant={'title'} fontSize={32} color={'#FFFFFF'} sx={{textTransform: 'uppercase'}}>Web Intelligence</Typography>
                  <Typography variant={'text'} fontSize={14} color={'#FFFFFF'}>Monitor keywords, hashtags, and online groups to perform targeting-based profiling for all types of social media content and activities</Typography>
                  <List>
                    <ListItem color={'#FFFFFF'} sx={{padding: '0px'}}>
                      <ListItemIcon>
                        <img src={webintBullet1} alt={''} />
                      </ListItemIcon>
                      <ListItemText
                        primary="Monitor online communities"
                        primaryTypographyProps={{
                          fontSize: 14,
                          color: '#FFFFFF',
                        }}
                      />
                    </ListItem>
                    <ListItem color={'#FFFFFF'} sx={{padding: '0px'}}>
                      <ListItemIcon>
                        <img src={webintBullet2} alt={''} />
                      </ListItemIcon>
                      <ListItemText
                        primary="Identify key roles within groups"
                        primaryTypographyProps={{
                          fontSize: 14,
                          color: '#FFFFFF',
                        }}
                      />
                    </ListItem>
                    <ListItem color={'#FFFFFF'} sx={{padding: '0px'}}>
                      <ListItemIcon>
                        <img src={webintBullet3} alt={''} />
                      </ListItemIcon>
                      <ListItemText
                        primary="Detect targets within social community"
                        primaryTypographyProps={{
                          fontSize: 14,
                          color: '#FFFFFF',
                        }}
                      />
                    </ListItem>
                  </List>
                </Stack>
                <img style={{}} src={webintImg} alt={webintImg} />
              </Stack>
            </CardContent>
            <CardActions></CardActions>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{
            minHeight: '188px',
            background: '#2E97D5',
            padding: 0,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <CardContent sx={{
              height: '100%',
              width: "100%",
              paddingBottom: 0,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}>
              <Stack direction={'row'} sx={{justifyContent: 'center', alignItems: 'center'}} spacing={3}>
                <Stack sx={{[theme.breakpoints.down("xl")]: {display: 'none'}}}>
                  <img src={treeImg} alt={''} />
                </Stack>
                <Stack direction={'column'} sx={{alignItems: 'center'}} spacing={2}>
                  <Typography
                    fontSize={16}
                    color={'#FFFFFF'}
                    sx={{fontFamily: 'Roboto, sans-serif', fontWeight: 400}}
                  >Turn a single data point into detailed and reliable identity reports</Typography>
                  <OsintInvestigationButton onClick={handleGoWebint}>Start an investigation</OsintInvestigationButton>
                </Stack>
                <Stack sx={{[theme.breakpoints.down("xl")]: {display: 'none'}}}>
                  <img style={{marginLeft: '70px'}} src={folderImg} alt={''} />
                </Stack>
              </Stack>
            </CardContent>
            <CardActions></CardActions>
          </Card>
        </Grid>


      </Grid>

    </PrivatePage>
  )
}
