import { E164Number } from "../../component/InputPhone"
import { BlackListAction} from "./actionType"


export const checkEmailBlacklist = (email: string, ipAddress: string, callback: Function): BlackListAction => ({ 
  type: 'blacklist/CHECK_EMAIL_BLACKLIST',
  email,
  ipAddress,
  callback,
})

//Phones

export const checkPhoneBlacklist = (phone: E164Number, callback: Function): BlackListAction => ({
  type: 'blacklist/CHECK_PHONE_BLACKLIST',
  phone,
  callback,
})  

export const checkIpAddressBlacklist = (ipAddress: string, callback: Function): BlackListAction => ({
  type: 'blacklist/CHECK_IP_ADDRESS_BLACKLIST',
  ipAddress,
  callback,
})  