import * as React from 'react'
import Joi from 'joi'
import { t } from 'i18next'
import {
  Close as CloseIcon,
  ExpandMore as SelectIcon,
} from '@mui/icons-material'
import support_ from '../../sass/support.module.sass'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextareaAutosize,
  Typography,
} from '@mui/material'
import { FormError } from '../../component/FormError'
import { ErrorsState, select, validateForm } from '../../function/validation'
import { useDispatch, useSelector } from '../../store'
import { InputDropzone } from '../../component/InputDropzone'
import { getTicketTypes, postTicket } from '../../store/support/actionCreator'
import { clearFieldError, showSnackbar } from '../../store/common/actionCreator'
import { TableRef } from '../../component/datatable/types'
import { FilePreview } from '../../component/support/FilePreview'
import { Attachment } from '../../store/support/reducer'

const schema = Joi.object({
  title: Joi.string().required().max(200).label(t('field.label.ticketName')),
  type: select.label(t('field.label.ticketType')),
  summary: Joi.string()
    .required()
    .label(t('field.label.summary')),
  attachment: Joi.array(),
})

type Props = {
  tableRef: React.RefObject<TableRef>
  onClose: () => void
}

export const PopupTicketCreate = ({ tableRef, onClose }: Props) => {
  const dispatch = useDispatch()

  const { ticketTypes } = useSelector((state) => state.support)

  React.useEffect(() => {
    dispatch(getTicketTypes)
  }, [dispatch])

  const [title, setTitle] = React.useState('')
  const [type, setType] = React.useState('null')
  const [summary, setSummary] = React.useState('')
  const [attachment, setAttachment] = React.useState<File[]>([])
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  const attachmentObjectUrls = React.useMemo(() => {
    return attachment.map((el) => {
      const file = new Blob([el], { type: el.type })
      return {
        fileName: el.name,
        url: URL.createObjectURL(file),
        isObjectUrl: true,
      }
    })
  }, [attachment])

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [dispatch, title, type, summary])

  function handleUpload(files: File[]) {
    files.forEach((el) => {
      setAttachment((state) => {
        if (state.length === 3) {
          dispatch(
            showSnackbar('error', 'Maximum number of uploading files is 3'),
          )
          return state
        } else return [...state, el]
      })
    })
  }

  function handleFileDelete(attachment: Attachment) {
    setAttachment((state) => [
      ...state.filter((el) => el.name !== attachment.fileName),
    ])
  }

  function handleSubmit() {
    const error = validateForm(schema, {
      title: title.trim(),
      type,
      summary: summary.trim(),
    })

    if (error) setErrors(error)
    else {
      setErrors(null)
      const formData = new FormData()

      formData.append('title', title)
      formData.append('ticketTypeId', type)
      formData.append('summary', summary)
      if (attachment[0]) formData.append('files', attachment[0])
      if (attachment[1]) formData.append('files', attachment[1])
      if (attachment[2]) formData.append('files', attachment[2])

      dispatch(postTicket(formData, () => tableRef.current?.refresh()))

      onClose()
    }
  }

  return (
    <Dialog open={true} fullWidth onClose={onClose}>
      <DialogTitle>
        {t('support.createTicket')}
        <IconButton className="button__close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Divider variant="popup" color="primary" />

      <Stack spacing={2}>
        {/** Ticket name */}
        <FormControl fullWidth>
          <FormLabel required>{t('field.label.ticketName')}</FormLabel>
          <OutlinedInput
            value={title}
            error={Boolean(errors?.title)}
            placeholder={t('field.hint.ticketName')}
            onChange={(e) => setTitle(e.target.value)}
          />
          <FormError error={errors?.title} />
        </FormControl>
        {/** Type */}
        <FormControl>
          <FormLabel required>{t('field.label.ticketType')}</FormLabel>
          <Select
            value={type}
            error={Boolean(errors?.type)}
            IconComponent={SelectIcon}
            onChange={(e) => setType(e.target.value)}
          >
            <MenuItem disabled value="null">
              <em>{t('field.hint.ticketType')}</em>
            </MenuItem>
            {ticketTypes?.map((el) => (
              <MenuItem key={el.id} value={el.id}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Box
                    className={support_.ticket}
                    sx={{ background: el.color }}
                  />
                  <Typography sx={{ textTransform: 'capitalize' }}>
                    {el.name}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </Select>
          <FormError error={errors?.type} />
        </FormControl>
        {/** Summary */}
        <FormControl>
          <FormLabel required>{t('field.label.summary')}</FormLabel>
          <TextareaAutosize
            className={
              errors?.summary ? 'form__textarea_error' : 'form__textarea'
            }
            minRows={2}
            placeholder={t('field.hint.summary')}
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
          />
          <FormError error={errors?.summary} />
        </FormControl>
        {/** Attachment */}
        <FormControl>
          <Stack direction="row" alignItems="center" spacing={1}>
            <FormLabel>{t('field.label.attachment')}</FormLabel>
            <Typography variant="bold" color="secondary">
              (Optional)
            </Typography>
          </Stack>
          <InputDropzone onUpload={handleUpload} />
          <Box mt={1}>
            <Stack direction="row" flexWrap="wrap" spacing={1}>
              {attachmentObjectUrls.map((el, idx) => (
                <FilePreview
                  key={idx}
                  attachment={el}
                  onDelete={handleFileDelete}
                />
              ))}
            </Stack>
          </Box>
          <FormError error={errors?.attachment} />
        </FormControl>
      </Stack>

      <Divider variant="popup" color="primary" />

      <DialogActions sx={{ justifyContent: 'right' }}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {t('support.createTicket')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
