import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent, CardMedia, Grid, IconButton, Popover, Stack, Typography } from '@mui/material'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import CommentIcon from '@mui/icons-material/Comment'
import CommentItem from './CommentItem'
import { ImageCarousel } from '../../../../component/ImageCarousel'
import { Marker } from 'react-mark.js'
import { OpenInNew } from '@mui/icons-material'

type Props = {
  el?: any,
  searchValue: string
}

const PostItem = (props: Props) => {
  const {el, searchValue} = props

  //state
  const images = props.el.images_url
  const [currentImage, setCurrentImage] = useState()
  const [currentVideo, setCurrentVideo] = useState()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  //effects
  useEffect(() => {
    if (el?.images_url?.length > 0) {
      setCurrentImage(el?.images_url?.[0])
    } else if (el?.videos_url?.length > 0) {
      setCurrentVideo(el?.videos_url?.[0])
    }
  }, [el])

  //handlers
  const handleLikeClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  
  if (el && el.timeStamp){
    el.timeStamp = (el.timeStamp as string).replace("00:00:00", "").split(".")[0]
  }

  return <Card
    sx={{
      display: "flex",
      backgroundColor: "white",
      marginBottom: '10px',
      flexDirection: 'row',
      padding: '10px',
    }}
  >
    {
      images && images.length > 0 && <ImageCarousel images={images} imagesPerPage={1}/>
    }
    {/* {
      currentImage && <CardMedia
        component="img"
        sx={{ height: 250, width: 250, borderRadius: "16px", padding: "1%", flexShrink: 0 }}
        image={currentImage}
        alt=""
      />
    } */}
    {
      currentVideo && <CardMedia
        component='video'
        sx={{ height: 250, width: 250, borderRadius: "16px", padding: "1%", flexShrink: 0 }}
        image={currentVideo}
        autoPlay={false}
        controls
      />
    }
    <Box
      sx={{ display: "flex", flexDirection: "column", borderRadius: "16px", flexShrink: 1, width: '100%' }}
    >
      <CardContent sx={{ flex: "1 0 auto",  margin: "16px" }}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}><Marker as={'span'} mark={searchValue}>{el.text}</Marker></Typography>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          component="div"
        ></Typography>
        {/*<Stack direction={'row'} gap={'3px'}>
          {
            el?.images_url?.map((img: string) => <img src={img} title={''} style={{width: '50px', height: '50px', objectFit: 'cover', border: '1px solid lightgrey', borderRadius: '5px'}} />)
          }
        </Stack>*/}
      </CardContent>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexDirection: "row",
          borderRadius: "16px",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "16px",
            marginTop: "2px",
          }}
        >
          <Typography sx={{ fontSize: 16, fontWeight: 600 }}><Marker as={'span'} mark={searchValue}>{el.profile_id}</ Marker></Typography>
          <Typography
            variant="subtitle1"
            color="#AAADB2"
            sx={{ fontSize: 14 }}
          >
            <Marker as={'span'} mark={searchValue}>
              {el?.timeStamp}
            </Marker>
          </Typography>
        </Box>
        {
          el?.comments?.length > 0 && <Box>
            <IconButton aria-label="delete" onClick={handleLikeClick}>
              {/* <ThumbUpOffAltIcon /> */}
              <CommentIcon />
            </IconButton>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              sx={{'.MuiPaper-rounded': {borderRadius: '16px'}}}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", borderRadius: "16px", maxWidth: '350px', maxHeight: '400px', overflowY: 'auto' }}
                className={'custom-scroll'}
              >
                {
                  el.comments.map((comment: any) => <CommentItem comment={comment} />)
                }
              </Box>
            </Popover>
          </Box>
        }
        {
          el.post_url && <a href={el.post_url} target="_blank"><IconButton 
            sx={{outline:'#FFFF00 ' + (searchValue.length > 0 && el.post_url.toLowerCase().includes(searchValue.toLowerCase()) ? "solid" : "none") + ' 5px'}}
          >
              <OpenInNew/>
          </IconButton></a>
        }
      </Box>
    </Box>
  </Card>
}

export default PostItem