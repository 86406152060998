import React from 'react'
import { Box, Divider, Grid, Stack, Tab, Tabs, Typography } from '@mui/material'
import { PhoneDataItem, SearchResult } from '../../../../store/searcher/reducer'
import { getLabel, RenderProfileData } from '../../../../component/searcher/RenderData'
import { renderComplexItem } from '../../../../function/string'
import PostItem from './PostItem'

type Props = {
  el?: any
}
type objType = {
  [key: string]: object
}
const exceptions = ['dateRange']
const dateFields = ['dateRange', 'validSince', 'lastSeen']
const tabs = [
  {title: 'Person', field: 'person'},
]

const LunaTabItem = (props: Props) => {
  const {el} = props

  function renderSingleItem(title: string, value: any){
    const isArray = (Array.isArray(value) && value.length > 0)
    return (
      value
      && !exceptions.includes(title)
      && (!Array.isArray(value) || isArray)
    ) ? <Grid item xs={12}>
      <Typography variant="text" fontSize={14} sx={{marginRight: '10px'}}>{getLabel(title)}</Typography>
      <Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{isArray ? renderArrayItems(title, value) : renderComplexItem(value, title, dateFields)}</Typography>
    </Grid> : null
  }

  function renderArrayItems(title: string, value: any[]){
    return value.map((someObj) => (
      (
        typeof someObj === "object" &&
        Object.entries(someObj).map(([key, val]) => renderSingleItem(key, val))
      ) || (
        typeof someObj !== "object" &&
        renderSingleItem(title, someObj)
      )
    ))
  }

  function displayArrayOrObject(el: any, field: string, isArray: boolean){
    let displayLunaArray
    if (isArray){
      displayLunaArray = el?.[field]
    }else {
      if (el && el?.[field] && Object.values(el?.[field]).length > 0){
        displayLunaArray = Object.values(el?.[field])
      }else {
        displayLunaArray = []
      }
    }
    if (displayLunaArray.length > 0){
      return displayLunaArray.map((breach: any, idx: number) => 
        <Grid container key={idx} sx={{borderBottom: '1px solid lightgray', marginBottom: '10px', paddingBottom: '10px', '&:last-child': {borderBottom: 'none'}}}>
          {
            Object.entries(breach).map(([title, value]) =>
              renderSingleItem(title, value),
            )
          }
        </Grid>,
      )
    } else {
      return <></>
    }
  }
  return <Box sx={{ p: 3 }}>
    {
      ['addresses', 'educations', 'emails', 'jobs', 'languages', 'names', 'phones', 'relationships', 'urls', 'userIds', 'usernames'].map(field =>
        (
          el?.[field]
          && ((!Array.isArray(el?.[field]) && Object.values(el?.[field]).length > 0) || (Array.isArray(el?.[field]) && el?.[field]?.length > 0))
        ) && <>
            <Grid item xs={12} >
              <Grid container sx={{display: 'flex'}}>
                <Grid item xs={2}>
                  <Typography variant="text" fontSize={14}>{getLabel(field)}</Typography>
                </Grid>
                <Grid item xs={10}>
                  {
                    displayArrayOrObject(el, field, Array.isArray(el?.[field]))
                  }
                </Grid>
              </Grid>
            </Grid>
            <Divider
              sx={{
                width: "100%",
                margin: '5px 0px 15px 0px',
                border: `0.1px solid rgba(46, 151, 213, 0.3)`,
              }}
            />
          </>,
      )
    }
  </Box>
}

const LunaItem = (props: Props) => {
  const {el} = props
  const {person} = el.data[el.from]
  let {sources} = el.data[el.from]
  if (sources && !Array.isArray(sources)){
    sources = Object.values(sources)
  }

  //state
  const [currentTab, setCurrentTab] = React.useState(0)

  //handlers
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  if (person || sources?.length > 0) {
    return <Stack>
      <Box sx={{padding: '20px 0'}}>
        <Tabs value={currentTab} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab label={"Person"} id={`simple-tab-0`} aria-controls={`simple-tabpanel-0`} />
          {
            sources.length > 0 && <Tab label={"Sources"} id={`simple-tab-1`} aria-controls={`simple-tabpanel-1`} />
          }
        </Tabs>
      </Box>

      {
        person && <div role="tabpanel" hidden={currentTab !== 0} id={`simple-tabpanel-0`} aria-labelledby={`simple-tab-0`}>
          {currentTab === 0 && (
            <LunaTabItem el={person} />
          )}
        </div>
      }
      {
        sources.length > 0 && <div role="tabpanel" hidden={currentTab !== 1} id={`simple-tabpanel-1`} aria-labelledby={`simple-tab-1`}>
          {currentTab === 1 && (
            <Stack>
              {
                sources.slice(0, 20).map((source: any, idx: number) => <>
                  <Typography variant="h3" fontSize={18} sx={{padding: '20px 0'}}>{source?.name ? source.name : `Source ${idx + 1}`}</Typography>
                  <LunaTabItem el={source} />
                </>)
              }
            </Stack>
          )}
        </div>
      }

    </Stack>
  }
  return null
}

export default LunaItem