import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from '../store'
import { deleteUser } from '../store/settings/actionCreator'
import { Box, Button, Card, Grid, Typography } from '@mui/material'
import { FormPersonalInfo } from '../container/settings/FormPersonalInfo'
import { FormOther } from '../container/settings/FormOther'
import { PrivatePage } from '../component/PagePrivate'
import { Avatar } from '../component/Avatar'
import { PopupAsk } from '../component/PopupAsk'
import { Dialog2FAInput } from '../container/settings/Dialog2FAInput'
import { visitPage } from './visit-page-loger/visit-page-loger-segment'

export function SettingsPage() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const user = useSelector((state) => state.settings)

  React.useEffect(() => {
    if (user.uid) {
      visitPage(user.uid, "/settings")
    }
  }, [])
  
  const [sureDeleteOpen, setSureDeleteOpen] = React.useState(false)
  const [twoFAInputOpen, setTwoFAInputOpen] = React.useState(false)

  const handleDelete = () => {
    if (user.is2FAEnabled) setTwoFAInputOpen(true)
    else setSureDeleteOpen(true)
  }

  return (
    <PrivatePage>
      <Typography variant="title">{t('settings.title')}</Typography>

      <Card>
        <Grid container>
          {/*LEFT SIDE*/}
          <Grid item xs={12} lg={7}>
            <Grid container direction="column">
              <Typography variant="semiBold" fontSize={16}>
                {t('settings.editProfile')}
              </Typography>

              <Grid item>
                <Box my={2}>
                  <Avatar size="large" upload />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/*RIGHT SIDE*/}
          <Grid item xs={12} lg={5}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button variant="outlined" color="error" onClick={handleDelete}>
                  {t('settings.deleteAccount')}
                </Button>
                {sureDeleteOpen && (
                  <PopupAsk
                    onYes={() => dispatch(deleteUser)}
                    onClose={() => setSureDeleteOpen(false)}
                  />
                )}
                {twoFAInputOpen && (
                  <Dialog2FAInput
                    mode="deleteUser"
                    onClose={() => setTwoFAInputOpen(false)}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          {/*LEFT SIDE*/}
          <Grid item xs={12} lg={7}>
            <FormPersonalInfo />
          </Grid>
          {/*RIGHT SIDE*/}
          <Grid item xs={12} lg={5}>
            <FormOther />
          </Grid>
        </Grid>
      </Card>
    </PrivatePage>
  )
}
