import React from 'react'
import { Checkbox, Stack, TableCell, TableHead, TableRow } from '@mui/material'
import { useTableStyles } from '../hook/useStyles'
import { colorSecondaryDark } from '../../../mui'
import { SvgIcon } from '../../SvgIcon'
import { HeaderData, SortDirections } from '../types'

type Props = {
  headerData: HeaderData[]
  sortKey?: string
  sortDirections?: SortDirections
  selectable?: boolean
  isPagesRowsSelected?: boolean
  onSort?: (key: string) => void
  onSelectAll?: () => void
}

const sortIconSize = { width: 10, height: 10 }

export function Header(props: Props) {
  const {
    headerData,
    sortKey,
    sortDirections,
    selectable,
    isPagesRowsSelected,
    onSort,
    onSelectAll,
  } = props

  const classes = useTableStyles()

  function handleSort(column: HeaderData) {
    if (column.sortable && onSort) onSort(column.key)
  }

  return (
    <TableHead>
      <TableRow>
        {selectable && (
          <TableCell classes={classes} padding="checkbox">
            <Checkbox checked={isPagesRowsSelected} onClick={onSelectAll} />
          </TableCell>
        )}
        {headerData.map((el) => (
          <TableCell
            key={el.key}
            classes={classes}
            className={el?.className}
            style={el.style}
            onClick={() => handleSort(el)}
          >
            <Stack
              direction="row"
              alignItems="center"
              sx={{ cursor: el.sortable ? 'pointer' : 'text' }}
            >
              <span>{el.text}</span>
              {el.sortable && sortDirections && (
                <Stack ml={1}>
                  {el.key === sortKey ? (
                    sortDirections[el.key] === 'DESC' ? (
                      <SvgIcon
                        name="sortDown"
                        color={colorSecondaryDark}
                        size={sortIconSize}
                      />
                    ) : (
                      <SvgIcon
                        name="sortUp"
                        color={colorSecondaryDark}
                        size={sortIconSize}
                        style={{transform: "rotate(180deg)"}}
                      />
                    )
                  ) : (
                    <>
                      <SvgIcon
                        name="sortUp"
                        color={colorSecondaryDark}
                        size={sortIconSize}
                        style={{transform: "rotate(180deg)"}}
                      />
                      <SvgIcon
                        name="sortDown"
                        color={colorSecondaryDark}
                        size={sortIconSize}
                        style={{ marginTop: 1 }}
                      />
                    </>
                  )}
                </Stack>
              )}
            </Stack>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
