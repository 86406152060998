import { call, put, takeEvery } from 'redux-saga/effects'
import { api, Response } from '../../function/axios'
import { handleError } from '../../function/error'
import * as actionType from './actionType'
import * as actionCreator from './actionCreator'
import { showSnackbar } from '../common/actionCreator'

function* getDeveloperVideo() {
  try {
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: '/developer-information',
      responseType: 'blob',
    })

    yield put(actionCreator.setDeveloperVideo(data))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* get() {
  try {
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: '/api-keys',
    })

    yield put(actionCreator.setApikey(data.apiKey))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* generateKeys(action: actionType.GenerateKey) {
  try {
    const response: Response = yield call(api, {
      method: 'GET',
      url: `/api-keys/generate`,
      params: {
        length: action.length,
      },
    })

    yield put(actionCreator.setGeneratedKeys(response.data))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* post(action: actionType.PostApikey) {
  try {
    const { data }: Response = yield call(api, {
      method: 'POST',
      url: '/api-keys',
      body: action.data,
    })

    yield put(actionCreator.fetchApikey)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* edit(action: actionType.EditApikey) {
  const { id, ...restData } = action.data

  try {
    const { data }: Response = yield call(api, {
      method: 'PUT',
      url: `/api-keys/${id}`,
      body: restData,
    })

    yield put(actionCreator.fetchApikey)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* _delete(action: actionType.DeleteApikey) {
  try {
    const { data }: Response = yield call(api, {
      method: 'DELETE',
      url: `/api-keys/${action.id}`,
    })

    yield put(actionCreator.fetchApikey)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

export function* watchApikey() {
  yield takeEvery(actionType.GET_DEVELOPER_VIDEO, getDeveloperVideo)
  yield takeEvery(actionType.GET_APIKEY, get)
  yield takeEvery(actionType.GENERATE_KEYS, generateKeys)
  yield takeEvery(actionType.POST_APIKEY, post)
  yield takeEvery(actionType.EDIT_APIKEY, edit)
  yield takeEvery(actionType.DELETE_APIKEY, _delete)
}
