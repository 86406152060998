import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export const useSocialStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 44,
    },
    google: {
      border: '1px solid',
      borderColor: theme.palette.secondary.main,
    },
    facebook: {
      background: '#3B5998',
      '&:hover': {
        background: '#4c70c4',
      },
    },
    microsoft: {
      background: '#00ADEF',
      '&:hover': {
        background: '#24c3ff',
      },
    },
  }),
)
