import React from 'react'

import { Box, Grid, IconButton, Typography, useTheme } from '@mui/material'
import { IpPhoneGeolocationItem, SearchResult } from '../../../store/searcher/reducer'
import { prepareIpPhoneGeolocationResult } from '../../../store/searcher/service'
import sprite_ from '../../../sass/sprite.module.sass'
import ReactCountryFlag from 'react-country-flag'
import VerifiedIcon from '@mui/icons-material/Verified'
import { NewReleases } from '@mui/icons-material'

type Props = {
  apiData: SearchResult
}

export function IpPhoneGeolocationCommon(props: Props) {
  const theme = useTheme()
  const result = prepareIpPhoneGeolocationResult(props.apiData)

  const fromSource = JSON.parse(JSON.stringify(props.apiData))
  const sourceName = fromSource.apiDataEntities 
    && Array.isArray(fromSource.apiDataEntities) 
      && fromSource.apiDataEntities[0]
       && fromSource.apiDataEntities[0].from ? fromSource.apiDataEntities[0].from : "No data"
  return (
    
      <Grid container spacing={0}>
        {
          result.data && result.data?.map((el: IpPhoneGeolocationItem, idx: number) => {
            if (el && sourceName === 'IP_GEO_PHONE_VALIDATION') {
              return (
              <>
                  <Grid item xs={3} >Information About IP</Grid>
                  <Grid item xs={3} > 
                            <Typography variant="semiBold">
                              IP Address:
                            </Typography>
                  </Grid>
                  <Grid item xs={3}> 
                            <Typography variant="semiBold">
                              {el.ip || "Unknown"}
                            </Typography>
                  </Grid>
                  <Grid item xs={1} container justifyContent="center" alignItems="center"> 
                            <Typography variant="semiBold">
                            <Box
                                    sx={{
                                      marginBottom: '10px !important',
                                      [theme.breakpoints.only("xs")]: {
                                        marginLeft: '2px!important',
                                        marginRight: '1px',
                                      },
                                    }}
                                    key={'web_collection_phone'}
                                    className={sprite_['social__large_' + 'web_collection_phone']}
                                  />
                            </Typography>
                  </Grid>
                  <Grid item xs={1} container justifyContent="center" alignItems="center" > 
                            <Typography variant="semiBold">
                              
                            </Typography>
                  </Grid>
                  <Grid item xs={1} container justifyContent="center" alignItems="center"> 
                            <Typography variant="semiBold">
                            <Box
                                    sx={{
                                      marginBottom: '10px !important',
                                      [theme.breakpoints.only("xs")]: {
                                        marginLeft: '2px!important',
                                        marginRight: '1px',
                                      },
                                    }}
                                    key={'geolocation_number'}
                                    className={sprite_['social__large_' + 'geolocation_number']}
                                  />
                            </Typography>
                  </Grid>
                  
                  <Grid item xs={3} ></Grid>
                  <Grid item xs={3} > 
                            <Typography variant="semiBold">
                              IP Country:
                            </Typography>
                  </Grid>
                  <Grid item xs={3} > 
                            <Typography variant="semiBold">
                              {el.ipCountry|| "Unknown"}
                            </Typography>
                  </Grid>
                  <Grid item xs={1} container justifyContent="center" alignItems="center"> 
                    {
                      el.phoneCountryCode ?
                        <ReactCountryFlag
                          className="emojiFlag"
                          svg
                          cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                          cdnSuffix="svg"
                          countryCode={el.phoneCountryCode}
                          style={{
                            fontSize: '3em',
                            lineHeight: '0.5',
                          }} /> :
                        <Box
                          sx={{
                            marginBottom: '10px !important',
                            [theme.breakpoints.only("xs")]: {
                              marginLeft: '2px!important',
                              marginRight: '1px',
                            },
                          }}
                          key={'country_fullname'}
                          className={sprite_['social__large_' + 'country_fullname']}
                        />
                    }
                  </Grid>
                  <Grid item xs={1} container justifyContent="center" alignItems="center" > 
                              {
                                el.phoneCountryCode && el.ipCountryCode && el.phoneCountryCode === el.ipCountryCode ?
                                <IconButton>
                                <VerifiedIcon sx={{ fontSize: 40 }} color={'success'}/>
                                </IconButton>
                                :
                                <IconButton>
                                <NewReleases sx={{ fontSize: 40 }} color={'error'}/>
                                </IconButton>
                              }
                  </Grid>
                  <Grid item xs={1} container justifyContent="center" alignItems="center"> 
                    {
                      el.ipCountryCode ?
                        <ReactCountryFlag 
                        className="emojiFlag" 
                          svg
                          cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                          cdnSuffix="svg"
                          countryCode={el.ipCountryCode} style={{
                            fontSize: '3em',
                            lineHeight: '0.5',
                          }} /> :
                        <Box
                          sx={{
                            marginBottom: '10px !important',
                            [theme.breakpoints.only("xs")]: {
                              marginLeft: '2px!important',
                              marginRight: '1px',
                            },
                          }}
                          key={'country_fullname'}
                          className={sprite_['social__large_' + 'country_fullname']}
                        />
                    }
                  </Grid>
                  

                  <Grid item xs={3} ></Grid>
                  <Grid item xs={3} > 
                            <Typography variant="semiBold">
                              IP Country Code:
                            </Typography>
                  </Grid>
                  <Grid item xs={6} > 
                            <Typography variant="semiBold">
                              {el.ipCountryCode|| "Unknown"}
                            </Typography>
                  </Grid>
                  
                  <Grid item xs={3} >Phone info:</Grid>
                  <Grid item xs={3} > 
                            <Typography variant="semiBold">
                              Phone:
                            </Typography>
                  </Grid>
                  <Grid item xs={6} > 
                            <Typography variant="semiBold">
                              {el.phone|| "Unknown"}
                            </Typography>
                  </Grid>

                  <Grid item xs={3} ></Grid>
                  <Grid item xs={3} > 
                            <Typography variant="semiBold">
                              Phone Country:
                            </Typography>
                  </Grid>
                  <Grid item xs={6} > 
                            <Typography variant="semiBold">
                              {el.phoneCountry|| "Unknown"}
                            </Typography>
                  </Grid>

                  <Grid item xs={3} ></Grid>
                  <Grid item xs={3} > 
                            <Typography variant="semiBold">
                              Phone Country Code:
                            </Typography>
                  </Grid>
                  <Grid item xs={6} > 
                            <Typography variant="semiBold">
                              {el.phoneCountryCode|| "Unknown"}
                            </Typography>
                  </Grid>

                  <Grid item xs={3}>Result:</Grid>

                  <Grid item xs={9} > 
                  {
                    ( el.status === false ?                             
                    <Typography variant="semiBold" style={{color: "red"}}>
                    IP Address is from {el.ipCountry || "Unknown"} but phone number is from {el.phoneCountry|| "Unknown"}
                    </Typography>
                    :
                    <Typography variant="semiBold" style={{color: "green"}}>
                    IP Address and Phone number are from the same country
                    </Typography>
                    )
                  }
                  </Grid>
              </>
              
              )
            } else {
              return (
              <>
                <Grid item xs={6} container justifyContent="center" alignItems="center" >Result:</Grid>
                <Grid item xs={6} container justifyContent="center" alignItems="center"> E-mail Domain Validation</Grid>

                <Grid item xs={6} container justifyContent="center" alignItems="center">
                  {
                    (el.status === false ?
                      <Typography variant="semiBold" style={{ color: "red" }}>
                        E-mail is not valid (E-mail domain is not exists or fake) 
                      </Typography>
                      :
                      <Typography variant="semiBold" style={{ color: "green" }}>
                       This E-mail is valid (E-mail domain exists)
                      </Typography>
                    )
                  }
                </Grid>
                <Grid item xs={6} container justifyContent="center" alignItems="center">
                    {
                      (el.status === false ?
                        <IconButton>
                          <NewReleases sx={{ fontSize: 40 }} color={'error'} />
                        </IconButton>
                        :
                        <IconButton>
                          <VerifiedIcon sx={{ fontSize: 40 }} color={'success'} />
                        </IconButton>
                      )
                    }
                </Grid>
              </> )
            } 
          })
        }
    </Grid>
    
  )
}
