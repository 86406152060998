import React, { MouseEventHandler } from 'react'
import Linkify from 'react-linkify'
import { format } from 'date-fns'
import { Avatar, Box, Grid, IconButton, Link, Stack, Typography, useTheme } from '@mui/material'
import { colorSecondaryLight } from '../../mui'
import { store } from '../../store'
import { TypographyVariant } from '../../type/mui'
import { getByBase64 } from '../../function/image'
import { CommonState } from '../../store/common/reducer'
import { truncate } from '../../function/string'
import emptyImage from '../../asset/icon/defaultUser.png'
import { Marker } from 'react-mark.js'
import sprite_ from '../../sass/sprite.module.sass'

export const imageKeys = [
  'thumbnail_link',
  'image_url',
  'user_comment_avatar_url',
  'photoUrl',
  'thumbnail',
  'avatar',
  'friendImageUrl',
  'gift_image_url',
  'group_thumbnail_url',
  'profileImageUrl',
  'school_image_url',
  'thumbnail_url',
]

export const nameLocalData = {
  vk: ['last_online_status'],
}

export const linkKeys = ['family_members']

export function getLabel(key: string) {
  const letter1 = key[0].toUpperCase()
  return (letter1 + key.slice(1)).replaceAll('_', ' ') + ' '
}

export function getObjectValue(object: any, key: string): any {
  const date = ['createdTime', 'online']
  const phone = ['criteria']
  const commonState: CommonState = store.getState().common
  const timeFormatWithSeconds = commonState.timeFormatWithSeconds

  let value

  // eslint-disable-next-line array-callback-return
  Object.keys(object).some((k) => {
    if (k === key) {
      value = object[k]

      if (date.includes(key) && value !== 0) {
        value = format(new Date(value), `dd/MM/yyyy ${timeFormatWithSeconds}`)
      } else {
        value = object[k]
      }

      return true
    }

    if (object[k] && typeof object[k] === 'object') {
      value = getObjectValue(object[k], key)
      return value !== undefined
    }
  })
  return value
}

export function getCorsImageUrl(url: string | undefined) {
  return url === 'NO AVATAR'
    ? ''
    : `${
        process.env.REACT_APP_API_BASE_URL
      }/searcher/image?url=${url?.replaceAll('&', '%26')}`
}

interface RenderImageProps {
  _key: string
  val: string
  imgHeight?: number | string,
  onClick?: MouseEventHandler<HTMLAnchorElement> | undefined,
}

export function RenderImage({ _key, val, imgHeight, onClick }: RenderImageProps) {
  let src = val

  if (val.match(/http|mycdn/)) {
    if (!val.startsWith('http')) {
      src = 'https://' + val
    }
  } else if (val === 'NO AVATAR') return <span>NO AVATAR</span>
  else if (val === '') return <span>null</span>
  else {
    src = getByBase64(val)
  }

  return (
    <a href={src} target="_blank" rel="noreferrer" style={{ display: 'block' }} onClick={onClick}>
      <img
        src={src}
        alt=""
        width="100%"
        height={imgHeight}
        style={{ objectFit: 'cover' }}
        onError={(e: any) => e.target.src = emptyImage}
      />
    </a>
  )
}

interface RenderLabelProps {
  label: string
  fontSize?: number,
  search?: string,
}

export function RenderLabel({ label, fontSize, search }: RenderLabelProps) {
  return (
    // если цифра и меньше 100
    !isNaN(label as any) && Number(label) < 100 ? (
      <Typography>- </Typography>
    ) : // если цифра и больше 100
    !isNaN(label as any) ? (
      <Typography fontSize={16}>
        <Marker as={'span'} mark={search}>{label}: </Marker>
      </Typography>
    ) : imageKeys.includes(label) ? null : (
      <Typography fontSize={fontSize}>
        <Marker as={'span'} mark={search}>{getLabel(label)}</Marker>
      </Typography>
    )
  )
}

interface RenderValueProps {
  label: string
  value?: string
  variant?: TypographyVariant
  source?: string,
  search?: string,
}

export function getHastagLink(
  hashtag: string,
  source: string | undefined,
): string {
  hashtag = hashtag.slice(1)
  let hashtagLink: string
  switch (source) {
    case 'vk':
      hashtagLink = `https://m.vk.com/feed?section=search&q=%23${hashtag}`
      break
    case 'twitter':
      hashtagLink = `https://twitter.com/hashtag/${hashtag}?src=hashtag_click`
      break
    case 'fb':
      hashtagLink = `https://www.facebook.com/hashtag/${hashtag}`
      break
    case 'instagram':
      hashtagLink = `https://www.instagram.com/explore/tags/${hashtag}`
      break
    default:
      hashtagLink = hashtag
      break
  }
  return hashtagLink
}

export function getVkLink(link: string): string {
  if (link.startsWith('https')) {
    return link
  }
  return `https://vk.com/${link}`
}

export const getJSXByWord = (
  word: string,
  source: string | undefined,
  idx: any,
  search?: string,
) => {
  if (word.startsWith('#')) {
    return (
      <React.Fragment key={idx}>
        <Link key={idx} target="_blank" href={getHastagLink(word, source)}>
          <Marker as={'span'} mark={search}>{word}</Marker>
        </Link>
      </React.Fragment>
    )
  }
  if (
    word.startsWith('@') &&
    (source === 'twitter' || source === 'instagram')
  ) {
    const mentionUrls = {
      twitter: `https://twitter.com/${word}`,
      instagram: `https://www.instagram.com/${word.substr(1)}`,
    }
    return (
      <React.Fragment key={idx}>
        <Link key={idx} target="_blank" href={`${mentionUrls[source]}`}>
          <Marker as={'span'} mark={search}>{word}</Marker>
        </Link>
      </React.Fragment>
    )
  }

  if (
    word.startsWith('[id') ||
    word.startsWith('[club') ||
    word.startsWith('[https')
  ) {
    let [link, name] = word.split('|')
    link = link.replace(/-/g, '').replace('[', '')
    name = name.replace(/-/g, ' ').replace(']', '')
    return (
      <React.Fragment key={idx}>
        <Link key={idx} target="_blank" href={getVkLink(link)}>
          <Marker as={'span'} mark={search}>{name}</Marker>
        </Link>
      </React.Fragment>
    )
  }
  return <Marker as={'span'} mark={search}>{' ' + word}</Marker>
}

export function RenderValue({
  label,
  value,
  variant,
  source,
  search,
}: RenderValueProps) {
  const linkComponentDecorator = (
    decoratedHref: string,
    decoratedText: string,
    key: number,
  ) => (
    <a target="blank" href={decoratedHref} key={key}>
      <Marker as={'span'} mark={search}>{truncate(decoratedText, 30)}</Marker>
    </a>
  )

  let lines: any = value?.replace(/# /g, '#')
  lines = lines?.replace(/\s+(?=[^[\]]*\])/g, '-').split('\n')

  return (
    <>
      {value ? (
        imageKeys.includes(label) ? (
          <Avatar src={value} />
        ) : (
          <Linkify componentDecorator={linkComponentDecorator}>
            {lines.map((line: any, idx: number) =>
              line === '' ? (
                <br key={idx} />
              ) : (
                <React.Fragment key={idx}>
                  <Typography variant={variant ? variant : 'semiBold'}>
                    {line?.split(' ').map((word: string, idx: number) => {
                      return getJSXByWord(word, source, idx, search)
                    })}
                  </Typography>
                </React.Fragment>
              ),
            )}
          </Linkify>
        )
      ) : (
        <Typography variant="semiBold">-</Typography>
      )}
    </>
  )
}

export function RenderHTMLValue({value, search}: RenderValueProps) {
  const linkComponentDecorator = (
    decoratedHref: string,
    decoratedText: string,
    key: number,
  ) => (
    <a target="blank" href={decoratedHref} key={key}>
      <Marker as={'span'} mark={search}>{truncate(decoratedText, 30)}</Marker>
    </a>
  )

  return (
    <>
      {
        value
          ? <Linkify componentDecorator={linkComponentDecorator}>{value}</Linkify>
          : <Typography variant="semiBold">-</Typography>
      }
    </>
  )
}

interface RenderProfileDataProps {
  data: object
  isRecursive?: boolean
  fullData?: any,
  search?: string,
}

const customFields = {
  vk: {
    'Personal information': [
      'last_online_status',
      'profileSex',
      'total_friends_count',
    ],
  },
}

const cardFields = {
  ok: [
    {
      field: 'Subscribers',
      cardImage: 'subscriber_image_url',
      cardTitle: 'subscriber_name',
      cardAddress: 'subscriber_address',
      cardInfo:  'subscriber_age',
      cardUrl: '',
    },
  ],
  instagram: [
    {
      field: 'highlights',
      cardImage: 'thumbnail_url',
      cardTitle: 'highlight_name',
      cardAddress: '',
      cardInfo:  '',
      cardUrl: '',
    },
    {
      field: 'stories',
      cardImage: 'thumbnail_url',
      cardTitle: 'video_url',
      cardAddress: '',
      cardInfo:  '',
      cardUrl: 'video_url',
    },
  ],
}

interface CardField {
  field: string,
  cardImage: string,
  cardTitle: string,
  cardAddress: string,
  cardInfo: string,
  cardUrl: string
}

export function RenderProfileData({
  data,
  isRecursive,
  fullData,
  search,
}: RenderProfileDataProps) {
  const theme = useTheme()

  function renderUrl(card: CardField | undefined, val: any | undefined){
    if (card && val){
      if (card.cardUrl in val && val[card.cardUrl] && (val[card.cardUrl] as string).trim().length > 0){
        return (
          <a href={val[card.cardUrl] || ''}>
            <Typography variant="bold">{val[card.cardTitle] || ''}</Typography>
          </a>
        )
      } else if (card.field === 'stories' && card.cardImage in val){
        return <a href={val[card.cardImage] || ''}>
            <Typography variant="bold">{val[card.cardImage] || ''}</Typography>
          </a>
      }
      else {
        return (<Typography variant="bold">{val[card.cardTitle] || ''}</Typography>)
      } 
    }
  }

  function renderClickableAvatarOrRegularAvatar(card:CardField | undefined, val: any | undefined){
    if (card && val){
      if (card.field !== 'highlights' && card.cardImage in val){
        return (
          <IconButton
            onClick={() => window.open((val as any)[card?.cardImage as string])}
            sx={{padding: '0px'}}
          >
            <Avatar
              src={getCorsImageUrl((val as any)[card?.cardImage as string])}
              sx={{
                height: 50,
                width: 50,
                [theme.breakpoints.only("xs")]: {
                  width: 45,
                  height: 45,
                },
              }}
            />
          </IconButton>
        )
      } else {
        return (
          <Avatar
              src={getCorsImageUrl((val as any)[card?.cardImage as string])}
              sx={{
                height: 50,
                width: 50,
                [theme.breakpoints.only("xs")]: {
                  width: 45,
                  height: 45,
                },
              }}
            />
        )
      }
    }
  }

  return (
    <Grid container>
      {Object.entries(data).map(([sectionName, sectionData]) => (
        <Grid
          key={sectionName}
          item
          p={1}
          borderBottom={
            isRecursive ? 'none' : `1px solid ${colorSecondaryLight}`
          }
          xs={isRecursive ? 6 : 12}
          sx={{
            [theme.breakpoints.only("xs")]: {
              '& *': {
                fontSize: '12px!important',
              },
            },
          }}
        >
          <RenderLabel label={sectionName} fontSize={18} search={search} />

          {Object.entries(customFields).map(
            ([customNetwork, customFieldObject]) => {
              if (customNetwork === fullData.from) {
                return Object.entries(customFieldObject).map(
                  ([customField, customValue]) => {
                    if (customField === sectionName) {
                      return customValue.map((el) => {
                        const value = getObjectValue(fullData, el)?.toString()

                        return (
                          !!value && (
                            <Box key={customField + el + value} sx={{
                              [theme.breakpoints.only("xs")]: {
                                '& *': {
                                  fontSize: '10px!important',
                                },
                              },
                            }}>
                              <RenderLabel label={el} search={search}  />
                              <RenderValue label={el} value={value} search={search} />
                            </Box>
                          )
                        )
                      })
                    }
                    return null
                  },
                )
              }
              return null
            },
          )}

          {sectionData === null ? (
            <RenderValue key={sectionName} label={sectionName} value="-" />
          ) : typeof sectionData === 'string' ? (
            <RenderValue
              key={sectionName}
              label={sectionName}
              value={sectionData}
              search={search}
            />
          ) : (
            Object.entries(sectionData).map(([key, val]) => {
              if (
                cardFields[fullData?.from as keyof typeof cardFields] &&
                cardFields[fullData.from as keyof typeof cardFields].find((el: any) => el.field === sectionName)
              ) {
                const card = cardFields[fullData.from as keyof typeof cardFields].find((el: any) => el.field === sectionName)
                return <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  sx={{marginBottom: '5px'}}
                >
                  {renderClickableAvatarOrRegularAvatar(card, val)}
                  <Stack sx={{overflow: 'hidden'}}>
                    {renderUrl(card, val as any)}
                    <Typography variant="semiBold" color="secondary" sx={{
                      fontSize: '10px',
                    }}>
                      {(val as any)[card?.cardAddress as string] || ''}
                    </Typography>
                    <Typography variant="bold" color="secondary" sx={{fontSize: '10px'}}>
                      <Marker as={'span'}>{(val as any)[card?.cardInfo as string] || ''}</Marker>
                    </Typography>
                  </Stack>
                </Stack>
              }
              return (typeof val === 'string' || typeof val === 'number' ? (
                <Box key={key}>
                  <RenderLabel label={key} search={search}  />
                  <RenderValue key={key} label={key} value={val + ''} search={search} />
                </Box>
              ) : typeof val === 'object' && val ? (
                <React.Fragment key={key}>
                  <RenderProfileData
                    key={key}
                    data={val}
                    fullData={fullData}
                    isRecursive
                    search={search}
                  />
                </React.Fragment>
              ) : (
                <Box key={key}>
                  <RenderLabel label={key} search={search}  />
                  <RenderValue key={key} label={key} value="-" search={search} />
                </Box>
              )
              )
            })
          )}
        </Grid>
      ))}
    </Grid>
  )
}
