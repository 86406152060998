import React from 'react'
import { useDropzone } from 'react-dropzone'
import { Stack, Typography } from '@mui/material'
import { SvgIcon } from './SvgIcon'
import { showSnackbar } from '../store/common/actionCreator'
import { useDispatch } from '../store'

export const FILE_SIZE_LIMIT = 1024 * 1024 * 4
export const FILE_FORMATS = ['pdf', 'png', 'jpeg', 'jpg', 'gif', 'tiff']

interface Props {
  onUpload: (files: File[]) => void,
  imagesOnly?: boolean,
  maxFiles?: number,
  multiple?: boolean,
  sizeLimit?: number,
}

export function InputDropzone({ onUpload, imagesOnly = true, maxFiles = 0, multiple = true, sizeLimit = FILE_SIZE_LIMIT }: Props) {
  const dispatch = useDispatch()

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      const allowedFiles = acceptedFiles.filter((el: File) => {
        const type = el.type.split('/')[1]
        if (el.size > sizeLimit) {
          dispatch(
            showSnackbar(
              'error',
              'Maximum file size: ' + sizeLimit / 1000 + 'KB',
            ),
          )
          return false
        }
        if (imagesOnly && !FILE_FORMATS.includes(type)) {
          dispatch(
            showSnackbar(
              'error',
              `Only ${FILE_FORMATS.map((el) => ' ' + el)} formats are allowed`,
            ),
          )
          return false
        }
        return true
      })

      onUpload(allowedFiles)
    },
    [dispatch, onUpload],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles, multiple })

  return (
    <div className="form__dropzone" {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <Stack direction="row" alignItems="center" spacing={1}>
          <SvgIcon name="attach" size={{ width: 15, height: 16 }} />
          <Typography variant="bold">Browse file to attach</Typography>
        </Stack>
      )}
    </div>
  )
}
