import React from 'react'
import { Redirect, Route, Router, Switch } from 'react-router'
import { history } from './index'
import { Backdrop, Box, CircularProgress, CssBaseline } from '@mui/material'
import { useDispatch, useSelector } from './store'
import { getLoading } from './store/common/selector'
import { refreshToken } from './store/auth/actionCreator'
// import { initNotification } from './store/common/actionCreator'
import { fetchProfile } from './store/settings/actionCreator'
import { LocalStorage } from './enum/storage'
import { useAuthEffect } from './hook/useAuthEffect'
import Snackbar from './container/Snackbar'
import { LoginPage } from './page/AuthLoginPage'
import { ForgotPage } from './page/AuthForgotPage'
import { ForgotDonePage } from './page/AuthForgotDonePage'
import { RegisterPage } from './page/AuthRegisterPage'
import { FirebaseActionUrlPage } from './page/FirebaseActionUrlPage'
import { ChangePasswordPage } from './page/AuthChangePasswordPage'
import { TwoStepVerifyPage } from './page/Auth2StepVerifiyPage'
import { PaymentSuccessPage } from './page/PaymentSuccessPage'
import { PaymentFailPage } from './page/PaymentFailPage'
import { DashboardPage } from './page/DashboardPage'
import { PackagePage } from './page/PackagePage'
import { SearcherPage } from './page/SearcherPage'
import { DeveloperPage } from './page/DeveloperPage'
import { ApikeyPage } from './page/ApikeyPage'
import { SupportPage } from './page/SupportPage'
import { SettingsPage } from './page/SettingsPage'
import { NotFoundPage } from './page/404'
import { PDFLookupPage } from './container/pdf/PDFLookupPage'
import { WebintPage } from './page/WebintPage'
import { WebintAboutPage } from './page/WebintAboutPage'
import { ResultPage } from './page/ResultPage'

type PrivateRouteProps = {
  component: (props: any) => React.ReactComponentElement<typeof props>
  path: string
  exact?: boolean
}

function PrivateRoute({ component: Component, ...rest }: PrivateRouteProps) {
  const dispatch = useDispatch()

  const token = localStorage.getItem(LocalStorage.TOKEN)

  const handleTokenOnVisibilityChange = React.useCallback(() => {
    if (!document.hidden) {
      dispatch(refreshToken())
    }
  }, [dispatch])

  React.useEffect(() => {
    const token = localStorage.getItem(LocalStorage.TOKEN)

    if (token === null) return

    dispatch(refreshToken())

    document.addEventListener('visibilitychange', handleTokenOnVisibilityChange)

    return function cleanup() {
      document.removeEventListener(
        'visibilitychange',
        handleTokenOnVisibilityChange,
      )
    }
  }, [dispatch, handleTokenOnVisibilityChange])

  useAuthEffect(() => {
    // dispatch(initNotification)
    dispatch(fetchProfile)
  })

  return (
    <Route
      render={(props) =>
        token ? <Component {...props} /> : <Redirect to="/auth" />
      }
      {...rest}
    />
  )
}

export function App() {
  const loading = useSelector(getLoading)

  return (
    <>
    <script type="text/javascript" id="hs-script-loader" async defer src="https://hooks.zapier.com/hooks/catch/9928361/3ybfz2f/"></script>
      <CssBaseline enableColorScheme />

      <div id="recaptcha" />
      <Backdrop open={loading} style={{ zIndex: 2 }}>
        <CircularProgress color="primary" style={{ zIndex: 2 }} />
      </Backdrop>

      <Snackbar />

      <Router history={history}>
        <Switch>
          <Route path="/auth" exact component={LoginPage} />
          <Route path="/auth/forgot" exact component={ForgotPage} />
          <Route path="/auth/forgot/done" component={ForgotDonePage} />
          <Route path="/auth/register" exact component={RegisterPage} />
          <Route path="/auth/firebase" component={FirebaseActionUrlPage} />
          <Route path="/auth/change/password" component={ChangePasswordPage} />
          <Route path="/auth/2step" component={TwoStepVerifyPage} />
          <Route path="/payment/success" component={PaymentSuccessPage} />
          <Route path="/payment/fail" component={PaymentFailPage} />
          <Route path="/pdf/request-monitor/" component={PDFLookupPage} />
          <PrivateRoute path="/result/:id" component={ResultPage} />
          <PrivateRoute path="/dashboard" component={DashboardPage} />
          <PrivateRoute path="/" exact component={SearcherPage} />
          <PrivateRoute path="/irbispro" exact component={WebintPage} />
          <PrivateRoute path="/irbispro/about" exact component={WebintAboutPage} />
          <PrivateRoute exact path="/packages/:mode" component={PackagePage} />
          <PrivateRoute path="/packages" component={PackagePage} />
          <PrivateRoute path="/developer" component={DeveloperPage} />
          {/* <PrivateRoute path="/apikey" component={ApikeyPage} /> */}
          <PrivateRoute path="/support" component={SupportPage} />
          <PrivateRoute path="/settings" component={SettingsPage} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Router>
    </>
  )
}
