import { CSSProperties } from 'react'
import sprite from '../asset/sprite/spite.svg'

interface Size {
  width: number
  height: number
}

interface Props {
  name: string
  color?: string
  size: Size
  className?: string
  style?: CSSProperties
}

export const SvgIcon = ({ name, color, size, ...props }: Props) => {
  return (
    <svg width={size.width} height={size.height} fill={color} {...props}>
      <use href={sprite + `#${name}`} />
    </svg>
  )
}
