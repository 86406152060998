import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  LinearProgress,
  Grid,
} from '@mui/material'
import { useSelector } from '../../store'
import {
  getIsOnBoarding,
  getOnBoardingStep,
} from '../../store/settings/selector'
import { OnBoardingStep2 } from '../onboarding/Step2'
import { useUserPackage } from '../../hook/useUserPackage'

export function AccountBalance() {
  const { t } = useTranslation()
  const history = useHistory()

  const { isExpired} = useUserPackage()

  const { packageStartBalance, packageBalance, package: currentPackage } = useSelector(
    (state) => state.settings,
  )

  const isOnBoarding = useSelector(getIsOnBoarding)
  const onBoardingStep = useSelector(getOnBoardingStep)

  const progress = React.useMemo(() => {
    if (!packageBalance || !packageStartBalance) return 0
    else {
      const percentage = (packageBalance * 100) / packageStartBalance
      if (percentage > 100) return 100
      else return percentage
    }
  }, [packageStartBalance, packageBalance])

  const displayPackageBalance = React.useMemo(() => {
    return (currentPackage && currentPackage?.creditsPerUnit > 0)
      ? `${(packageBalance || 0) * currentPackage?.creditsPerUnit} credits`
      : `€${packageBalance?.toFixed(0)}`
  }, [packageBalance, currentPackage])

  const zIndex = onBoardingStep === 2 ? 2 : 1

  return (
    <div style={{ position: 'relative', zIndex: zIndex, height: '100%' }}>
      <Card>
        <CardHeader title={t('accountBalance.title')} />
        <CardContent>
          <Typography variant="body1">{t('accountBalance.text')}</Typography>
          <LinearProgress variant="determinate" value={progress} />
        </CardContent>
        <CardActions>
          <Grid container justifyContent="space-between" alignItems="flex-end">
            <Grid item>
              <Typography variant="semiBold" color="primary">
                <span>
                  {isExpired ? 0 : displayPackageBalance}&nbsp;
                  {/*/
                  {isExpired ? 0 : packageStartBalance}€{' '}*/}
                </span>
              </Typography>
              <Typography>remaining</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="underlined"
                onClick={() => history.push('/packages')}
              >
                {t(isExpired ? 'package.update' : 'package.change')}
              </Button>
            </Grid>
          </Grid>
        </CardActions>

        {isOnBoarding && onBoardingStep === 2 && <OnBoardingStep2 />}
      </Card>
    </div>
  )
}
