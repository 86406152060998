import { Alert, AlertTitle, Box } from '@mui/material'

export function NotFoundPage() {
  return (
    <Alert severity="error">
      <AlertTitle>404 Not found</AlertTitle>
      <Box>Page not found</Box>
    </Alert>
  )
}
