import React from 'react'
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api'
import Typography from '@mui/material/Typography'
import { Box, Grid, Stack } from '@mui/material'
import { IPGeolocationItem, SearchResult } from '../../../store/searcher/reducer'
import { prepareIpGeolocationResult } from '../../../store/searcher/service'
import { ResultNotVerified, ResultTitle } from '../../../style/result'

type Props = {
  apiData: SearchResult
}

export function IPGeolocationCommon(props: Props) {
  const zoom = 5
  const result = prepareIpGeolocationResult(props.apiData)

  const containerStyle = {
    width: '450px',
    height: '450px',
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_FIREBASE_API_KEY || "",
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    if (result.data) {
      result.data.map(el => {
        const bounds = new window.google.maps.LatLngBounds({ lat: el.latitude, lng: el.longitude })
        map.fitBounds(bounds)
      },
      )
    }
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const noresults = (
    <Stack>
      <div style={{
        position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)',
      }}>
        <ResultNotVerified>Results not found</ResultNotVerified>
      </div>
    </Stack>
  )

  return (
    <>
      <Stack>
        {
          isLoaded && result && result.data && result.data?.map((el: IPGeolocationItem, idx: number) => {
            if (el.latitude && el.longitude) {
              return (
                <Stack>
                  <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={{ lat: el.latitude, lng: el.longitude }}
                        zoom={zoom}
                        options={{ minZoom: 3, maxZoom: 12, clickableIcons: false }}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                      >
                        <MarkerF
                          position={{
                            lat: el.latitude,
                            lng: el.longitude,
                          }}
                        />
                      </GoogleMap>
                    </Grid>
                    <Grid item xs={5}>
                      <Box sx={{ height: 'auto', width: '100%' }}>
                        <Stack direction={'column'} sx={{ margin: '10px 5px' }}>
                          <ResultTitle>Country:</ResultTitle>
                          <Typography fontSize={12} fontWeight={200} color={'#000000'} sx={{ wordBreak: 'break-all' }}>{el.country}</Typography>
                        </Stack>
                        <Stack direction={'column'} sx={{ margin: '10px 5px' }}>
                          <ResultTitle>City:</ResultTitle>
                          <Typography fontSize={12} fontWeight={200} color={'#000000'} sx={{ wordBreak: 'break-all' }}>{el.city}</Typography>
                        </Stack>
                        <Stack direction={'column'} sx={{ margin: '10px 5px' }}>
                          <ResultTitle>Continent:</ResultTitle>
                          <Typography fontSize={12} fontWeight={200} color={'#000000'} sx={{ wordBreak: 'break-all' }}>{el.continent}</Typography>
                        </Stack>
                        <Stack direction={'column'} sx={{ margin: '10px 5px' }}>
                          <ResultTitle>Details:</ResultTitle>

                          <Stack direction={'row'} sx={{ margin: '0px 5px' }}>
                            <Typography>Region: </Typography>
                            <Typography style={{ fontWeight: 700 }}>{el.region}</Typography>
                          </Stack>
                          
                          <Stack direction={'row'} sx={{ margin: '0px 5px' }}>
                            <Typography>Postal Code: </Typography>
                            <Typography style={{ fontWeight: 700 }}>{el.postal_code}</Typography>
                          </Stack>
                          
                          <Stack direction={'row'} sx={{ margin: '0px 5px' }}>
                            <Typography>VPN: </Typography>
                            <Typography style={{ fontWeight: 700 }}>{el.is_vpn.toString()}</Typography>
                          </Stack>
                          
                          <Stack direction={'row'} sx={{ margin: '0px 5px' }}>
                            <Typography>Country Geo ID: </Typography>
                            <Typography style={{ fontWeight: 700 }}>{el.country_geoname_id}</Typography>
                          </Stack>
                          
                          <Stack direction={'row'} sx={{ margin: '0px 5px' }}>
                            <Typography>City Geo ID: </Typography>
                            <Typography style={{ fontWeight: 700 }}>{el.city_geoname_id}</Typography>
                          </Stack>
                          
                          <Stack direction={'row'} sx={{ margin: '0px 5px' }}>
                            <Typography>Region Geo ID: </Typography>
                            <Typography style={{ fontWeight: 700 }}>{el.region_geoname_id}</Typography>
                          </Stack>
                          
                          <Stack direction={'row'} sx={{ margin: '0px 5px' }}>
                            <Typography>Continent Geo ID: </Typography>
                            <Typography style={{ fontWeight: 700 }}>{el.continent_geoname_id}</Typography>
                          </Stack>
                          
                          <Stack direction={'row'} sx={{ margin: '0px 5px' }}>
                            <Typography>Region ISO Code: </Typography>
                            <Typography style={{ fontWeight: 700 }}>{el.region_iso_code}</Typography>
                          </Stack>
                          
                          <Stack direction={'row'} sx={{ margin: '0px 5px' }}>
                            <Typography>Country Code: </Typography>
                            <Typography style={{ fontWeight: 700 }}>{el.country_code}</Typography>
                          </Stack>
                          
                          <Stack direction={'row'} sx={{ margin: '0px 5px' }}>
                            <Typography>In EU: </Typography>
                            <Typography style={{ fontWeight: 700 }}>{el.country_is_eu.toString()}</Typography>
                          </Stack>
                          
                          <Stack direction={'row'} sx={{ margin: '0px 5px' }}>
                            <Typography>Country Code: </Typography>
                            <Typography style={{ fontWeight: 700 }}>{el.continent_code}</Typography>
                          </Stack>

                        </Stack>
                      </Box>

                    </Grid>
                  </Grid>
                </Stack>
              )
            } else {
              return noresults
            }
          })
        }
        {result && result.data && result.data.length === 0 && noresults}
      </Stack>
    </>
  )
}
