import {
  OAuthCredential,
  UserCredential,
  EmailAuthCredential,
} from 'firebase/auth'
import { Provider } from '../../enum/provider'
import { AuthStep } from './reducer'

export const FLUSH_STATE = 'auth/FLUSH_STATE'
export interface FlushState {
  type: typeof FLUSH_STATE
}

export const GET_PROVIDER_USER = 'auth/GET_PROVIDER_USER'
export interface GetProviderUser {
  type: typeof GET_PROVIDER_USER
  provider: Provider
}

export const SET_USER_CREDENTIAL = 'auth/SET_USER_CREDENTIAL'
export interface SetUserCredential {
  type: typeof SET_USER_CREDENTIAL
  userCredential: UserCredential
}

export const SET_AUTH_CREDENTIAL = 'auth/SET_AUTH_CREDENTIAL'
export interface SetAuthCredential {
  type: typeof SET_AUTH_CREDENTIAL
  credential: OAuthCredential | EmailAuthCredential | null
}

export const SET_REGISTER_STEP = 'auth/SET_REGISTER_STEP'
export interface SetRegisterStep {
  type: typeof SET_REGISTER_STEP
  mode: AuthStep
}

export const SET_REGISTER_INPUT = 'auth/SET_REGISTER_INPUT'
export interface SetRegisterInput {
  type: typeof SET_REGISTER_INPUT
  data: object
}

export const SET_REGISTER_INPUT_EMPTY = 'auth/SET_REGISTER_INPUT_EMPTY'
export interface SetRegisterInputInit {
  type: typeof SET_REGISTER_INPUT_EMPTY
}

export const SET_USERNAME_INPUT_HELPER = 'auth/SET_USERNAME_INPUT_HELPER'
export interface SetUsernameInputHelper {
  type: typeof SET_USERNAME_INPUT_HELPER
  value: string
}

export const GET_PHONE_CODE = 'auth/GET_PHONE_CODE'
export interface GetPhoneCode {
  type: typeof GET_PHONE_CODE
  phone: string
  mode?: 'register' | 'login'
}

export const SET_AUTH_PHONE_VERIFY = 'auth/SET_AUTH_PHONE_VERIFY'
export interface SetAuthPhoneVerify {
  type: typeof SET_AUTH_PHONE_VERIFY
  value: boolean
}

export const SIGNUP_BY_PROVIDER = 'auth/SIGNUP_BY_PROVIDER'
export interface SignupByProvider {
  type: typeof SIGNUP_BY_PROVIDER
  code: string
  provider: Provider
}

export const SEND_EMAIL_VERIFICATION = 'auth/SEND_EMAIL_VERIFICATION'
export interface SendEmailVerification {
  type: typeof SEND_EMAIL_VERIFICATION
  email: string
  password: string
}

export const VERIFY_EMAIL = 'auth/VERIFY_EMAIL'
export interface VerifyEmail {
  type: typeof VERIFY_EMAIL
  oobCode: string
  continueUrl: string | null
}

export const CHECK_EMAIL_VERIFY = 'auth/CHECK_EMAIL_VERIFY'
export interface CheckEmailVerify {
  type: typeof CHECK_EMAIL_VERIFY
  email: string
  password: string
}

export const SIGNUP_BY_EMAIL = 'auth/SIGNUP_BY_EMAIL'
export interface SignupByEmail {
  type: typeof SIGNUP_BY_EMAIL
  code: string
}

export const SIGN_IN_BY_PROVIDER = 'auth/SIGN_IN_BY_PROVIDER'
export interface SignInByProvider {
  type: typeof SIGN_IN_BY_PROVIDER
  provider: Provider
}

export const SIGN_IN_BY_PHONE = 'auth/SIGN_IN_BY_PHONE'
export interface SignInByPhone {
  type: typeof SIGN_IN_BY_PHONE
  code: string
}

export const SIGN_IN_EMAIL = 'auth/SIGN_IN_EMAIL'
export interface SignInEmail {
  type: typeof SIGN_IN_EMAIL
  email: string
  password: string
}

export const SIGN_IN_2FA = 'auth/SIGN_IN_2FA'
export interface SignIn2Fa {
  type: typeof SIGN_IN_2FA
  code: string
}

export const REQUEST_FORGOT = 'auth/REQUEST_FORGOT'
export interface RequestForgot {
  type: typeof REQUEST_FORGOT
  email: string
}

export const UPDATE_PASSWORD = 'auth/UPDATE_PASSWORD'
export interface UpdatePassword {
  type: typeof UPDATE_PASSWORD
  obbCode: string
  password: string
}

export const VERIFY_AND_CHANGE_EMAIL = 'auth/VERIFY_AND_CHANGE_EMAIL'
export interface VerifyAndChangeEmail {
  type: typeof VERIFY_AND_CHANGE_EMAIL
  oobCode: string
  continueUrl: string
}

export const REFRESH_TOKEN = 'auth/REFRESH_TOKEN'
export interface RefreshToken {
  type: typeof REFRESH_TOKEN
  token?: string
  redirect?: string
}

export const SET_REFRESHED_TOKEN = 'auth/SET_REFRESHED_TOKEN'
export interface SetRefreshedToken {
  type: typeof SET_REFRESHED_TOKEN
  token: string
}

export const SIGN_OUT = 'auth/SIGN_OUT'
export interface SignOut {
  type: typeof SIGN_OUT
}

export type AuthAction =
  | FlushState
  | GetProviderUser
  | SetUserCredential
  | SetAuthCredential
  | SetRegisterStep
  | SetRegisterInput
  | SetRegisterInputInit
  | SetUsernameInputHelper
  | GetPhoneCode
  | SetAuthPhoneVerify
  | SignupByProvider
  | SendEmailVerification
  | VerifyEmail
  | CheckEmailVerify
  | SignupByEmail
  | SignInByProvider
  | SignInByPhone
  | SignInEmail
  | SignIn2Fa
  | RequestForgot
  | UpdatePassword
  | VerifyAndChangeEmail
  | RefreshToken
  | SetRefreshedToken
  | SignOut
