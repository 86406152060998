import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  DialogTitle,
  Divider,
  Typography,
  Button,
  Grid,
  Box,
} from '@mui/material'
import onBoarding_ from '../../sass/onBoarding.module.sass'
import {
  finishOnBoarding,
  setOnBoardingStep,
} from '../../store/settings/actionCreator'
import { useDispatch, useSelector } from '../../store'

export function OnBoardingStep2() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { packageBalance } = useSelector((state) => state.settings)

  return (
    <div className={onBoarding_.root_right}>
      <DialogTitle>{t('onBoarding.title')}</DialogTitle>

      <Typography
        color="primary"
        fontFamily="Montserrat-Bold, sans-serif"
        fontSize={16}
        textAlign="center"
      >
        {packageBalance}
        {t('onBoarding.titleSub')}
      </Typography>

      <Divider variant="popup" color="primary" />

      <Box my={5}>
        <Typography>{t('onBoarding.accountBalance')}</Typography>
      </Box>

      <Divider variant="popup" color="primary" />

      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Button
            variant="outlinedRounded"
            size="medium"
            color="primary"
            onClick={() => dispatch(finishOnBoarding)}
          >
            {t('common.skip')}
          </Button>
        </Grid>
        <Grid item>
          <Typography fontSize={16}>2 {t('common.of')} 3</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="containedRounded"
            size="medium"
            color="success"
            onClick={() => dispatch(setOnBoardingStep(3))}
          >
            {t('common.next')}
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}
