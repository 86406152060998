import * as React from 'react'
import {
  Close as CloseIcon,
  PictureAsPdf as PDFIcon,
} from '@mui/icons-material/'
import { Delete as DeleteIcon } from '@mui/icons-material'
import support_ from '../../sass/support.module.sass'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import {
  getS3Blob,
  getFileExtensionFromUrl,
  downloadBlob,
} from '../../store/support/service'
import { colorPrimary } from '../../mui'
import { SvgIcon } from '../SvgIcon'
import { Attachment } from '../../store/support/reducer'

interface PreviewProps {
  attachment: Attachment
  onDelete?: (attachment: Attachment) => void
}

function ImagePreview({ attachment, onDelete }: PreviewProps) {
  const [open, setOpen] = React.useState(false)

  const [src, setSrc] = React.useState<string | null>(null)

  const handleFetchS3Image = React.useCallback(async () => {
    const blob = await getS3Blob(attachment.url)
    const objectUrl = URL.createObjectURL(blob)
    setSrc(objectUrl)
  }, [attachment])

  React.useEffect(() => {
    handleFetchS3Image()
  }, [handleFetchS3Image])

  async function handleDownload() {
    const blob = await getS3Blob(attachment.url)
    downloadBlob(blob, attachment.fileName)
  }

  function handleDelete(e: React.MouseEvent) {
    e.stopPropagation()
    if (onDelete) onDelete(attachment)
  }

  if (src === null) return null

  return (
    <>
      <Button className={support_.preview} onClick={() => setOpen(true)}>
        <Stack justifyContent="space-between" height="100%">
          {onDelete && (
            <Box className={support_.preview__nav}>
              <Button
                className={support_.preview__navBtn}
                variant="contained"
                color="secondary"
                size="small"
                onClick={handleDelete}
              >
                <DeleteIcon />
              </Button>
            </Box>
          )}
          <Stack justifyContent="center" alignItems="center" height="75%">
            <img className={support_.preview__img} src={src} alt="" />
          </Stack>
          <Box className={support_.preview__info} />
          <Box className={support_.preview__text}>
            <Typography variant="semiBold" fontSize={11}>
              {attachment.fileName}
            </Typography>
          </Box>
        </Stack>
      </Button>

      {open && (
        <Dialog open={true} maxWidth="lg" onClose={() => setOpen(false)}>
          <DialogTitle sx={{ mb: 1, fontSize: 20 }}>
            <Box className="button__close">
              <IconButton onClick={handleDownload}>
                <SvgIcon
                  name="download"
                  size={{ width: 20, height: 20 }}
                  color={colorPrimary}
                />
              </IconButton>
              <IconButton onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>

          <Divider variant="popup" color="primary" />

          <img src={src} alt="" />
        </Dialog>
      )}
    </>
  )
}

function PdfPreview({ attachment, onDelete }: PreviewProps) {
  async function handlePreview() {
    if (attachment.isObjectUrl) {
      window.open(attachment.url)
    } else {
      const blob = await getS3Blob(attachment.url)
      const objectUrl = URL.createObjectURL(blob)
      window.open(objectUrl)
    }
  }

  function handleDelete(e: React.MouseEvent) {
    e.stopPropagation()
    if (onDelete) onDelete(attachment)
  }

  return (
    <Button className={support_.preview} onClick={handlePreview}>
      <Stack justifyContent="space-between" height="100%">
        {onDelete && (
          <Box className={support_.preview__nav}>
            <Button
              className={support_.preview__navBtn}
              variant="contained"
              color="secondary"
              size="small"
              onClick={handleDelete}
            >
              <DeleteIcon />
            </Button>
          </Box>
        )}
        <Stack justifyContent="center" alignItems="center" height="75%">
          <PDFIcon />
        </Stack>
        <Box className={support_.preview__info} />
        <Box className={support_.preview__text}>
          <Typography variant="semiBold" fontSize={11}>
            {attachment.fileName}
          </Typography>
        </Box>
      </Stack>
    </Button>
  )
}

interface Props {
  attachment: Attachment
  onDelete?: (attachment: Attachment) => void
}

export function FilePreview({ attachment, onDelete }: Props) {
  const extension = getFileExtensionFromUrl(
    attachment.isObjectUrl ? attachment.fileName : attachment.url,
  )

  return extension === 'pdf' ? (
    <PdfPreview attachment={attachment} onDelete={onDelete} />
  ) : (
    <ImagePreview attachment={attachment} onDelete={onDelete} />
  )
}
