import * as React from 'react'
import Joi from 'joi'
import { t } from 'i18next'
import { FileCopy as CopyIcon } from '@mui/icons-material'
import apikey_ from '../../sass/apikey.module.sass'
import { useDispatch, useSelector } from '../../store'
import {
  editApikey,
  generateKeys,
  postApikey,
  setGeneratedKeys,
} from '../../store/apikey/actionCreator'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Tooltip, useTheme,
} from '@mui/material'
import { ErrorsState, validateForm } from '../../function/validation'
import { FormError } from '../../component/FormError'
import { Apikey } from '../../store/apikey/reducer'
import { clearFieldError } from '../../store/common/actionCreator'

type MouseEvent =
  | React.MouseEvent<HTMLAnchorElement>
  | React.MouseEvent<HTMLButtonElement>

const schemaGenerate = Joi.object({
  length: Joi.number().required().min(16).max(32).integer(),
})
const schemaCreate = Joi.object({
  length: Joi.number().required().min(16).max(32).integer(),
  // domains: Joi.array().min(1).label(t('field.label.allowedDomains'))
})
const schemaEdit = Joi.object({
  length: Joi.number().required().min(16).max(32).integer(),
  key: Joi.string().required(),
  // domains: Joi.array().min(1).label(t('field.label.allowedDomains')),
})

type Props = {
  mode: 'create' | 'edit'
  onClose: () => void
  data?: Apikey
}

export const PopupCRUD = ({ mode, onClose, data }: Props) => {
  const dispatch = useDispatch()
  const theme = useTheme()

  const { generatedKeys } = useSelector((state) => state.apikey)

  const [length, setLength] = React.useState(data?.length || 24)
  const [key, setKey] = React.useState(data?.key || '')
  const [domainsInput, setDomainsInput] = React.useState('')
  const [domains, setDomains] = React.useState<string[]>(data?.domains || [])
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  const [disableGenerate, setDisableGenerate] = React.useState(false)
  const [disableSave, setDisableSave] = React.useState(true)

  React.useEffect(() => {
    if (generatedKeys === null) return

    setKey(generatedKeys.key)
    setDisableSave(false)

    return () => {
      dispatch(setGeneratedKeys(null))
    }
  }, [dispatch, generatedKeys])

  function handleGenerate() {
    const error = validateForm(schemaGenerate, { length })

    if (error) setErrors(error)
    else {
      setErrors(null)
      dispatch(generateKeys(length))
      setDisableSave(false)
      setDisableGenerate(true)
      setTimeout(() => setDisableGenerate(false), 15000)
    }
  }

  function handleDomainKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      setDomains((state) => [...state, domainsInput])
      setDomainsInput('')
    }
  }

  function handleDomainHintClick() {
    setDomains((state) => [...state, domainsInput])
    setDomainsInput('')
  }

  function handleDomainDelete(domain: string) {
    setDomains((state) => [...state.filter((el) => el !== domain)])
  }

  function handleCopyKey(e: MouseEvent) {
    e.stopPropagation()
    navigator.clipboard.writeText(String(key))
  }

  function handleSubmit() {
    if (mode === 'create') {
      const error = validateForm(schemaCreate, { length })

      if (error) setErrors(error)
      else {
        setErrors(null)

        const requestData = {
          length: length,
          domains: domains,
        }

        dispatch(postApikey(requestData))
        onClose()
      }
    } else {
      const error = validateForm(schemaEdit, { length, key })

      if (error) setErrors(error)
      else {
        setErrors(null)

        const requestData = {
          id: data?.id as number,
          length: length,
          key: key,
          domains: domains,
        }

        dispatch(editApikey(requestData))
        onClose()
      }
    }
  }

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [dispatch, length, key, domainsInput])

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Dialog open={true} fullWidth onClose={onClose} maxWidth="md">
        {mode === 'create' && (
          <DialogTitle>{t('apiKey.createAPIkey')}</DialogTitle>
        )}
        {mode === 'edit' && <DialogTitle>{t('apiKey.editAPIkey')}</DialogTitle>}

        <Divider variant="popup" color="primary" />

        <Stack spacing={2}>
          <Grid container>
            <Grid item xs={5}>
              <FormLabel>{t('apiKey.length')}</FormLabel>
            </Grid>
            <Grid item xs={7}>
              <FormControl fullWidth>
                <OutlinedInput
                  type="number"
                  value={length}
                  onChange={(e) => setLength(Number(e.target.value))}
                  inputProps={{
                    min: 16,
                    max: 32,
                  }}
                />
                <FormError error={errors?.length} />
              </FormControl>
            </Grid>
          </Grid>

          {mode === 'edit' && (
            <Grid container>
              <Grid item xs={5}>
                <FormLabel>{t('apiKey.key')}</FormLabel>
              </Grid>
              <Grid item xs={7}>
                <FormControl fullWidth>
                  <OutlinedInput
                    value={key}
                    placeholder={t('apiKey.hint')}
                    readOnly
                    endAdornment={
                      <InputAdornment position="end">
                        <Tooltip title={<span>{t('common.copy')}</span>}>
                          <IconButton onClick={handleCopyKey} tabIndex={-1}>
                            <CopyIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          )}

          <Grid container>
            <Grid item xs={5}>
              <FormLabel>{t('field.label.allowedDomains')}</FormLabel>
            </Grid>
            <Grid item xs={7}>
              <Box className={apikey_.domains}>
                {domains.length > 0 && (
                  <Stack className={apikey_.domains__chips}>
                    {domains.map((el) => (
                      <Chip
                        key={el}
                        label={el}
                        sx={{ mr: 1, mb: 1, background: el }}
                        onDelete={() => handleDomainDelete(el)}
                      />
                    ))}
                  </Stack>
                )}
                <Box className={apikey_.input}>
                  <input
                    className={apikey_.input__field}
                    value={domainsInput}
                    onChange={(e) => setDomainsInput(e.target.value)}
                    onKeyDown={handleDomainKeyDown}
                    autoFocus
                  />
                  {domainsInput && (
                    <Box
                      className={apikey_.input__hint}
                      onClick={handleDomainHintClick}
                    >
                      {domainsInput}
                    </Box>
                  )}
                </Box>
              </Box>
              <FormError error={errors?.domains} />
            </Grid>
          </Grid>
        </Stack>

        <Divider variant="popup" color="primary" />

        <DialogActions sx={{
          [theme.breakpoints.only("xs")]: {
            //flexDirection: 'column',
          },
        }}>
          <Button
            color="primary"
            size="large"
            onClick={onClose}
            variant="outlined"
          >
            {t('common.close')}
          </Button>
          {mode === 'edit' && (
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={handleGenerate}
              disabled={disableGenerate}
              sx={{
                [theme.breakpoints.only("xs")]: {
                  fontSize: '10px',
                },
              }}
            >
              {t('common.generate')}
            </Button>
          )}

          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={handleSubmit}
            disabled={mode === 'edit' ? disableSave : false}
          >
            {t('common.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}
