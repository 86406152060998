import React, { useEffect, useState } from 'react'
import {Box, Slide, Stack, IconButton, CardMedia, Typography} from '@mui/material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { image } from 'html2canvas/dist/types/css/types/image'

interface ImageCarouselProps {
    images: string[],
    imagesPerPage: number,
} 

export const ImageCarousel = (props: ImageCarouselProps) => {
    const images = props.images
    const [cards, setCards] = useState<React.ReactElement[]>()
    const [currPage, setCurrPage] = useState(0)
    const [slideDirection, setSlideDirection] = useState<"right" | "left" | undefined>("left")
    const cardsPerPage = props.imagesPerPage
    const imageMediaCards = images.map((image) => (
        <CardMedia 
            component="img"
            sx={{ height: 250, width: 250, borderRadius: "16px", padding: "1%", flexShrink: 0 }}
            image={image}
            alt="" 
        />
    ))

    useEffect(() => {
        setCards(imageMediaCards)
    }, [])

    function handleNextPage() {
        setSlideDirection("left")
        setCurrPage((prevPage) => prevPage + 1)
    }

    function handlePrevPage() {
        setSlideDirection("right")
        setCurrPage((prevPage) => prevPage - 1)
    }

    return <Box
    sx={{
        display:"flex",
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        height: "",
    }}
    >
        
        {cards && cards.map((card, index) => (
            <Box 
                key={`card-${index}`} 
                sx={{ 
                    display: currPage === index ? "block" : "none",
                }}
            >
                <Slide direction={slideDirection} in={currPage === index}>
                    <Stack
                        spacing={1}
                        direction="row"
                        alignContent="center"
                        justifyContent="center"
                        overflow="hidden"
                    >
                        {cards.slice(index * cardsPerPage, index * cardsPerPage + cardsPerPage)}
                    </Stack>
                </Slide>
            </Box>
        ))}
        {
            (cards && cards.length > 1 && 
                <Stack
                    sx={{
                        display:"flex",
                        flexDirection: "row",
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "center",
                        height: "",
                    }}
                >
                <IconButton
                    onClick={handlePrevPage}
                    sx={{margin: 2}}
                    disabled={currPage === 0}
                >
                    <NavigateBeforeIcon/>
                </IconButton>
                <Typography>{currPage + 1}/{Math.ceil((cards.length/cardsPerPage))}</Typography>
                <IconButton
                    onClick={handleNextPage}
                    sx={{margin: 2}}
                    disabled={currPage + 1 === Math.ceil((cards.length/cardsPerPage))}
                >
                    <NavigateNextIcon/>
                </IconButton>
                </Stack>
            )
        }

    </Box>
}