import { AdditionalLookup, AdditionalPackage, Token } from "./reducer"

export const GET_ADDITIONAL_PACKAGE = 'additional-package/GET_ADDITIONAL_PACKAGE'
export interface GetAdditionalPackage {
  type: typeof GET_ADDITIONAL_PACKAGE
}

export const POST_ADDITIONAL_PACKAGE = 'additional-package/POST_ADDITIONAL_PACKAGE'
export interface PostAdditionalPackage {
  type: typeof POST_ADDITIONAL_PACKAGE
  data : object
}

export const SET_ADDITIONAL_PACKAGE = 'additional-package/SET_ADDITIONAL_PACKAGE'
export interface SetAdditionalPackage {
  type: typeof SET_ADDITIONAL_PACKAGE
  data : AdditionalPackage | null
}


export const GET_ADDITIONAL_LOOKUP = 'additional-lookups/GET_ADDITIONAL_LOOKUP'
export interface GetAdditionalLookup {
  type: typeof GET_ADDITIONAL_LOOKUP
}

export const SET_ADDITIONAL_LOOKUP = 'additional-lookups/SET_ADDITIONAL_LOOKUP'
export interface SetAdditionalLookup {
  type: typeof SET_ADDITIONAL_LOOKUP
  data : AdditionalLookup | null
}

export const POST_ADDITIONAL_LOOKUP = 'additional-lookups/POST_ADDITIONAL_LOOKUP'
export interface PostAdditionalLookup {
  type: typeof POST_ADDITIONAL_LOOKUP
  data : object
}


export type AdditionalPackageAction =
  | GetAdditionalPackage
  | PostAdditionalPackage
  | SetAdditionalPackage

export type AdditionalLookupAction =
  | SetAdditionalLookup
  | PostAdditionalLookup
  | GetAdditionalLookup


export const SET_TOKEN = 'token/SET_TOKEN'
export interface SetToken {
  type: typeof SET_TOKEN
  data : Token | null
}

export const POST_TOKEN = 'token/POST_TOKEN'
export interface PostTokenKey {
  type: typeof POST_TOKEN
  data : object
}

export type TokenAction =
  | PostTokenKey
  | SetToken

  export const SET_QUOTA = 'quota/SET_QUOTA'
  export interface SetQuota {
    type: typeof SET_QUOTA
    data : object | null
  }
  
  export const POST_QUOTA = 'quota/POST_QUOTA'
  export interface PostQuota {
    type: typeof POST_QUOTA
    data : object
  }

  export const SET_QUOTA_TRIAL = 'quota/SET_QUOTA_TRIAL'
  export interface SetQuotaTrial {
    type: typeof SET_QUOTA_TRIAL
    data : object | null
  }

export type QuotaAction = 
  | PostQuota
  | SetQuota
  | SetQuotaTrial

