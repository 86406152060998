import * as firebase from 'firebase/auth'
import { api, Response } from '../../function/axios'
import { Provider } from '../../enum/provider'
import { store } from '../index'
import { signOut } from './actionCreator'
import { LocalStorage } from '../../enum/storage'

export function getProvider(provider: Provider) {
  switch (provider) {
    case Provider.GOOGLE:
      return new firebase.GoogleAuthProvider()
    case Provider.FACEBOOK:
      return new firebase.FacebookAuthProvider()
    case Provider.MICROSOFT:
    default:
      const microsoftProvider = new firebase.OAuthProvider(provider)
      // TODO choose right return
      return microsoftProvider.setCustomParameters({
        prompt: 'consent',
        tenant: 'f8cdef31-a31e-4b4a-93e4-5f571e91255a',
      })
  }
}

export function getProviderCredential(
  provider: Provider,
  result: firebase.UserCredential,
) {
  switch (provider) {
    case Provider.GOOGLE:
      return firebase.GoogleAuthProvider.credentialFromResult(result)
    case Provider.FACEBOOK:
      return firebase.FacebookAuthProvider.credentialFromResult(result)
    case Provider.MICROSOFT:
    default:
      return firebase.OAuthProvider.credentialFromResult(result)
  }
}

export function prepareProviderUserData(user: firebase.User) {
  let firstname = ''
  let lastname = ''
  let signInProvider = ''

  if (user.displayName) {
    const [first, second] = user.displayName?.split(' ')
    firstname = first
    lastname = second
  }

  if (user.providerData[0]) {
    signInProvider = user.providerData[0].providerId
  }

  return { firstname, lastname, signInProvider }
}

export function getEmailCredential(email: string, password: string) {
  return firebase.EmailAuthProvider.credential(email, password)
}

export function getFirebaseUser(): Promise<firebase.User> {
  const auth = firebase.getAuth()

  return new Promise((resolve) => {
    firebase.onAuthStateChanged(auth, (user) => {
      if (user) {
        resolve(user)
      } else {
        store.dispatch(signOut)
      }
    })
  })
}

export async function getToken(user: firebase.User): Promise<string> {
  return await user.getIdToken(true)
}

export async function refreshToken(): Promise<string> {
  const user = await getFirebaseUser()
  const token = await getToken(user)

  localStorage.setItem(LocalStorage.TOKEN, token)

  return token
}

export async function checkIs2FAEnabled(token: string) {
  const { data }: Response = await api({
    method: 'GET',
    url: '/auth/get2FA',
    token,
  })

  return data.result
}
