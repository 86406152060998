import * as React from 'react'
import { useTranslation } from 'react-i18next'
import 'chartjs-adapter-date-fns'
import { enUS } from 'date-fns/locale'
import { useHistory } from 'react-router-dom'
import zoomPlugin from 'chartjs-plugin-zoom'
import {
  differenceInMinutes,
  differenceInHours,
  subMonths,
  subDays,
  addDays,
  subHours,
  addHours,
  subMinutes,
  addMinutes,
  subYears,
  addYears,
} from 'date-fns'
import {
  ExpandMore as SelectIcon,
  SettingsBackupRestore as ResetIcon,
  Add as AddIcon,
  BarChart as TypeIcon,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardHeader,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography, useTheme,
} from '@mui/material'
import { useDispatch, useSelector } from '../../store'
import { formatDate, toDate } from '../../function/date'
import { DateType, RequestChart } from '../../store/dashboard/reducer'
import { fetchMonitor } from '../../store/dashboard/actionCreator'
import {
  Chart,
  ChartOptions,
  ChartType,
  TimeScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  TooltipItem,
  Tooltip as ChartTooltip,
  BarController,
  BarElement,
} from 'chart.js'
import { getRefreshedToken } from '../../store/auth/selector'

Chart.register(
  zoomPlugin,
  TimeScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  ChartTooltip,
  BarController,
  BarElement,
)

const oneMonthInMinutes = 43800
const oneMonthInHours = 730
const suggestOffset = 4

interface ChartCompProps {
  dateType: DateType
  chartType: ChartType
  chartRef: React.MutableRefObject<Chart | null>
}

function ChartComp({ dateType, chartType, chartRef }: ChartCompProps) {
  const { t } = useTranslation()
  const history = useHistory()

  const { timeFormat } = useSelector((state) => state.common)

  const { chart: data } = useSelector((state) => state.dashboard)

  const isMoreThanOneResult = React.useMemo(() => {
    if (data) return data.length > 1
    else return false
  }, [data])

  const limitFrom = React.useMemo(() => {
    return data ? new Date(data[0]?.time) : undefined
  }, [data])
  const limitTo = React.useMemo(() => {
    return data ? new Date(data[data.length - 1]?.time) : undefined
  }, [data])
  const chartSuggestedXMin = React.useMemo(() => {
    if (limitFrom === undefined || isMoreThanOneResult) return

    switch (dateType) {
      case DateType.MINUTE: {
        return subMinutes(limitFrom, suggestOffset)
      }
      case DateType.HOUR: {
        return subHours(limitFrom, suggestOffset)
      }
      case DateType.DAY: {
        return subDays(limitFrom, suggestOffset)
      }
      case DateType.MONTH: {
        return subMonths(limitFrom, suggestOffset)
      }
    }
  }, [dateType, limitFrom, isMoreThanOneResult])
  const chartSuggestedXMax = React.useMemo(() => {
    if (limitTo === undefined || isMoreThanOneResult) return

    switch (dateType) {
      case DateType.MINUTE: {
        return addMinutes(limitTo, suggestOffset)
      }
      case DateType.HOUR: {
        return addHours(limitTo, suggestOffset)
      }
      case DateType.DAY: {
        return addDays(limitTo, suggestOffset)
      }
      case DateType.MONTH: {
        return undefined
      }
    }
  }, [dateType, limitTo, isMoreThanOneResult])
  const chartXZoomMin = React.useMemo(() => {
    if (limitFrom === undefined) return

    return subYears(limitFrom, 1)
  }, [limitFrom])
  const chartXZoomMax = React.useMemo(() => {
    if (limitTo === undefined) return

    return addYears(limitTo, 1)
  }, [limitTo])

  const chartStepSize = React.useMemo(() => {
    switch (dateType) {
      case DateType.MINUTE: {
        if (limitFrom && limitTo) {
          const diffMinute = differenceInMinutes(limitTo, limitFrom)
          if (diffMinute > oneMonthInMinutes) {
            return 60
          }
        }
        return 1
      }
      case DateType.HOUR: {
        if (limitFrom && limitTo) {
          const diffHour = differenceInHours(limitTo, limitFrom)
          if (diffHour > oneMonthInHours) return 60
        }
        return 1
      }
      default:
        return 1
    }
  }, [limitFrom, limitTo, dateType])

  const chartOptions: ChartOptions | any = {
    animation: false,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 2,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: function (tooltipItem: TooltipItem<ChartType>[]) {
            const title = tooltipItem[0].label
            const dateUnix = Date.parse(title)

            switch (dateType) {
              case DateType.DAY:
                return formatDate(dateUnix, 'MMM dd, yyyy')
              case DateType.MONTH:
                return formatDate(dateUnix, 'MMMM yyyy')
              default:
                return toDate(dateUnix)
            }
          },
        },
      },
      legend: {
        display: false,
      },
      zoom: {
        limits: {
          x: {
            min: chartXZoomMin,
            max: chartXZoomMax,
          },
          y: {
            min: 0,
          },
        },
        zoom: {
          wheel: { enabled: true },
          pinch: { enabled: true },
          mode: 'x',
        },
        pan: {
          enabled: true,
          mode: 'xy',
        },
      },
    },
    scales: {
      x: {
        adapters: {
          date: {
            locale: enUS,
          },
        },
        type: 'time',
        time: {
          tooltipFormat: `yyyy-MM-dd ${timeFormat}`,
          minUnit: 'minute',
          stepSize: chartStepSize,
          unit: dateType,
          displayFormats: {
            day: 'dd MMM',
            hour: `dd MMM ${timeFormat}`,
            minute: `dd MMM ${timeFormat}`,
          },
          round: dateType,
        },
        suggestedMin: chartSuggestedXMin,
        suggestedMax: chartSuggestedXMax,
        ticks: {
          maxTicksLimit: 10,
          maxRotation: 0,
        },
        grid: {
          drawBorder: false,
          offset: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (label: any) {
            if (Math.floor(label) === label) return label
          },
        },
        grid: {
          display: false,
        },
      },
    },
  }

  function getFormattedData(data: RequestChart[]) {
    return {
      labels: data?.map((el) => new Date(el.time)),
      datasets: [
        {
          data: data?.map((el) => el.count),
          borderColor: '#5F2EEA',
          borderWidth: 2,
        },
      ],
    }
  }

  function canvasCallback(canvas: HTMLCanvasElement | null) {
    if (!canvas) return

    const ctx = canvas.getContext('2d')

    if (ctx && data) {
      chartRef.current?.destroy()

      chartRef.current = new Chart(ctx, {
        type: chartType,
        data: getFormattedData(data),
        options: chartOptions,
      })
    }
  }

  return (
    <Stack justifyContent="center" alignItems="center" height={315}>
      {data && data.length === 0 && (
        <Stack alignItems="center" spacing={3} width={380}>
          <Typography variant="text" fontSize={18} textAlign="center">
            {t('request.noResult')}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => history.push('/')}
          >
            {t('request.start')}
          </Button>
        </Stack>
      )}
      {data && data.length > 0 && <canvas ref={canvasCallback} />}
    </Stack>
  )
}

interface Props {
  height: number
}

export const Requests = ({ height }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const theme = useTheme()

  const { isMenuAnimation } = useSelector((state) => state.common)

  const refreshedToken = useSelector(getRefreshedToken)

  const chartRef = React.useRef<Chart | null>(null)

  const dateTypes = [
    { type: DateType.MINUTE, label: t('request.perMinute') },
    { type: DateType.HOUR, label: t('request.perHour') },
    { type: DateType.DAY, label: t('request.perDay') },
    { type: DateType.MONTH, label: t('request.perMonth') },
  ]

  const [dateType, setDateType] = React.useState<DateType>(DateType.MINUTE)
  const [chartType, setChartType] = React.useState<ChartType>('line')

  React.useEffect(() => {
    if (refreshedToken) dispatch(fetchMonitor(dateType))
  }, [refreshedToken, dispatch, dateType])

  const handleChangeChartType = () => {
    if (chartType === 'line') setChartType('bar')
    else setChartType('line')
  }

  const { chart: data } = useSelector((state) => state.dashboard)

  const isHugeData = !!data ? data.length > 250 : false
  return (
    <Box>
      <Card>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} height={40}>
            <CardHeader title={t('request.title')} />
            <Button
              style={{
                marginLeft: 'auto',
                width: 41,
                padding: 0,
                minWidth: 'unset',
              }}
              sx={{
                [theme.breakpoints.only("xs")]: {
                  display: 'none',
                },
              }}
              variant="outlined"
              color="secondary"
              onClick={handleChangeChartType}
            >
              <Tooltip title={String(t('request.changeChartType'))}>
                <TypeIcon />
              </Tooltip>
            </Button>
            <Button
              style={{ width: 41, padding: 0, minWidth: 'unset' }}
              sx={{
                [theme.breakpoints.only("xs")]: {
                  display: 'none',
                },
              }}
              variant="outlined"
              color="secondary"
              onClick={() => chartRef.current?.resetZoom()}
            >
              <Tooltip title={String(t('request.reset'))}>
                <ResetIcon />
              </Tooltip>
            </Button>
            <FormControl sx={{
              [theme.breakpoints.only("xs")]: {
                display: 'none',
              },
            }}>
              <Select
                style={{ height: 40 }}
                value={dateType}
                onChange={(e: SelectChangeEvent<any>) =>
                  setDateType(e.target.value)
                }
                IconComponent={SelectIcon}
              >
                {dateTypes.map((el) => (
                  <MenuItem key={el.type} value={el.type}>
                    {el.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => history.push('/')}
            >
              {t('request.start')}
            </Button>
          </Stack>
          <Box
            sx={{
              [theme.breakpoints.only("xs")]: {
                display: 'none',
              },
            }}
            height={height}
          >
            {(!isMenuAnimation || !isHugeData) && (
              <ChartComp
                chartRef={chartRef}
                chartType={chartType}
                dateType={dateType}
              />
            )}
          </Box>
        </Stack>
      </Card>
    </Box>
  )
}
