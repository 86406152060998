import React from 'react'
import {
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import {
  Grid,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  useTheme,
  Link,
} from '@mui/material'
import { useAccordionStyles } from '../../../style/lookup'
import { Phone } from '../../../store/searcher/reducer'
import { prepareDeepWebResult } from '../../../store/searcher/service'
import { getLinkToProfile, getTitle, isHttpUrl } from '../../../function/string'
import { SearchResult } from '../../../store/searcher/reducer'
import { ResultTitle } from '../../../style/result'
import { LinkPreview } from '../../../component/searcher/LinkPreview'

export interface Name {
  app: Phone
  name: string
}

type Props = {
  apiData: any
}

export const JsonCommon = (props: Props) => {
  const {apiData} = props
  const data = apiData?.apiDataEntities
  return (
    <>
      {
        data && data?.length > 0 && <ResultTitle sx={{marginTop: '20px'}}>API results:</ResultTitle>
      }
      <Stack sx={{backgroundColor: '#F0F2FA', overflowX: 'auto'}}>
        <pre>{JSON.stringify(data, null, ' ')}</pre>
      </Stack>
    </>

  )
}