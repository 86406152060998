import * as React from 'react'
import Joi from 'joi'
import { t } from 'i18next'
import { useDispatch } from '../../store'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormLabel,
  Grid,
  Stack,
} from '@mui/material'
import { InputPassword } from '../../component/InputPassword'
import { updatePassword } from '../../store/settings/actionCreator'
import {
  ErrorsState,
  password,
  passwordRepeat,
  validateForm,
} from '../../function/validation'
import { clearFieldError } from '../../store/common/actionCreator'

const schema = Joi.object({
  oldPassword: password.label(t('field.label.oldPassword')),
  password: password,
  passwordRepeat: passwordRepeat,
})

type Props = {
  onClose: () => void
}

export const DialogChangePassword = ({ onClose }: Props) => {
  const dispatch = useDispatch()

  const [oldPassword, setOldPassword] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [passwordRepeat, setPasswordRepeat] = React.useState('')
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  const handleSubmit = () => {
    const error = validateForm(schema, {
      oldPassword,
      password,
      passwordRepeat,
    })

    if (error) setErrors(error)
    else {
      setErrors(null)
      dispatch(updatePassword(oldPassword, password))
      handleClose()
    }
  }

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [dispatch, password, oldPassword, passwordRepeat])

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog open={true} fullWidth onClose={handleClose}>
      <DialogTitle>{t('settings.changePassword')}</DialogTitle>

      <Divider variant="popup" color="primary" />

      <Stack spacing={2}>
        <Grid container>
          <Grid item xs={5}>
            <FormLabel required>{t('field.label.oldPassword')}</FormLabel>
          </Grid>
          <Grid item xs={7}>
            <InputPassword
              value={oldPassword}
              error={errors?.oldPassword}
              onChange={setOldPassword}
              placeholder={t('field.hint.oldPassword')}
              inputProps={{
                autoFocus: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5}>
            <FormLabel required>{t('field.label.password')}</FormLabel>
          </Grid>
          <Grid item xs={7}>
            <InputPassword
              value={password}
              error={errors?.password}
              onChange={setPassword}
              placeholder={t('field.hint.password')}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5}>
            <FormLabel required>{t('field.label.confirmPassword')}</FormLabel>
          </Grid>
          <Grid item xs={7}>
            <InputPassword
              value={passwordRepeat}
              error={errors?.passwordRepeat}
              onChange={setPasswordRepeat}
              placeholder={t('field.hint.passwordRepeat')}
            />
          </Grid>
        </Grid>
      </Stack>

      <Divider variant="popup" color="primary" />

      <DialogActions>
        <Button size="large" color="primary" onClick={handleClose}>
          {t('common.close')}
        </Button>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={handleSubmit}
        >
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
