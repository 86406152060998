import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  LinearProgress,
  Grid,
} from '@mui/material'
import { useUserPackage } from '../../hook/useUserPackage'

export function PackageRenewal() {
  const { t } = useTranslation()
  const history = useHistory()
  const { packageDays, daysLeft, isExpired } = useUserPackage()

  const progress = React.useMemo(() => {
    const percentage = Math.ceil((daysLeft * 100) / packageDays)

    if (percentage > 100) return 100
    else return percentage
  }, [daysLeft, packageDays])

  return (
    <Card>
      <CardHeader title={t('packageRenewal.title')} />
      <CardContent>
        <Typography variant="body1">{t('packageRenewal.text')}</Typography>
        <LinearProgress variant="determinate" value={progress} />
      </CardContent>
      <CardActions>
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item>
            <Typography>
              {daysLeft}
              {t('common.days')} / {packageDays}days
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="underlined"
              onClick={() => history.push('/packages')}
            >
              {t(isExpired ? 'package.update' : 'package.change')}
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}
