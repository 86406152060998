import { call, put, takeEvery } from 'redux-saga/effects'
import * as actionType from './actionType'
import { api, Response } from '../../function/axios'
import { handleError } from '../../function/error'
import * as actionCreator from './actionCreator'
import { showSnackbar } from '../common/actionCreator'

function* getAdditionaPackage() {
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: '/webint-center/additional-package',
    })

    yield put(actionCreator.postAdditionaPackage(data))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* postAdditionaPackage(action: actionType.PostAdditionalPackage) {
  try {
    const { data }: Response = yield call(api, {
      method: 'POST',
      url: '/webint-center/additional-package',
      body : action.data,
    })

    yield put(actionCreator.setAdditionalPackage(data))

  } catch (e) {
    yield call(handleError, e)
  }
}


function* postToken(action: actionType.PostTokenKey) {
  try {
    const { data }: Response = yield call(api, {
      method: 'POST',
      url: '/webint-center',
      body : action.data,
    })
    
    yield put(actionCreator.setToken(data))

  } catch (e) {
    yield call(handleError, e)
  }
}

function* postAdditionalLookup(action: actionType.PostAdditionalLookup) {
  try {
    const { data }: Response = yield call(api, {
      method: 'POST',
      url: '/webint-center/additional-lookups',
      body : action.data,
    })
    yield put(actionCreator.setAdditionalLookup(data))

  } catch (e) {
    yield call(handleError, e)
  }
}

function* getAdditionalookup() {
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: '/webint-center/additional-lookups',
    })

    yield put(actionCreator.postAdditionalLookup(data))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* postQuota(action: actionType.PostQuota) {
  try {
    const { data }: Response = yield call(api, {
      method: 'POST',
      url: '/webint-center/user-quota',
      body : action.data,
    })
    yield put(actionCreator.setQuota(data))

  } catch (e) {
    yield call(handleError, e)
  }
}

function* setQuotaTrial(action: actionType.SetQuotaTrial) {
  try {
    const { data }: Response = yield call(api, {
      method: 'POST',
      url: '/webint-center/user-quota-trial',
      body : action.data,
    })
    yield put(actionCreator.setQuota(data))

  } catch (e) {
    yield call(handleError, e)
  }
}


export function* watchToken() {
  yield takeEvery(actionType.POST_TOKEN, postToken)
}

export function* watchAdditionalLookup() {
  yield takeEvery(actionType.POST_ADDITIONAL_LOOKUP, postAdditionalLookup)
  yield takeEvery(actionType.GET_ADDITIONAL_LOOKUP, getAdditionalookup)
}

export function* watchAdditionalPackage() {
  yield takeEvery(actionType.POST_ADDITIONAL_PACKAGE, postAdditionaPackage)
  yield takeEvery(actionType.GET_ADDITIONAL_PACKAGE, getAdditionaPackage)
}

export function* watchUserQuota() {
  yield takeEvery(actionType.POST_QUOTA, postQuota)
  yield takeEvery(actionType.SET_QUOTA_TRIAL, setQuotaTrial)
}


