import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Joi from 'joi'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from '../store'
import { requestForgot } from '../store/auth/actionCreator'
import auth_ from '../sass/auth.module.sass'
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  OutlinedInput,
  Stack,
} from '@mui/material'
import { Page } from '../component/auth/Page'
import {
  email,
  ErrorsState,
  preventEmptySpaceOnInput,
  validateForm,
} from '../function/validation'
import { FormError } from '../component/FormError'
import { clearFieldError } from '../store/common/actionCreator'

const schema = Joi.object({
  email: email,
})

export function ForgotPage() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const { fieldErrors } = useSelector((state) => state.common)

  const [email, setEmail] = React.useState('')
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(clearFieldError)

    const error = validateForm(schema, { email })

    if (error) setErrors(error)
    else {
      setErrors(null)
      dispatch(requestForgot(email))
    }
  }

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [email, dispatch])
  return (
    <Page>
      <Grid className={auth_.title} item>
        {t('auth.forgot.title')}
      </Grid>

      <Grid className={auth_.hint_light} item mt={10} mb={3}>
        {t('auth.forgot.hint')}
      </Grid>

      <Grid item>
        <form onSubmit={handleSubmit} noValidate>
          <Stack spacing={3}>
            <FormControl>
              <FormLabel required>{t('field.label.email')}</FormLabel>
              <OutlinedInput
                name="email"
                placeholder={t('field.label.email')}
                value={email}
                error={Boolean(fieldErrors.forgot || errors?.email)}
                onChange={(e) => preventEmptySpaceOnInput(e, setEmail)}
                autoFocus
              />
              <FormError error={fieldErrors.forgot || errors?.email} />
            </FormControl>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
            >
              {t('auth.forgot.resetPassword')}
            </Button>

            <Button onClick={() => history.push('/auth')}>
              {t('auth.register.back')}
            </Button>
          </Stack>
        </form>
      </Grid>
    </Page>
  )
}
