import { ApikeyAction } from './actionType'

export interface GeneratedKeys {
  key: string
  secret: string
}

export interface Apikey {
  id: number
  length: number
  key: string
  domains: string[]
  createdTime: string
}

export type ApikeyState = {
  developerVideo: Blob | undefined
  apikey: Apikey | undefined | null
  generatedKeys: GeneratedKeys | null
}

const initialState: ApikeyState = {
  developerVideo: undefined,
  apikey: undefined,
  generatedKeys: null,
}

export function apikeyReducer(state = initialState, action: ApikeyAction) {
  switch (action.type) {
    case 'apikey/SET_DEVELOPER_VIDEO':
      return {
        ...state,
        developerVideo: action.file,
      }
    case 'apikey/SET_APIKEY':
      return {
        ...state,
        apikey: action.data,
      }
    case 'apikey/SET_GENERATED_KEYS':
      return {
        ...state,
        generatedKeys: action.data,
      }
    default:
      return state
  }
}
