import { SupportAction } from './actionType'
import { TicketType } from './reducer'

export const getTicketTypes: SupportAction = {
  type: 'support/GET_TICKET_TYPES',
}

export const setTicketTypes = (data: TicketType[]): SupportAction => ({
  type: 'support/SET_TICKET_TYPES',
  data,
})

export const postTicket = (
  data: FormData,
  callback: () => void,
): SupportAction => ({
  type: 'support/SET_APIKEY',
  data,
  callback,
})

export const deleteTicket = (
  id: number,
  callback: () => void,
): SupportAction => ({
  type: 'support/DELETE_TICKET',
  id,
  callback,
})

export const sendMessage = (formData: FormData): SupportAction => ({
  type: 'support/SEND_MESSAGE',
  formData,
})
