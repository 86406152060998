import * as React from 'react'
import Joi from 'joi'
import { t } from 'i18next'
import { ExpandMore as SelectIcon } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from '@mui/material'
import countryJson from '../../json/country.json'
import { useDispatch, useSelector } from '../../store'
import { ErrorsState, select, validateForm } from '../../function/validation'
import { FormError } from '../../component/FormError'
import {
  editBillingAddress,
  fetchBillingAddress,
} from '../../store/settings/actionCreator'
import { clearFieldError } from '../../store/common/actionCreator'

const max40Validation = Joi.string().required().min(2).max(40)

const max100Validation = Joi.string().required().min(2).max(100)

const max20Validation = Joi.string().required().min(2).max(20)

const schema = Joi.object({
  country: select.label(t('field.label.country')),
  city: max40Validation.label(t('field.label.city')),
  state: max100Validation.label(t('field.label.state')),
  address: max100Validation.label(t('field.label.address')),
  postCode: max20Validation.label(t('field.label.postCode')),
})

type Props = {
  onClose: () => void
}

export const DialogBillingAddress = ({ onClose }: Props) => {
  const dispatch = useDispatch()

  const { billingAddress: data } = useSelector((state) => state.settings)

  React.useEffect(() => {
    if (data === null) return

    setCountry(data.country || 'null')
    setCity(data.city || '')
    setState(data.state || '')
    setAddress(data.address || '')
    setPostCode(data.postCode || '')
  }, [data])

  const [country, setCountry] = React.useState(data?.country || 'null')
  const [city, setCity] = React.useState(data?.city || '')
  const [state, setState] = React.useState(data?.state || '')
  const [address, setAddress] = React.useState(data?.address || '')
  const [postCode, setPostCode] = React.useState(data?.postCode || '')
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  React.useEffect(() => {
    dispatch(fetchBillingAddress)
  }, [dispatch])

  const requestData = {
    country,
    city: city.trim(),
    state: state.trim(),
    address: address.trim(),
    postCode: postCode.trim(),
  }

  const handleSubmit = () => {
    const error = validateForm(schema, requestData)

    if (error) setErrors(error)
    else {
      setErrors(null)
      dispatch(editBillingAddress(requestData))
      onClose()
    }
  }

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [dispatch, country, city, state, address, postCode])

  return (
    <Dialog open={true} fullWidth onClose={onClose}>
      <DialogTitle>{t('settings.billingAddress')}</DialogTitle>

      <Divider variant="popup" color="primary" />

      <Stack spacing={2}>
        <Grid container>
          <Grid item xs={5}>
            <FormLabel required>{t('field.label.country')}</FormLabel>
          </Grid>
          <Grid item xs={7}>
            <FormControl fullWidth>
              <Select
                value={country}
                error={Boolean(errors?.country)}
                onChange={(e) => setCountry(e.target.value)}
                IconComponent={SelectIcon}
              >
                <MenuItem value="null">
                  {t('field.hint.chooseCountry')}
                </MenuItem>
                {countryJson.map((el) => (
                  <MenuItem key={el.code} value={el.code}>
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
              <FormError error={errors?.country} />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5}>
            <FormLabel required>{t('field.label.city')}</FormLabel>
          </Grid>
          <Grid item xs={7}>
            <FormControl fullWidth>
              <OutlinedInput
                value={city}
                error={Boolean(errors?.city)}
                placeholder={t('field.hint.city')}
                onChange={(e) => setCity(e.target.value)}
              />
              <FormError error={errors?.city} />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5}>
            <FormLabel required>{t('field.label.state')}</FormLabel>
          </Grid>
          <Grid item xs={7}>
            <FormControl fullWidth>
              <OutlinedInput
                value={state}
                error={Boolean(errors?.state)}
                placeholder={t('field.hint.state')}
                onChange={(e) => setState(e.target.value)}
              />
              <FormError error={errors?.state} />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5}>
            <FormLabel required>{t('field.label.address')}</FormLabel>
          </Grid>
          <Grid item xs={7}>
            <FormControl fullWidth>
              <OutlinedInput
                value={address}
                error={Boolean(errors?.address)}
                placeholder={t('field.hint.address')}
                onChange={(e) => setAddress(e.target.value)}
              />
              <FormError error={errors?.address} />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5}>
            <FormLabel required>{t('field.label.postCode')}</FormLabel>
          </Grid>
          <Grid item xs={7}>
            <FormControl fullWidth>
              <OutlinedInput
                value={postCode}
                error={Boolean(errors?.postCode)}
                placeholder={t('field.hint.postCode')}
                onChange={(e) => setPostCode(e.target.value)}
              />
              <FormError error={errors?.postCode} />
            </FormControl>
          </Grid>
        </Grid>
      </Stack>

      <Divider variant="popup" color="primary" />

      <DialogActions>
        <Button color="primary" size="large" onClick={onClose}>
          {t('common.close')}
        </Button>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={handleSubmit}
        >
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
