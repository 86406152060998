import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  DialogContent,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Divider,
  Avatar,
  Link,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Popover,
  PopoverOrigin,
  Grid,
} from '@mui/material'
import sprite_ from '../../../sass/sprite.module.sass'
import { usePostObserverDialog } from '../../../style/lookup'
import { Source } from '../../../store/searcher/reducer'
import {
  isTextLimitPassed,
  parseText,
  truncate,
} from '../../../function/string'
import { search } from '../../../function/data'

import {
  getJSXByWord,
  RenderValue,
} from '../../../component/searcher/RenderData'
import { useSelector } from '../../../store'
import { getViewResult } from '../../../store/searcher/selector'
import { preparePostResult } from '../../../store/searcher/service'
import { usePopover } from '../../../hook/usePopover'

const postLimit = 45

const searchExcludes = ['profile_id', 'keyphrase', 'keyword']

function getAccordionStyle(idx: number, textLength: number) {
  return {
    background: idx % 2 ? '#FFF' : '#F0F2FA',
    margin: '0px !important',
    cursor: textLength > postLimit ? 'pointer' : 'auto',
  }
}

function getFilterIcon(source: Source, sourceFilter: Source[]) {
  if (sourceFilter.includes(source)) return sprite_['social__large_' + source]
  else return sprite_['social__small_' + source]
}

type Props = {
  pdfData: any
}

type PropsLinks = {
  links: any
}

export const Links = ({ links }: PropsLinks) => {
  return (
    <React.Fragment>
      <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
        {links.map((el: any, idx: number) => {
          return el.url ? (
            <Link variant="underlined" href={el.url} target="_blank" key={idx}>
              {el.name}
            </Link>
          ) : null
        })}
      </Grid>
    </React.Fragment>
  )
}

export const PostPagePdf = ({ pdfData: apiData }: Props) => {
  const { t } = useTranslation()
  const dialogClasses = usePostObserverDialog()

  const data = preparePostResult(apiData)

  const initState = React.useMemo(() => {
    return data.data?.map((_) => true) || []
  }, [data.data])

  const [accordionOpen, setAccordionOpen] = React.useState<boolean[]>(initState)
  const [filter, setFilter] = React.useState<Source[]>([])
  const [searchValue, setSearchValue] = React.useState('')

  const filteredData = React.useMemo(() => {
    let filtered =
      filter.length > 0
        ? data.data?.filter((el) => filter.includes(el.from))
        : data.data

    if (searchValue.length > 0) {
      filtered = filtered?.filter((row) =>
        search(searchValue, row, searchExcludes),
      )
    }

    return filtered
  }, [data.data, filter, searchValue])

  const sources = React.useMemo(() => {
    return [...new Set(data.data?.map((el) => el.from))] || []
  }, [data.data])

  const handleClickPost =
    (url: string) => (e: React.MouseEvent<HTMLSpanElement>) => {
      e.stopPropagation()

      window.open(url, '_blank')?.focus()
    }

  const handleChangeAccordion =
    (idx: number) => (event: React.SyntheticEvent, expanded: boolean) => {
      setAccordionOpen((state) => {
        const newState = [...state]
        newState[idx] = expanded
        return newState
      })
    }

  const handleSourceFilter = (source: Source) => {
    setFilter((state) => {
      const index = state.indexOf(source)
      const newState = [...state]

      if (index > -1) {
        newState.splice(index, 1)
        return newState
      } else {
        return [...state, source]
      }
    })
  }

  const getProfileId = (id: string | number, source: string) => {
    if (source !== 'vk') return '@' + id
    return id
  }

  const fileName = React.useMemo(() => {
    if (apiData) {
      const date = new Date(String(apiData?.createdTime))
      const days = date.getDate()
      const month = date.getMonth()
      const year = date.getFullYear()
      const type =
        apiData?.type.charAt(0).toUpperCase() + apiData?.type.slice(1)
      const criteria = apiData.criteria.replace(' ', '_')
      return `${type}_${criteria}_${days}_${month}_${year}.pdf`
    }
  }, [apiData])

  return (
    <div style={{ padding: '30px', backgroundColor: 'white' }}>
      <Stack>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
            mb={3}
          >
            <Typography variant="semiBold" fontSize={24} lineHeight={1}>
              {t('searcher.post.popup.title')}
            </Typography>

            <Stack direction="row" alignItems="flex-start" height={42}>
              <Stack
                direction="row"
                alignItems="flex-end"
                sx={{ marginTop: '-8px' }}
              >
                {data.sources.map((el, idx) => (
                  <IconButton
                    key={idx}
                    onClick={() => handleSourceFilter(el.name)}
                  >
                    <Box className={getFilterIcon(el.name, filter)} />
                  </IconButton>
                ))}
              </Stack>

              <Typography fontSize={15} style={{ color: '#8B929B' }} ml={2}>
                {t('searcher.name.popup.searchCriteriaUsed')}:
              </Typography>
              <Typography fontSize={15} style={{ color: '#00D348' }} ml={1}>
                {data.criteria}
              </Typography>
            </Stack>
            {/*input */}
            <OutlinedInput
              value={searchValue}
              placeholder={t('searcher.name.popup.searchPlaceholder')}
              onChange={(e) => setSearchValue(e.target.value)}
              sx={{
                background: 'none',
              }}
              endAdornment={
                <InputAdornment position="end">
                  <Box className={sprite_.common__search} />
                </InputAdornment>
              }
              style={{ width: 192, height: 38, visibility: 'hidden' }}
            />
          </Stack>
        </DialogTitle>
        {/* start */}
        <DialogContent>
          {filteredData?.map((el, idx) => {
            const links: any = [
              { name: 'image_1', url: el?.images_url[0] },
              { name: 'image_2', url: el?.images_url[1] },
              { name: 'image_3', url: el?.images_url[2] },
              { name: 'image_4', url: el?.images_url[3] },
              { name: 'image_5', url: el?.images_url[4] },
              { name: 'video_1', url: el?.videos_url[0] },
              { name: 'video_2', url: el?.videos_url[1] },
            ]

            return (
              <Accordion
                key={idx}
                square
                expanded={accordionOpen[idx]}
                onChange={handleChangeAccordion(idx)}
                sx={{ position: 'static' }}
              >
                <AccordionSummary
                  style={getAccordionStyle(idx, el.text.length)}
                >
                  <Stack direction="row" alignItems="center" spacing={3}>
                    <Box pl={2}>
                      <Avatar src={el.original_post_user_image} />
                    </Box>
                    <Box width={100} textAlign="center">
                      <Typography
                        variant="bold"
                        sx={{
                          overflowWrap: 'break-word',
                        }}
                      >
                        {el.original_post_user_name}
                      </Typography>
                    </Box>
                    <Box className={sprite_['social__small_' + el.from]} />
                    <Box
                      width={100}
                      sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      {el.profile_id && (
                        <Link
                          variant="underlined"
                          href={el?.posted_by_profile_url}
                          target="_blank"
                        >
                          {getProfileId(el?.profile_id, el?.from)}
                        </Link>
                      )}
                    </Box>
                    <Box width={150}>
                      <Typography>{el.timeStamp}</Typography>
                    </Box>
                    <Box width={400}>
                      {Boolean(el.text) ? (
                        <Typography
                          variant="bold"
                          color="primary"
                          onClick={
                            isTextLimitPassed(el.text, postLimit)
                              ? undefined
                              : handleClickPost(el.post_url)
                          }
                        >
                          {truncate(parseText(el.text), postLimit)}
                        </Typography>
                      ) : (
                        <Link
                          variant="bold"
                          color="primary"
                          href={el.post_url}
                          target="_blank"
                        >
                          {truncate(el.post_url, postLimit)}
                        </Link>
                      )}
                    </Box>

                    <div style={{ marginLeft: '360px', display: 'flex' }}>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                        width={75}
                        height={26}
                        sx={{ padding: '0 5px' }}
                      >
                        <Box className={sprite_.common__like} />
                        <Typography>{el.total_reactions_count}</Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                        width={75}
                        height={26}
                      >
                        <Box className={sprite_.common__comment} />
                        <Typography>{el.comments_count}</Typography>
                      </Stack>
                    </div>
                  </Stack>
                </AccordionSummary>
                {/*start Details */}

                <AccordionDetails
                  style={getAccordionStyle(idx, el.text.length)}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    px={2}
                  >
                    <Box width={'90%'} sx={{ overflowWrap: 'break-word' }}>
                      <RenderValue
                        label="text"
                        value={el.text}
                        variant="inherit"
                        source={el.from}
                      />
                      {/*LINKS*/}
                      <Links links={links} />
                    </Box>
                    <Box
                      className={sprite_['social__small_' + el.from]}
                      ml="auto"
                    />
                    <Link
                      href={el.post_url}
                      target="_blank"
                      variant="underlined"
                      color="primary"
                      ml={1}
                    >
                      {t('searcher.post.popup.originalPost')}
                    </Link>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </DialogContent>
      </Stack>
    </div>
  )
}
