import * as React from 'react'
import { AddUser as UploadIcon } from 'react-iconly'
import sprite_ from '../sass/sprite.module.sass'
import { useSelector } from '../store'
import { Avatar as MuiAvatar, Box, IconButton } from '@mui/material'
import { DialogImageUpload } from '../container/settings/DialogImageUpload'
import { useAvatarStyles } from '../style/common'

type Props = {
  size: 'small' | 'large'
  upload?: boolean
}

export const Avatar = ({ size, upload = false }: Props) => {
  const classes = useAvatarStyles()

  const user = useSelector((state) => state.settings)

  const [uploadOpen, setUploadOpen] = React.useState(false)

  const imgUrl = user.avatar
  let fullName = ''

  if (user.avatar === null) {
    if (user.firstname && user.lastname) {
      fullName = `${user.firstname} ${user.lastname}`
    }
  }

  return (
    <>
      <IconButton size={size} onClick={() => setUploadOpen(true)} disableRipple>
        {imgUrl && upload === false && (
          <MuiAvatar
            src={imgUrl}
            alt={fullName}
            classes={classes}
            className={size === 'small' ? classes.small : classes.large}
          />
        )}
        {imgUrl === null && upload === false && (
          <Box className={sprite_.common__user} />
        )}
        {upload && (
          <>
            {imgUrl ? (
              <MuiAvatar
                src={imgUrl}
                alt={fullName}
                classes={classes}
                className={classes.uploadAvatar}
              />
            ) : (
              <Box classes={classes} className={classes.uploadBtn}>
                <UploadIcon set="light" />
              </Box>
            )}
          </>
        )}
      </IconButton>
      {uploadOpen && upload && (
        <DialogImageUpload src={imgUrl} onClose={() => setUploadOpen(false)} />
      )}
    </>
  )
}
