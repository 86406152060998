import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Step, StepLabel, Stepper as MuiStepper } from '@mui/material'

interface StepperProps {
  stepActive: number
  stepIsSkipped: (index: number) => boolean
}

export function RegisterStepper({ stepActive, stepIsSkipped }: StepperProps) {
  const { t } = useTranslation()

  const steps = [t('auth.steps.1'), t('auth.steps.2')]

  return (
    <MuiStepper activeStep={stepActive}>
      {steps.map((label, index) => {
        const stepProps: { completed?: boolean } = {}
        const labelProps: { optional?: React.ReactNode } = {}

        if (stepIsSkipped(index)) {
          stepProps.completed = false
        }
        return (
          <Step key={label} {...stepProps}>
            <StepLabel {...labelProps}>{label}</StepLabel>
          </Step>
        )
      })}
    </MuiStepper>
  )
}
