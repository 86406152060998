import { ApikeyAction, EditData } from './actionType'
import { Apikey, GeneratedKeys } from './reducer'

export const fetchDeveloperVideo: ApikeyAction = {
  type: 'apikey/GET_DEVELOPER_VIDEO',
}

export const setDeveloperVideo = (file: Blob): ApikeyAction => ({
  type: 'apikey/SET_DEVELOPER_VIDEO',
  file,
})

export const generateKeys = (length: number): ApikeyAction => ({
  type: 'apikey/GENERATE_KEYS',
  length,
})

export const setGeneratedKeys = (data: GeneratedKeys | null): ApikeyAction => ({
  type: 'apikey/SET_GENERATED_KEYS',
  data,
})

export const postApikey = (data: object): ApikeyAction => ({
  type: 'apikey/POST_APIKEY',
  data,
})

export const fetchApikey: ApikeyAction = { type: 'apikey/GET_APIKEY' }

export const setApikey = (data: Apikey | null): ApikeyAction => ({
  type: 'apikey/SET_APIKEY',
  data,
})

export const editApikey = (data: EditData): ApikeyAction => ({
  type: 'apikey/EDIT_APIKEY',
  data,
})

export const deleteApikey = (id: number): ApikeyAction => ({
  type: 'apikey/DELETE_APIKEY',
  id,
})
