import { Tabs, Tab, Box } from '@mui/material'
import { el } from 'date-fns/locale'
import React from 'react'
import PostItem from '../container/searcher/details/items/PostItem'
import WebmiiItem from '../container/searcher/details/items/WebmiiItem'
import { RenderProfileData } from './searcher/RenderData'
import { NameProfileData } from '../container/searcher/details/NameCommon'
import { Social, Source } from '../store/searcher/reducer'

type ProfileTabProps = {
    el: NameProfileData,
    searchValue: string,
}

export function ProfileTabs(props: ProfileTabProps) {
    const {el, searchValue} = props
    const [currentTab, setCurrentTab] = React.useState(0)

    function handleChangeTab(e: React.SyntheticEvent, value: number){
        if (value === 0){
            setCurrentTab(0)
        }
        else if (el.posts.length > 0){
            setCurrentTab(value)
        }
    }

    return (
        <>
          <Tabs value={currentTab} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab label={"Profile"} id={`simple-tab-0`} aria-controls={`simple-tabpanel-0`} />
            {
              el.posts?.length > 0 && <Tab label={"Posts"} id={`simple-tab-1`} aria-controls={`simple-tabpanel-1`} />
            }
          </Tabs>
          <div
            role="tabpanel"
            hidden={currentTab !== 0}
            id={`simple-tabpanel-0`}
            aria-labelledby={`simple-tab-0`}
          >
            {currentTab === 0 && (
              <Box sx={{ p: 3 }}>
                {el.originalObject.profileData && (
                  (() => {
                    switch (el.originalObject.from as (Source)) {
                      case 'webmii':
                        return <WebmiiItem el={el.originalObject} searchValue={searchValue}/>
                      default:
                        return <RenderProfileData data={el.originalObject.profileData} fullData={el.originalObject} search={searchValue} />
                    }
                  })()
                )}
              </Box>
            )}
          </div>

          {
            el.posts?.length > 0 && <div
              role="tabpanel"
              hidden={currentTab !== 1}
              id={`simple-tabpanel-1`}
              aria-labelledby={`simple-tab-1`}
            >
              <Box sx={{ p: 3 }}>
                {
                  el.posts.map((post: any, idx: number) => <PostItem key={idx} el={post} searchValue={searchValue}/>)
                }
              </Box>
            </div>
          }
        </>
      )
}