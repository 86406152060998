import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationEn from './en.json'
import { LocalStorage } from '../enum/storage'

const fallbackLng = 'en'
const localStorageValue = localStorage.getItem(LocalStorage.LANG)

if (localStorageValue === null)
  localStorage.setItem(LocalStorage.LANG, fallbackLng)

export const getLocaleJson = (lang: string) => {
  switch (lang) {
    case 'en':
    default:
      return translationEn
  }
}

const translate = i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEn },
    // he: {translation: translationHe},
  },
  lng: localStorageValue || undefined,
  fallbackLng: fallbackLng,
  interpolation: { escapeValue: false },
})

export default translate
