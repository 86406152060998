import * as React from 'react'
import Joi from 'joi'
import { t } from 'i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextareaAutosize,
  Typography,
} from '@mui/material'
import { useDispatch } from '../store'
import { postPersonalPlan } from '../store/package/actionCreator'
import {
  email,
  ErrorsState,
  preventEmptySpaceOnInput,
  validateForm,
} from '../function/validation'
import { FormError } from './FormError'
import { clearFieldError } from '../store/common/actionCreator'

const LIMIT_TEXT = 500

const schema = Joi.object({
  email: email,
  text: Joi.string()
    .required()
    .min(5)
    .max(LIMIT_TEXT)
    .label(t('field.label.request')),
})

type Props = {
  onClose: () => void
}

export const PopupRequestCustomPackage = ({ onClose }: Props) => {
  const dispatch = useDispatch()

  const [email, setEmail] = React.useState('')
  const [text, setText] = React.useState('')
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  const handleSubmit = () => {
    const error = validateForm(schema, { email, text: text.trim() })

    if (error) setErrors(error)
    else {
      setErrors(null)
      dispatch(postPersonalPlan(email, text.trim()))
      onClose()
    }
  }

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [dispatch, email, text])
  return (
    <Dialog open={true} fullWidth onClose={onClose}>
      <DialogTitle>{t('customPackage.requestCustom')}</DialogTitle>

      <Divider variant="popup" color="primary" />

      <Stack spacing={2} sx={{ width: '100%' }}>
        <Grid container>
          <Grid item xs={2}>
            <FormLabel>{t('field.label.email')}</FormLabel>
          </Grid>
          <Grid item xs={10}>
            <FormControl sx={{ width: '100%' }}>
              <OutlinedInput
                value={email}
                error={Boolean(errors?.email)}
                placeholder={t('field.hint.email')}
                onChange={(e) => preventEmptySpaceOnInput(e, setEmail)}
                sx={{ width: '100%' }}
              />
              <FormError error={errors?.email} />
            </FormControl>
          </Grid>
        </Grid>

        <FormControl>
          <TextareaAutosize
            className={errors?.text ? 'form__textarea_error' : 'form__textarea'}
            minRows={8}
            placeholder={t('field.hint.request')}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />

          <FormError error={errors?.text} />
        </FormControl>
        <Typography
          sx={{
            width: '100%',
            position: 'relative',
            top: '-10px',
            textAlign: 'end',
          }}
          color={text.length > LIMIT_TEXT ? 'error' : undefined}
        >
          {text.length}/{LIMIT_TEXT}
        </Typography>
      </Stack>

      <Divider
        variant="popup"
        color="primary"
        sx={{
          marginTop: '0px',
        }}
      />

      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={onClose}
        >
          {t('common.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit}
        >
          {t('settings.sendRequest')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
