import { call, put, takeEvery } from 'redux-saga/effects'
import { handleError } from '../../function/error'
import * as actionType from './actionType'
import { api, Response } from '../../function/axios'
import { showSnackbar } from '../common/actionCreator'
import { setTicketTypes } from './actionCreator'

function* getTicketTypes() {
  try {
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: '/ticket-type',
    })

    yield put(setTicketTypes(data))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* post(action: actionType.PostTicket) {
  try {
    const { data }: Response = yield call(api, {
      method: 'POST',
      url: '/ticket',
      body: action.data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* remove(action: actionType.DeleteTicket) {
  try {
    const { data }: Response = yield call(api, {
      method: 'DELETE',
      url: `/ticket/${action.id}`,
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* sendMessage(action: actionType.SendMessage) {
  try {
    yield call(api, {
      method: 'POST',
      url: '/comment',
      body: action.formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  } catch (e) {
    yield call(handleError, e)
  }
}

export function* watchSupport() {
  yield takeEvery(actionType.GET_TICKET_TYPES, getTicketTypes)
  yield takeEvery(actionType.POST_TICKET, post)
  yield takeEvery(actionType.DELETE_TICKET, remove)
  yield takeEvery(actionType.SEND_MESSAGE, sendMessage)
}
