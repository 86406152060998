import * as React from 'react'
import { User as DefaultIcon } from 'react-iconly'
import { useTranslation } from 'react-i18next'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import settings_ from '../../sass/settings.module.sass'
import { useDispatch } from '../../store'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid, useTheme,
} from '@mui/material'
import { uploadImage, deleteImage } from '../../store/settings/actionCreator'
import { showSnackbar } from '../../store/common/actionCreator'
import { blobToBase64, dataURItoBlob, resizeImage } from '../../function/image'

type Props = {
  src: string | null
  onClose: any
}

export const DialogImageUpload = ({ src, onClose }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()

  const cropperRef = React.useRef<HTMLImageElement>(null)

  const [base64, setBase64] = React.useState(undefined)
  const [crop, setCrop] = React.useState<string | null>(null)

  const cropperSrc = React.useMemo(() => {
    return base64 || src
  }, [src, base64])

  const handleUpload = ({ target }: any) => {
    const fileTypes = ['jpg', 'jpeg', 'png']
    const file = target.files[0]
    const extension = file.name.split('.').pop().toLowerCase()
    const isSuccess = fileTypes.indexOf(extension) > -1
    const isOverWeight = file.size > 1024 * 1024 * 4
    if (isOverWeight) {
      dispatch(showSnackbar('error', 'Photo size should be less than 4 Mb.'))
    } else {
      if (isSuccess) {
        const fileReader = new FileReader()

        fileReader.readAsDataURL(file)

        fileReader.onload = (e: any) => {
          if (e.target) {
            setBase64(e.target.result)
          }
        }
      } else {
        dispatch(showSnackbar('error', t('message.error.profilePicture')))
      }
    }
  }

  const handleCrop = (e: any) => {
    const imageElement: any = cropperRef?.current
    const cropper: any = imageElement?.cropper

    setCrop(cropper.getCroppedCanvas().toDataURL())
  }

  const handleSubmit = async () => {
    if (crop) {
      const imageBlob: any = dataURItoBlob(crop)
      const resizedImageBlob: any = await resizeImage(imageBlob)
      const base64: any = await blobToBase64(resizedImageBlob)
      dispatch(uploadImage(base64))
      onClose()
    } else
      dispatch(showSnackbar('error', t('message.error.profilePictureRequired')))
  }

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <DialogTitle>{t('settings.picture')}</DialogTitle>

      <Divider variant="popup" color="primary" />

      <DialogContent>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            {cropperSrc ? (
              <Cropper
                ref={cropperRef}
                src={cropperSrc}
                style={{ height: 200, width: 200 }}
                viewMode={3}
                dragMode="move"
                autoCropArea={1}
                cropBoxResizable={false}
                cropBoxMovable={false}
                guides={false}
                center={false}
                minCropBoxWidth={170}
                minCropBoxHeight={170}
                crop={handleCrop}
              />
            ) : (
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <div className={settings_.defaultAvatar}>
                    <DefaultIcon />
                  </div>
                </Grid>
                <Grid item>{t('settings.pictureHint')}</Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <Divider variant="popup" color="primary" />

      <DialogActions sx={{
        [theme.breakpoints.only("xs")]: {
          flexDirection: 'row',
          flexWrap: 'wrap',
          lineHeight: 3,
        },
      }}>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={() => dispatch(deleteImage)}
          disabled={src === null}
        >
          {t('common.delete')}
        </Button>
        <input
          type="file"
          accept="image/*"
          id="uploadImage"
          multiple
          style={{ display: 'none' }}
          onChange={handleUpload}
        />
        <label htmlFor="uploadImage" style={{ marginLeft: 8 }}>
          <Button variant="outlined" color="primary" component="span">
            {t('settings.uploadNewImage')}
          </Button>
        </label>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={cropperSrc === null}
        >
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
