import * as firebaseMessaging from 'firebase/messaging'
import { call, put, takeEvery } from 'redux-saga/effects'
import { api, Response } from '../../function/axios'
import * as actionCreator from './actionCreator'
import * as actionType from './actionType'
import { handleError } from '../../function/error'
import { refreshToken } from '../auth/service'

const tokenOptions: firebaseMessaging.GetTokenOptions = {
  vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
}

// function* initNotification() {
//   try {
//     let messaging = null

//     const browserSupported: boolean = yield call(
//       () => new Promise((resolve) => {
//         firebaseMessaging.isSupported().then((_result) => {
//           resolve(_result)
//         })
//       }),
//     )
//     if (browserSupported) {
//       messaging = firebaseMessaging.getMessaging()
//       const currentToken: string = yield call(
//         firebaseMessaging.getToken,
//         messaging,
//         tokenOptions,
//       )

//       yield call(api, {
//         method: 'PUT',
//         url: '/users/firebase-token',
//         body: {
//           firebaseToken: currentToken,
//         },
//       })
//     }
//   } catch (e) {
//     yield call(handleError, e)
//   }
// }

function* getNotification(action: actionType.GetNotification) {
  try {
    if (action.refreshToken) yield call(refreshToken)

    const { data } = yield call(api, {
      method: 'GET',
      url: `/notifications?offset=${action.offset}&limit=${action.limit}`,
    })

    if (action.fetchMore) yield put(actionCreator.addNotification(data.list))
    else yield put(actionCreator.setNotification(data.list))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* patchCampaign(action: actionType.PatchCampaign) {
  try {
    yield call(api, {
      method: 'PATCH',
      url: `/marketing-campaign/${action.id}`,
      params: {
        count: action.action,
      },
    })
  } catch (e) {
    yield call(handleError, e)
  }
}

function* getTable(action: actionType.GetTable) {
  try {
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: action.url,
      params: action.params,
    })

    yield put(actionCreator.setTable(data, action.tableId))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* clearNotifications() {
  try {
    const { data }: Response = yield call(api, {
      method: 'DELETE',
      url: '/notifications/',
    })
    yield put(actionCreator.clearNotificationsSuccess())
    yield put(actionCreator.showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}
function* getbackendAlive(){
  try {
    // const { data }: Response = yield call(api, {
    const response: Response = yield call(api, {
      method: 'GET',
      url: '/ping',
    })
    if (response.data === 'pong') {
      yield put(actionCreator.setBackendAlive(true))
    }
  } catch (e) {
    yield call(handleError, e)
  }
}

export function* watchCommon() {
  // yield takeEvery(actionType.INIT_NOTIFICATION, initNotification)
  yield takeEvery(actionType.GET_NOTIFICATION, getNotification)
  yield takeEvery(actionType.PATCH_CAMPAIGN, patchCampaign)
  yield takeEvery(actionType.GET_TABLE, getTable)
  yield takeEvery(actionType.CLEAR_NOTIFICATIONS, clearNotifications)
  yield takeEvery(actionType.GET_BACKEND_ALIVE, getbackendAlive)
}
