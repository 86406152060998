import { PackageAction } from './actionType'

export const fetchPackage: PackageAction = { type: 'package/GET_PACKAGE' }

export const setPackage = (data: object[]): PackageAction => ({
  type: 'package/SET_PACKAGE',
  data,
})

export const postPersonalPlan = (
  email: string,
  text: string,
): PackageAction => ({
  type: 'package/POST_PERSONAL_PLAN',
  email,
  text,
})
export const doReferenceCharge = (
  productId: number,
  callback: () => void,
): PackageAction => ({
  type: 'package/DO_REFERENCE_CHARGE',
  productId,
  callback,
})
