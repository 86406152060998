import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import auth_ from '../sass/auth.module.sass'
import { Box, Button, Grid, Typography } from '@mui/material'
import { Page } from '../component/auth/Page'

interface State {
  email: string
}

export function ForgotDonePage() {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation<State>()

  const state = location.state || {}

  return (
    <Page>
      <Grid className={auth_.title} item>
        {t('auth.forgot.title')}
      </Grid>

      <Grid item>
        <Box className={auth_.hint_large} mt={10} mb={3}>
          <Typography fontSize={16}>
            {t('auth.forgot.doneHint1')}{' '}
            <a href={`mailto: ${state.email}`}>{state.email}</a>{' '}
            {t('auth.forgot.doneHint2')}
          </Typography>
        </Box>
      </Grid>

      <Grid item>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={() => history.push('/auth')}
        >
          Ok
        </Button>
      </Grid>
    </Page>
  )
}
