import { call, put, takeEvery } from 'redux-saga/effects'
import * as actionType from './actionType'
import { api, Response } from '../../function/axios'
import { showSnackbar } from '../common/actionCreator'
import { setPackage } from './actionCreator'
import { handleError } from '../../function/error'

function* getPackage() {
  try {
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: '/package/dashboard',
    })

    yield put(setPackage(data))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* postPersonalPlan(action: actionType.PostPersonalPlan) {
  try {
    const { data }: Response = yield call(api, {
      method: 'POST',
      url: '/personal-plan',
      body: {
        text: action.text,
        email: action.email,
      },
    })

    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* doReferenceCharge(action: actionType.DoReferenceCharge) {
  try {
    const { data }: Response = yield call(api, {
      method: 'POST',
      url: '/pay-pro',
      body: {
        productId: action.productId,
      },
    })

    yield call(action.callback)

    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

export function* watchPackage() {
  yield takeEvery(actionType.GET_PACKAGE, getPackage)
  yield takeEvery(actionType.POST_PERSONAL_PLAN, postPersonalPlan)
  yield takeEvery(actionType.DO_REFERENCE_CHARGE, doReferenceCharge)
}
