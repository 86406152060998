import { DateType } from './reducer'

export const GET_CHART = 'dashboard/GET_CHART'
export interface GetChart {
  type: typeof GET_CHART
  dateType: DateType
}

export const SET_CHART = 'dashboard/SET_CHART'
export interface SetChart {
  type: typeof SET_CHART
  data: []
}

export type DashboardAction = GetChart | SetChart
