import { call, delay, put, takeEvery } from 'redux-saga/effects'
import { handleError } from '../../function/error'
import * as actionType from './actionType'
import { Response, api } from '../../function/axios'
import { boolean } from 'joi'
/*
  for anyone reading this:
  this is here:
  because blacklist shouldn't be visible on the site as is.
  blacklist logic moved to backend.
*/

export function* checkEmailBlacklist(action: actionType.CheckEmailBlacklist){
  try{
    // this entire function needs to work before we move on with registration.
    if (!action.email || action.email.length === 0){
      action.callback(true)
    }
    else{
      const {data}: Response = yield api({
        url: `/blacklist/email?email=${action.email}&ipAddress=${action.ipAddress}`,
        method: "GET",
        responseType: boolean,
      })

      action.callback(data)
  }
  }catch(e){
    action.callback(true)
    handleError(e)
  }
}

export function* checkPhoneBlacklist(action: actionType.CheckPhoneBlacklist){
  try{
    const { data }: Response = yield api({
      url: `/blacklist/phone?phone=${action.phone}`,
      method: "GET",
      responseType: boolean,
    })
    
    action.callback(data)
    
  }catch(e){
    action.callback(true)
    handleError(e)
  }
}

export function* checkIpAddressBlackList(action: actionType.CheckIpAddressBlacklist){
  try{
    const { data }: Response = yield api({
      url: `/blacklist/one_address?ipAddress=${action.ipAddress}`,
      method: "GET",
      responseType: boolean,
    })

    action.callback(data)
    
  }catch(e){
    action.callback(true)
    handleError(e)
  }
}

export function* watchBlackList(){
  yield takeEvery(actionType.CHECK_EMAIL_BLACKLIST, checkEmailBlacklist)
  yield takeEvery(actionType.CHECK_PHONE_BLACKLIST, checkPhoneBlacklist)
  yield takeEvery(actionType.CHECK_IP_ADDRESS_BLACKLIST, checkIpAddressBlackList)
}