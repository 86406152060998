import { State } from '../index'

export const getSettingsState = (state: State) => state.settings

export const getUid = (state: State) => getSettingsState(state).uid
export const getIsOnBoarding = (state: State) =>
  getSettingsState(state).isOnBoarding
export const getOnBoardingStep = (state: State) =>
  getSettingsState(state).onBoardingStep
export const getRulesAcknowledgement = (state: State) =>
  getSettingsState(state).RulesAcknowledgement