import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import auth_ from '../../sass/auth.module.sass'
import sprite_ from '../../sass/sprite.module.sass'
import { Box, Grid, Hidden, Typography } from '@mui/material'

type Props = {
  children: React.ReactNode
}

const socials = ['fb', 'instagram', 'linkedin', 'twitter', 'ok', 'vk']
const apps = [
  'getcontact',
  'drupe',
  'skype',
  'viber',
  'telegram',
  'whatsapp',
  'eyecon',
  'tc',
  'callapp',
  'hiya',
  'imo',
  'tamtam',
  'botim',
  'luna',
  'taro',
  'web_collection_phone',
]

export function Page({ children }: Props) {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <Grid container className={auth_.root}>
      <Grid item xs={12} lg={3} className={auth_.info}>
        <div
          className={auth_.info__logo}
          onClick={() => history.push('/auth')}
        />

      <Hidden mdDown>
         <Box mb={1}>
           <span className={auth_.info__text}>{t('auth.info1.aboutIrbisParagraph1')}</span>
         </Box>
 
         <Box mb={1} display="flex" alignItems="left" justifyContent="flex-start">
           <ul>
           <li className={auth_.info__text}>{t('auth.info2.subParagraph1')}</li>
           <li className={auth_.info__text}>{t('auth.info2.subParagraph2')}</li>
           <li className={auth_.info__text}>{t('auth.info2.subParagraph3')}</li>
           </ul>
         </Box>
 
         <Box mb={5}>
           <span className={auth_.info__text}>{t('auth.info3.aboutIrbisParagraph2')}</span>
         </Box>
 
         <Box mb={5}>
           <Grid container direction="column" spacing={2}>
              <Grid item>
                <span className={auth_.info__title}>{t('auth.sources')}:</span>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  {socials.map((el) => (
                    <Grid item key={el}>
                      <Box className={sprite_['social__large_' + el]} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={1}>
                  {apps.map((el) => (
                    <Grid item key={el}>
                      <Box className={sprite_['social__large_' + el]} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Hidden>
      </Grid>

      <Grid item xs={12} lg={9}>
        <Grid className={auth_.content} container direction="column">
          {children}
        </Grid>
      </Grid>
    </Grid>
  )
}
