import { SupportAction } from './actionType'
import { TableStatusType } from '../../component/datatable/types'
import { Profile } from '../settings/reducer'

export interface TicketType {
  id: number
  name: string
  color: string
}

export interface Attachment {
  fileName: string
  url: string
  isObjectUrl?: boolean
}

export interface Ticket {
  id: number
  title: string
  assignee: Profile
  createdTime: string
  ticketType: TicketType | null
  summary: string
  attachments: Attachment[] | null
  status: TableStatusType
}

export interface Comment {
  id: number
  context: string
  attachment: Attachment | null
  createdTime: string
  user: {
    roleId: number
    avatar: string
    firstname: string
    lastname: string
  }
}

export type SupportState = {
  ticketTypes: TicketType[] | null
}

const initialState: SupportState = {
  ticketTypes: null,
}

export function supportReducer(state = initialState, action: SupportAction) {
  switch (action.type) {
    case 'support/SET_TICKET_TYPES':
      return {
        ...state,
        ticketTypes: action.data,
      }
    default:
      return state
  }
}
