import { call, put, takeEvery, takeLeading } from 'redux-saga/effects'
import { api, Response } from '../../function/axios'
import { SearchResult } from './reducer'
import * as actionType from './actionType'
import * as actionCreator from './actionCreator'
import * as commonActionCreator from '../common/actionCreator'
import {
  addTableRow,
  fetchTable,
  replaceSearcherTableRow,
  showSnackbar,
} from '../common/actionCreator'
import { handleError } from '../../function/error'
import { updateBalance } from '../settings/actionCreator'
import { setLookup } from './actionCreator'

function* getLookup() {
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: '/lookup/dashboard',
    })

    yield put(setLookup(data))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* startLookup(action: actionType.StartLookup) {
  if (action.data.type === 'phone') action.criteria = '+' + action.criteria
  try {
    const body:any = {
      value: action.criteria,
      lookupId: action.data.id,
      country: action.country,
      inputIp: action.inputIp,
    }
    if (action.data.type === 'deepweb' ||
      action.data.type === 'kyc' ||
      action.data.type === 'ip_geo' ||
      action.data.type === 'web_collection' ||
      action.data.type === 'combined_id' ||
      action.data.type === 'name_by_country' ||
      action.data.type === 'phone_list' || 
      action.data.type === 'psycho_profile' ||
      action.data.type === 'name_api') {
      body.lookupType = action.lookupType
    }
    
    
    if (action.data.type === 'ipgeo_number') {
      body.lookupType = action.lookupType
      body.inputIp = action.inputIp
    }

    const { data }: Response = yield call(api, {
      method: 'POST',
      url: `/searcher/${action.data.type}`,
      body,
    })

    const newResult: SearchResult = {
      id: data.id,
      requestId: data.requestId,
      criteria: action.criteria,
      type: action.data.type,
      createdTime: String(new Date()),
      status: 'progress',
      percentage: action.percentage,
      sources: action.data.sources.map((el) => ({ name: el, percentage: 0 })),
    }

    const { data: _data }: Response = yield call(api, {
      method: 'GET',
      url: '/request-monitor?sort=dateDESC',
      params: { limit: 20, offset: 0 },
    })

    yield put(commonActionCreator.setTable(_data, 'searcher'))
    yield put({ type: 'searcher/SET_PAGE', number: 1 })

    yield put(updateBalance(-action.data.price))
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* startVerify(action: actionType.StartVerify) {
  try {
  const body : any = {
    names : action.data.name,
    source : action.data.source,
    requestId: action.data.requestId,
    checkPrice: action.data.checkPrice,
  }

  const { data }: Response = yield call(api, {
    method: 'POST',
    url: `/searcher/verify_name`,
    body,
  })

  const { data: _data }: Response = yield call(api, {
    method: 'GET',
    url: '/request-monitor?sort=dateDESC',
    params: { limit: 20, offset: 0 },
  })

  yield put(commonActionCreator.setTable(_data, 'searcher'))
  yield put({ type: 'searcher/SET_PAGE', number: 1 })

  yield put(updateBalance(-action.data.checkPrice))
  yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }

}

function* getResultById(action: actionType.FetchResultBuId) {
  try {
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: `/request-monitor/${action.id}`,
    })

    yield put(replaceSearcherTableRow(data))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* getResultByIdAsync(action: actionType.FetchResultBuIdAsync) {
  try {
    const {id, callback} = action
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: `/request-monitor/${id}`,
    })
    yield call(callback, data)
  } catch (e) {
    yield call(handleError, e)
  }
}

function* getNotificationResult(action: actionType.FetchNotificationResult) {
  try {
    const response: Response = yield call(api, {
      method: 'GET',
      url: `/request-monitor/${action.requestMonitorId}`,
    })

    yield put(actionCreator.toggleViewResult(response.data))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* deleteResults(action: actionType.DeleteResults) {
  try {
    const { data }: Response = yield call(api, {
      method: 'DELETE',
      url: `/request-monitor?ids=${action.ids.toString()}`,
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* getPDFData(action: actionType.GetPDFData) {
  try {
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: `/pdf/request-monitor/?id=${action.requestMonitorId}&apiKey=${action.apiKey}`,
    })
    yield put({ type: 'searcher/SET_PDF_DATA', pdfData: data })
  } catch (e) {
    yield call(handleError, e)
  }
}

function* getPDF(action: actionType.GetPDF) {
  try {
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: `/pdf/request-monitor/download/?id=${action.requestMonitorId}`,
    })
  } catch (e) {
    yield call(handleError, e)
  }
}

function* getLinkPreview(action: actionType.GetLinkPreview) {
  try {
    const {link, callback} = action
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: `/searcher/url?url=${link}`,
    })
    callback(data)
  } catch (e) {
    yield call(handleError, e)
  }
}

export function* watchSearcher() {
  yield takeLeading(actionType.GET_LOOKUP, getLookup)
  yield takeLeading(actionType.START_LOOKUP, startLookup)
  yield takeLeading(actionType.START_VERIFY, startVerify)
  yield takeEvery(actionType.FETCH_RESULT_BY_ID, getResultById)
  yield takeEvery(actionType.FETCH_RESULT_BY_IDASYNC, getResultByIdAsync)
  yield takeEvery(actionType.FETCH_NOTIFICATION_RESULT, getNotificationResult)
  yield takeEvery(actionType.DELETE_RESULTS, deleteResults)
  yield takeEvery(actionType.GET_PDF_DATA, getPDFData)
  yield takeEvery(actionType.GET_PDF, getPDF)
  yield takeEvery(actionType.GET_LINK_PREVIEW, getLinkPreview)
}
