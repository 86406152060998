import { format, isValid } from 'date-fns'
import { store } from '../store'
import { CommonState } from '../store/common/reducer'

export const formatDate = (
  isoDate: string | number | Date,
  dateFormat: string,
): string => {
  return isValid(new Date(isoDate))
    ? format(new Date(isoDate), dateFormat)
    : String(isoDate)
}

export function getNow() {
  const commonState: CommonState = store.getState().common
  const timeFormatWithSeconds = commonState.timeFormatWithSeconds

  return format(new Date(), `yyyy-MM-dd-${timeFormatWithSeconds}`)
}

export function toDate(datetime: any) {
  const commonState: CommonState = store.getState().common
  const timeFormatWithSeconds = commonState.timeFormatWithSeconds

  return isValid(new Date(datetime))
    ? format(new Date(datetime), `dd.MM.yyyy ${timeFormatWithSeconds}`)
    : datetime
}

export function toDMY(datetime: any) {
  return isValid(new Date(datetime))
    ? format(new Date(datetime), 'dd.MM.yyyy')
    : datetime
}

export function generateYearList(startYear: number): number[] {
  const currentYear = new Date().getFullYear()
  const years = []

  startYear = startYear || 1980

  while (startYear <= currentYear) {
    years.push(startYear++)
  }

  return years.reverse()
}

export function generateDayList(daysNumber: number): number[] {
  const days: number[] = []
  days.length = daysNumber
  return days.fill(0, 0, daysNumber).map((el, idx) => idx + 1)
}

export function fromUnix(unix: number) {
  return new Date(unix)
}
