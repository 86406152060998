import React from 'react'
import { ErrorOutline as ErrorIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { TypeFormError } from '../function/validation'

interface Props {
  error: TypeFormError
}

export function FormError({ error }: Props) {
  if (error === undefined) return null

  return (
    <>
      {typeof error === 'object' ? (
        Object.entries(error).map(([key, val], idx) => (
          <Stack
            key={`error-${key}-${idx}`}
            direction="row"
            alignItems="center"
            mt={1}
          >
            <ErrorIcon color="error" sx={{ fontSize: 20 }} />
            <Typography fontSize={12} color="error" ml={1}>
              {val}
            </Typography>
          </Stack>
        ))
      ) : (
        <Stack direction="row" alignItems="center" mt={1}>
          <ErrorIcon color="error" sx={{ fontSize: 20 }} />
          <Typography fontSize={12} color="error" ml={1}>
            {error}
          </Typography>
        </Stack>
      )}
    </>
  )
}
