import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Add as AddIcon,
  SupportAgent as SupportIcon,
} from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  Stack,
  TableCell,
  TableRow,
  Typography, useTheme,
} from '@mui/material'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import { PrivatePage } from '../component/PagePrivate'
import { DataTable } from '../component/datatable'
import { useTableStyles } from '../component/datatable/hook/useStyles'
import { PopupTicketCreate } from '../container/support/PopupTicketCreate'
import { PopupTicket } from '../container/support/PopupTicket'
import { PopupAsk } from '../component/PopupAsk'
import { useDispatch, useSelector } from '../store'
import { deleteTicket } from '../store/support/actionCreator'
import { Ticket } from '../store/support/reducer'
import { formatDate } from '../function/date'
import support_ from '../sass/support.module.sass'
import { HeaderData, TableRef } from '../component/datatable/types'
import { TableStatus } from '../component/datatable/component'
import { visitPage } from './visit-page-loger/visit-page-loger-segment'
import { color } from '@mui/system'

export function SupportPage() {
  const { t } = useTranslation()
  const classesTable = useTableStyles()
  const dispatch = useDispatch()
  const theme = useTheme()

  const user = useSelector((state) => state.settings)
  
  React.useEffect(() => {
    if (user.uid) {
      visitPage(user.uid, "/support")
    }
  }, [])

  const tableRef = React.useRef<TableRef>(null)

  const { tables } = useSelector((state) => state.common)

  const [ticketOpen, setTicketOpen] = React.useState<Ticket | null>(null)
  const [createOpen, setCreateOpen] = React.useState(false)
  const [deleteOpen, setDeleteOpen] = React.useState<number>()

  const tableData = tables.ticketDesk?.list || []

  const tableHeaderData: HeaderData[] = [
    { key: 'id', text: t('support.table.id'), className: "wideVisible"  },
    { key: 'title', text: t('support.table.name'), sortable: true },
    { key: 'createdTime', text: t('support.table.created'), sortable: true },
    { key: 'type', text: t('support.table.type'), sortable: true, className: "wideVisible" },
    { key: 'summary', text: t('support.table.summary'),  className: "wideVisible" },
    { key: 'status', text: t('support.table.status'), sortable: true,  className: "wideVisible" },
    { key: 'assignee', text: t('support.table.assignee'),  className: "wideVisible" },
  ]

  const TableBody = (row: Ticket, idx: number) => {
    return (
      <TableRow
        key={row.id}
        className={idx % 2 !== 0 ? classesTable.rowEven : ''}
        hover
        onClick={() => setTicketOpen(row)}
      >
        <TableCell classes={classesTable} className="wideVisible">{row.id}</TableCell>
        <TableCell classes={classesTable}>{row.title}</TableCell>
        <TableCell classes={classesTable} className="wideVisible">
          {formatDate(row.createdTime, 'dd.MM.yy')}
        </TableCell>
        <TableCell classes={classesTable} className="wideVisible">
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Box className={support_.ticket} bgcolor={row.ticketType?.color} />
            <Typography sx={{ textTransform: 'capitalize' }}>
              {row.ticketType?.name}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell classes={classesTable} width={400} className="wideVisible">
          <Box
            sx={{
              width: 400,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {row.summary}
          </Box>
        </TableCell>
        <TableCell classes={classesTable} className="wideVisible">
          <TableStatus status={row.status} />
        </TableCell>
        <TableCell classes={classesTable} className="wideVisible">
          {row.assignee ? (
            <Stack direction="row" alignItems="center" spacing={1}>
              {row.assignee.avatar ? (
                <Avatar
                  src={row.assignee.avatar}
                  sx={{ width: 32, height: 32 }}
                />
              ) : (
                <Box className={support_.avatar__bg}>
                  <SupportIcon />
                </Box>
              )}
              <Typography>
                {row.assignee.firstname} {row.assignee.lastname}
              </Typography>
              {!row.assignee.firstname && !row.assignee.lastname && (
                <em>{row.assignee.email}</em>
              )}
            </Stack>
          ) : (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box className={support_.avatar__bg}>
                <SupportIcon />
              </Box>
              <Typography>Support agent</Typography>
            </Stack>
          )}
        </TableCell>
      </TableRow>
    )
  }

  function deleteCallback() {
    tableRef.current?.refresh()
  }

  function gotToHelpPage() {
    const helpPageUrl = 'https://irbis-user-manual.espysys.com/'
    window.open(helpPageUrl, '_blank')
  }

  return (
    <PrivatePage>
      <Stack direction="row" justifyContent="space-between" alignItems="right">
        <Typography variant="title">{t('support.title')}</Typography>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
        {tableData?.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setCreateOpen(true)}
          >
            {t('support.createNewTicket')}
          </Button>
        )}
          <Button
            variant="contained"
            startIcon={<AutoStoriesIcon />}
            sx={{backgroundColor: "#4bde97"}}
            onClick={gotToHelpPage}
          >
            {t('support.help')}
          </Button>
        </Stack>
      </Stack>

      <DataTable
        ref={tableRef}
        id="ticketDesk"
        className={classesTable.card}
        url="/ticket"
        headerData={tableHeaderData}
        renderBody={TableBody}
        search
        pagination
        emptyText={
          <Stack spacing={2} alignItems="center" sx={{
            [theme.breakpoints.only("xs")]: {
              width: '100%!important',
            },
          }}>
            <Typography
              variant="light"
              //width={380}
              fontSize={18}
              align="center"
              sx={{
                [theme.breakpoints.only("xs")]: {
                  width: '100%!important',
                },
              }}
            >
              No support requests yet
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={
                <AddIcon
                  style={{ position: 'relative', right: -5, fontSize: 11 }}
                />
              }
              onClick={() => setCreateOpen(true)}
              sx={{ padding: '15px', height: 41, fontSize: 14 }}
            >
              {t('support.createNewTicket')}
            </Button>
          </Stack>
        }
      />

      {createOpen && (
        <PopupTicketCreate
          tableRef={tableRef}
          onClose={() => setCreateOpen(false)}
        />
      )}
      {ticketOpen && (
        <PopupTicket
          data={ticketOpen}
          onDelete={(id) => setDeleteOpen(id)}
          onClose={() => setTicketOpen(null)}
        />
      )}
      {deleteOpen && (
        <PopupAsk
          onYes={() => dispatch(deleteTicket(deleteOpen, deleteCallback))}
          onClose={() => setDeleteOpen(undefined)}
        />
      )}
    </PrivatePage>
  )
}
