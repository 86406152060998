import { api } from '../../function/axios'

export const visitPage = async (uid: string, pageName: string) => {
    try {
      const response = await api({
        method: 'POST',
        url: '/segment-api',
        body: {
          uid: uid,
          pageName: pageName,
        },
      })
      
    } catch (error) {
      
    }
  }