import { Notification, TableBodyData, BackendAliveState } from './reducer'
import { SearchResult } from '../searcher/reducer'
import { AlertColor } from '@mui/material/Alert/Alert'

export const SET_LOADING = 'common/SET_LOADING'
export interface SetLoading {
  type: typeof SET_LOADING
  status: boolean
}

export const SET_LANGUAGE = 'common/SET_LANGUAGE'
export interface SetLanguage {
  type: typeof SET_LANGUAGE
  language: string
}

export const SHOW_SNACKBAR = 'common/SHOW_SNACKBAR'
export interface ShowSnackbar {
  type: typeof SHOW_SNACKBAR
  severity: AlertColor
  message?: string
}

export const HIDE_SNACKBAR = 'common/HIDE_SNACKBAR'
export interface HideSnackbar {
  type: typeof HIDE_SNACKBAR
}

export const SET_FIELD_ERROR = 'common/SET_FIELD_ERROR'
export interface SetFieldError {
  type: typeof SET_FIELD_ERROR
  key: string
  error: string | undefined
}

export const CLEAR_FIELD_ERROR = 'common/CLEAR_FIELD_ERROR'
export interface ClearFieldError {
  type: typeof CLEAR_FIELD_ERROR
}

// export const INIT_NOTIFICATION = 'common/INIT_NOTIFICATION'
// export interface InitNotification {
//   type: typeof INIT_NOTIFICATION
// }

export const GET_NOTIFICATION = 'common/GET_NOTIFICATION'
export interface GetNotification {
  type: typeof GET_NOTIFICATION
  offset: number
  limit: number
  fetchMore?: boolean
  refreshToken?: boolean
}

export const SET_NOTIFICATION = 'common/SET_NOTIFICATION'
export interface SetNotification {
  type: typeof SET_NOTIFICATION
  data: Notification[]
}

export const ADD_NOTIFICATION = 'common/ADD_NOTIFICATION'
export interface AddNotification {
  type: typeof ADD_NOTIFICATION
  data: Notification[]
}

export const ADD_NEW_NOTIFICATION = 'common/ADD_NEW_NOTIFICATION'
export interface AddNewNotification {
  type: typeof ADD_NEW_NOTIFICATION
  data: Notification[]
}

export const READ_NOTIFICATION = 'common/READ_NOTIFICATION'
export interface ReadNotification {
  type: typeof READ_NOTIFICATION
  id: number
}

export const PATCH_CAMPAIGN = 'common/PATCH_CAMPAIGN'
export interface PatchCampaign {
  type: typeof PATCH_CAMPAIGN
  id: number
  action: 'view' | 'action'
}

export const GET_TABLE = 'common/GET_TABLE'
export interface GetTable {
  type: typeof GET_TABLE
  url: string
  params: Record<string, number | string>
  tableId: string
}

export const SET_TABLE = 'common/SET_TABLE'
export interface SetTable {
  type: typeof SET_TABLE
  data: TableBodyData
  tableId: string
}

export const ADD_TABLE_ROW = 'common/ADD_TABLE_ROW'
export interface AddTableRow {
  type: typeof ADD_TABLE_ROW
  row: object
  tableLimit: number
  tableId: string
}

// TODO export to searcher module
export const REPLACE_SEARCHER_TABLE_ROW = 'common/REPLACE_SEARCHER_TABLE_ROW'
export interface ReplaceSearcherTableRow {
  type: typeof REPLACE_SEARCHER_TABLE_ROW
  row: SearchResult
}
export const UPDATE_SEARCHER_TABLE_ROW_PERCENTAGE =
  'common/UPDATE_SEARCHER_TABLE_ROW_PERCENTAGE'
export interface UpdateSearcherTableRow {
  type: typeof UPDATE_SEARCHER_TABLE_ROW_PERCENTAGE
  id: number
  name: string
  progress: number
}

export const CLEAR_NOTIFICATIONS = 'common/CLEAR_NOTIFICATIONS'

export interface ClearNotifications {
  type: typeof CLEAR_NOTIFICATIONS
}

export const CLEAR_NOTIFICATIONS_SUCCESS = 'common/CLEAR_NOTIFICATIONS_SUCCESS'
export interface ClearNotificationsSuccess {
  type: typeof CLEAR_NOTIFICATIONS_SUCCESS
}

export const TOGGLE_MENU_ANIMATION = 'common/TOGGLE_MENU_ANIMATION'

export interface ToggleMenuAnimation {
  type: typeof TOGGLE_MENU_ANIMATION
}
// 
export const GET_BACKEND_ALIVE = 'backend/GET_BACKEND_ALIVE'
export interface GetbackendAlive {
  type: typeof GET_BACKEND_ALIVE
}

export const SET_BACKEND_ALIVE = 'backend/SET_BACKEND_ALIVE'
export interface SetBackendAlive {
  type: typeof SET_BACKEND_ALIVE
  backendAlive: BackendAliveState
}
export interface SetBackendAliveAction {
  type: typeof SET_BACKEND_ALIVE;
  backendAlive: boolean;
}

export type CommonAction =
  | SetLoading
  | SetLanguage
  | ShowSnackbar
  | HideSnackbar
  | SetFieldError
  | ClearFieldError
  | PatchCampaign
  // | InitNotification
  | GetNotification
  | SetNotification
  | AddNotification
  | AddNewNotification
  | ReadNotification
  | GetTable
  | SetTable
  | AddTableRow
  | ReplaceSearcherTableRow
  | UpdateSearcherTableRow
  | ClearNotifications
  | ClearNotificationsSuccess
  | ToggleMenuAnimation
  | GetbackendAlive
  | SetBackendAlive
  | SetBackendAliveAction
