import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import {
  getLabel,
  getObjectValue,
  RenderImage,
} from '../../../component/searcher/RenderData'
import {
  ExpandMore as ExpandMoreIcon,
  Person as PersonIcon,
} from '@mui/icons-material'
import { fromUnix } from '../../../function/date'
import { isNumeric } from '../../../function/number'
import { store, useSelector } from '../../../store'
import { CommonState } from '../../../store/common/reducer'
import { Phone, PhoneDataItem } from '../../../store/searcher/reducer'
import * as React from 'react'
import { preparePhoneResult } from '../../../store/searcher/service'
import { useAccordionStyles } from '../../../style/lookup'
import { PopupPhoneLookup } from '../../searcher/PopupPhoneLookup'
import { getViewResult } from '../../../store/searcher/selector'
import { usePopover } from '../../../hook/usePopover'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import sprite_ from '../../../sass/sprite.module.sass'
import { LocalData } from '../../../component/LocalData'
import '../../../sass/index.sass'

const apps: Phone[] = [
  'getcontact',
  'drupe',
  'skype',
  'viber',
  'telegram',
  'whatsapp',
  'eyecon',
  'tc',
  'callapp',
  'tamtam',
  'imo',
  'hiya',
]
export const necessaryData = [
  'email',
  'city',
  'country',
  'createdTime',
  'name',
  'criteria',
  'online',
  'isFailed',
]

export interface Name {
  app: Phone
  name: string
}

export interface LastOnline {
  app: Phone
  online: any
}
export interface Birthday {
  app: Phone
  birthday: string
}

export interface Images {
  app: Phone
  image: string
}

function getPreview(data: PhoneDataItem[] | undefined) {
  const commonState: CommonState = store.getState().common
  const timeFormat = commonState.timeFormat
  const noNeedInFormatDate = ['Offline', 'Online']

  const images: Images[] = []
  const lastOnline: LastOnline[] = []
  const names: Name[] = []
  const birthday: Birthday[] = []
  data?.forEach((el) => {
    apps.forEach((app) => {
      if (el.data[app]) {
        const avatar: string | undefined = getObjectValue(
          el.data[app],
          'avatar',
        )
        const name: string | undefined = getObjectValue(el.data[app], 'name')
        const name2: string | undefined = getObjectValue(
          el.data[app],
          'nickname',
        )
        let online_unix: number | string | undefined = getObjectValue(
          el.data[app],
          'online',
        )
        let online2_unix: string | undefined | 'Recently' = getObjectValue(
          el.data[app],
          'last_seen',
        )
        const online3_unix: number | undefined = getObjectValue(
          el.data[app],
          'lastUpdateTime',
        )
        const _birthday = getObjectValue(el.data[app], 'birthday')

        const image: string | undefined = getObjectValue(el.data[app], 'image')
        if (image) {
          images.push({ app: app, image: image })
        }
        const photoUrl: string | undefined = getObjectValue(
          el.data[app],
          'photoUrl',
        )
        if (photoUrl) {
          images.push({ app: app, image: photoUrl })
        }

        if (app === 'getcontact') {
          const imageGetContact = el?.data?.getcontact?.photo
          if (imageGetContact) images.push({ app: app, image: imageGetContact })
        }
        if (typeof _birthday === 'string' && _birthday) {
          birthday.push({ app: app, birthday: _birthday })
        }

        if (_birthday && app === 'callapp' && _birthday.formattedYear) {
          birthday.push({
            app: app,
            birthday:
              _birthday?.formattedYear +
              '-' +
              _birthday?.formattedMonth +
              '-' +
              _birthday?.formattedDay,
          })
        }
        if (avatar) {
          images.push({ app: app, image: avatar })
        }

        if (name) {
          names.push({ app: app, name: name })
        }

        if (name2) {
          if (app !== 'telegram') {
            names.push({ app: app, name: name2 })
          }
        }
        if (app === 'tamtam') {
          const name = getObjectValue(el?.data[app], 'names')
          if (name) {
            names.push({ app: app, name: name[1]?.name })
          }
        }
        if (online_unix) {
          if (isNumeric(String(online_unix))) {
            online_unix = format(
              new Date(online_unix),
              `dd/MM/yyyy ${timeFormat}`,
            )
          }

          lastOnline.push({
            app: app,
            online: online_unix,
          })
        }

        if (online2_unix) {
          if (
            !noNeedInFormatDate.includes(online2_unix) &&
            online2_unix !== 'Recently'
          ) {
            if (online2_unix === 'LastWeek' || online2_unix === 'LastMonth') {
              switch (online2_unix) {
                case 'LastWeek':
                  online2_unix = 'Last Week'
                  break
                case 'LastMonth':
                  online2_unix = 'Last Month'
                  break
              }
            } else {
              online2_unix = format(
                fromUnix(Number(online2_unix)),
                `dd/MM/yyyy ${timeFormat}`,
              )
            }
          }
          if (app !== 'tamtam') {
            lastOnline.push({ app: app, online: online2_unix })
          }
        }

        if (online3_unix) {
          lastOnline.push({
            app: app,
            online: format(fromUnix(online3_unix), `dd/MM/yyyy ${timeFormat}`),
          })
        }
      }
    })
  })

  return { images, lastOnline, names, birthday }
}

type Props = {
  pdfData: any
}

export const PhonePagePdf = ({ pdfData }: Props) => {
  const { t } = useTranslation()

  const data = preparePhoneResult(pdfData)

  const accordionClasses: any = useAccordionStyles()
  const regionNames = new Intl.DisplayNames(['EN'], { type: 'region' })

  const initState = React.useMemo(() => {
    return data.data ? data.data.map((_: any) => true) : []
  }, [data.data])

  const [accordionOpen, setAccordionOpen] = React.useState<boolean[]>(initState)

  const { images, names, lastOnline, birthday } = getPreview(data.data)

  const handleChangeAccordion =
    (idx: number) => (event: React.SyntheticEvent, expanded: boolean) => {
      setAccordionOpen((state) => {
        const newState = [...state]
        newState[idx] = expanded
        return newState
      })
    }

  const minWidthFor2items = 1000

  const isOnRightSideOnPage = React.useMemo(() => {
    if (window.innerWidth < minWidthFor2items) {
      return true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth])

  return (
    <div style={{ padding: '30px', backgroundColor: 'white' }}>
      <Stack direction="row" alignItems="flex-start" spacing={7}>
        <Stack minWidth={153} maxWidth={153}>
          <Grid container spacing={1} alignItems="flex-start">
            {images.length > 0
              ? images.map((el, idx) => (
                  <Grid item xs={idx === 0 ? 12 : 4} key={idx}>
                    <RenderImage
                      _key={el.image}
                      val={el.image}
                      imgHeight={idx === 0 ? 150 : 45}
                    />
                  </Grid>
                ))
              : [...Array(4)].map((_el, idx) => {
                  return (
                    <Grid item xs={idx === 0 ? 12 : 4} key={idx}>
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        bgcolor="lightgrey"
                        height={idx === 0 ? 153 : 46}
                      >
                        <PersonIcon
                          color="primary"
                          style={{
                            fontSize: idx === 0 ? 150 : 40,
                            color: 'white',
                          }}
                        />
                      </Stack>
                    </Grid>
                  )
                })}
          </Grid>
          <Typography variant="semiBold" fontSize={18}>
            {names[0]?.name}
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              color: '#8B929B',
            }}
          >
            {data.criteria}
          </Typography>
          {birthday.length > 0 && (
            <Stack mt={2}>
              <Typography variant="bold">Birthday:</Typography>
              {birthday.map((el: any) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  mt={1}
                  key={el.app}
                >
                  <Box className={sprite_['social__small_' + el.app]} />
                  <Typography
                    sx={{
                      fontSize: '15px',
                      color: '#8B929B',
                      fontWeight: '500',
                    }}
                  >
                    {el.birthday}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          )}

          {names.length > 0 && (
            <Stack mt={2}>
              <Typography variant="bold">
                {t('searcher.phone.popup.names')}:
              </Typography>
              {names.map((el: any) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  mt={1}
                  key={el.app}
                >
                  <Box className={sprite_['social__small_' + el.app]} />
                  <Typography
                    sx={{
                      fontSize: '15px',
                      color: '#8B929B',
                      fontWeight: '500',
                    }}
                  >
                    {el.name}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          )}

          {lastOnline.length > 0 && (
            <Stack mt={2}>
              <Typography variant="bold">
                {t('searcher.phone.popup.lastOnline')}:
              </Typography>
              {lastOnline.map((el: any) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  mt={1}
                  key={el.app}
                >
                  <Box className={sprite_['social__small_' + el.app]} />
                  <Typography
                    sx={{
                      fontSize: '15px',
                      color: '#8B929B',
                      fontWeight: '500',
                    }}
                  >
                    {el.online}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          )}
        </Stack>
        <Stack>
          <DialogTitle>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              lineHeight={1}
              mb={3}
            >
              <Typography variant="semiBold" fontSize={24}>
                {t('searcher.phone.popup.title')}
              </Typography>
              <Stack direction="row" spacing={1}>
                {/* images on title */}
                {data.sources.map((el: any, idx: number) => (
                  <Box
                    key={idx}
                    className={sprite_['social__small_' + el.name]}
                  />
                ))}
              </Stack>
            </Stack>
          </DialogTitle>
          <DialogContent>
            {data.data
              ?.filter((el: any) => el.from !== 'ip')
              .map((dataEl: any, idx: number) => {
                return (
                  <Accordion
                    classes={accordionClasses}
                    key={idx}
                    square
                    expanded={accordionOpen[idx]}
                    onChange={handleChangeAccordion(idx)}
                  >
                    <AccordionSummary>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Box
                          className={sprite_['social__small_' + dataEl.from]}
                        />
                        <Typography
                          variant="semiBold"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {dataEl.from}
                        </Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Divider
                        sx={{
                          margin: '-5px 0px 15px 0px',
                          border: `0.1px solid rgba(46, 151, 213, 0.3)`,
                        }}
                      />
                      <Grid container>
                        {necessaryData.map((el) => {
                          let label = el
                          switch (el) {
                            case 'online':
                              label = 'last_online'
                              break
                            case 'criteria':
                              label = 'phone'
                              break
                            case 'createdTime':
                              label = 'created'
                              break
                            case 'email':
                              label = 'emails'
                              break
                          }
                          let value = getObjectValue(dataEl, el) || '-'
                          if (
                            dataEl?.from === 'callapp' &&
                            el === 'email' &&
                            getObjectValue(dataEl, el) === undefined &&
                            value !== '-'
                          ) {
                            value =
                              getObjectValue(dataEl, 'emails')?.email || '-'
                          }

                          if (
                            dataEl.from === 'tc' &&
                            el === 'email' &&
                            getObjectValue(dataEl, el) === undefined
                          ) {
                            value =
                              getObjectValue(dataEl, 'internetAddresses')?.[0]
                                ?.id || '-'
                          }

                          if (
                            (dataEl.from === 'getcontact' ||
                              dataEl.from === 'tc') &&
                            el === 'country' &&
                            // eslint-disable-next-line
                            getObjectValue(dataEl, el) != 1 &&
                            // eslint-disable-next-line
                            getObjectValue(dataEl, 'countryCode') !== undefined
                          ) {
                            value =
                              regionNames.of(
                                getObjectValue(dataEl, 'countryCode'),
                              ) || '-'
                          }
                          if (
                            el === 'isFailed' &&
                            getObjectValue(dataEl, el) === true
                          ) {
                            value = getObjectValue(dataEl, el).toString()
                          } else if (
                            el === 'isFailed' &&
                            getObjectValue(dataEl, el) !== true
                          ) {
                            return <React.Fragment key={el}></React.Fragment>
                          }
                          return (
                            <Grid item xs={6} key={el}>
                              <Grid container={value.length < 22}>
                                {/*spacing={value.length > 24 ? 3 : -2} */}
                                <Grid item xs={4}>
                                  <Typography variant="text" fontSize={14}>
                                    {getLabel(label)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography variant="bold">
                                    {value}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        })}
                        <LocalData dataEl={dataEl} />
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )
              })}
          </DialogContent>
        </Stack>
      </Stack>
    </div>
  )
}
