import React from 'react'
import {
  Checkbox,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { TableBodyRow, TableBodyData } from '../../../store/common/reducer'
import { useTableStyles } from '../hook/useStyles'

type MouseEvent =
  | React.MouseEvent<HTMLAnchorElement>
  | React.MouseEvent<HTMLButtonElement>

type Props = {
  bodyData: TableBodyData | undefined
  renderBody: (row: TableBodyRow, idx: number) => React.ReactNode
  cellCount: number
  selectable?: boolean
  selectedRowIds?: number[]
  onRowClick?: (row: TableBodyRow) => void
  onSelect?: (row: TableBodyRow, isChecked: boolean) => void
  emptyText?: React.ReactNode
}

export function Body(props: Props) {
  const {
    bodyData,
    renderBody,
    cellCount,
    selectable,
    selectedRowIds,
    onRowClick,
    onSelect,
    emptyText,
  } = props
  const classesTable = useTableStyles()

  function handleRowClick(row: TableBodyRow) {
    if (onRowClick) onRowClick(row)
  }

  function handleSelect(
    e: React.ChangeEvent<HTMLInputElement>,
    isChecked: boolean,
    row: TableBodyRow,
  ) {
    if (onSelect) onSelect(row, isChecked)
  }

  return (
    <TableBody>
      {bodyData?.list.map((row, idx) => {
        if (selectable) {
          return (
            <TableRow
              key={(row as any).id}
              className={idx % 2 !== 0 ? classesTable.rowEven : ''}
              hover={Boolean(onRowClick)}
              onClick={() => handleRowClick(row)}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedRowIds?.includes((row as any).id) || false}
                  onClick={(e: MouseEvent) => e.stopPropagation()}
                  onChange={(e, checked) => handleSelect(e, checked, row)}
                />
              </TableCell>
              {renderBody(row, idx)}
            </TableRow>
          )
        }
        return renderBody(row, idx)
      })}
      {bodyData?.list.length === 0 && emptyText && (
        <TableRow>
          <TableCell colSpan={cellCount}>
            <Stack justifyContent="center" alignItems="center" height={342}>
              <Typography
                //width={380}
                fontSize={18}
                align="center"
              >
                {emptyText}
              </Typography>
            </Stack>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  )
}
