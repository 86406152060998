import React from 'react'
import { Grid, Stack, Typography } from '@mui/material'
import { PhoneDataItem, SearchResult } from '../../../../store/searcher/reducer'
import { getLabel } from '../../../../component/searcher/RenderData'

type Props = {
  el?: any
}
type objType = {
  [key: string]: object;
};

const TaroItem = (props: Props) => {
  const {el} = props
  const taroItem = el.data[el.from]?.['data']
  if (taroItem && Object.keys(taroItem).length > 0) {
    return <>
      {
        taroItem?.breach_details?.breaches && <Grid item xs={12} >
          <Grid container sx={{display: 'flex'}}>
            <Grid item xs={2}>
              <Typography variant="text" fontSize={14}>Breaches</Typography>
            </Grid>
            <Grid item xs={10}>
              {
                taroItem?.breach_details?.breaches?.map((breach: any, idx: number) => <Stack key={idx} direction={'row'}>
                    {
                      Object.entries(breach).map(([title, value]) =>
                        <Grid item xs={4}>
                          <Typography variant="text" fontSize={14} sx={{marginRight: '10px'}}>{getLabel(title)}</Typography>
                          <Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{value + ''}</Typography>
                        </Grid>,
                      )
                    }
                  </Stack>,
                )
              }
            </Grid>
          </Grid>
        </Grid>
      }
      {
        taroItem?.breach_details?.first_breach && <Grid item xs={12}>
          <Grid container sx={{display: 'flex'}}>
            <Grid item xs={2}><Typography variant="text" fontSize={14}>First Breach</Typography></Grid>
            <Grid item xs={10}><Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{taroItem?.breach_details?.first_breach}</Typography></Grid>
          </Grid>
        </Grid>
      }
      {
        taroItem?.breach_details?.number_of_breaches && <Grid item xs={12}>
          <Grid container sx={{display: 'flex'}}>
            <Grid item xs={2}><Typography variant="text" fontSize={14}>Number of breaches</Typography></Grid>
            <Grid item xs={10}><Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{taroItem?.breach_details?.number_of_breaches}</Typography></Grid>
          </Grid>
        </Grid>
      }
      {
        taroItem?.email && <Grid item xs={12}>
          <Grid container sx={{display: 'flex'}}>
            <Grid item xs={2}><Typography variant="text" fontSize={14}>Email</Typography></Grid>
            <Grid item xs={10}><Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{taroItem?.email}</Typography></Grid>
          </Grid>
        </Grid>
      }
      {
        taroItem?.history && <Grid item xs={12} >
          <Grid container sx={{display: 'flex'}}>
            <Grid item xs={2}>
              <Typography variant="text" fontSize={14}>History</Typography>
            </Grid>
            <Grid item xs={10}>
              <Grid container>
                {
                  Object.entries(taroItem?.history).map(([title, value]) =>
                    <Grid item xs={12}>
                      <Typography variant="text" fontSize={14} sx={{marginRight: '10px'}}>{getLabel(title)}</Typography>
                      <Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{value + ''}</Typography>
                    </Grid>,
                  )
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      {
        taroItem?.domain_details && <Grid item xs={12} >
          <Grid container sx={{display: 'flex'}}>
            <Grid item xs={2}>
              <Typography variant="text" fontSize={14}>Domain Details</Typography>
            </Grid>
            <Grid item xs={10}>
              <Grid container>
                {
                  Object.entries(taroItem?.domain_details).map(([title, value]) =>
                    <Grid item xs={12}>
                      <Typography variant="text" fontSize={14} sx={{marginRight: '10px'}}>{getLabel(title)}</Typography>
                      <Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{value + ''}</Typography>
                    </Grid>,
                  )
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      {
        Object.entries(taroItem?.account_details).map(([service, serviceData]: [string, any]) =>
          serviceData?.registered && Object.keys(serviceData).length > 1 &&
          <Grid item xs={12} >
            <Grid container sx={{display: 'flex'}}>
              <Grid item xs={2}>
                <Typography variant="text" fontSize={14}>{getLabel(service)}</Typography>
              </Grid>
              <Grid item xs={10}>
                <Grid container>
                  {
                    serviceData ? Object.entries(serviceData as Object)?.map(([title, value]) =>
                      <Grid item xs={12}>
                        <Typography variant="text" fontSize={14} sx={{marginRight: '10px'}}>{getLabel(title)}</Typography>
                        <Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{value + ''}</Typography>
                      </Grid>,
                    ) : null
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>,
        )
      }
    </>
  }
  return null
}

export default TaroItem