import * as React from 'react'
import { useDispatch, useSelector } from '../store'

import { PrivatePage } from '../component/PagePrivate'
import { fetchAdditionalLookup, fetchAdditionaWebIntPackage, postQuota, postToken } from '../store/webint/actionCreator'

import { useState } from 'react'
import moment from 'moment'
import { visitPage } from './visit-page-loger/visit-page-loger-segment'

export function WebintPage() {
  const dispatch = useDispatch()
  const { additionalLookup }  = useSelector((state) => state.additionalLookup)
  const { token } = useSelector((state) => state.token)
  const { additionalPackage } = useSelector((state) => state.additionalPackage)
  const user = useSelector((state) => state.settings)
  const playerRef = React.useRef<HTMLVideoElement>(null)
  const [releaseVideo, setReleaseVideo] = React.useState<boolean>(false)
  const webintPath = process?.env?.REACT_APP_WEBINT_BEARER
  const webintDemo = process?.env?.REACT_APP_WEBINT_DEMO
  const webintPathPro = process?.env?.REACT_APP_WEBINT_ADVANCED

  React.useEffect(() => {
    if (user.uid) {
      visitPage(user.uid, "/irbispro")
    }
  }, [])

  const [mode, setMode] = useState<'demo'|'bearer'|'advanced'|null>(null)

  React.useEffect(() => {
    dispatch(fetchAdditionalLookup)
    dispatch(fetchAdditionaWebIntPackage)
  }, [user.uid] )

  React.useEffect(() => {
    if (additionalLookup && user.uid) {
      dispatch(postToken({userId: user.uid, quotas: additionalLookup.input}))
      dispatch(postQuota({userId: user.uid}))
    }

    if (user.additionalPackageId && moment(user.additionalPackageEndDate) > moment()) {
      user.irbisproUpgrade ? setMode('advanced') : setMode('bearer')
    } else {
      setMode('demo')
    }
  }, [additionalLookup] )


  React.useEffect(() => {
      //console.log('token', token)
    },
    [token],
  )

  return (
    <PrivatePage style={{padding: 0}}>
      {
        mode === 'bearer'
        && webintPath
        && token
        && <embed src={`${webintPath}${token}`} style={{width: '100%', height: 'calc(100vh - 73px)'}} />
      }
      {
        mode === 'advanced'
        && webintPathPro
        && token
        && <embed src={`${webintPathPro}${token}`} style={{width: '100%', height: 'calc(100vh - 73px)'}} />
      }
      {
        mode === 'demo'
        && <embed src={`${webintDemo}`} style={{width: '100%', height: 'calc(100vh - 73px)'}} />
      }
    </PrivatePage>
  )
}
