import * as React from 'react'
import Joi from 'joi'
import { t } from 'i18next'
import NumberFormat from 'react-number-format'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Grid,
} from '@mui/material'
import { delete2FAUser, toggle2step } from '../../store/settings/actionCreator'
import { ErrorsState, validateForm } from '../../function/validation'
import { useDispatch, useSelector } from '../../store'
import { FormError } from '../../component/FormError'

const schema = Joi.object({
  code: Joi.string().required().min(6).label(t('field.label.verificationCode')),
})

type Props = {
  mode: 'deleteUser' | 'toggle2FA'
  onClose: () => void
}

export const Dialog2FAInput = ({ mode, onClose }: Props) => {
  const dispatch = useDispatch()

  const { is2FAEnabled } = useSelector((state) => state.settings)

  const [code, setCode] = React.useState('')
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const error = validateForm(schema, { code })

    if (error) setErrors(error)
    else {
      if (mode === 'deleteUser') dispatch(delete2FAUser(code))
      else dispatch(toggle2step(code, !is2FAEnabled))
      onClose()
    }
  }

  return (
    <Dialog open={true} fullWidth onClose={onClose}>
      <DialogTitle>{t('auth.2step.title')}</DialogTitle>

      <Divider variant="popup" color="primary" />

      <form onSubmit={handleSubmit} noValidate>
        <Grid container>
          <Grid item xs={5}>
            <FormLabel>{t('field.label.verificationCode')}</FormLabel>
          </Grid>
          <Grid item xs={7}>
            <FormControl className="form__phone" fullWidth>
              <NumberFormat
                value={code}
                onValueChange={(values) => setCode(values.value)}
                mask="_"
                format="### ###"
                autoFocus
                style={{ textAlign: 'center' }}
              />
              <FormError error={errors?.code} />
            </FormControl>
          </Grid>
        </Grid>

        <Divider variant="popup" color="primary" />

        <DialogActions>
          <Button color="primary" size="large" onClick={onClose}>
            {t('common.close')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
          >
            {t('common.save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
