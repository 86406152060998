import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as RouterProvider } from 'react-router-dom'
import { Provider as StoreProvider } from 'react-redux'
import { createBrowserHistory } from 'history'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import * as Firebase from './firebase'
import * as Mui from './mui'
import { store } from './store'
import './i18n'
import { App } from './App'
import './sass/index.sass'

if (process.env.REACT_APP_SENTRY) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
  })
}

Firebase.init()

export const history = createBrowserHistory()

ReactDOM.render(
  <RouterProvider>
    <StoreProvider store={store}>
      <MuiThemeProvider theme={Mui.customTheme}>
        <App />
      </MuiThemeProvider>
    </StoreProvider>
  </RouterProvider>,
  document.getElementById('root'),
)
