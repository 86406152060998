import * as React from 'react'
import { differenceInSeconds, format } from 'date-fns'
import { useSelector } from '../store'

export function useUserPackage() {
  const { packageEndDate, packageStartDate } = useSelector(
    (state) => state.settings,
  )
  const { additionalPackageEndDate, additionalPackageStartDate } = useSelector(
    (state) => state.settings,
  )
  const packageDays = React.useMemo(() => {
    
    if (!packageStartDate || !packageEndDate) return 0

    const secondsDiff = differenceInSeconds(
      new Date(packageEndDate),
      new Date(packageStartDate),
    )
    return Math.ceil(secondsDiff / 60 / 60 / 24)
  }, [packageStartDate, packageEndDate])

  const additionalPackageDays = React.useMemo(() => {
    if (!additionalPackageStartDate || !additionalPackageEndDate) return 0

    const secondsDiff = differenceInSeconds(
      new Date(additionalPackageEndDate),
      new Date(additionalPackageStartDate),
    )
    return Math.ceil(secondsDiff / 60 / 60 / 24)
  }, [additionalPackageStartDate, additionalPackageEndDate])

  const secondsLeft = React.useMemo(() => {
    if (!packageEndDate) return 0

    const todayFormatted = format(new Date(), 'yyyy-MM-dd') + ' 00:00:00'
    const endDateFormatted =
      format(new Date(packageEndDate), 'yyyy-MM-dd') + ' 00:00:00'

    return differenceInSeconds(
      new Date(endDateFormatted),
      new Date(todayFormatted),
    )
  }, [packageEndDate])
//============
  const additionalSecondsLeft = React.useMemo(() => {
    if (!additionalPackageEndDate) return 0

    const todayFormatted = format(new Date(), 'yyyy-MM-dd') + ' 00:00:00'
    const endDateFormatted =
      format(new Date(additionalPackageEndDate), 'yyyy-MM-dd') + ' 00:00:00'

    return differenceInSeconds(
      new Date(endDateFormatted),
      new Date(todayFormatted),
    )
  }, [additionalPackageEndDate])


  const daysLeft = React.useMemo(() => {
    const daysDiff = Math.ceil(secondsLeft / 60 / 60 / 24)

    if (secondsLeft < 0) return 0
    else return daysDiff
  }, [secondsLeft])

  const isExpired = React.useMemo(() => {
    return secondsLeft < 0
  }, [secondsLeft])

  const additionalDaysLeft = React.useMemo(() => {
    const daysDiff = Math.ceil(additionalSecondsLeft / 60 / 60 / 24)

    if (additionalSecondsLeft < 0) return 0
    else return daysDiff
  }, [additionalSecondsLeft])

  const additionalIsExpired = React.useMemo(() => {
    return additionalSecondsLeft < 0
  }, [additionalSecondsLeft])

 
  return { packageDays, daysLeft, isExpired, additionalPackageDays, additionalDaysLeft, additionalIsExpired }
}
