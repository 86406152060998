import { styled } from '@mui/material/styles'
import { Theme } from '@mui/material'

interface PackageProps {
  theme?: Theme,
  mode?: 'active' | 'pro' | 'advanced' | 'custom' | '',
  disabled?: boolean,
  isActive?: boolean,
  isShort?: boolean,
  isAnnual?: boolean,
  isBought?: boolean,
  isMobile?: boolean,
}
export const StyledPackage = styled('div')((
  {
    theme,
    mode = '',
    disabled = false,
    isActive = false,
    isShort = false,
    isAnnual = false,
    isBought = false,
    isMobile = false,
  }: PackageProps,
) => ({
  display: 'flex',
  width: !isShort ? '270px' : 'auto',
  height: (() => {
    if (isMobile) return '40px'
    return isShort ? '72px' : ''
  })(),
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'stretch',
  gap: '30px',
  borderRadius: '15px',
  opacity: disabled ? '0.5' : '1',
  background: (() => {
    if (isBought) return '#778899'
    if (isActive) return '#3898D3'
    switch (mode) {
      /*case 'active':
        return '#3898D3'*/
      case 'pro':
        return "#03173D"
      case 'advanced':
        return '#454550'
      case 'custom':
        return '#F0F2FA'
      default:
        return '#FFF'
    }
  })(),
  padding: (() => {
    if (isShort) return '5px 15px'
    switch (mode) {
      case 'pro':
      case 'advanced':
      case 'custom':
        return '0 0 20px'
      default:
        return '25px 0 20px'
    }
  })(),
  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.15)',
  position: 'relative',
  cursor: (() => {
    if (isShort) return "pointer"
  })(),
  '.package__header': {
    display: "flex",
    flexFlow: "row",
    flexDirection: "row",
    padding: "0 10px 5px 10px",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    width: "100%",
  },
  '.package__currency': {
    color: (() => {
      if (isActive) return "#FFF"
      switch (mode) {
        //case 'active':
        case 'pro':
        case 'advanced':
          return "#FFF"
        default:
          return '#3898D3'
      }
    })(),
    fontSize: isMobile ? '12px!important' : '20px',
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
  },
  '.package__price': {
    fontSize: isMobile ? '16px' : '40px',
    fontStyle: 'normal',
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
    lineHeight: 'normal',
    color: (() => {
      if (isActive) return "#FFF"
      switch (mode) {
        //case 'active':
        case 'pro':
        case 'advanced':
          return "#FFF"
        default:
          return '#3898D3'
      }
    })(),
  },
  '.package__price_old': {
    position: 'absolute',
    bottom: '-15px',
    left: '0',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
    lineHeight: 'normal',
    textDecoration: 'line-through',
    color: (() => {
      if (isActive) return "#D7D7D7"
      switch (mode) {
        //case 'active':
        case 'pro':
        case 'advanced':
          return "#D7D7D7"
        default:
          return '#bbbbbb'
      }
    })(),
  },
  '.package__name': {
    maxWidth: '130px',
    overflow: 'hidden',
  },
  '.package__divider': {
    width: '1px',
    height: '62px',
    position: 'relative',
    background: (() => {
      if (isActive) return "#FFF"
      switch (mode) {
        //case 'active':
        case 'pro':
        case 'advanced':
          return "#FFF"
        default:
          return '#3898D3'
      }
    })(),
  },
  '.package__balance': {
    borderRadius: '5px 5px 0px 0px',
    background: '#FFF',
    width: '48px',
    height: '16px',
    padding: '0px 5px',
    position: 'absolute',
    bottom: '-11px',
    right: '-34px',
  },
  '.package__balance_text': {
    color: '#3898D3',
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '20px',
    display: 'block',
  },
  '.package__title': {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: (() => {
      if (isActive) return "#FFF"
      switch (mode) {
        //case 'active':
        case 'pro':
        case 'advanced':
          return "#FFF"
        default:
          return '#3898D3'
      }
    })(),
    fontSize: isMobile ? '16px' : '25px',
    fontStyle: 'normal',
    fontWeight: 300,
    fontFamily: 'Inter, sans-serif',
    lineHeight: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&.package__subtitle': {
      fontSize: isMobile ? '10px' : '14px',
      marginLeft: isShort ? '15px' : '',
    },
    '&.package__annual_info': {
      fontSize: '10px',
      marginLeft: isShort ? '15px' : '',
    },
  },
  '.package__credits': {
    flexFlow: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: (() => {
      if (isActive) return "#FFF"
      switch (mode) {
        default:
          return '#3898D3'
      }
    })(),
  },
  '.package__credits_price': {
    display: 'inline',
    marginRight: '5px',
    fontSize: '20px',
    fontWeight: 700,
    color: (() => {
      if (isActive) return "#FFF"
      switch (mode) {
        default:
          return '#3898D3'
      }
    })(),
  },
  '.package__icons': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '.package__features_container': {
    padding: '0 20px',
    //height: '190px',
    height: '100%',
    flexShrink: 1,
  },
  '.package__features': {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    paddingLeft: '20px',
    color: (() => {
      // if (isBought) return "#778899"
      if (isActive) return "#FFF"
      switch (mode) {
        //case 'active':
        case 'pro':
        case 'advanced':
          return "#FFF"
        default:
          return '#727272'
      }
    })(),
    'li': {
      paddingBottom: '10px',
    },
  },
  '.package__features_title': {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '18px',
    display: 'block',
    color: (() => {
      if (isActive) return "#FFF"
      switch (mode) {
        //case 'active':
        case 'pro':
        case 'advanced':
          return "#FFF"
        default:
          return '#727272'
      }
    })(),
  },
  '.package__features_subtitle': {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    display: 'block',
    color: (() => {
      if (isActive) return "#FFF"
      switch (mode) {
        //case 'active':
        case 'pro':
        case 'advanced':
          return "#FFF"
        default:
          return '#727272'
      }
    })(),
  },
  '.package__buy': {
    background: (() => {
      if (isActive) return "#FFF"
      switch (mode) {
        //case 'active':
        //case 'pro':
        //case 'custom':
          //return "#FFF"
      }
    })(),
    color: (() => {
      if (isActive) return "#3898D3"
      switch (mode) {
        case 'active':
        //case 'pro':
          return "#3898D3"
      }
    })(),
    '&:hover': {
      opacity: (() => {
        if (isActive) return "0.5"
        switch (mode) {
          case 'active':
          //case 'pro':
            return "0.5"
        }
      })(),
      transform: 'scale(1.03)',
    },
  },
  '.package__badge_container': {
    position: 'absolute',
    top: '5px',
    right: '5px',
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '5px',
  },
  '.package__badge_active': {
    borderRadius: '100px',
    background: '#4BDE97',
    color: '#FFF',
    textAlign: 'center',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    padding: '0 10px',
    //width: '52px',
  },
  '.package__badge_custom': {
    borderRadius: '100px',
    background: '#454550',
    color: '#FFF',
    textAlign: 'center',
    fontSize: '14px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    padding: '0 10px',
  },
  '.package__pro_kind_container': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flexStart',
  },
  '.package__pro_icons_container': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: isAnnual ? '225px' : '184px',
  },
  '.package__pro_icons_item': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '.package__pro_icon': {
    color: '#FFF',
    fontFamily: 'Inter, sans-serif',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '22px',
    marginLeft: '3px',
  },
  '.package__pro_kind_badge': {
    borderRadius: '0px 0px 5px 5px',
    fontSize: '24px',
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    padding: '5px 10px',
  },
  '.package__pro_kind_badge_pro': {
    background: '#FFF',
    color: '#161624',
  },
  '.package__pro_kind_badge_custom': {
    background: '#4BDE97',
    color: '#FFFFFF',
  },
  '.package__title_custom': {
    color: '#747474',
    textAlign: 'center',
    fontSize: '30px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal',
  },

}))