import { Apikey, GeneratedKeys } from './reducer'

export const GET_DEVELOPER_VIDEO = 'apikey/GET_DEVELOPER_VIDEO'
export interface GetDeveloperVideo {
  type: typeof GET_DEVELOPER_VIDEO
}

export const SET_DEVELOPER_VIDEO = 'apikey/SET_DEVELOPER_VIDEO'
export interface SetDeveloperVideo {
  type: typeof SET_DEVELOPER_VIDEO
  file: Blob
}

export const GET_APIKEY = 'apikey/GET_APIKEY'
export interface GetApikey {
  type: typeof GET_APIKEY
}

export const SET_APIKEY = 'apikey/SET_APIKEY'
export interface SetApikey {
  type: typeof SET_APIKEY
  data: Apikey | null
}

export const GENERATE_KEYS = 'apikey/GENERATE_KEYS'
export interface GenerateKey {
  type: typeof GENERATE_KEYS
  length: number
}

export const SET_GENERATED_KEYS = 'apikey/SET_GENERATED_KEYS'
export interface SetGeneratedKeys {
  type: typeof SET_GENERATED_KEYS
  data: GeneratedKeys | null
}

export const POST_APIKEY = 'apikey/POST_APIKEY'
export interface PostApikey {
  type: typeof POST_APIKEY
  data: object
}

export type EditData = {
  id: number
  length: number
  domains: string[]
}

export const EDIT_APIKEY = 'apikey/EDIT_APIKEY'
export interface EditApikey {
  type: typeof EDIT_APIKEY
  data: EditData
}

export const DELETE_APIKEY = 'apikey/DELETE_APIKEY'
export interface DeleteApikey {
  type: typeof DELETE_APIKEY
  id: number
}

export type ApikeyAction =
  | GetDeveloperVideo
  | SetDeveloperVideo
  | GetApikey
  | SetApikey
  | GenerateKey
  | SetGeneratedKeys
  | PostApikey
  | EditApikey
  | DeleteApikey
