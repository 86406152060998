import * as React from 'react'
import { useTranslation } from 'react-i18next'
import payment_ from '../sass/payment.module.sass'
import { Grid } from '@mui/material'

export function PaymentFailPage() {
  const { t } = useTranslation()

  return (
    <Grid
      className={payment_.root}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid className={payment_.title} item>
        {t('payment.titleFail')}
      </Grid>

      <Grid item>
        <div className={payment_.icon_fail} />
      </Grid>

      <Grid className={payment_.text} item>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nunc
        pulvinar posuere tortor nullam metus. Ante sit feugiat augue quam mauris
        porttitor integer. Enim fermentum elementum blandit sollicitudin ornare
        aliquam neque, non. Mollis mauris nisl netus elementum, urna elit.
        Lobortis vitae, viverra bibendum felis tellus sit. Viverra mauris arcu
        elit phasellus posuere. Amet quam convallis laoreet leo.
      </Grid>
    </Grid>
  )
}
