import React from 'react'
import { useSelector } from '../store'
import { getRefreshedToken } from '../store/auth/selector'

export function useAuthEffect(callback: () => void) {
  const refreshedToken = useSelector(getRefreshedToken)

  const [request, setRequest] = React.useState(true)

  React.useEffect(() => {
    if (refreshedToken && request) {
      callback()
      setRequest(false)
    }
  }, [refreshedToken, request, callback])
}
