import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
} from '@mui/material'

interface IAreYouSure {
  onYes: () => void
  onClose: () => void
}

export const PopupAsk = ({ onYes, onClose }: IAreYouSure) => {
  const { t } = useTranslation()

  const handleYes = () => {
    onYes()
    onClose()
  }

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{t('common.sure')}</DialogTitle>

      <Divider variant="popup" color="primary" />

      <DialogActions>
        <Button
          color="primary"
          size="large"
          variant="outlined"
          onClick={onClose}
        >
          {t('common.no')}
        </Button>
        <Button
          onClick={handleYes}
          color="primary"
          variant="contained"
          size="large"
        >
          {t('common.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
