import * as React from 'react'
import {
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { FormError } from './FormError'
import {
  preventEmptySpaceOnInput,
  preventEmptySpaceOnKeyDown,
  TypeFormError,
} from '../function/validation'

interface Props {
  value: string
  placeholder: string
  onChange?: React.Dispatch<React.SetStateAction<string>>
  label?: string
  error?: TypeFormError
  inputProps?: {
    [key: string]: any
  }
  required?: boolean
}

export const InputPassword = ({
  label,
  value,
  error,
  onChange,
  placeholder,
  inputProps,
  required,
}: Props) => {
  const [showPass, setShowPass] = React.useState(false)

  const handleClickShowPassword = () => {
    setShowPass(!showPass)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
  }

  return (
    <FormControl fullWidth>
      {label && <FormLabel required={required}>{label}</FormLabel>}
      <OutlinedInput
        type={showPass ? 'text' : 'password'}
        placeholder={placeholder}
        value={value}
        error={Boolean(error)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              tabIndex={-1}
            >
              {showPass ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        fullWidth
        onChange={(e) =>
          preventEmptySpaceOnInput(
            e,
            onChange as React.Dispatch<React.SetStateAction<string>>,
          )
        }
        onKeyDown={preventEmptySpaceOnKeyDown}
        {...inputProps}
      />
      {error && <FormError error={error} />}
    </FormControl>
  )
}
