import * as React from 'react'
import Joi from 'joi'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from '@mui/material'
import { useDispatch, useSelector } from '../../store'
import {
  ErrorsState,
  validateForm,
  preventEmptySpaceOnInput,
  email,
} from '../../function/validation'
import { FormError } from '../../component/FormError'
import { getEmailAccounting } from '../../store/settings/actionCreator'
import { t } from 'i18next'
import NumberFormat from 'react-number-format'
import { clearFieldError } from '../../store/common/actionCreator'

const schema = Joi.object({
  dateFrom: Joi.date().iso().label(t('field.hint.startDate')),
  dateTo: Joi.date()
    .iso()
    .greater(Joi.ref('dateFrom'))
    .label(t('field.hint.endDate')),
  email: email,
}).messages({
  'date.format': 'Incorrect format',
  'date.greater': `"To" must be greater than "From"`,
  'any.ref': 'second input must have correct format',
})

type Props = {
  onClose: () => void
}

export const DialogEmailAccounting = ({ onClose }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [dateFrom, setDateFrom] = React.useState('')
  const [dateTo, setDateTo] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  const handleSubmit = () => {
    const error = validateForm(schema, { dateFrom, dateTo, email })

    if (error) setErrors(error)
    else {
      setErrors(null)
      dispatch(getEmailAccounting(new Date(dateFrom), new Date(dateTo), email))
      onClose()
    }
  }

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [dispatch, dateFrom, dateTo, email])

  return (
    <Dialog open={true} fullWidth onClose={onClose}>
      <DialogTitle>{t('settings.emailAccounting')}</DialogTitle>
      <Divider variant="popup" color="primary" />
      <Grid xs={12} textAlign="center" marginBottom={2} item>
        <FormLabel>{t('settings.chooseAccontingPeriod')}</FormLabel>
      </Grid>
      <Stack spacing={2}>
        <Grid container>
          <Grid item xs={4}>
            <FormLabel required>{t('field.hint.startDate')}</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth className="form__phone">
              <NumberFormat
                className="form__phone"
                value={dateFrom}
                error={Boolean(errors?.dateFrom)}
                placeholder="YYYY-MM-DD"
                format="####-##-##"
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </FormControl>
            <FormError error={errors?.dateFrom} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={4}>
            <FormLabel required>{t('field.hint.endDate')}</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth className="form__phone">
              <NumberFormat
                value={dateTo}
                format="####-##-##"
                error={Boolean(errors?.dateTo)}
                placeholder="YYYY-MM-DD"
                onChange={(e) => setDateTo(e.target.value)}
              />
            </FormControl>
            <FormError error={errors?.dateTo} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={4}>
            <FormLabel required>{t('field.label.email')}</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth>
              <OutlinedInput
                value={email}
                error={Boolean(errors?.email)}
                placeholder={t('field.hint.email')}
                onChange={(e) => preventEmptySpaceOnInput(e, setEmail)}
              />
            </FormControl>
            <FormError error={errors?.email} />
          </Grid>
        </Grid>
      </Stack>

      <Divider variant="popup" color="primary" />

      <DialogActions>
        <Button onClick={onClose} color="primary" size="large">
          {t('common.close')}
        </Button>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={handleSubmit}
        >
          {t('settings.sendRequest')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
