import { DashboardAction } from './actionType'

export enum DateType {
  MONTH = 'month',
  DAY = 'day',
  HOUR = 'hour',
  MINUTE = 'minute',
}

export type RequestChart = {
  count: number
  time: Date
}

export type DashboardState = {
  chart: RequestChart[] | null | undefined
}

const initialState: DashboardState = {
  chart: undefined,
}

export function dashboardReducer(
  state = initialState,
  action: DashboardAction,
) {
  switch (action.type) {
    case 'dashboard/SET_CHART':
      return {
        ...state,
        chart: action.data,
      }
    default:
      return state
  }
}
