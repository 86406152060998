import { AES, enc } from 'crypto-js'
import { LocalStorage } from '../enum/storage'

function getSecret() {
  const secret: string | undefined = process.env.REACT_APP_CRYPT_SECRET

  if (secret === undefined) {
    console.error('No installed REACT_APP_CRYPT_SECRET environment')
    return
  }

  return secret
}

export function encrypt(value: any) {
  const stringed = JSON.stringify(value)
  const secret = getSecret()

  if (secret) {
    return AES.encrypt(stringed, secret).toString()
  }
}

export function decrypt(value: string) {
  const secret = getSecret()

  if (secret) {
    const decrypted = AES.decrypt(value, secret)
    return JSON.parse(decrypted.toString(enc.Utf8))
  }
}

export function setCryptLocalStorage(key: string, value: any) {
  const encrypted = encrypt(value)

  if (encrypted) localStorage.setItem(key, encrypted)
}

export function getCryptLocalStorage(key: string) {
  const encrypted: any = localStorage.getItem(key)

  if (encrypted) return decrypt(encrypted)
  else return null
}

export function setAuthLocalStorageArray(key: string, value: any) {
  const uid = localStorage.getItem(LocalStorage.UID)
  localStorage.setItem(`${key}-${uid}`, JSON.stringify(value))
}

export function addAuthLocalStorageItemToArray(key: string, value: any) {
  const existingStorage = getAuthLocalStorageArray(key)
  const newStorage = [...existingStorage, value]

  setAuthLocalStorageArray(key, newStorage)
}

export function getAuthLocalStorageArray(key: string) {
  const uid = localStorage.getItem(LocalStorage.UID)
  const storage: any = localStorage.getItem(`${key}-${uid}`)

  if (storage) return JSON.parse(storage)
  else return []
}

export function removeAuthLocalStorage(key: string, unAuthUid?: string) {
  const uid = localStorage.getItem(LocalStorage.UID) || unAuthUid
  localStorage.removeItem(key + '-' + uid)
}
