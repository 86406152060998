import React from 'react'
import {
  Pagination as MuiPagination,
  PaginationItem,
  Stack,
} from '@mui/material'
import { useTablePaginationStyles } from '../hook/useStyles'
import {
  ArrowBack as IconLeft,
  ArrowForward as IconRight,
} from '@mui/icons-material'
import { useDispatch, useSelector } from '../../../store'
import { setPage } from '../../../store/searcher/actionCreator'

type Props = {
  pageCount: number | undefined
  setParams: (state: Record<string, any>) => void
  limit: number
}

export function Pagination({ pageCount, setParams, limit }: Props) {
  const classes = useTablePaginationStyles()
  const dispatch = useDispatch()
  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    dispatch({ type: 'searcher/SET_PAGE', number: page })
    setParams((state: Record<string, any>) => ({
      ...state,
      offset: (page - 1) * limit,
    }))
  }
  const page = useSelector((state) => state.searcher.page)
  return (
    <Stack direction="row" justifyContent="flex-end" mt={2} width="100%">
      <MuiPagination
        classes={classes}
        count={pageCount}
        page={page}
        shape="rounded"
        renderItem={(item) => (
          <PaginationItem
            components={{ previous: IconLeft, next: IconRight }}
            {...item}
          />
        )}
        onChange={handlePageChange}
      />
    </Stack>
  )
}
