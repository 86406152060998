import React from 'react'
import {
  Box,
  Stack, Tooltip, Typography,
} from '@mui/material'
import { ResultTitle } from '../../../style/result'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook, faGoogle, faApple, faTwitter, faWindows, faYahoo, faEbay, faInstagram, faSpotify,
  faTumblr, faLinkedin, faFoursquare, faLastfm, faPinterest, faSkype, faDiscord, faOdnoklassniki,
  faAirbnb, faAmazon, faEvernote, faPatreon, faWeibo, faGithub, faVimeoV, faFlickr, faQuora, faAtlassian, faWordpress,
} from '@fortawesome/free-brands-svg-icons'
import { Source } from '../../../store/searcher/reducer'
import { t } from 'i18next'
import { getTitle } from '../../../function/string'
import sprite_ from '../../../sass/sprite.module.sass'

type Props = {
  socialNetworks?: string[],
  found?: boolean,
}

export const VerifiedServicesInline = (props: Props) => {
  //const
  const {socialNetworks, found = true} = props

  const services = [
    {icon: faFacebook, short: 'facebook'},
    {icon: faGoogle, short: 'google'},
    {icon: faApple, short: 'apple'},
    {icon: faTwitter, short: 'twitter'},
    {icon: faWindows, short: 'microsoft'},
    {icon: faYahoo, short: 'yahoo'},
    {icon: faEbay, short: 'ebay'},
    {icon: faInstagram, short: 'instagram'},
    {icon: faSpotify, short: 'spotify'},
    {icon: faTumblr, short: 'tumblr'},
    {icon: faLinkedin, short: 'linkedin'},
    {icon: faFoursquare, short: 'foursquare'},
    {icon: faLastfm, short: 'lastfm'},
    {icon: faPinterest, short: 'pinterest'},
    {icon: faSkype, short: 'skype'},
    {icon: faDiscord, short: 'discord'},
    {icon: faOdnoklassniki, short: 'ok'},
    {icon: faAirbnb, short: 'airbnb'},
    {icon: faAmazon, short: 'amazon'},
    {icon: faEvernote, short: 'evernote'},
    {icon: faPatreon, short: 'patreon'},
    {icon: faWeibo, short: 'weibo'},
    {icon: faGithub, short: 'github'},
    {icon: faVimeoV, short: 'vimeo'},
    {icon: faFlickr, short: 'flickr'},
    {icon: faQuora, short: 'quora'},
    {icon: faAtlassian, short: 'atlassian'},
    {icon: faWordpress, short: 'wordpress'},
  ]

  //hooks

  //state

  //handlers

  return (
    <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} paddingTop={'10px'} width={'100%'} flexWrap={'wrap'}>
      {
        found && <Typography sx={{marginRight: '10px'}}>Verified by:</Typography>
      }
      {
        found && services
          .filter(service => socialNetworks?.includes(service.short))
          .map((service, idx) => <Box key={idx} sx={{width: '15px', marginRight: '10px'}}>
              <Tooltip title={service.short}>
                <FontAwesomeIcon
                  color={socialNetworks?.includes(service.short) ? '#40A0D9' : '#000000'}
                  icon={service.icon}
                  style={{opacity: socialNetworks?.includes(service.short) ? '1' : '0.2'}}
                />
              </Tooltip>
            </Box>,
          )
      }
      {
        !found && <Stack direction={'row'} alignItems={'flex-start'} justifyContent={'flex-start'} paddingTop={'10px'} width={'100%'}>
          {/*<Stack sx={{flexShrink: 0}}>
            <Typography sx={{marginRight: '10px'}}>Not verified by:</Typography>
          </Stack>*/}
          <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} flexWrap={'wrap'}>
          {
            services
              .filter(service => !socialNetworks?.includes(service.short))
              .map((service, idx) => <Box key={idx} sx={{width: '15px', marginRight: '10px'}}>
                  <Tooltip title={service.short}>
                    <FontAwesomeIcon
                      color={socialNetworks?.includes(service.short) ? '#40A0D9' : '#000000'}
                      icon={service.icon}
                      style={{opacity: socialNetworks?.includes(service.short) ? '1' : '0.2'}}
                    />
                  </Tooltip>
                </Box>,
              )
          }
          </Stack>
        </Stack>
      }
    </Stack>
    /*<Box sx={{ height: 'auto', width: '100%' }}>
      <Stack direction={'column'} sx={{margin: '10px 0', width: '100%'}}>
        <ResultTitle>Verified Services:</ResultTitle>
        <Stack
          direction={'row'}
          sx={{height: '83px', width: '90%'}}
          alignItems={'flex-start'}
          flexWrap={'wrap'}
          gap={'20px'}
        >
          {
            services
              .filter(service => socialNetworks?.includes(service.short))
              .map((service, idx) => <Box key={idx} sx={{width: '15px'}}>
                <Tooltip title={service.short}>
                  <FontAwesomeIcon
                    color={socialNetworks?.includes(service.short) ? '#40A0D9' : '#000000'}
                    icon={service.icon}
                    style={{opacity: socialNetworks?.includes(service.short) ? '1' : '0.2'}}
                  />
                </Tooltip>
              </Box>,
              )
          }
          {
            services
              .filter(service => !socialNetworks?.includes(service.short))
              .map((service, idx) => <Box key={idx} sx={{width: '15px'}}>
                  <Tooltip title={service.short}>
                    <FontAwesomeIcon
                      color={socialNetworks?.includes(service.short) ? '#40A0D9' : '#000000'}
                      icon={service.icon}
                      style={{opacity: socialNetworks?.includes(service.short) ? '1' : '0.2'}}
                    />
                  </Tooltip>
                </Box>,
              )
          }
        </Stack>
      </Stack>
    </Box>*/
  )
}