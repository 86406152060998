import { createStyles, CSSProperties, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export const useCardStyles = makeStyles((theme: Theme) => {
  const before: CSSProperties = {
    position: 'absolute',
    content: '""',
    left: 0,
    top: 0,
    bottom: 0,
    width: 10,
    height: '100%',
  }

  return createStyles({
    root: {
      position: 'relative',
    },
    yellowGreen: {
      '&::before': {
        ...before,
        background: theme.palette.yellowGreen.main,
      },
    },
  })
})
