
import React, { useCallback } from 'react'
import { GoogleMap, LoadScript, MarkerF, useJsApiLoader } from '@react-google-maps/api'
import Typography from '@mui/material/Typography'
import { Grid, Stack } from '@mui/material'


type Props = {
    el?: any
}

export function WhatsApplItem(props: Props) {
    const el = props?.el || {}

    const containerStyle = {
        width: '300px',
        height: '400px',
    }

    const zoom = 20

    const [map, setMap] = React.useState(null)

    const onLoad = useCallback((map) => {
        setMap(map)
    }, [])

    const latitude = parseFloat(el?.latitude) || 0 // Fallback to 0 if not available
    const longitude = parseFloat(el?.longitude) || 0

    return (
        <Stack>
            <Grid item xs={12} >
                <Grid container sx={{ display: 'flex' }}>
                    <Grid item xs={3}>
                        <Typography variant="text" fontSize={14}>
                            Name
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        {
                            (el.linked_accounts?.fb_page?.display_name ?
                                <Typography variant="bold" fontSize={14} sx={{ wordBreak: 'break-all' }}>{el.linked_accounts?.fb_page?.display_name}</Typography>
                                :
                                <Typography variant="bold" fontSize={14} sx={{ wordBreak: 'break-all' }}>No name provided</Typography>
                            )
                        }
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="text" fontSize={14}>
                            
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        {
                            ((el.latitude && el.longitude) ?
                                <LoadScript googleMapsApiKey={process.env.REACT_APP_FIREBASE_API_KEY || ""}>
                                    <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        center={{ lat: latitude, lng: longitude }}
                                        zoom={zoom}
                                        options={{ minZoom: 3, maxZoom: 12, clickableIcons: false }}
                                        onLoad={onLoad}
                                    >
                                        <MarkerF
                                            position={{
                                                lat: latitude,
                                                lng: longitude,
                                            }}
                                        />
                                    </GoogleMap>
                                </LoadScript>
                                :
                                <Typography variant="bold" fontSize={14} sx={{ wordBreak: 'break-all' }}>No name provided</Typography>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Stack>
    )

}

export default WhatsApplItem