import { useTranslation } from 'react-i18next'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
  Box,
} from '@mui/material'

function BackdropComponent() {
  return null
}

export function MaintenancePopup() {
  const { t } = useTranslation()

  return (
    <Dialog
      open={true}
      BackdropComponent={BackdropComponent}
      maxWidth="md" // Increase the maxWidth to 'md' (medium) or any other value as needed
      fullWidth
      sx={{ // Custom styling to increase height
        '& .MuiDialogContent-root': {
          minHeight: '200px', // Increase the minHeight to the desired value
        },
      }}
    >
      <DialogTitle>{t('maintenance.title')}</DialogTitle>
      <Divider variant="popup" color="primary" />

      <DialogContent>
        <Stack>
          <Box>
            <Typography fontSize={16}>
              {t('maintenance.paragraph1')}
            </Typography>
          </Box>
          <Box marginTop={2}>
            <Typography fontSize={16}>
              {t('maintenance.paragraph2')}
            </Typography>
          </Box>
          <Box marginTop={2}>
            <Typography fontSize={16}>
              {t('maintenance.paragraph3')}
            </Typography>
          </Box>
        </Stack>
      </DialogContent>

      <Divider variant="popup" color="primary" />
    </Dialog>
  )
}
