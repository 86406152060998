import { AdditionalLookupAction, AdditionalPackageAction, QuotaAction, TokenAction } from './actionType'
import { AdditionalLookup, AdditionalPackage, Quota, Token } from './reducer'

export const fetchAdditionalLookup: AdditionalLookupAction = { type: 'additional-lookups/GET_ADDITIONAL_LOOKUP' }

export const fetchAdditionaWebIntPackage: AdditionalPackageAction = { type: 'additional-package/GET_ADDITIONAL_PACKAGE'}

export const setQuota = (data: Quota | null): QuotaAction => ({
  type: 'quota/SET_QUOTA',
  data,
})

export const setTrialQuota = (data: object) : QuotaAction => ({
  type: 'quota/SET_QUOTA_TRIAL',
  data,
})

export const postQuota = (data: object): QuotaAction => ({
  type: 'quota/POST_QUOTA',
  data,
})

export const postToken = (data : object): TokenAction => ({
    type: 'token/POST_TOKEN',
    data,
  })

export const setToken = (data: Token | null): TokenAction => ({
  type: 'token/SET_TOKEN',
  data,
})

export const postAdditionalLookup = (data : object): AdditionalLookupAction => ({
  type: 'additional-lookups/POST_ADDITIONAL_LOOKUP',
  data,
})

export const setAdditionalLookup = (data: AdditionalLookup | null): AdditionalLookupAction => ({
type: 'additional-lookups/SET_ADDITIONAL_LOOKUP',
data,
})


export const postAdditionaPackage = (data : object): AdditionalPackageAction => ({
  type: 'additional-package/POST_ADDITIONAL_PACKAGE',
  data,
  })

export const setAdditionalPackage = (data: AdditionalPackage | null): AdditionalPackageAction => ({
  type: 'additional-package/SET_ADDITIONAL_PACKAGE',
  data,
  })

