import { SettingsAction } from './actionType'
import { Profile } from './reducer'

export const setState = (key: string, value: any): SettingsAction => ({
  type: 'settings/SET_STATE',
  key,
  value,
})

export const flushState: SettingsAction = { type: 'settings/FLUSH_STATE' }

export const fetchProfile: SettingsAction = { type: 'settings/GET_PROFILE' }

export const fetchBalance: SettingsAction = { type: 'settings/UPDATE_BALANCE', value: 0}

export const setProfile = (data: Profile): SettingsAction => ({
  type: 'settings/SET_PROFILE',
  data,
})

export const setOnBoardingStep = (step: number): SettingsAction => ({
  type: 'settings/SET_ON_BOARDING_STEP',
  step,
})

export const finishOnBoarding: SettingsAction = {
  type: 'settings/FINISH_ON_BOARDING',
}

export const finishRulesAcknowledgement: SettingsAction = {
  type: 'settings/FINISH_RULES_ACKNOWLEDGEMENT',
}

export const setRulesAcknowledgementStatus = (value: boolean): SettingsAction => ({
  type: 'settings/SET_RULES_ACKNOWLEDGEMENT_STATUS',
  value,
})

export const setOnBoardingStatus = (value: boolean): SettingsAction => ({
  type: 'settings/SET_ON_BOARDING_STATUS',
  value,
})

export const uploadImage = (base64: string): SettingsAction => ({
  type: 'settings/UPLOAD_IMAGE',
  base64,
})

export const deleteImage: SettingsAction = { type: 'settings/DELETE_IMAGE' }

export const putData = (key: string, value: string): SettingsAction => ({
  type: 'settings/PUT_DATA',
  key,
  value,
})

export const sendEmailUpdateVerification = (
  newEmail: string,
  password: string,
): SettingsAction => ({
  type: 'settings/SEND_EMAIL_UPDATE_VERIFICATION',
  newEmail,
  password,
})

export const updatePassword = (
  oldPassword: string,
  password: string,
): SettingsAction => ({
  type: 'settings/UPDATE_PASSWORD',
  oldPassword,
  password,
})

export const updatePhone = (
  code: string,
  phone: string,
  callback: () => void,
): SettingsAction => ({
  type: 'settings/UPDATE_PHONE',
  code,
  phone,
  callback,
})

export const fetchQr: SettingsAction = { type: 'settings/GET_QR' }

export const setQr = (qr: string | null): SettingsAction => ({
  type: 'settings/SET_QR',
  qr,
})

export const toggle2step = (code: string, is2FA: boolean): SettingsAction => ({
  type: 'settings/TOGGLE_2STEP',
  code,
  is2FA,
})

export const deleteUser: SettingsAction = { type: 'settings/DELETE_USER' }

export const delete2FAUser = (code: string): SettingsAction => ({
  type: 'settings/DELETE_2FA_USER',
  code,
})

export const updateBalance = (value: number): SettingsAction => ({
  type: 'settings/UPDATE_BALANCE',
  value,
})

export const setEmailAccounting = (email: string): SettingsAction => ({
  type: 'settings/SET_EMAIL_ACCOUNTING',
  email,
})

export const postEmailAccounting = (email: string): SettingsAction => ({
  type: 'settings/POST_EMAIL_ACCOUNTING',
  email,
})

export const fetchBillingAddress: SettingsAction = {
  type: 'settings/GET_BILLING_ADDRESS',
}

export const setBillingAddress = (data: object): SettingsAction => ({
  type: 'settings/SET_BILLING_ADDRESS',
  data,
})

export const editBillingAddress = (data: object): SettingsAction => ({
  type: 'settings/EDIT_BILLING_ADDRESS',
  data,
})

export const switchAutoRenewal = (value: boolean): SettingsAction => ({
  type: 'users/auto-renewal',
  value,
})

export const switchAutoRefill = (value: boolean): SettingsAction => ({
  type: 'users/auto-refill',
  value,
})

export const switchWebIntAutoRenewal = (value: boolean): SettingsAction => ({
  type: 'users/auto-renewal-irbispro',
  value,
})

export const getEmailAccounting = (
  from: object,
  to: object,
  email: string,
): SettingsAction => ({
  type: 'settings/GET_EMAIL_ACCOUNTING',
  from,
  to,
  email,
})
