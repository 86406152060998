import * as React from 'react'
import { useHistory } from 'react-router-dom'
import Joi from 'joi'
import { t } from 'i18next'
import auth_ from '../sass/auth.module.sass'
import { Box, Button, Grid, Link, Stack } from '@mui/material'
import { InputUsername } from '../container/auth/InputUsername'
import { InputPassword } from '../component/InputPassword'
import { isNumeric } from '../function/number'
import { useDispatch, useSelector } from '../store'
import {
  fetchPhoneCode,
  setAuthPhoneVerify,
  signInByPhone,
  signInByProvider,
  signInEmail,
} from '../store/auth/actionCreator'
import { Page } from '../component/auth/Page'
import { setCryptLocalStorage } from '../function/storage'
import { LocalStorage } from '../enum/storage'
import { Provider } from '../enum/provider'
import { useSocialStyles } from '../style/auth'
import { FormVerifyPhone } from '../component/FormVerifyPhone'
import sprite_ from '../sass/sprite.module.sass'
import { AuthStep, AuthStorage } from '../store/auth/reducer'
import {
  email,
  ErrorsState,
  notRequired,
  password,
  phone,
  validateForm,
} from '../function/validation'
import { clearFieldError } from '../store/common/actionCreator'

export type InputMode = 'phone' | 'email'

const schemaStep1 = Joi.object({
  inputMode: Joi.any().required(),
  username: Joi.when('inputMode', {
    is: 'email',
    then: email,
    otherwise: phone.label(t('field.label.emailOrPhone')),
  }),
  password: Joi.when('inputMode', {
    is: 'email',
    then: password,
    otherwise: notRequired,
  }),
  phoneCode: notRequired,
})

export function LoginPage() {
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useSocialStyles()

  const { fieldErrors } = useSelector((state) => state.common)
  const { usernameInputHelper, authPhoneVerify } = useSelector(
    (state) => state.auth,
  )

  const [authMode, setAuthMode] = React.useState<AuthStep>('EMAIL_FORM')
  const [inputMode, setInputMode] = React.useState<InputMode>('phone')
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [phoneCode, setPhoneCode] = React.useState('')
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  React.useEffect(() => {
    if (authPhoneVerify) {
      const data: AuthStorage = { type: 'VERIFY_EMAIL_PHONE', phone: username }
      setCryptLocalStorage(LocalStorage.SIGNUP_STATE, data)
      setAuthMode('VERIFY_EMAIL_PHONE')
    } else {
      localStorage.removeItem(LocalStorage.SIGNUP_STATE)
    }

    return function cleanup() {
      setAuthMode('EMAIL_FORM')
    }
  }, [authPhoneVerify, username])

  const handleInputUsername = React.useCallback(
    (value = usernameInputHelper) => {
      let inputMode: InputMode = 'phone'

      value = value.replaceAll(/ /g, '')

      if (isNumeric(value) || value.startsWith('+')) {
        if (!value.startsWith('+')) {
          value = '+' + value
        }
      } else {
        inputMode = 'email'
      }

      setInputMode(inputMode)
      setUsername(value)
    },
    [usernameInputHelper],
  )

  React.useEffect(() => {
    handleInputUsername()
  }, [handleInputUsername])

  const handleSubmitStep1 = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(clearFieldError)

    const error = validateForm(schemaStep1, { inputMode, username, password })

    if (error) setErrors(error)
    else {
      setErrors(null)
      if (inputMode === 'phone') {
        dispatch(fetchPhoneCode(username, 'login'))
      } else dispatch(signInEmail(username, password))
    }
  }

  const handleSubmitStep2 = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(signInByPhone(phoneCode))
  }

  const handleBack = () => {
    dispatch(setAuthPhoneVerify(false))
    setAuthMode('EMAIL_FORM')
    localStorage.removeItem(LocalStorage.SIGNUP_STATE)
  }

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [username, password, dispatch])

  return (
    <Page>
      <Grid className={auth_.title} item>
        {t('auth.welcome.title')}
      </Grid>

      {authMode === 'EMAIL_FORM' && (
        <>
          <Grid className={auth_.hint_light} item mt={10}>
            {t('auth.welcome.hint')}
          </Grid>

          <Grid item mt={4}>
            <Stack direction="row" spacing={3}>
              <Button
                classes={classes}
                className={classes.google}
                onClick={() => dispatch(signInByProvider(Provider.GOOGLE))}
              >
                <Box className={sprite_.social__large_googleProvider} />
              </Button>
              <Button
                classes={classes}
                className={classes.facebook}
                onClick={() => dispatch(signInByProvider(Provider.FACEBOOK))}
              >
                <Box className={sprite_.social__large_fbProvider} />
              </Button>
              <Button
                style={{ display: 'none' }}
                classes={classes}
                className={classes.microsoft}
                onClick={() => dispatch(signInByProvider(Provider.MICROSOFT))}
              >
                <Box className={sprite_.social__large_microsoftProvider} />
              </Button>
            </Stack>
          </Grid>

          <Grid item mt={4}>
            <Box className={auth_.or}>
              <hr />
              <span>{t('auth.or')}</span>
              <hr />
            </Box>
          </Grid>
        </>
      )}

      <Grid item mt={4}>
        {authMode === 'EMAIL_FORM' && (
          <form onSubmit={handleSubmitStep1} noValidate>
            <Stack spacing={3} alignItems="center">
              <InputUsername
                inputMode={inputMode}
                username={username}
                error={fieldErrors.login || errors?.username}
                onChange={handleInputUsername}
                label={t('field.label.emailOrPhone')}
              />
              {inputMode === 'email' && (
                <InputPassword
                  label={t('field.label.password')}
                  value={password}
                  error={fieldErrors.loginPassword || errors?.password}
                  onChange={setPassword}
                  placeholder={t('field.hint.password')}
                  required
                />
              )}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                {t('auth.welcome.signIn')}
              </Button>
            </Stack>
          </form>
        )}

        {authMode === 'VERIFY_EMAIL_PHONE' && (
          <form onSubmit={handleSubmitStep2} noValidate>
            <Stack alignItems="center" spacing={3}>
              <FormVerifyPhone
                phoneCode={phoneCode}
                phone={username}
                setPhoneCode={setPhoneCode}
              />

              <Button onClick={handleBack}>{t('auth.register.back')}</Button>
            </Stack>
          </form>
        )}

        {authMode === 'EMAIL_FORM' && (
          <Stack alignItems="center" mt={1}>
            <Button onClick={() => history.push('/auth/forgot')}>
              {t('auth.welcome.forgot')}
            </Button>

            <Box mt={2}>
              <span className={auth_.hint}>
                {t('auth.welcome.noAccountYet')}{' '}
              </span>
              <Link
                variant="semiBold"
                color="primary"
                onClick={() => history.push('/auth/register')}
              >
                {t('auth.welcome.signUpNow')}
              </Link>
            </Box>
          </Stack>
        )}
      </Grid>
    </Page>
  )
}
