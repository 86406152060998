import { parsePhoneNumber } from 'react-phone-number-input'
import { t } from 'i18next'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { history } from '../../index'
import { api, Response } from '../../function/axios'
import {
  decrypt,
  getCryptLocalStorage,
  removeAuthLocalStorage,
  setCryptLocalStorage,
} from '../../function/storage'
import { getErrorMessage, handleError } from '../../function/error'
import { State } from '../index'
import { AuthState, AuthStorage } from './reducer'
import { SettingsState } from '../settings/reducer'
import { FirebaseError } from 'firebase/app'
import * as firebase from 'firebase/auth'
import * as service from './service'
import * as actionType from './actionType'
import * as actionCreator from './actionCreator'
import * as commonActionCreator from '../common/actionCreator'
import * as settingsActionCreator from '../settings/actionCreator'
import { setLoading, showSnackbar } from '../common/actionCreator'
import { LocalStorage } from '../../enum/storage'
import { Provider } from '../../enum/provider'
import { setFieldError } from '../common/actionCreator'
import ReactGA from 'react-ga'

const TRACKING_ID = "UA-193472056-1"
ReactGA.initialize(TRACKING_ID)

function* getProviderUser(action: actionType.GetProviderUser) {
  yield put(setLoading(true))

  const auth = firebase.getAuth()
  const provider = service.getProvider(action.provider)

  try {
    const userCredential: firebase.UserCredential = yield call(
      firebase.signInWithPopup,
      auth,
      provider,
    )

    const { email, photoURL } = userCredential.user

    const { data: isEmailExist }: Response = yield call(api, {
      method: 'GET',
      url: `/users/email?email=${email}`,
    })

    if (isEmailExist) {
      yield put(showSnackbar('error', t('message.error.emailAlreadyInUse')))
      return
    }

    const credential = service.getProviderCredential(
      action.provider,
      userCredential,
    )

    const providerUserdata = service.prepareProviderUserData(
      userCredential.user,
    )

    const data: AuthStorage = {
      type: 'PROVIDER_FORM',
      firstname: providerUserdata.firstname,
      lastname: providerUserdata.lastname,
      signInProvider: providerUserdata.signInProvider,
      email: email || '',
      photoURL: photoURL || '',
    }

    yield put(actionCreator.setUserCredential(userCredential))
    yield put(actionCreator.setAuthCredential(credential))
    yield put(actionCreator.setRegisterInput(data))
    yield put(actionCreator.setRegisterStep('PROVIDER_FORM'))

    setCryptLocalStorage(LocalStorage.SIGNUP_STATE, data)
  } catch (e) {
    yield call(handleError, e)
  } finally {
    yield put(setLoading(false))
  }
}

function* getPhoneCode(action: actionType.GetPhoneCode) {
  const auth = firebase.getAuth()

  const phone = action.phone.replace('+', '%2B')

  yield put(setLoading(true))

  try {
    const {isBlocked} = yield call(api, {
      method: 'GET',
      url: `/blacklist/phone?phone=${action.phone}`,
    })

    if (action.mode === 'register') {
      const { data: phoneExist }: Response = yield call(api, {
        method: 'GET',
        url: `/users/phone?phone=${phone}`,
      })

      if (phoneExist) {
        yield put(
          commonActionCreator.setFieldError(
            'phone',
            t('message.error.phoneAlreadyRegistered'),
          ),
        )
        return
      }
    }

    if (action.mode === 'login') {
      const { data: phoneExist }: Response = yield call(api, {
        method: 'GET',
        url: `/users/phone?phone=${phone}`,
      })

      if (phoneExist === false) {
        yield put(
          commonActionCreator.setFieldError(
            'login',
            t('message.error.accountNotFound'),
          ),
        )
        return
      }
    }

    if (window.recaptchaVerifier === undefined) {
      window.recaptchaVerifier = new firebase.RecaptchaVerifier(
        'recaptcha',
        { size: 'invisible' },
        auth,
      )
    }

    window.confirmationResult = yield call(
      firebase.signInWithPhoneNumber,
      auth,
      action.phone as string,
      window.recaptchaVerifier,
    )

    yield put(actionCreator.setAuthPhoneVerify(true))

    yield put(showSnackbar('info', t('message.info.phoneCodeSent')))
  } catch (e) {
    yield call(handleError, e)
  } finally {
    yield put(setLoading(false))
  }
}

function* signupByProvider(action: actionType.SignupByProvider) {
  const auth = firebase.getAuth()
  const { userCredential, authCredential }: AuthState = yield select(
    (state) => state.auth,
  )
  const storage = getCryptLocalStorage(LocalStorage.SIGNUP_STATE)

  if (!userCredential || !authCredential) {
    console.error('signupByProvider: userCredential or authCredential is null')
    return
  }

  yield put(setLoading(true))

  try {
    if (window.confirmationResult === undefined) {
      yield put(showSnackbar('info', t('message.info.clickSendAgain')))
      return
    }

    const phoneCredential: firebase.UserCredential = yield call(
      [window.confirmationResult, window.confirmationResult.confirm],
      action.code,
    )

    yield call(api, {
      method: 'DELETE',
      url: `/users/email?email=${userCredential.user.email}&uid=${userCredential.user.uid}`,
    })

    const linkResult: firebase.UserCredential = yield call(
      firebase.linkWithCredential,
      phoneCredential.user,
      authCredential,
    )

    yield call(api, {
      method: 'POST',
      url: '/auth/signup-provider',
      body: {
        firstname: storage.firstname,
        lastname: storage.lastname,
        email: storage.email,
        phone: storage.phone,
        signInProvider: storage.signInProvider,
        avatar: storage.photoURL,
        uid: linkResult.user.uid,
        countryCode: parsePhoneNumber(storage.phone)?.country,
        utmInfo: {
          gaClientId: storage.utmInfo.gaClientId || "",
          handlCustom1: storage.utmInfo.handlCustom1 || "",
          handlIP: storage.utmInfo.handlIP || "",
          utmCampaign: storage.utmInfo.utmCampaign || "",
          utmContent: storage.utmInfo.utmContent || "",
          utmMedium: storage.utmInfo.utmMedium || "",
          utmSource: storage.utmInfo.utmSource || "",
          utmTerm: storage.utmInfo.utmTerm || "",
          isIpPhoneMatches: storage.utmInfo.isIpPhoneMatches || false,
        },
      },
    })

    const linkCredential =
      firebase.OAuthProvider.credentialFromResult(linkResult)

    if (linkCredential === null) {
      console.error('signupByProvider: linkCredential in null')
      return
    }

    const { user }: firebase.UserCredential = yield call(
      firebase.signInWithCredential,
      auth,
      linkCredential,
    )

    const token: string = yield call(service.getToken, user)
    yield put(actionCreator.setRefreshedToken(token))
    localStorage.setItem(LocalStorage.TOKEN, token)
    
    ReactGA.pageview("User successfully registered")

    window.gtag('event', 'conversion', {
      'send_to': 'AW-11178073971/_rdvCLOm6KQYEPO-j9Ip'})

    
    history.push('/')
  } catch (e) {
    yield call(handleError, e)
  } finally {
    yield put(setLoading(false))
  }
}

function* sendEmailVerification(action: actionType.SendEmailVerification) {
  const auth = firebase.getAuth()
  const storage: AuthStorage = getCryptLocalStorage(LocalStorage.SIGNUP_STATE)

  yield put(setLoading(true))

  try {
    const { data: isEmailExist }: Response = yield call(api, {
      method: 'GET',
      url: `/users/email?email=${action.email}`,
    })

    if (isEmailExist) {
      yield put(
        commonActionCreator.setFieldError(
          'registerEmail',
          t('message.error.emailAlreadyInUse'),
        ),
      )
      return
    }

    const result: [] = yield call(
      firebase.fetchSignInMethodsForEmail,
      auth,
      action.email,
    )
    const emailExistOnFirebase = result.length > 0

    if (emailExistOnFirebase) {
      yield call(api, {
        method: 'DELETE',
        url: `/users/email?email=${action.email}`,
      })
    }

    const userCredential: firebase.UserCredential = yield call(
      firebase.createUserWithEmailAndPassword,
      auth,
      action.email,
      action.password,
    )
    yield call(firebase.sendEmailVerification, userCredential.user)

    const newStorage: AuthStorage = { type: 'CHECK_EMAIL' }
    setCryptLocalStorage(LocalStorage.SIGNUP_STATE, {
      ...storage,
      ...newStorage,
    })
    yield put(actionCreator.setRegisterStep('CHECK_EMAIL'))
  } catch (e) {
    localStorage.removeItem(LocalStorage.SIGNUP_STATE)

    if (e instanceof FirebaseError) {
      yield put(commonActionCreator.setFieldError('login', getErrorMessage(e)))
      return
    }

    yield call(handleError, e)
  } finally {
    yield put(setLoading(false))
  }
}

function* verifyEmail(action: actionType.VerifyEmail) {
  const auth = firebase.getAuth()

  yield put(setLoading(true))

  try {
    yield call(firebase.applyActionCode, auth, action.oobCode)

    if (action.continueUrl) {
      // If admin register so redirect to admin host
      const url = new URL(action.continueUrl)

      yield call(api, {
        method: 'POST',
        url: '/auth/admin/register',
        body: {
          uid: url.searchParams.get('uid'),
          email: url.searchParams.get('email'),
          departmentId: url.searchParams.get('departmentId'),
        },
      })

      window.location.replace(action.continueUrl)
    } else {
      history.push('/auth/register')
    }

    yield put(showSnackbar('success', t('message.info.emailVerified')))
  } catch (e) {
    yield call(handleError, e)
  } finally {
    yield put(setLoading(false))
  }
}

function* checkEmailVerify(action: actionType.CheckEmailVerify) {
  const auth = firebase.getAuth()

  yield put(setLoading(true))

  try {
    const userCredential: firebase.UserCredential = yield call(
      firebase.signInWithEmailAndPassword,
      auth,
      action.email,
      action.password,
    )

    if (userCredential.user.emailVerified) {
      const storage: AuthStorage = getCryptLocalStorage(
        LocalStorage.SIGNUP_STATE,
      )
      const newStorage: AuthStorage = { ...storage, type: 'INPUT_EMAIL_PHONE' }
      setCryptLocalStorage(LocalStorage.SIGNUP_STATE, newStorage)

      yield put(actionCreator.setUserCredential(userCredential))
      yield put(actionCreator.setRegisterStep('INPUT_EMAIL_PHONE'))
    } else {
      yield put(showSnackbar('error', t('message.error.emailIsNotVerified')))
    }
  } catch (e) {
    yield call(handleError, e)
  } finally {
    yield put(setLoading(false))
  }
}

function* signupByEmail(action: actionType.SignupByEmail) {
  const auth = firebase.getAuth()
  const storage = getCryptLocalStorage(LocalStorage.SIGNUP_STATE)
  const { userCredential }: AuthState = yield select((state) => state.auth)

  yield put(setLoading(true))

  try {
    if (window.confirmationResult === undefined) {
      yield put(showSnackbar('info', t('message.info.clickSendAgain')))
      return
    }

    const { user: phoneUser }: firebase.UserCredential = yield call(
      [window.confirmationResult, window.confirmationResult.confirm],
      action.code,
    )

    if (userCredential === null || phoneUser === null) {
      console.error('signupByEmail: passwordUser or phoneUser is null')
      return
    }

    // ?? удаление юзера
    yield call(api, {
      method: 'DELETE',
      url: `/users/email?email=${userCredential.user.email}&uid=${userCredential.user.uid}`,
    })

    // получение email + пароль с токена?
    const credential: firebase.EmailAuthCredential = service.getEmailCredential(
      storage.email,
      storage.password,
    )
    // получение, запись в базу?
    const linkResult: firebase.UserCredential = yield call(
      firebase.linkWithCredential,
      phoneUser,
      credential,
    )
    // регистрация на нашем backend
    yield call(api, {
      method: 'POST',
      url: '/auth/signup-provider',
      body: {
        firstname: storage.firstname,
        lastname: storage.lastname,
        email: storage.email,
        phone: storage.phone,
        signInProvider: Provider.EMAIL,
        uid: linkResult.user.uid,
        countryCode: parsePhoneNumber(storage.phone)?.country,
        utmInfo: {
          gaClientId: storage.utmInfo.gaClientId || "",
          handlCustom1: storage.utmInfo.handlCustom1 || "",
          handlIP: storage.utmInfo.handlIP || "",
          utmCampaign: storage.utmInfo.utmCampaign || "",
          utmContent: storage.utmInfo.utmContent || "",
          utmMedium: storage.utmInfo.utmMedium || "",
          utmSource: storage.utmInfo.utmSource || "",
          utmTerm: storage.utmInfo.utmTerm || "",
          isIpPhoneMatches: storage.utmInfo.isIpPhoneMatches || false,
        },
      },
    })
    // вхождение в firebase ради токена?
    const { user }: firebase.UserCredential = yield call(
      firebase.signInWithCredential,
      auth,
      credential,
    )
    // получение токена?
    const token: string = yield call(service.getToken, user)
    yield put(actionCreator.setRefreshedToken(token))
    localStorage.setItem(LocalStorage.TOKEN, token)

    ReactGA.pageview("User successfully registered")
    
    window.gtag('event', 'conversion', {
      'send_to': 'AW-11178073971/_rdvCLOm6KQYEPO-j9Ip'})
    
    history.push('/')
  } catch (e: any) {
    yield call(handleError, e)
  } finally {
    yield put(setLoading(false))
  }
}

function* requestForgot(action: actionType.RequestForgot) {
  const auth = firebase.getAuth()

  yield put(setLoading(true))

  try {
    const { data: isEmailExist }: Response = yield call(api, {
      method: 'GET',
      url: `/users/email?email=${action.email}`,
    })

    if (isEmailExist) {
      yield call(firebase.sendPasswordResetEmail, auth, action.email)

      history.push({
        pathname: '/auth/forgot/done',
        state: { email: action.email },
      })
    } else {
      yield put(
        commonActionCreator.setFieldError('forgot', 'Sorry, account not found'),
      )
    }
  } catch (e) {
    if (e instanceof FirebaseError) {
      yield put(commonActionCreator.setFieldError('forgot', getErrorMessage(e)))
      return
    }
    yield call(handleError, e)
  } finally {
    yield put(setLoading(false))
  }
}

function* signInByProvider(action: actionType.SignInByProvider) {
  const auth = firebase.getAuth()
  const provider = service.getProvider(action.provider)

  yield put(setLoading(true))

  try {
    const userCredential: firebase.UserCredential = yield call(
      firebase.signInWithPopup,
      auth,
      provider,
    )

    if (userCredential.user.phoneNumber === null) {
      const providerUserdata = service.prepareProviderUserData(
        userCredential.user,
      )

      const data: AuthStorage = {
        type: 'PROVIDER_FORM',
        firstname: providerUserdata.firstname,
        lastname: providerUserdata.lastname,
        signInProvider: providerUserdata.signInProvider,
        email: userCredential.user.email || '',
        photoURL: userCredential.user.photoURL || '',
      }

      const credential = service.getProviderCredential(
        action.provider,
        userCredential,
      )

      yield put(actionCreator.setUserCredential(userCredential))
      yield put(actionCreator.setAuthCredential(credential))
      yield put(actionCreator.setRegisterInput(data))

      setCryptLocalStorage(LocalStorage.SIGNUP_STATE, data)

      history.push('/auth/register')

      yield put(actionCreator.setRegisterStep('PROVIDER_FORM'))

      yield put(showSnackbar('error', t('message.error.accountNotFound')))

      return
    }

    const token: string = yield call(service.getToken, userCredential.user)
    const is2FA: boolean = yield call(service.checkIs2FAEnabled, token)

    if (is2FA) {
      localStorage.setItem(LocalStorage.TOKEN_TMP, token)
      history.push('/auth/2step')
      return
    }

    removeAuthLocalStorage(
      LocalStorage.CAMPAIGN_EVERY_LOGIN,
      userCredential.user.uid,
    )
    yield put(actionCreator.setUserCredential(userCredential)) // TODO remove if useless
    yield put(commonActionCreator.hideSnackbar)
    yield put(actionCreator.refreshToken(token, '/'))
  } catch (e) {
    yield call(handleError, e)
  } finally {
    yield put(setLoading(false))
  }
}

function* signInEmail(action: actionType.SignInEmail) {
  const auth = firebase.getAuth()
  yield put(setLoading(true))

  try {
    const { user }: firebase.UserCredential = yield call(
      firebase.signInWithEmailAndPassword,
      auth,
      action.email,
      action.password,
    )

    const token: string = yield call(service.getToken, user)

    const is2FA: boolean = yield call(service.checkIs2FAEnabled, token)

    if (is2FA) {
      localStorage.setItem(LocalStorage.TOKEN_TMP, token)
      history.push('/auth/2step')
      return
    }

    removeAuthLocalStorage(LocalStorage.CAMPAIGN_EVERY_LOGIN, user.uid)
    yield put(commonActionCreator.hideSnackbar)
    yield put(actionCreator.refreshToken(token, '/'))
  } catch (e) {
    if (e instanceof FirebaseError) {
      if (e.code === 'auth/wrong-password')
        yield put(
          commonActionCreator.setFieldError(
            'loginPassword',
            getErrorMessage(e),
          ),
        )
      else
        yield put(
          commonActionCreator.setFieldError('login', getErrorMessage(e)),
        )
      return
    }
    yield call(handleError, e)
  } finally {
    yield put(setLoading(false))
  }
}

function* signIn2Fa(action: actionType.SignIn2Fa) {
  const auth = firebase.getAuth()
  const tmpToken = localStorage.getItem(LocalStorage.TOKEN_TMP)

  if (tmpToken === null) {
    yield put(showSnackbar('error', `Something went wrong. Please try again`))
    yield put(setLoading(false))
    history.push('/auth')
    return
  }

  yield put(setLoading(true))

  try {
    const { data }: Response = yield call(api, {
      method: 'POST',
      url: '/auth/authenticate',
      body: {
        twoFACode: action.code,
      },
      token: tmpToken,
    })

    const { user }: firebase.UserCredential = yield call(
      firebase.signInWithCustomToken,
      auth,
      data.token,
    )
    const token: string = yield call(service.getToken, user)

    localStorage.removeItem(LocalStorage.TOKEN_TMP)
    removeAuthLocalStorage(LocalStorage.CAMPAIGN_EVERY_LOGIN, user.uid)

    yield put(commonActionCreator.hideSnackbar)
    yield put(actionCreator.refreshToken(token, '/'))
  } catch (e) {
    yield call(handleError, e)
  } finally {
    yield put(setLoading(false))
  }
}

function* signInByPhone(action: actionType.SignInByPhone) {
  yield put(setLoading(true))

  try {
    const { user }: firebase.UserCredential = yield call(
      [window.confirmationResult, window.confirmationResult.confirm],
      action.code,
    )

    yield call(api, {
      method: 'POST',
      url: '/auth/signup-provider',
      body: {
        uid: user.uid,
        email: user.email,
        phone: user.phoneNumber,
      },
    })

    const token: string = yield call(service.getToken, user)

    removeAuthLocalStorage(LocalStorage.CAMPAIGN_EVERY_LOGIN, user.uid)
    yield put(commonActionCreator.hideSnackbar)
    yield put(actionCreator.refreshToken(token, '/'))
  } catch (e) {
    yield call(handleError, e)
  } finally {
    yield put(setLoading(false))
  }
}

function* updatePassword(action: actionType.UpdatePassword) {
  const auth = firebase.getAuth()

  try {
    yield call(firebase.verifyPasswordResetCode, auth, action.obbCode)
    yield call(
      firebase.confirmPasswordReset,
      auth,
      action.obbCode,
      action.password,
    )

    yield put(showSnackbar('success'))
    localStorage.removeItem(LocalStorage.OOB_CODE)
    history.push('/auth')
  } catch (e) {
    yield call(handleError, e)
  }
}

function* verifyAndChangeEmail(action: actionType.VerifyAndChangeEmail) {
  const auth = firebase.getAuth()

  yield put(setLoading(true))

  try {
    yield call(firebase.applyActionCode, auth, action.oobCode)

    const url = new URL(action.continueUrl)
    const newEmail = url.searchParams.get('newEmail') || ''
    const password = decrypt(url.searchParams.get('password') || '')

    const { user }: firebase.UserCredential = yield call(
      firebase.signInWithEmailAndPassword,
      auth,
      newEmail,
      password,
    )

    if (user === null) return

    yield call(firebase.updateEmail, user, newEmail)

    const token: string = yield call(service.getToken, user)

    yield call(api, {
      method: 'PUT',
      url: '/users/email',
      body: {
        email: newEmail,
      },
      token: token,
    })
    //yield put(showSnackbar('success', 'Email succesfully changed'))
    yield put(settingsActionCreator.setState('email', newEmail))

    window.location.replace(action.continueUrl)
    // yield put(showSnackbar('success', 'Email succesfully changed'))
  } catch (e) {
    yield call(handleError, e)
    history.push('/auth')
  } finally {
    //yield put(showSnackbar('success', 'Email succesfully changed'))
    yield put(setLoading(false))
  }
}

function* refreshToken(action: actionType.RefreshToken) {
  let token = action.token

  const { refreshedToken }: AuthState = yield select((state) => state.auth)

  try {
    const user: firebase.User = yield call(service.getFirebaseUser)
    if (token === undefined) token = yield call(service.getToken, user)

    localStorage.setItem(LocalStorage.TOKEN, token as string)
    localStorage.setItem(LocalStorage.UID, user.uid)

    yield call(api, {
      method: 'POST',
      url: '/users/session',
      body: { uid: user.uid },
    })

    if (refreshedToken) return

    yield put(actionCreator.setRefreshedToken(token as string))

    if (action.redirect) history.push(action.redirect)
  } catch (e) {
    yield call(handleError, e)
  }
}

function* signOut() {
  const auth = firebase.getAuth()

  try {
    const { is2FAEnabled }: SettingsState = yield select(
      (state: State) => state.settings,
    )

    if (is2FAEnabled) {
      yield put(settingsActionCreator.setState('is2FAEnabled', false))

      yield call(api, {
        method: 'PUT',
        url: '/auth/logout2FA',
      })
    } else {
      yield call([auth, auth.signOut])
    }

    localStorage.removeItem(LocalStorage.UID)
    localStorage.removeItem(LocalStorage.TOKEN)

    yield put(actionCreator.flushState)
    yield put(settingsActionCreator.flushState)

    history.push('/auth')
    const extensionEnabled = process.env.REACT_APP_CROME_EXTENSION_ENABLED
    const extensionId = process.env.REACT_APP_CROME_EXTENSION_ID
    if (extensionEnabled === 'true') {
      if (typeof chrome !== 'undefined' && chrome.runtime && chrome.runtime.sendMessage) {
        // The ID of the chrome extension we want to talk to.
        chrome.runtime.sendMessage(extensionId, { action: 'signOut', data: {} }, function(response) {
          // Get a response from the extension
          if (response) {
            console.log('A response was received from the extension:', response)
          } else {
            console.log('No response received from chrome extension')
          }
        })
      } else (
        console.log('chrome.runtime.sendMessage is not available')
      )
    }
  } catch (e) {
    yield call(handleError, e)
  } finally {
    yield put(setLoading(false))
  }
}

export function* watchAuth() {
  yield takeEvery(actionType.GET_PROVIDER_USER, getProviderUser)
  yield takeEvery(actionType.GET_PHONE_CODE, getPhoneCode)
  yield takeEvery(actionType.SIGNUP_BY_PROVIDER, signupByProvider)
  yield takeEvery(actionType.SEND_EMAIL_VERIFICATION, sendEmailVerification)
  yield takeEvery(actionType.VERIFY_EMAIL, verifyEmail)
  yield takeEvery(actionType.CHECK_EMAIL_VERIFY, checkEmailVerify)
  yield takeEvery(actionType.SIGNUP_BY_EMAIL, signupByEmail)
  yield takeEvery(actionType.REQUEST_FORGOT, requestForgot)
  yield takeEvery(actionType.SIGN_IN_BY_PROVIDER, signInByProvider)
  yield takeEvery(actionType.SIGN_IN_EMAIL, signInEmail)
  yield takeEvery(actionType.SIGN_IN_2FA, signIn2Fa)
  yield takeEvery(actionType.SIGN_IN_BY_PHONE, signInByPhone)
  yield takeEvery(actionType.UPDATE_PASSWORD, updatePassword)
  yield takeEvery(actionType.VERIFY_AND_CHANGE_EMAIL, verifyAndChangeEmail)
  yield takeEvery(actionType.REFRESH_TOKEN, refreshToken)
  yield takeEvery(actionType.SIGN_OUT, signOut)
}
