import createSagaMiddleware from 'redux-saga'
import {
  useDispatch as _useDispatch,
  useSelector as _useSelector,
  TypedUseSelectorHook,
} from 'react-redux'
import { combineReducers, createStore, applyMiddleware } from 'redux'
import { all } from 'redux-saga/effects'
import { CommonState, commonReducer, backendAliveReducer, BackendAliveState } from './common/reducer'
import { AuthState, authReducer } from './auth/reducer'
import { DashboardState, dashboardReducer } from './dashboard/reducer'
import { SearcherState, searcherReducer } from './searcher/reducer'
import { PackageState, packageReducer } from './package/reducer'
import { ApikeyState, apikeyReducer } from './apikey/reducer'
import { SupportState, supportReducer } from './support/reducer'
import { SettingsState, settingsReducer } from './settings/reducer'
import { watchCommon } from './common/saga'
import { watchAuth } from './auth/saga'
import { watchDashboard } from './dashboard/saga'
import { watchSearcher } from './searcher/saga'
import { watchPackage } from './package/saga'
import { watchApikey } from './apikey/saga'
import { watchSupport } from './support/saga'
import { watchSettings } from './settings/saga'
import { watchAdditionalLookup, watchAdditionalPackage, watchToken, watchUserQuota } from './webint/saga'
import { additionalLookupReducer, AdditionalLookupState, additionalPackageReducer, AdditionalPackageState, tokenReducer, TokenState, userQuotaReducer, QuotaState } from './webint/reducer'
import { balcklistedReducer } from './blacklist/reducer'
import { watchBlackList } from './blacklist/saga'

type Dispatch = typeof store.dispatch

export type State = {
  common: CommonState
  auth: AuthState
  dashboard: DashboardState
  searcher: SearcherState
  package: PackageState
  apikey: ApikeyState
  support: SupportState
  settings: SettingsState
  token : TokenState
  additionalLookup: AdditionalLookupState
  additionalPackage : AdditionalPackageState
  userQuota : QuotaState
  backendAlive : BackendAliveState
}

const reducer = combineReducers({
  common: commonReducer,
  auth: authReducer,
  dashboard: dashboardReducer,
  searcher: searcherReducer,
  package: packageReducer,
  apikey: apikeyReducer,
  support: supportReducer,
  settings: settingsReducer,
  token : tokenReducer,
  additionalLookup: additionalLookupReducer,
  additionalPackage: additionalPackageReducer,
  userQuota: userQuotaReducer,
  blackList: balcklistedReducer,
  backendAlive : backendAliveReducer,
})

const sagaMiddleware = createSagaMiddleware()

export const store = createStore(reducer, applyMiddleware(sagaMiddleware))

export const useSelector: TypedUseSelectorHook<State> = _useSelector

export const useDispatch = () => _useDispatch<Dispatch>()

function* rootSaga() {
  yield all([
    watchCommon(),
    watchAuth(),
    watchDashboard(),
    watchSearcher(),
    watchPackage(),
    watchApikey(),
    watchSupport(),
    watchSettings(),
    watchToken(),
    watchAdditionalLookup(),
    watchAdditionalPackage(),
    watchUserQuota(),
    watchBlackList(),
  ])
}

sagaMiddleware.run(rootSaga)
