import { FirebaseError } from 'firebase/app'
import { setFieldError, showSnackbar } from '../store/common/actionCreator'
import { store } from '../store'
import { signOut } from '../store/auth/actionCreator'

const invisibleErrors = [
  'auth/popup-closed-by-user',
  'messaging/permission-blocked',
]

export function getErrorMessage(e: FirebaseError) {
  // new additional codes were brought from https://firebase.google.com/docs/auth/admin/errors
  switch (e.code) {
    case 'auth/claims-too-large':
      return 'Thr claims payload for setCustomUserClaims() exceeded allowed size of 1000 bytes'
    case 'auth/email-already-exists':
      return 'This email is already in use by an existing user'
    case 'auth/id-token-expired':
      return 'The provided Firebase ID token is expired'
    case 'auth/id-token-revoked':
      return 'The Firebase ID token has been revoked'
    case 'auth/insufficient-permission': // relevant for Admin SDK
      return 'Insufficient permission to access the requested resource'
    case 'auth/internal-error':
      return 'unexpected error while trying to process the request'
    case 'auth/invalid-argument':
      return 'Invalid argument provided to Authorization method'
    case 'auth/invalid-claims':
      return 'The custom claim attributes provided to setCustomUserClaims() are invalid'
    case 'auth/invalid-continue-uri':
      return 'The continue URL must be a valid URL string'
    case 'auth/invalid-creation-time':
      return 'The creation time must be a valid UTC date string'
    case 'auth/invalid-credential':
      return 'Provided AdminSDK credentials cannot be used to perform the desired action'
    case 'auth/invalid-disabled-field':
      return 'Provided value for the disabled user property is invalid - It must be a boolean'
    case 'auth/invalid-display-name':
      return 'Provided value for the displayName user property is invalid'
    case 'auth/invalid-dynamic-link-domain':
      return 'Provided dynamic link domain is not configured/authorized for the current project'
    case 'auth/invalid-email-verified':
      return 'Provided value for the emailVerified user property is invalid'
    case 'auth/invalid-hash-algorithm':
      return 'Hash algorithm must match one of the strings in the list of supported algorithms'
    case 'auth/invalid-hash-block-size':
      return 'Hash block size must be a valid number'
    case 'auth/invalid-hash-derived-key-length':
      return 'Hash derived key length must be a valid number'
    case 'auth/invalid-hash-key':
      return 'Hash key must a valid byte buffer'
    case 'auth/invalid-hash-memory-cost':
      return 'Hash memory cost must be a valid number'
    case 'auth/invalid-hash-parallelization':
      return 'Hash parallelization must be a valid number'
    case 'auth/invalid-hash-rounds':
      return 'Hash rounds must be a valid number'
    case 'auth/invalid-hash-salt-separator':
      return 'Hashing algorithm salt separator field must be a valid byte buffer'
    case 'auth/invalid-id-token':
      return 'Provided ID token is not a valid Firebase ID token'
    case 'auth/invalid-last-sign-in-time':
      return 'Last sign-in time must be a valid UTC date string'
    case 'auth/invalid-page-token':
      return 'Provided next page token in listUsers() is invalid'
    case 'auth/invalid-password':
      return 'Provided value for the password user property is invalid'
    case 'auth/invalid-password-hash':
      return 'Password hash must be a valid byte buffer'
    case 'auth/invalid-password-salt':
      return 'Password salt must be a valid byte buffer'
    case 'auth/invalid-phone-number':
      return 'Provided value for the phoneNumber is invalid'
    case 'auth/invalid-photo-url':
      return 'Provided value for the photoURL user property is invalid'
    case 'auth/invalid-provider-data':
      return 'The providerData must be a valid array of UserInfo objects'
    case 'auth/invalid-provider-id':
      return 'The providerId must be a valid supported provider identifier string'
    case 'auth/invalid-oauth-responsetype':
      return 'Only exactly one OAuth responseType should be set to true'
    case 'auth/invalid-session-cookie-duration':
      return 'The session cookie duration must be a valid number in milliseconds between 5 minutes and 2 weeks'
    case 'auth/invalid-uid':
      return 'The provided uid must be a non-empty string with at most 128 characters'
    case 'auth/invalid-user-import':
      return 'The user record to import is invalid'
    case 'auth/maximum-user-count-exceeded':
      return 'The maximum allowed number of users to import has been exceeded'
    case 'auth/missing-android-pkg-name':
      return 'An Android Package Name must be provided if the Android App is required to be installed'
    case 'auth/missing-continue-uri':
      return 'A valid continue URL must be provided in the request'
    case 'auth/missing-hash-algorithm':
      return 'Importing users with password hashes requires hashing algorithm and its parameters'
    case 'auth/missing-ios-bundle-id':
      return 'The request is missing a Bundle ID'
    case 'auth/missing-uid':
      return 'A uid identifier is required for the current operation'
    case 'auth/missing-oauth-client-secret':
      return 'The OAuth configuration client secret is required to enable OIDC code flow'
    case 'auth/operation-not-allowed':
      return 'The provided sign-in provider is disabled for your Firebase project'
    case 'auth/phone-number-already-exists':
      return 'Provided phoneNumber is already in use by an existing user'
    case 'auth/project-not-found':
      return 'No Firebase project was found for the credential used to initialize the Admin SDKs'
    case 'auth/reserved-claims':
      return 'One or more custom user claims provided to setCustomUserClaims() are reserved'
    case 'auth/session-cookie-expired':
      return 'The provided Firebase session cookie is expired'
    case 'auth/session-cookie-revoked':
      return 'The Firebase session cookie has been revoked'
    case 'auth/uid-already-exists':
      return 'The provided uid is already in use by an existing user'
    case 'auth/unauthorized-continue-uri':
      return 'The domain of the continue URL is not whitelisted'
    // ======================= previous cases ========================
    case 'auth/invalid-verification-code':
      return 'Invalid verification code'
    case 'auth/requires-recent-login':
      return 'Security-sensitive actions! Please, re-authenticate'
    case 'auth/user-not-found':
      return 'Sorry, account not found'
    case 'auth/wrong-password':
      return 'Sorry, incorrect password. Try again'
    case 'auth/code-expired':
      return 'Sorry, verification code has been expired. Please press Send again button'
    case 'auth/too-many-requests':
      return 'Sorry, too many requests. Try again later'
    case 'auth/account-exists-with-different-credential':
      return 'Sorry, this account exists with different credentials. Please use another account.'
    case 'auth/network-request-failed':
      return 'Sorry, request has failed. Please, try registering again'
    case 'auth/user-token-expired':
      return 'Sorry, token has expired. Please, try sign up again'
    case 'auth/invalid-action-code':
      return 'Sorry, confirmation link has already expired'
    case 'auth/invalid-email':
      return 'Sorry, this email is invalid'
    case 'auth/quota-exceeded':
      return 'Exceeded quota for verifying passwords'
    case 'auth/provider-already-linked':
      return 'User can only be linked to one identity for the given provider'
    case 'messaging/token-subscribe-failed': {
      store.dispatch(signOut)
      return 'Sorry, something went wrong'
    }
    default:
      // to clarify the message in case we didn't catch it.
      return `error code: ${e.code}, message: ${e.message}`
  }
}

export const handleError = (e: any) => {
  if (e?.code === 'auth/invalid-verification-code') {
    store.dispatch(setFieldError('phoneCode', 'Invalid verification code'))
    return
  }
  if (e instanceof FirebaseError) {
    if (!invisibleErrors.includes(e.code)) {
      store.dispatch(showSnackbar('error', getErrorMessage(e)))
    }
  } else if (e.response) {
    store.dispatch(showSnackbar('error', e.response.data.message))
  } else if (e instanceof Error) {
    store.dispatch(showSnackbar('error', e.message))
  } else {
    console.error('end error: ', e)
  }
}
