import { createStyles, CSSProperties, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import commonSprite from '../asset/sprite/common.png'

export const usePopoverStyles = makeStyles((theme: Theme) => {
  const popover: CSSProperties = {
    top: -10,
    pointerEvents: 'none',
  }
  const paper: CSSProperties = {
    position: 'relative',
    padding: 10,
    maxWidth: 200,
    fontSize: 13,
    background: theme.palette.secondary.light,
    pointerEvents: 'auto',
    overflowY: 'unset',
    overflowX: 'unset',
  }

  const before: CSSProperties = {
    position: 'absolute',
    bottom: -10,
    content: `""`,
    background: `url(${commonSprite}) -162px -140px`,
    width: 20,
    height: 22,
  }

  return createStyles({
    popoverRight: {
      ...popover,
      left: 5,
    },
    popoverLeft: {
      ...popover,
      right: 5,
    },
    paperRight: {
      ...paper,
      left: '100px',
      '&::before': {
        ...before,
        left: -5,
      },
    },
    paperLeft: {
      ...paper,
      right: '100px',
      '&::before': {
        ...before,
        right: -5,
        transform: 'scaleX(-1)',
      },
    },

    miniPaperRight: {
      ...paper,
      left: '100px',
      '&::before': {
        ...before,
        left: -5,
      },
      maxWidth: 100,
    },

    miniPaperLeft: {
      ...paper,
      right: '100px',
      '&::before': {
        ...before,
        right: -5,
        transform: 'scaleX(-1)',
      },
      maxWidth: 100,
    },
  })
})
