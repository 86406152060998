import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material'
import { PopupRequestCustomPackage } from '../../component/PopupRequestCustomPackage'
import { useCardStyles } from '../../style/card'

export const CustomPackage = () => {
  const { t } = useTranslation()
  const cardClasses = useCardStyles()

  const [showPopup, setShowPopup] = React.useState(false)

  return (
    <>
      <Card classes={cardClasses} className={cardClasses.yellowGreen}>
        <CardHeader title={t('customPackage.title')} />
        <CardContent>
          <Typography>{t('customPackage.text')}</Typography>
        </CardContent>
        <CardActions>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlinedRounded"
                color="primary"
                size="small"
                onClick={() => setShowPopup(true)}
              >
                {t('customPackage.request')}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>

      {showPopup && (
        <PopupRequestCustomPackage onClose={() => setShowPopup(false)} />
      )}
    </>
  )
}
