import { TicketType } from './reducer'

export const GET_TICKET_TYPES = 'support/GET_TICKET_TYPES'
export interface GetTicketTypes {
  type: typeof GET_TICKET_TYPES
}

export const SET_TICKET_TYPES = 'support/SET_TICKET_TYPES'
export interface SetTicketTypes {
  type: typeof SET_TICKET_TYPES
  data: TicketType[]
}

export const POST_TICKET = 'support/SET_APIKEY'
export interface PostTicket {
  type: typeof POST_TICKET
  data: FormData
  callback: () => void
}

export const DELETE_TICKET = 'support/DELETE_TICKET'
export interface DeleteTicket {
  type: typeof DELETE_TICKET
  id: number
  callback: () => void
}

export const SEND_MESSAGE = 'support/SEND_MESSAGE'
export interface SendMessage {
  type: typeof SEND_MESSAGE
  formData: FormData
}

export type SupportAction =
  | GetTicketTypes
  | SetTicketTypes
  | PostTicket
  | DeleteTicket
  | SendMessage
