import React from 'react'
import { Box, Grid, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import { PhoneDataItem, SearchResult } from '../../../../store/searcher/reducer'
import { getLabel } from '../../../../component/searcher/RenderData'
import sprite_ from '../../../../sass/sprite.module.sass'
import { Marker } from 'react-mark.js'

type Props = {
  el?: any,
  searchValue: string
}
type objType = {
  [key: string]: object;
};

const WebmiiItem = (props: Props) => {
  const {el, searchValue} = props
  const theme = useTheme()
  const profileData = el?.['profileData']
  if (profileData) {
    return <>
      <Grid item xs={12}>
        <Grid container sx={{display: 'flex'}}>
          <Grid item xs={2}>
            <Typography variant="text" fontSize={14}>
              <Marker as='span' mark={searchValue}>Score</Marker>
              </Typography>
          </Grid>
          <Grid item xs={10}>
            <Box sx={{backgroundColor: '#2798de', padding: '5px 20px', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100px'}}>
              <Typography variant="bold" fontSize={14} sx={{marginRight: '10px', color: '#FFF'}}>
                <Marker as='span' mark={searchValue}>{profileData.webmii_score}</Marker>
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container sx={{display: 'flex', marginTop: '20px'}}>
          <Grid item xs={2}>
            <Typography variant="text" fontSize={14}>
              <Marker as='span' mark={searchValue}>Contact Names</Marker>
              </Typography>
          </Grid>
          <Grid item xs={10}>
            <Stack direction={'row'} gap={'10px'} flexWrap={'wrap'}>
              {
                profileData.contact_names.map((name: string, idx: number) => <Box
                  key={idx}
                  sx={{
                    border: '1px solid #2798de',
                    padding: '5px 20px',
                    borderRadius: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    [theme.breakpoints.only("xs")]: {
                      padding: '5px',
                      marginLeft: '10px',
                    },
                }}
                >
                  <Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>
                    <Marker as='span' mark={searchValue}>{name}</Marker>
                  </Typography>
                </Box>)
              }
            </Stack>
          </Grid>
        </Grid>

        <Grid container sx={{display: 'flex', marginTop: '20px'}}>
          <Grid item xs={3} md={2}>
            <Typography variant="text" fontSize={14}><Marker as='span' mark={searchValue}>Profiles</Marker></Typography>
          </Grid>
          <Grid item xs={9} md={10}>
            <Stack direction={'row'} gap={'20px'} flexWrap={'wrap'}>
              {
                ['facebook_id', 'instagram_id', 'linkedin_id', 'twitter_id'].map((sn: string, idx: number) => {
                  const clearSn = profileData[sn]?.replace("\n", '')?.trim()
                  if (clearSn?.length) {
                    return <Stack key={idx} direction={'row'} sx={{}} alignItems={'center'}>
                      {
                        profileData[sn] && <Tooltip title={sn.replace('_id', '')} placement="top">
                          <Box className={sprite_[`social__small_${sn.replace('_id', '')}`]} sx={{marginRight: '8px'}} />
                        </Tooltip>
                      }
                      <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>
                        <Marker as='span' mark={searchValue}>{profileData[sn]}</Marker>
                      </Typography>
                    </Stack>
                  }
                  return null
                })
              }
            </Stack>
          </Grid>
        </Grid>

        <Grid container sx={{display: 'flex', marginTop: '20px'}}>
          <Grid item md={2} xs={3}>
            <Typography variant="text" fontSize={14}>
              <Marker as='span' mark={searchValue}>Web Results</Marker>
              </Typography>
          </Grid>
          <Grid item md={10} xs={9}>
            <Stack direction={'column'} gap={'10px'}>
              {
                profileData.web_results.map((web: any, idx: number) =>
                  <Stack
                    direction={'row'}
                    key={idx}
                    sx={{
                      [theme.breakpoints.only("xs")]: {
                        flexDirection: 'column',
                      },
                    }}
                  >
                    {
                      web.result_image && <img src={web.result_image} style={{
                        width: '150px', height: '150px', objectFit: 'cover', flexShrink: 0, marginRight: '20px',
                        outline: '#FFFF00 ' + (searchValue.length > 0 && web.result_image.toLowerCase().includes(searchValue.toLowerCase()) ? "solid" : "none") + ' 5px',
                      }} />
                    }
                    <Stack direction={'column'} sx={{width: '100%', flexShrink: 1, padding: '5px 20px 5px 0'}}>
                      <Box>
                        <a href={web.result_link} target={'_blank'}>
                        <Typography variant="bold" fontSize={14} >
                          <Marker as='span' mark={searchValue.length > 0 ? (web.result_link && (web.result_link.toLowerCase().includes(searchValue.toLowerCase()) && web.result_title) || searchValue) : ""}>
                            {web.result_title}
                          </Marker>
                        </Typography></a>
                      </Box>
                      <Typography variant="text" fontSize={14} sx={{marginRight: '10px'}}>
                        <Marker as='span' mark={searchValue}>{web.result_preview}</Marker>
                      </Typography>
                    </Stack>
                  </Stack>,
                )
              }
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      {/*{
        taroItem?.breach_details?.breaches && <Grid item xs={12} >
          <Grid container sx={{display: 'flex'}}>
            <Grid item xs={2}>
              <Typography variant="text" fontSize={14}>Breaches</Typography>
            </Grid>
            <Grid item xs={10}>
              {
                taroItem?.breach_details?.breaches?.map((breach: any, idx: number) => <Stack key={idx} direction={'row'}>
                    {
                      Object.entries(breach).map(([title, value]) =>
                        <Grid item xs={4}>
                          <Typography variant="text" fontSize={14} sx={{marginRight: '10px'}}>{getLabel(title)}</Typography>
                          <Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{value + ''}</Typography>
                        </Grid>,
                      )
                    }
                  </Stack>,
                )
              }
            </Grid>
          </Grid>
        </Grid>
      }
      {
        taroItem?.breach_details?.first_breach && <Grid item xs={12}>
          <Grid container sx={{display: 'flex'}}>
            <Grid item xs={2}><Typography variant="text" fontSize={14}>First Breach</Typography></Grid>
            <Grid item xs={10}><Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{taroItem?.breach_details?.first_breach}</Typography></Grid>
          </Grid>
        </Grid>
      }
      {
        taroItem?.breach_details?.number_of_breaches && <Grid item xs={12}>
          <Grid container sx={{display: 'flex'}}>
            <Grid item xs={2}><Typography variant="text" fontSize={14}>Number of breaches</Typography></Grid>
            <Grid item xs={10}><Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{taroItem?.breach_details?.number_of_breaches}</Typography></Grid>
          </Grid>
        </Grid>
      }
      {
        taroItem?.email && <Grid item xs={12}>
          <Grid container sx={{display: 'flex'}}>
            <Grid item xs={2}><Typography variant="text" fontSize={14}>Email</Typography></Grid>
            <Grid item xs={10}><Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{taroItem?.email}</Typography></Grid>
          </Grid>
        </Grid>
      }
      {
        taroItem?.history && <Grid item xs={12} >
          <Grid container sx={{display: 'flex'}}>
            <Grid item xs={2}>
              <Typography variant="text" fontSize={14}>History</Typography>
            </Grid>
            <Grid item xs={10}>
              <Grid container>
                {
                  Object.entries(taroItem?.history).map(([title, value]) =>
                    <Grid item xs={12}>
                      <Typography variant="text" fontSize={14} sx={{marginRight: '10px'}}>{getLabel(title)}</Typography>
                      <Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{value + ''}</Typography>
                    </Grid>,
                  )
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      {
        taroItem?.domain_details && <Grid item xs={12} >
          <Grid container sx={{display: 'flex'}}>
            <Grid item xs={2}>
              <Typography variant="text" fontSize={14}>Domain Details</Typography>
            </Grid>
            <Grid item xs={10}>
              <Grid container>
                {
                  Object.entries(taroItem?.domain_details).map(([title, value]) =>
                    <Grid item xs={12}>
                      <Typography variant="text" fontSize={14} sx={{marginRight: '10px'}}>{getLabel(title)}</Typography>
                      <Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{value + ''}</Typography>
                    </Grid>,
                  )
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      {
        Object.entries(taroItem?.account_details).map(([service, serviceData]: [string, any]) =>
          serviceData?.registered && Object.keys(serviceData).length > 1 &&
          <Grid item xs={12} >
            <Grid container sx={{display: 'flex'}}>
              <Grid item xs={2}>
                <Typography variant="text" fontSize={14}>{getLabel(service)}</Typography>
              </Grid>
              <Grid item xs={10}>
                <Grid container>
                  {
                    serviceData ? Object.entries(serviceData as Object)?.map(([title, value]) =>
                      <Grid item xs={12}>
                        <Typography variant="text" fontSize={14} sx={{marginRight: '10px'}}>{getLabel(title)}</Typography>
                        <Typography variant="bold" fontSize={14} sx={{marginRight: '10px'}}>{value + ''}</Typography>
                      </Grid>,
                    ) : null
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>,
        )
      }*/}
    </>
  }
  return null
}

export default WebmiiItem