import { format } from 'date-fns'
import { getLabel, getObjectValue } from '../component/searcher/RenderData'
import { store } from '../store'
import { CommonState } from '../store/common/reducer'
import { fromUnix } from './date'
import { isNumeric } from './number'

export function getDataArray(data: object, length: number) {
  return [...Array(length)].map((_) => {
    return data
  })
}

export function search(
  searchValue: string,
  target: object | [],
  excludeKeys?: string[],
) {
  if (searchValue.indexOf('@') === 0) {
    searchValue = searchValue.substring(1)
  }
  function searchInObject(object: object): boolean {
    return Object.entries(object).some(([key, value]) => {
      if (excludeKeys?.includes(key) || value === null) {
        return false
      }
      if (key && getLabel(key).toLowerCase().indexOf(searchValue.toLowerCase()) > -1) {
        return true
      }
      if (typeof value === 'string') {
        return value.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
      } else if (Array.isArray(value)) {
        return value.some((el) => {
          if (typeof el === 'object') {
            return searchInObject(el)
          } else {
            return el.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
          }
        })
      } else if (typeof value === 'object') {
        return searchInObject(value)
      }

      return false
    })
  }

  return searchInObject(target)
}

export const getValueAndLabel = (dataEl: any, subEl: any): any => {
  const noNeedInFormatDate = ['Online']
  const commonState: CommonState = store.getState().common

  const timeFormat = commonState.timeFormat
  let label = getLabel(subEl)

  let value = getObjectValue(dataEl, subEl) || '-'
  if (subEl === 'companyName') label = 'Company'
  if (
    subEl === 'last_seen' &&
    !noNeedInFormatDate.includes(value) &&
    value !== 'Recently' &&
    value !== '-'
  ) {
    if (value === 'LastWeek') {
      value = 'Last week'
    } else if (isNumeric(value)) {
      value = format(fromUnix(Number(value)), `dd/MM/yyyy ${timeFormat}`)
    }
  }
  if (subEl === 'names') {
    value = value[1]?.name || value[0]?.name || '-'
    label = 'Full name'
  }
  if (
    subEl === 'birthday' &&
    dataEl.from === 'callapp' &&
    value?.formattedYear
  ) {
    value =
      value?.formattedYear +
      '-' +
      value?.formattedMonth +
      '-' +
      value?.formattedDay
  }

  if (
    (subEl === 'events' || subEl === 'websites') &&
    dataEl.from === 'callapp'
  ) {
    value = value[0]
    if (typeof value === 'object' && value?.websiteUrl) {
      value = value.websiteUrl
    }
  }
  if (subEl === 'last_time' && value !== undefined && value !== '-') {
    value = format(fromUnix(value * 1000), `dd/MM/yyyy ${timeFormat}`)
  }

  if (subEl === 'internetAddresses' && dataEl.from === 'tc') {
    value = value[1]?.id
    label = 'Website'
  }
  if (subEl === 'phones' && dataEl.from === 'tc') {
    label = 'Phone type'
    value = value[0]?.type
  }
  if (subEl === 'addresses' && dataEl.from === 'callapp') {
    value = value[0]?.street
    label = 'Address'
  }

  if (dataEl.from === 'tc' && subEl === 'birthday' && value?.formattedYear) {
    value = `${value?.formattedYear}-${value?.formattedMonth}-${value?.formattedDay}`
  }

  if (value === undefined) value = '-'
  return { value, label }
}
