import * as React from 'react'
import { Grid } from '@mui/material'
import { useSelector } from '../store'
import { getIsOnBoarding, getOnBoardingStep } from '../store/settings/selector'
import { Requests } from '../container/dashboard/Requests'
import { AccountBalance } from '../container/dashboard/AccountBalance'
import { PackageRenewal } from '../container/dashboard/PackageRenewal'
import { CurrentPackage } from '../container/dashboard/CurrentPackage'
import { CustomPackage } from '../container/dashboard/CustomPackage'
import { OnBoardingStep1 } from '../container/onboarding/Step1'
import { PrivatePage } from '../component/PagePrivate'
import { visitPage } from './visit-page-loger/visit-page-loger-segment'

const line2Height = 350

export function DashboardPage() {
  const isOnBoarding = useSelector(getIsOnBoarding)
  const onBoardingStep = useSelector(getOnBoardingStep)
  const user = useSelector((state) => state.settings)

  React.useEffect(() => {
    if (user.uid) {
      visitPage(user.uid, "/dashboard")
    }
  }, [])
  return (
    <PrivatePage>
      <Grid container spacing={{xs: 2, sm: 4, xl: 4}}>
        <Grid item xs={12} sm={6} xl={3}>
          <AccountBalance />
        </Grid>

        <Grid item xs={12} sm={6} xl={3}>
          <PackageRenewal />
        </Grid>

        <Grid item xs={12} sm={6} xl={3}>
          <CurrentPackage />
        </Grid>

        <Grid item xs={12} sm={6} xl={3}>
          <CustomPackage />
        </Grid>
      </Grid>

      <Requests height={line2Height} />

      {isOnBoarding && onBoardingStep === 1 && <OnBoardingStep1 />}
    </PrivatePage>
  )
}
