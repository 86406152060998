import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { colorPrimary } from '../mui'

export const useButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    yellowGreen: {
      color: theme.palette.black.main,
      background: theme.palette.yellowGreen.main,
      '&:hover': {
        background: theme.palette.yellowGreen.light,
      },
    },
    delete: {
      padding: '0 20px',
      color: '#FFF',
      background: theme.palette.error.dark,
      '&:hover': {
        background: theme.palette.error.main,
      },
    },
    clear: {
      width: '50px',
      color: colorPrimary,
      position: 'absolute',
      bottom: '22px',
      left: '300px',
      fontSize: 15,
      textAlign: 'right',
    },
  }),
)
