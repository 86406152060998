import * as React from 'react'
import PhoneInput, { Value } from 'react-phone-number-input'
import { TypeFormError } from '../function/validation'

export type E164Number = Value

const ForwardedInput = React.forwardRef<
  any,
  React.InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
  const { onChange, value } = props

  return <input type="text" ref={ref} onChange={onChange} value={value} />
})

interface Props {
  value: E164Number | undefined
  error: TypeFormError
  onInput: (value: E164Number | undefined) => void
  autoFocus?: boolean
}

export function InputPhone({ value, error, onInput }: Props) {
  const ref = React.useRef<any>(null)

  React.useEffect(() => {
    ref.current?.focus()
  }, [])

  return (
    <PhoneInput
      ref={ref}
      className={error ? 'form__phone_error' : 'form__phone'}
      value={value}
      inputComponent={ForwardedInput}
      international
      onChange={(value) => onInput(value)}
    />
  )
}
