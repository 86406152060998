export enum LocalStorage {
  TOKEN = 'TOKEN',
  TOKEN_TMP = 'TOKEN_TMP',
  UID = 'UID',
  LANG = 'LANG',
  SIGNUP_STATE = 'SIGNUP_STATE',
  MENU_OPEN = 'MENU_OPEN',
  OOB_CODE = 'OOB_CODE',
  CAMPAIGN_EVERY_LOGIN = 'CAMPAIGN_EVERY_LOGIN',
  CAMPAIGN_ONCE_A_DAY = 'CAMPAIGN_ONCE_A_DAY',
  READ_NOTIFICATION = 'READ_NOTIFICATION',
}
