import * as React from 'react'
import { Button, FormControl, Stack, Typography } from '@mui/material'
import { Replay as AgainIcon } from '@mui/icons-material'
import NumberFormat from 'react-number-format'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from '../store'
import {
  fetchPhoneCode,
  setAuthPhoneVerify,
  setRegisterStep,
} from '../store/auth/actionCreator'
import { LocalStorage } from '../enum/storage'
import { E164Number } from './InputPhone'
import { clearFieldError, showSnackbar } from '../store/common/actionCreator'
import { AuthStep } from '../store/auth/reducer'
import { FormError } from './FormError'

interface Props {
  phone: E164Number | undefined
  phoneCode: string
  setPhoneCode: (value: string) => void
  registerStep?: AuthStep
  setStepActive?: any
}

export function FormVerifyPhone({ phone, phoneCode, setPhoneCode }: Props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { fieldErrors } = useSelector((state) => state.common)

  React.useEffect(() => {
    return function cleanup() {
      dispatch(setAuthPhoneVerify(false))
      dispatch(setRegisterStep('EMAIL_FORM'))
      localStorage.removeItem(LocalStorage.SIGNUP_STATE)
    }
  }, [dispatch])

  React.useEffect(() => {
    dispatch(clearFieldError)
  }, [dispatch, phoneCode])

  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <FormControl className="form__phone">
          <NumberFormat
            value={phoneCode}
            onValueChange={(values) => setPhoneCode(values.value)}
            format="### ###"
            mask="_"
            required
            autoFocus
            style={{
              textAlign: 'center',
              border: fieldErrors.phoneCode ? '1px solid red' : '',
            }}
          />
          <FormError error={fieldErrors.phoneCode} />
        </FormControl>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          startIcon={<AgainIcon />}
          onClick={() => {
            dispatch(fetchPhoneCode(String(phone)))
          }}
          style={{
            position: 'relative',
            top: fieldErrors.phoneCode ? '-15px' : '0px',
          }}
        >
          {t('auth.register.sendAgain')}
        </Button>
      </Stack>

      <Button
        type="submit"
        variant="contained"
        size="large"
        color="primary"
        fullWidth
        disabled={phoneCode.length < 6}
      >
        {t('common.done')}
      </Button>
    </Stack>
  )
}
