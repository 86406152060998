export const GET_PACKAGE = 'package/GET_PACKAGE'
export interface GetPackage {
  type: typeof GET_PACKAGE
}

export const SET_PACKAGE = 'package/SET_PACKAGE'
export interface SetPackage {
  type: typeof SET_PACKAGE
  data: object[]
}

export const POST_PERSONAL_PLAN = 'package/POST_PERSONAL_PLAN'
export interface PostPersonalPlan {
  type: typeof POST_PERSONAL_PLAN
  text: string
  email: string
}

export const DO_REFERENCE_CHARGE = 'package/DO_REFERENCE_CHARGE'
export interface DoReferenceCharge {
  type: typeof DO_REFERENCE_CHARGE
  productId: number
  callback: () => void
}

export type PackageAction =
  | GetPackage
  | SetPackage
  | PostPersonalPlan
  | DoReferenceCharge
