import * as React from 'react'
import Joi from 'joi'
import { t } from 'i18next'
import {
  Close as CloseIcon,
} from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Stack,
  TextareaAutosize,
  Typography,
} from '@mui/material'
import { FormError } from '../../../component/FormError'
import { ErrorsState, select, validateForm } from '../../../function/validation'
import { useDispatch, useSelector } from '../../../store'
import { InputDropzone } from '../../../component/InputDropzone'
import { clearFieldError, showSnackbar } from '../../../store/common/actionCreator'

const schema = Joi.object({
  summary: Joi.string()
    .required()
    .label(t('field.label.text')),
  attachment: Joi.array(),
})

type Props = {
  onClose: () => void,
  onSetInput: (el: string) => void,
  input: string,
}

export const PopupTextUpload = ({ onClose, input, onSetInput }: Props) => {
  const dispatch = useDispatch()

  //const [summary, setSummary] = React.useState('')
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [dispatch, input])

  function handleUpload(files: File[]) {
    const [file] = files

    const reader = new FileReader()
    reader.addEventListener('load', (event) => {
      try {
        const content: string = event?.target?.result + ''
        onSetInput(content)
      } catch (e: any) {
        showSnackbar('error', 'Parsing text file error')
      }
    })
    reader.readAsText(file, "UTF-8")

  }

  function handleSubmit() {
    const error = validateForm(schema, {
      summary: input.trim(),
    })

    if (error) setErrors(error)
    else {
      setErrors(null)
      /*const formData = new FormData()

      formData.append('summary', summary)
      if (attachment[0]) formData.append('files', attachment[0])
      if (attachment[1]) formData.append('files', attachment[1])
      if (attachment[2]) formData.append('files', attachment[2])*/

      //dispatch(postTicket(formData, () => tableRef.current?.refresh()))

      onClose()
    }
  }

  function handleClear() {
    setErrors(null)
    onSetInput('')
  }

  return (
    <Dialog open={true} fullWidth onClose={onClose}>
      <DialogTitle>
        {t('searcher.sentiment.uploadText')}
        <IconButton className="button__close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Divider variant="popup" color="primary" />

      <Stack spacing={2}>
        <FormControl>
          <FormLabel required>{t('field.label.text')}</FormLabel>
          <TextareaAutosize
            className={
              errors?.summary ? 'form__textarea_error' : 'form__textarea'
            }
            minRows={6}
            placeholder={t('field.hint.text')}
            value={input}
            onChange={
              (e) => {
                onSetInput(e.target.value)
              }
            }
          />
          <FormError error={errors?.summary} />
        </FormControl>
        {/** Attachment */}
        <FormControl>
          <Stack direction="row" alignItems="center" spacing={1}>
            <FormLabel>{t('field.label.attachment')}</FormLabel>
            <Typography variant="bold" color="secondary">
              (Optional)
            </Typography>
          </Stack>
          <InputDropzone
            onUpload={handleUpload}
            imagesOnly={false}
            maxFiles={1}
            multiple={false}
            sizeLimit={1024 * 500}
          />
          {/*<Box mt={1}>
            <Stack direction="row" flexWrap="wrap" spacing={1}>
              {attachmentObjectUrls.map((el, idx) => (
                <FilePreview
                  key={idx}
                  attachment={el}
                  onDelete={handleFileDelete}
                />
              ))}
            </Stack>
          </Box>*/}
          <FormError error={errors?.attachment} />
        </FormControl>
      </Stack>

      <Divider variant="popup" color="primary" />

      <DialogActions sx={{ justifyContent: 'right' }}>
        <Button variant="outlined" onClick={handleClear}>
          {t('searcher.sentiment.clear')}
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {t('searcher.sentiment.saveText')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
