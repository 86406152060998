import Stack from "@mui/material/Stack"

import { LinearProgressBar } from "react-percentage-bar"

function CircularProgressWithLabel(props : any) {
  return (
  <LinearProgressBar
  color={[
    props.color,
    props.color,
  ]}
  percentageColor="black"
  percentagePosition="onleft"
  startDirection="left"
  text="Phone number quality score:"
  textStyle={{
    color: '#000000',
    fontSize: '10px',
    textAlign: 'center',
  }}
  trackColor="#808080"
  width={150}
  percentage={props.value}

/>
  )
}

export default function CircularDeterminate(props: any) {
  return (
    <Stack spacing={2} direction="row">
      <CircularProgressWithLabel value={props.progress} color={props.color}/>
    </Stack>
  )
}
