import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Chip,
} from '@mui/material'
import { useSelector } from '../../store'
import {
  getIsOnBoarding,
  getOnBoardingStep,
} from '../../store/settings/selector'
import { OnBoardingStep3 } from '../onboarding/Step3'
import { useUserPackage } from '../../hook/useUserPackage'

export function CurrentPackage() {
  const { t } = useTranslation()

  const { isExpired } = useUserPackage()

  const { userPackage, packageBalance } = useSelector((state) => state.settings)
  const isOnBoarding = useSelector(getIsOnBoarding)
  const onBoardingStep = useSelector(getOnBoardingStep)

  const zIndex = onBoardingStep === 3 ? 2 : 1

  return (
    <div style={{ position: 'relative', zIndex: zIndex, height: '100%' }}>
      <Card>
        <CardHeader title={t('currentPackage.title')} />
        <CardContent>
          <Typography variant="body2">{userPackage?.title}</Typography>
        </CardContent>
        <CardActions>
          <Grid container justifyContent="flex-end">
            <Grid item>
              {packageBalance || packageBalance === 0 ? (
                isExpired || packageBalance === 0 ? (
                  <Chip label={t('package.expired')} color="error" />
                ) : (
                  <Chip label={t('package.active')} color="success" />
                )
              ) : null}
            </Grid>
          </Grid>
        </CardActions>
      </Card>

      {isOnBoarding && onBoardingStep === 3 && <OnBoardingStep3 />}
    </div>
  )
}
