import React from 'react'
import Joi, { Schema, ValidationOptions } from 'joi'
import { t } from 'i18next'
import { LocalStorage } from '../enum/storage'
import { isValidPhoneNumber } from 'react-phone-number-input'

export type FormState = Record<string, any>
export type ErrorsState = Record<string, string | Record<string, string>> | null
export type TypeFormError = string | Record<string, string> | undefined
type EventInputChange = React.ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement
>

export const regex = {
  latinOnly: /^[a-zA-Z0-9.,_\-!:€"() ]*$/,
  onlyLatinLetters: /^[a-zA-Z ]*$/,
}

export const notRequired = Joi.any().allow('')
export const stringRequired = Joi.string()
  .required()
  .messages({ 'string.empty': t('message.error.required') })
export const stringSize = Joi.string()//.min(3).max(100).required()
export const latinOnly = Joi.string()
  .regex(regex.latinOnly)
  .disallow(' ')
  .required()
  .messages({ 'string.pattern.base': t('Only Latin letters are allowed.') })
export const onlyLatinLetters = Joi.string()
  .regex(regex.onlyLatinLetters)
  .disallow(' ')
  .required()
  .messages({ 'string.pattern.base': t('Only Latin letters are allowed.') })
export const notRequiredString = Joi.string()
  .allow('')
  .regex(/^([^0-9]*)$/)
  .messages({ 'string.pattern.base': t('message.error.requiredString') })
export const email = Joi.string()
  .regex(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
  .required()
  .min(6)
  .max(320)
  .messages({ 'string.pattern.base': t('message.error.email') })
  .label(t('field.label.email'))
export const password = Joi.string()
  .required()
  .min(6)
  .max(255)
  .label(t('field.label.password'))
export const passwordRepeat = Joi.string()
  .required()
  .min(6)
  .max(255)
  .valid(Joi.ref('password'))
  .messages({ 'any.only': t('message.error.passwordRepeat') })
  .label(t('field.label.confirmPassword'))
export const select = Joi.required()
  .disallow('null')
  .messages({ 'any.invalid': t('message.error.required') })
export const phone = Joi.string()
  .required()
  .label(t('field.label.phone'))
  .custom((value, helper) => {
    if (isValidPhoneNumber(value) === false) {
      if ( value.includes("+9985") && value.length === 13) {
				return true
			} else
      return helper.message({
        custom: t('message.error.phone'),
      })
    } else {
      return true
    }
  })

export function preventEmptySpaceOnKeyDown(
  e: React.KeyboardEvent<HTMLInputElement>,
) {
  if (e.key === ' ') e.preventDefault()
}

export function preventEmptySpaceOnInput(
  e: EventInputChange,
  setState: React.Dispatch<React.SetStateAction<string>>,
) {
  const value = e.target.value.replaceAll(/ /g, '')
  setState(value)
}

export function preventCyrillicOnInput(
  e: EventInputChange,
  setState: React.Dispatch<React.SetStateAction<string>>,
) {
  const value = e.target.value.replaceAll(/[а-яА-Я]/g, '')
  setState(value)
}

const options: ValidationOptions = {
  abortEarly: false,
  errors: {
    language: localStorage.getItem(LocalStorage.LANG) || undefined,
  },
}

export function validateForm(schema: Schema, data: FormState) {
  const { error } = schema.validate(data, options)

  if (error?.details) {
    const errors: ErrorsState = {}

    error.details.forEach((el) => {
      const key: string | undefined = el.context?.key

      if (key === undefined) return

      const isArrayError = el.path.length === 3

      if (isArrayError) {
        const arrayName = el.path[0]
        const arrayElementIndex = el.path[1]
        const arrayElementKey: string | number = el.path[2]
        const arrayElementName: string = arrayName + String(arrayElementIndex)

        const innerError =
          (errors[arrayElementName] as Record<string, string>) || {}

        innerError[arrayElementKey] = el.message

        errors[arrayElementName] = innerError
      } else {
        errors[key] = el.message
      }
    })

    return errors
  } else {
    return false
  }
}
