import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from '../../../store'
import {
  Box,
  DialogContent,
  Grid,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  DialogTitle,
  Divider,
  useTheme,
} from '@mui/material'
import sprite_ from '../../../sass/sprite.module.sass'
import { useAccordionStyles } from '../../../style/lookup'
import { Phone } from '../../../store/searcher/reducer'
import { addLeadingZeros, isNumeric } from '../../../function/number'
import { prepareDeepWebResult } from '../../../store/searcher/service'
import { usePopover } from '../../../hook/usePopover'
import { getTitle } from '../../../function/string'

export const necessaryData = [
    'email',
    'city',
    'country',
    'createdTime', 
    'name',
    'criteria', 
    'online',
]
  
export interface Name {
    app: Phone
    name: string
}
  
type Props = {
    pdfData: any
}


export function DeepwebPagePdf({ pdfData: apiData }: Props) {
  //const
  const { t } = useTranslation()
  const minWidthFor2items = 1000
  const dispatch = useDispatch()
  const accordionClasses: any = useAccordionStyles()
  const theme = useTheme()
  const regionNames = new Intl.DisplayNames(['EN'], { type: 'region' })
  const data = prepareDeepWebResult(apiData)

  //hooks
  const isOnRightSideOnPage = React.useMemo(() => {
    if (window.innerWidth < minWidthFor2items) {
      return true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth])

  const { popoverClasses, popoverOptions } = usePopover(
    isOnRightSideOnPage,
    true,
  )
  const initState = React.useMemo(() => {
    return data.data ? data.data.map((_) => true) : []
  }, [data.data])

  const fileName = React.useMemo(() => {
    if (apiData) {
      const date = new Date(String(apiData?.createdTime))
      const days = addLeadingZeros(date.getDate(), 2)
      const month = addLeadingZeros(date.getMonth() + 1, 2)
      const year = date.getFullYear()
      const type =
        apiData?.type.charAt(0).toUpperCase() + apiData?.type.slice(1)
      return `${type}_${apiData.criteria}_${days}_${month}_${year}.pdf`
    } else {
      return 'file.pdf'
    }
  }, [apiData])

//   //state
  const [accordionOpen, setAccordionOpen] = React.useState<boolean[]>(initState)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [popoverOpen, setPopoverOpen] = React.useState(false)
  const [popoverName, setPopoverName] = React.useState<string>('')

  //handlers
  const handleChangeAccordion =
    (idx: number) => (event: React.SyntheticEvent, expanded: boolean) => {
      setAccordionOpen((state) => {
        const newState = [...state]
        newState[idx] = expanded
        return newState
      })
    }

  const handlePopoverEnter = (
    event: React.MouseEvent<HTMLElement>,
    name: string,
  ) => {
    setAnchorEl(event.currentTarget)
    setPopoverName(name)
    setPopoverOpen(true)
  }

  const handlePopoverLeave = () => {
    setPopoverOpen(false)
  }

  return (
    <div style={{ padding: '30px', backgroundColor: 'white' }}>
      <Stack direction="row" alignItems="flex-start" spacing={7} sx={{
        [theme.breakpoints.only("xs")]: {
          flexDirection: 'column',
        },
      }}>
        <Stack style={{width: '100%'}} sx={{
          [theme.breakpoints.only("xs")]: {
            marginLeft: '0!important',
            marginTop: '10px!important',
          },
        }}>
          <DialogTitle sx={{
            [theme.breakpoints.only("xs")]: {
              display: 'none',
            },
          }}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              lineHeight={1}
              mb={3}
            >
              <Typography variant="semiBold" fontSize={24} sx={{
                [theme.breakpoints.only("xs")]: {
                  fontSize: 14,
                },
              }}>
                {t('searcher.deepweb.popup.title')}
              </Typography>
              <Stack direction="row" spacing={1}>
                {/* images on title */}
                {data.sources.map((el, idx) => (
                  <Box
                    key={idx}
                    className={sprite_['social__small_' + el.name]}
                    onMouseEnter={(e) => {
                      handlePopoverEnter(e, el.name)
                    }}
                    onMouseLeave={handlePopoverLeave}
                  />
                ))}
              </Stack>
            </Stack>
          </DialogTitle>
          <DialogContent>
            {
              data.data?.map((dataEl, idx) => (
                <Accordion
                  classes={accordionClasses}
                  key={idx}
                  square
                  expanded={accordionOpen[idx]}
                  onChange={handleChangeAccordion(idx)}

                >
                  <AccordionSummary >
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {/*<Box
                        className={sprite_['social__small_' + dataEl.from]}
                      />*/}
                      <Typography
                        variant="semiBold"
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {/*{dataEl.from} - */}{data.criteria}
                      </Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Divider
                      sx={{
                        margin: '-5px 0px 15px 0px',
                        border: `0.1px solid rgba(46, 151, 213, 0.3)`,
                      }}
                    />
                    
                    <Grid container>
                      {
                        Object.entries(dataEl).filter(el => el[1]).map(([label, value]) => {
                          if (typeof value !== 'object') {
                            return <Grid item xs={6} key={label}>
                              <Grid container={(value + '').length < 35}>
                                <Grid item xs={5}>
                                  <Typography variant="text" fontSize={14}>
                                    {getTitle(label || '')}
                                  </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                  <Typography variant="bold">{value || '-'}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          }
                        })
                      }
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
          </DialogContent>
        </Stack>
      </Stack>

    </div>
  )
}