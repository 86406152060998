import React from 'react'
import { useTranslation } from 'react-i18next'
import { ExpandMore as SelectIcon } from '@mui/icons-material'
import table_ from '../../../sass/table.module.sass'
import {
  CardHeader,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { showSnackbar } from '../../../store/common/actionCreator'
import { useDispatch } from '../../../store'
import { useSearchStyles } from '../hook/useStyles'
import { SvgIcon } from '../../SvgIcon'
import { Filter, FilterState } from '../types'

interface Props {
  title?: string
  search?: boolean
  searchPlaceholder?: string
  filters?: Filter[]
  selectedRowIds?: number[]
  selectNav?: React.ReactNode
  onFilter?: (state: FilterState[]) => void
  onSearch?: (state: string) => void
}

export function Navigation(props: Props) {
  const {
    title,
    search,
    searchPlaceholder,
    filters,
    selectedRowIds,
    selectNav,
    onFilter,
    onSearch,
  } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const searchClasses = useSearchStyles()

  const initFilter = React.useMemo(() => {
    return (
      filters?.map((el) => ({ key: el.key, value: 'all', text: 'All' })) || []
    )
  }, [filters])

  const [filterState, setFilterState] =
    React.useState<FilterState[]>(initFilter)
  const [searchState, setSearchState] = React.useState('')

  const handleChangeFilter = (filter: Filter, value: string) => {
    if (filter.options === undefined) return

    const text = filter.options.find((el) => el.id === value)?.text || 'All'
    const newFilterState = filterState.map((el) =>
      el.key === filter.key ? { ...el, value, text } : el,
    )

    setFilterState(newFilterState)

    if (onFilter) onFilter(newFilterState)
  }

  const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setSearchState(searchState.trim())
      handleSearch()
    }
  }

  const handleSearch = () => {
    if (onSearch) {
      if (searchState.length > 320) {
        dispatch(showSnackbar('info', t('message.info.max320')))
      } else {
        onSearch(searchState)
      }
    }
  }

  if (selectedRowIds && selectedRowIds.length > 0) {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        height={54}
        px={4}
        pb={2}
      >
        <CardHeader
          title={`Selected: ${selectedRowIds.length}`}
          sx={{ marginRight: 'auto' }}
        />
        {selectNav}
      </Stack>
    )
  }

  if (!title && !search) return null

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      px={4}
      pb={2}
    >
      <CardHeader title={title} sx={{ marginRight: 'auto' }} />
      {(filters || search) && (
        <Stack direction="row" spacing={2}>
          {filters?.map((filter) => (
            <Stack key={filter.key} direction="row" alignItems="center">
              <Select
                className={table_.select}
                value={filterState.find((el) => el.key === filter.key)?.value}
                renderValue={(selected) => (
                  <Stack direction="row" spacing={0.5}>
                    <Typography variant="semiBold" fontSize={14}>
                      {filter.placeholder}:
                    </Typography>
                    <Typography>
                      {
                        filterState.filter((el) => el.value === selected)[0]
                          ?.text
                      }
                    </Typography>
                  </Stack>
                )}
                IconComponent={SelectIcon}
                onChange={(event: SelectChangeEvent) =>
                  handleChangeFilter(filter, event.target.value)
                }
              >
                <MenuItem key={0} value="all">
                  {t('common.all')}
                </MenuItem>
                {filter.options?.map((el) => (
                  <MenuItem key={el.id} value={el.id}>
                    {el.text}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          ))}
          {search && (
            <OutlinedInput
              classes={searchClasses}
              value={searchState}
              placeholder={
                searchPlaceholder ? searchPlaceholder : t('common.search')
              }
              onKeyDown={handleSearchKeyDown}
              onChange={(e) => setSearchState(e.target.value)}
              onBlur={(e) => setSearchState(searchState.trim())}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton size="small" tabIndex={-1} onClick={handleSearch}>
                    <SvgIcon name="search" size={{ width: 15, height: 15 }} />
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
        </Stack>
      )}
    </Stack>
  )
}
