import * as React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch } from '../store'
import { verifyEmail, verifyAndChangeEmail } from '../store/auth/actionCreator'
import { setCryptLocalStorage } from '../function/storage'
import { LocalStorage } from '../enum/storage'

/*
 * В Firebase админ-панели в разделе "Authentication" в подвкладке "Templates" указывается "Action URL". Туда делается
 * редирект по необходимости
 * */

export function FirebaseActionUrlPage() {
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()

  React.useEffect(() => {
    const params = new URLSearchParams(location.search)
    const mode = params.get('mode')
    const oobCode: string | null = params.get('oobCode')
    const continueUrl: string | null = params.get('continueUrl')

    switch (mode) {
      case 'verifyEmail':
        if (oobCode === null) return

        dispatch(verifyEmail(oobCode, continueUrl))
        break
      case 'verifyAndChangeEmail':
        if (oobCode === null) return
        if (continueUrl) dispatch(verifyAndChangeEmail(oobCode, continueUrl))
        break
      case 'resetPassword':
        if (continueUrl) {
          window.location.replace(`${continueUrl}?oobCode=${oobCode}`)
        } else {
          setCryptLocalStorage(LocalStorage.OOB_CODE, oobCode)
          history.push('/auth/change/password')
        }
    }
  }, [location, dispatch, history])

  return null
}
