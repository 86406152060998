import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  DialogContent,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Divider,
  Avatar,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Link,
  Popover,
  PopoverOrigin,
  CircularProgress, useTheme, Tabs, Tab,
} from '@mui/material'
import { Marker } from "react-mark.js"
import sprite_ from '../../../sass/sprite.module.sass'
import { useAccordionStyles } from '../../../style/lookup'
import { NameDataItem, Source } from '../../../store/searcher/reducer'
import {
  getCorsImageUrl,
  RenderProfileData,
} from '../../../component/searcher/RenderData'
import { search } from '../../../function/data'
import { useDispatch, useSelector } from '../../../store'
import { prepareNameResult } from '../../../store/searcher/service'
import { usePopover } from '../../../hook/usePopover'
import { SearchResult } from '../../../store/searcher/reducer'
import { ResultTitle } from '../../../style/result'
import * as _ from 'lodash'
import PostItem from './items/PostItem'
import WebmiiItem from './items/WebmiiItem'
import { ProfileTabs } from '../../../component/ProfileTabs'
import CollapsibleTable from './NameApiCommon'

const searchExcludes = [
  'profile_id',
  'keyphrase',
  'keyword',
  'friendOf',
  'friendName',
  'friendProfileId',
  'friendImageUrl',
  'customrequestid',
  'type',
  'album_name',
  'setting=RABBIT_MQ_STATUS_MONITORING_QUEUE_NAME',
  'actualMainUrl',
  'profileCoverPhotoUrl',
]

function getFilterIcon(source: Source, sourceFilter: Source[]) {
  if (sourceFilter.includes(source)) return sprite_['social__large_' + source]
  else return sprite_['social__small_' + source]
}

function getAccordionStyle(idx: number, profileUrl: object) {
  return {
    cursor:
      profileUrl && Object.keys(profileUrl).length !== 0 ? 'pointer' : 'auto',
  }
}

type Props = {
  apiData: SearchResult
}

export type NameProfileData = {
  originalObject: NameDataItem,
  posts: any,
}

export function NameCommon(props: Props) {
  const { t } = useTranslation()
  const accordionClasses = useAccordionStyles()
  const theme = useTheme()

  const {apiData} = props
  const data = prepareNameResult(apiData)

  const hasApiResults = data.sources.some(source => 
      source.name === "x_api" || source.name === "fb_api" || source.name === "instagram_api" || source.name === "linkedin_api")
   
  const initState = React.useMemo(() => {
    return data.data ? data.data.map((_) => false) : []
  }, [data.data])

  console.log(data)
  const [accordionOpen, setAccordionOpen] = React.useState<boolean[]>(initState)
  const [filter, setFilter] = React.useState<Source[]>([])
  const [searchValue, setSearchValue] = React.useState('')

  const displayProfiles: NameProfileData[] = React.useMemo(() => {
    let filtered: NameProfileData[]
      if (data && data.data){
        filtered = data.data.filter( (d)=> "profileData" in d).map((d) => {
          const profPosts = data.data?.filter((f) => f.from === d.from && f.profile_id === d.profile_id && (f as any)?.type === "PostItem")
          return { 
          originalObject: d,
          posts: profPosts,
        }})

        if (searchValue.length > 0) {
          filtered = filtered?.filter((row) =>
            search(searchValue, row, searchExcludes),
          ).map((row) => {return {
            originalObject: row.originalObject,
            posts: row.posts?.filter((post: any) => search(searchValue, post, searchExcludes)) || row.posts,
          }})
        }
    
        return filtered
      }else return []
    }
  ,[data.data, filter, searchValue])
  
  const sources = React.useMemo(() => {
    return [...new Set(data.data?.map((el) => el.from))] || []
  }, [data.data])

  const handleChangeAccordion =
    (idx: number) => (event: React.SyntheticEvent, expanded: boolean) => {
      setAccordionOpen((state) => {
        const newState = [...state]
        newState[idx] = expanded
        return newState
      })
    }


  const handleSearchValue = (value: string) => {
    setSearchValue(value)
  }


  const dispatch = useDispatch()

  return (
    <>
      <Stack>
        {
          data && data.data && data.data.length > 0 && <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            lineHeight={1}
            mb={3}
          >
            <ResultTitle>Details:</ResultTitle>
            <OutlinedInput
              value={searchValue}
              sx={{
                background: 'none',
              }}
              placeholder={t('searcher.name.popup.searchPlaceholder')}
              onChange={(e) => handleSearchValue(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <Box className={sprite_.common__search} />
                </InputAdornment>
              }
              style={{ marginLeft: 'auto', width: 192, height: 38 }}
            />
          </Stack>
        }
        <>
          {displayProfiles.map((el: NameProfileData, idx: number) => {
            return (
              <Accordion
                key={idx}
                classes={accordionClasses}
                square
                expanded={
                  el.originalObject.profileData && Object.keys(el.originalObject.profileData).length !== 0
                    ? accordionOpen[idx]
                    : false
                }
                onChange={handleChangeAccordion(idx)}
              >
                <AccordionSummary
                  style={getAccordionStyle(idx, el.originalObject.profileData)}
                  sx={{
                    [theme.breakpoints.only("xs")]: {
                      justifyContent: 'flex-start',
                      margin: '3px',
                    },
                    margin: '10px',
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={3}
                    width="100%"
                  >
                    <Avatar
                      src={getCorsImageUrl(el.originalObject.profileImageUrl)}
                      sx={{
                        height: 75,
                        width: 75,
                        [theme.breakpoints.only("xs")]: {
                          width: 45,
                          height: 45,
                        },
                      }}
                    />
                    <Stack>
                      <Typography variant="bold"><Marker as={'span'} mark={searchValue}>{el.originalObject.profileName}</Marker></Typography>
                      <Typography variant="semiBold" color="secondary" sx={{
                        [theme.breakpoints.only("xs")]: {
                          fontSize: '10px',
                        },
                      }}>
                        {el.originalObject.profileData && el.originalObject.profileData['Profile Headline']}
                      </Typography>
                      <Typography variant="bold" color="secondary">
                        <Marker as={'span'} mark={searchValue}>{el.originalObject.profileCity}</Marker>
                      </Typography>
                    </Stack>
                    <Stack
                      justifyContent="space-between"
                      alignItems="flex-end"
                      style={{ marginLeft: 'auto', height: '100%' }}
                      sx={{
                        [theme.breakpoints.only("xs")]: {
                          flexDirection: 'row',
                          width: '60px!important',
                          overflow: 'hidden',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          flexShrink: '0',
                        },
                      }}
                    >
                      <Box className={sprite_['social__small_' + el.originalObject.from]} sx={{
                        [theme.breakpoints.only("xs")]: {
                          marginRight: '3px',
                        },
                      }} />
                      {el.originalObject.profileUrl && (
                        <Link
                          variant="underlined"
                          href={el.originalObject.profileUrl}
                          target="_blank"
                          sx={{
                            [theme.breakpoints.only("xs")]: {
                              fontSize: '10px',
                            },
                          }}
                        >
                          <Marker mark={searchValue}>@{el.originalObject.profile_id}</Marker>
                        </Link>
                      )}
                    </Stack>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <ProfileTabs el={el} searchValue={searchValue}/>
                  </Box>

                </AccordionDetails>
              </Accordion>
            )
          })}
          { (hasApiResults === true) && (
            <CollapsibleTable apiData={apiData}/> 
          ) }
        </>
      </Stack>
    </>
  )
}
