import * as React from 'react'
import { countries, CountryType } from '../Countries'
import { FormGroup, Autocomplete, Box, TextField, OutlinedInput } from '@mui/material'

interface NameByCountryProps {
    input: string
    setInput: React.Dispatch<React.SetStateAction<string>>
    country: CountryType
    setCountry: React.Dispatch<React.SetStateAction<CountryType>>
    placeholder: string
}

export default function NameByCountry(props: NameByCountryProps){
    const {input, country, setInput, setCountry, placeholder} = props
    const onChangeHandle = (value: CountryType | null) => {
      if (value){
          setCountry(value)
      }else{
          setCountry(country)
      }
    }

    return <div className={'MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-3'}>
                    <FormGroup row style={{ flexWrap: 'nowrap' }}>
                      <Autocomplete
                        id="country-select-demo"
                        sx={{ width: 300 }}
                        onChange={(event, value) => onChangeHandle(value)}
                        options={countries}
                        autoHighlight
                        isOptionEqualToValue={(value1, value2) => value1.code === value2.code}
                        value={country}
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              alt=""
                            />
                            {option.label} ({option.code})
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Choose a country"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "off", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                      <OutlinedInput
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder={placeholder}
                        style={{ marginLeft: 0, width: '100%' }}
                      />
                    </FormGroup>
                  </div>
}