import * as React from 'react'
import Joi from 'joi'
import NumberFormat from 'react-number-format'
import { useTranslation } from 'react-i18next'
import { Replay as AgainIcon } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Stack,
} from '@mui/material'
import { useDispatch, useSelector } from '../../store'
import { updatePhone } from '../../store/settings/actionCreator'
import {
  fetchPhoneCode,
  setAuthPhoneVerify,
} from '../../store/auth/actionCreator'
import { E164Number, InputPhone } from '../../component/InputPhone'
import {
  ErrorsState,
  validateForm,
  phone,
  notRequired,
} from '../../function/validation'
import { FormError } from '../../component/FormError'
import { clearFieldError } from '../../store/common/actionCreator'

const schema = Joi.object({
  phone: phone,
  phoneCode: notRequired,
})

type Props = {
  onClose: () => void
}

export const DialogChangePhone = ({ onClose }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { authPhoneVerify } = useSelector((state) => state.auth)

  const [phone, setPhone] = React.useState<E164Number | undefined>()
  const [phoneCode, setPhoneCode] = React.useState('')
  const [errors, setErrors] = React.useState<ErrorsState>(null)
  const fieldErrors = useSelector((state) => state.common.fieldErrors)
  React.useEffect(() => {
    return function cleanup() {
      dispatch(setAuthPhoneVerify(false))
    }
  }, [dispatch])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const error = validateForm(schema, { phone, phoneCode })

    if (error) setErrors(error)
    else {
      setErrors(null)
      if (authPhoneVerify) {
        dispatch(updatePhone(phoneCode, String(phone), () => handleClose()))
      } else {
        dispatch(fetchPhoneCode(String(phone), 'register'))
      }
    }
  }

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [dispatch, phone, phoneCode])

  const handleClose = () => {
    onClose()
    dispatch(setAuthPhoneVerify(false))
  }

  React.useEffect(() => {
    console.log(fieldErrors)
  }, [fieldErrors])
  return (
    <Dialog open={true} fullWidth onClose={handleClose}>
      <DialogTitle>{t('settings.changePhoneNumber')}</DialogTitle>

      <Divider variant="popup" color="primary" />

      <form onSubmit={handleSubmit} noValidate>
        <Stack spacing={2}>
          <FormControl>
            <Grid container>
              <Grid item xs={5}>
                <FormLabel required>{t('field.label.newPhone')}</FormLabel>
              </Grid>
              <Grid item xs={7}>
                <InputPhone
                  value={phone}
                  error={errors?.phone || fieldErrors?.phone}
                  onInput={setPhone}
                />
                <FormError error={errors?.phoneCode || fieldErrors?.phone || errors?.phone} />
              </Grid>
            </Grid>
          </FormControl>
          {authPhoneVerify && (
            <FormControl className="form__phone">
              <Grid container>
                <Grid item xs={5}>
                  <FormLabel>{t('field.label.verificationCode')}</FormLabel>
                </Grid>
                <Grid item xs={7}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <FormControl className="form__phone">
                      <NumberFormat
                        value={phoneCode}
                        onValueChange={(values) => setPhoneCode(values.value)}
                        format="### ###"
                        mask="_"
                        required
                        autoFocus
                        style={{
                          textAlign: 'center',
                          borderColor: fieldErrors?.phoneCode ? 'red' : '',
                        }}
                      />
                    </FormControl>
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      startIcon={<AgainIcon />}
                      onClick={() => dispatch(fetchPhoneCode(String(phone)))}
                    >
                      {t('auth.register.sendAgain')}
                    </Button>
                  </Stack>
                  <FormError
                    error={errors?.phoneCode || fieldErrors?.phoneCode}
                  />
                </Grid>
              </Grid>
            </FormControl>
          )}
        </Stack>

        <Divider variant="popup" color="primary" />

        <DialogActions>
          <Button onClick={handleClose} color="primary" size="large">
            {t('common.cancel')}
          </Button>
          {authPhoneVerify ? (
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={phoneCode.length < 6}
            >
              {t('common.verify')}
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
            >
              {t('common.save')}
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  )
}
