import { E164Number } from "../../component/InputPhone"

export const CHECK_EMAIL_BLACKLIST = 'blacklist/CHECK_EMAIL_BLACKLIST'
export interface CheckEmailBlacklist {
  type: typeof CHECK_EMAIL_BLACKLIST,
  email: string | undefined,
  ipAddress: string | undefined,
  callback: Function,
}

export const CHECK_PHONE_BLACKLIST = 'blacklist/CHECK_PHONE_BLACKLIST'
export interface CheckPhoneBlacklist {
  type: typeof CHECK_PHONE_BLACKLIST,
  phone: E164Number,
  callback: Function,
}

export const CHECK_IP_ADDRESS_BLACKLIST = 'blacklist/CHECK_IP_ADDRESS_BLACKLIST'
export interface CheckIpAddressBlacklist {
  type: typeof CHECK_IP_ADDRESS_BLACKLIST,
  ipAddress: string,
  callback: Function,
}

export type BlackListAction =
  | CheckEmailBlacklist
  | CheckPhoneBlacklist
  | CheckIpAddressBlacklist
