import { DashboardAction } from './actionType'
import { DateType } from './reducer'

export const fetchMonitor = (dateType: DateType): DashboardAction => ({
  type: 'dashboard/GET_CHART',
  dateType,
})

export const setMonitor = (data: []): DashboardAction => ({
  type: 'dashboard/SET_CHART',
  data,
})
