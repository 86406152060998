import * as React from 'react'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
import { useDispatch } from '../store'
import { signIn2Fa } from '../store/auth/actionCreator'
import auth_ from '../sass/auth.module.sass'
import { Box, Button, FormControl, FormLabel, Grid, Stack } from '@mui/material'
import { Page } from '../component/auth/Page'

export function TwoStepVerifyPage() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [code, setCode] = React.useState('')
  const [focused, setFocused] = React.useState(false)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(signIn2Fa(code))
  }

  return (
    <Page>
      <Grid className={auth_.title} item>
        <Box mb={6}>{t('auth.2step.title')}</Box>
      </Grid>

      <Grid item>
        <form onSubmit={handleSubmit} noValidate>
          <Stack spacing={4}>
            <FormControl className="form__phone">
              <FormLabel className={focused ? 'Mui-focused' : ''}>
                {t('field.label.verificationCode')}
              </FormLabel>
              <NumberFormat
                value={code}
                onValueChange={(values) => setCode(values.value)}
                format="### ###"
                mask="_"
                required
                autoFocus
                style={{ textAlign: 'center' }}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
              />
            </FormControl>

            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              disabled={code.length !== 6}
            >
              {t('common.done')}
            </Button>
          </Stack>
        </form>
      </Grid>
    </Page>
  )
}
