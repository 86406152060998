import * as React from 'react'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Avatar from '@mui/material/Avatar'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { red } from '@mui/material/colors'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ShareIcon from '@mui/icons-material/Share'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import {
  Box,
  Stack, Tooltip,
} from '@mui/material'

import { SearchResult, SentimentAnalysysItem } from '../../../store/searcher/reducer'


import { prepareSentimentalAnalysisResult } from '../../../store/searcher/service'
import sprite_ from '../../../sass/sprite.module.sass'
import { ResultEntity } from '../../../style/result'
import { getTitle } from '../../../function/string'

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

type Props = {
  apiData: SearchResult
}

export function SentimentAnalysisCommon(props: Props) {
  const result = prepareSentimentalAnalysisResult(props.apiData)
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  return (
   
      <>
      <Stack>
      { 
      result.data?.map( (el : SentimentAnalysysItem , idx : number) => {
        return (
          <Stack>
            <Stack direction={'row'} sx={{alignItems: 'center', justifyContent: 'space-between', marginBottom: '15px'}}>
              <Stack direction={'column'} sx={{marginBottom: '10px'}}>
                {
                  el.topic && <>
                    <Typography style={{margin: '5px 0', color: '#214A98', fontSize: '16px', fontWeight: 700}}>Topic:</Typography>
                    <Typography sx={{fontSize: '1.5em'}}>
                      {el.topic}
                    </Typography>
                  </>
                }
              </Stack>
              <Stack sx={{flex: '0 0 100px'}}>
                <Box className={sprite_[`common__sentiment_${el.sentiment?.toLowerCase()}`]} />
              </Stack>
            </Stack>

            <Stack direction={'row'} sx={{alignItems: 'center', flexWrap: 'wrap', gap: '12px'}}>
              {
                el?.entity && el?.entity?.split(', ').map((entity: string, idx: number) => {
                  if (entity) return (
                    <Tooltip title={entity} placement="top" key={idx}>
                    <ResultEntity
                      {...{sentiment: el.sentiment?.toLowerCase()}}
                    >
                      <div style={{
                        maxWidth: '200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'}}>{entity}</div>
                    </ResultEntity>
                  </Tooltip>
                  )
                },
                )
              }
            </Stack>
            <Stack direction={'column'} sx={{marginBottom: '30px'}}>
              {
                el.createdTime && <Stack direction={'row'} sx={{marginTop: '10px'}}>
                  <Typography fontSize={16} color={'#214A98'} fontWeight={700} sx={{marginRight: '10px'}}>Retrieved:</Typography>
                  <Typography fontSize={16} color={'#747474'} fontWeight={400}>{el.createdTime}</Typography>
                </Stack>
              }
            </Stack>

            <Stack>
              <Typography style={{margin: '5px 0', color: '#214A98', fontSize: '16px', fontWeight: 700}}>Summary:</Typography>
              <Typography variant="body2" color="#000000">
                {el.summary}
              </Typography>
            </Stack>
            <Stack sx={{paddingTop: '20px'}}>
              <Typography style={{margin: '5px 0', color: '#214A98', fontSize: '16px', fontWeight: 700}}>Criteria:</Typography>
              <Typography paragraph>
                {el.text}
              </Typography>
            </Stack>

          </Stack>
        )
      })
      }
      </Stack>
      </>
  )
}
