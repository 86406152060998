import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Joi from 'joi'
import { useDispatch } from '../store'
import { updatePassword } from '../store/auth/actionCreator'
import auth_ from '../sass/auth.module.sass'
import { Box, Button, Grid, Stack } from '@mui/material'
import { InputPassword } from '../component/InputPassword'
import { getCryptLocalStorage } from '../function/storage'
import { LocalStorage } from '../enum/storage'
import { Page } from '../component/auth/Page'
import {
  ErrorsState,
  password,
  passwordRepeat,
  validateForm,
} from '../function/validation'
import { clearFieldError } from '../store/common/actionCreator'

const schema = Joi.object({
  password: password,
  passwordRepeat: passwordRepeat,
})

export function ChangePasswordPage() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const oobCode = getCryptLocalStorage(LocalStorage.OOB_CODE)

  const [password, setPassword] = React.useState('')
  const [passwordRepeat, setPasswordRepeat] = React.useState('')
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const error = validateForm(schema, { password, passwordRepeat })

    if (error) setErrors(error)
    else {
      setErrors(null)
      dispatch(updatePassword(oobCode, password))
    }
  }

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [password, passwordRepeat, dispatch])

  return (
    <Page>
      <Grid className={auth_.title} item>
        {t('settings.changePassword')}
      </Grid>

      <Grid item>
        <Box mt={10}>
          <form onSubmit={handleSubmit} noValidate>
            <Stack spacing={4}>
              <InputPassword
                label={t('field.label.password')}
                value={password}
                error={errors?.password}
                onChange={setPassword}
                placeholder={t('field.hint.password')}
                required
              />

              <InputPassword
                label={t('field.label.confirmPassword')}
                value={passwordRepeat}
                error={errors?.passwordRepeat}
                onChange={setPasswordRepeat}
                placeholder={t('field.hint.passwordRepeat')}
                required
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                {t('settings.changePassword')}
              </Button>
            </Stack>
          </form>
        </Box>
      </Grid>
    </Page>
  )
}
