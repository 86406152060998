import React from 'react'
import Joi from 'joi'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import {
  Divider,
  FormControl,
  FormLabel,
  Grid,
  OutlinedInput,
  Stack,
} from '@mui/material'
import { useDispatch } from '../../store'
import { sendEmailUpdateVerification } from '../../store/settings/actionCreator'
import { InputPassword } from '../../component/InputPassword'
import {
  email,
  ErrorsState,
  password,
  preventEmptySpaceOnInput,
  validateForm,
} from '../../function/validation'
import { FormError } from '../../component/FormError'
import { clearFieldError } from '../../store/common/actionCreator'

const schema = Joi.object({
  email: email,
  password: password,
})

type Props = {
  handleClose: () => void
}

type InputEvent = React.ChangeEvent<HTMLInputElement>

export const DialogChangeEmail = ({ handleClose }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const error = validateForm(schema, { email, password })

    if (error) setErrors(error)
    else {
      setErrors(null)
      dispatch(sendEmailUpdateVerification(email, password))
      handleClose()
    }
  }

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [email, password, dispatch])
  return (
    <Dialog open={true} fullWidth onClose={handleClose}>
      <DialogTitle>{t('settings.changeEmail')}</DialogTitle>

      <Divider variant="popup" color="primary" />

      <form onSubmit={handleSubmit} noValidate>
        <Stack spacing={2}>
          <Grid container>
            <Grid item xs={5}>
              <FormLabel required>{t('field.label.newEmail')}</FormLabel>
            </Grid>
            <Grid item xs={7}>
              <FormControl fullWidth>
                <OutlinedInput
                  value={email}
                  error={Boolean(errors?.email)}
                  placeholder={t('field.hint.newEmail')}
                  onChange={(e: InputEvent) =>
                    preventEmptySpaceOnInput(e, setEmail)
                  }
                  autoFocus
                />
                <FormError error={errors?.email} />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={5}>
              <FormLabel required>{t('field.label.password')}</FormLabel>
            </Grid>
            <Grid item xs={7}>
              <InputPassword
                value={password}
                error={errors?.password}
                onChange={setPassword}
                placeholder={t('field.hint.password')}
              />
            </Grid>
          </Grid>
        </Stack>

        <Divider variant="popup" color="primary" />

        <DialogActions>
          <Button onClick={handleClose} color="primary" size="large">
            {t('common.close')}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="large"
          >
            {t('common.save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
