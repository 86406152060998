import { CommonAction, TOGGLE_MENU_ANIMATION, SetBackendAliveAction } from './actionType'
import { Notification, TableBodyData } from './reducer'
import { SearchResult } from '../searcher/reducer'
import { AlertColor } from '@mui/material/Alert/Alert'

export const setLoading = (status: boolean): CommonAction => ({
  type: 'common/SET_LOADING',
  status,
})

export const setLanguage = (language: string): CommonAction => ({
  type: 'common/SET_LANGUAGE',
  language,
})

export const showSnackbar = (
  severity: AlertColor,
  message?: string,
): CommonAction => ({
  type: 'common/SHOW_SNACKBAR',
  severity,
  message,
})

export const hideSnackbar: CommonAction = { type: 'common/HIDE_SNACKBAR' }

export const setFieldError = (
  key: string,
  error: string | undefined,
): CommonAction => ({
  type: 'common/SET_FIELD_ERROR',
  key,
  error,
})

export const clearFieldError: CommonAction = {
  type: 'common/CLEAR_FIELD_ERROR',
}

export const patchCampaign = (
  id: number,
  action: 'view' | 'action',
): CommonAction => ({
  type: 'common/PATCH_CAMPAIGN',
  id,
  action,
})

// export const initNotification: CommonAction = {
//   type: 'common/INIT_NOTIFICATION',
// }

export const fetchNotification = (
  offset: number,
  limit: number,
  fetchMore?: boolean,
  refreshToken?: boolean,
): CommonAction => ({
  type: 'common/GET_NOTIFICATION',
  offset,
  limit,
  fetchMore,
  refreshToken,
})

export const setNotification = (data: Notification[]): CommonAction => ({
  type: 'common/SET_NOTIFICATION',
  data,
})

export const addNotification = (data: Notification[]): CommonAction => ({
  type: 'common/ADD_NOTIFICATION',
  data,
})

export const addNewNotification = (data: Notification[]): CommonAction => ({
  type: 'common/ADD_NEW_NOTIFICATION',
  data,
})

export const readNotification = (id: number): CommonAction => ({
  type: 'common/READ_NOTIFICATION',
  id,
})

export const fetchTable = (
  url: string,
  params: Record<string, any>,
  tableId: string,
): CommonAction => ({
  type: 'common/GET_TABLE',
  url,
  params,
  tableId,
})

export const setTable = (
  data: TableBodyData,
  tableId: string,
): CommonAction => ({
  type: 'common/SET_TABLE',
  data,
  tableId,
})

export const addTableRow = (
  row: object,
  tableLimit: number,
  tableId: string,
): CommonAction => ({
  type: 'common/ADD_TABLE_ROW',
  row,
  tableLimit,
  tableId,
})

// TODO export to searcher module
export const replaceSearcherTableRow = (row: SearchResult): CommonAction => ({
  type: 'common/REPLACE_SEARCHER_TABLE_ROW',
  row,
})
export const updateSearcherTableRowPercentage = (
  id: number,
  name: string,
  progress: number,
): CommonAction => ({
  type: 'common/UPDATE_SEARCHER_TABLE_ROW_PERCENTAGE',
  id,
  name,
  progress,
})

export const clearNotifications = (): CommonAction => ({
  type: 'common/CLEAR_NOTIFICATIONS',
})

export const clearNotificationsSuccess = (): CommonAction => ({
  type: 'common/CLEAR_NOTIFICATIONS_SUCCESS',
})

export const toggleMenuAnimation = {
  type: TOGGLE_MENU_ANIMATION,
}

// BackendAlive
export const fetchBackendAlive: CommonAction = { type: 'backend/GET_BACKEND_ALIVE' }

export const setBackendAlive = (backendAlive: boolean): SetBackendAliveAction => ({
  type: 'backend/SET_BACKEND_ALIVE',
  backendAlive,
})
