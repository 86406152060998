import { BlackListAction } from "./actionType"


export function balcklistedReducer(state: {}, action: BlackListAction){
  switch(action.type){
    case "blacklist/CHECK_EMAIL_BLACKLIST":
      return {}
    case "blacklist/CHECK_PHONE_BLACKLIST":
      return {}
    case "blacklist/CHECK_IP_ADDRESS_BLACKLIST":
      return {}
    default:
      return {}
  }
}