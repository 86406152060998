import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Circle as CircleIcon } from '@mui/icons-material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
  Box,
  Button,
  Grid,
} from '@mui/material'
import { useDispatch, useSelector } from '../../store'
import onBoarding_ from '../../sass/onBoarding.module.sass'
import {
  setOnBoardingStep,
  setOnBoardingStatus,
  finishOnBoarding,
} from '../../store/settings/actionCreator'

function BackdropComponent() {
  return null
}

export function OnBoardingStep1() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { packageBalance } = useSelector((state) => state.settings)

  return (
    <Dialog
      open={true}
      onClose={() => dispatch(setOnBoardingStatus(false))}
      BackdropComponent={BackdropComponent}
    >
      <DialogTitle>{t('onBoarding.title')}</DialogTitle>
      <Typography
        color="primary"
        fontFamily="Montserrat-Bold, sans-serif"
        fontSize={16}
        textAlign="center"
      >
        {packageBalance}
        {t('onBoarding.titleSub')}
      </Typography>

      <Divider variant="popup" color="primary" />

      <DialogContent>
        <Typography
          fontFamily="Montserrat-Bold, sans-serif"
          fontSize={18}
          color="black"
        >
          {t('onBoarding.opportunity.title')}
        </Typography>
        <Stack spacing={2} mt={2} mb={4}>
          <Box>
            <CircleIcon className={onBoarding_.listDot_primary} />
            <Typography fontSize={16}>
              {t('onBoarding.opportunity.1')}
            </Typography>
          </Box>
          <Box>
            <CircleIcon className={onBoarding_.listDot_primary} />
            <Typography fontSize={16}>
              {t('onBoarding.opportunity.2')}
            </Typography>
          </Box>
          <Box>
            <CircleIcon className={onBoarding_.listDot_primary} />
            <Typography fontSize={16}>
              {t('onBoarding.opportunity.3')}
            </Typography>
          </Box>
        </Stack>

        <Typography
          fontFamily="Montserrat-Bold, sans-serif"
          fontSize={18}
          color="black"
        >
          {t('onBoarding.coming.title')}
        </Typography>
        <Stack spacing={2} mt={2}>
          <Box>
            <CircleIcon className={onBoarding_.listDot_secondary} />
            <Typography fontSize={16}>{t('onBoarding.coming.1')}</Typography>
          </Box>
          <Box>
            <CircleIcon className={onBoarding_.listDot_secondary} />
            <Typography fontSize={16}>{t('onBoarding.coming.2')}</Typography>
          </Box>
          <Box>
            <CircleIcon className={onBoarding_.listDot_secondary} />
            <Typography fontSize={16}>{t('onBoarding.coming.3')}</Typography>
          </Box>
        </Stack>
      </DialogContent>

      <Divider variant="popup" color="primary" />

      <DialogActions>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Button
              variant="outlinedRounded"
              size="medium"
              color="primary"
              onClick={() => dispatch(finishOnBoarding)}
            >
              {t('common.skip')}
            </Button>
          </Grid>
          <Grid item>
            <Typography fontSize={16}>1 {t('common.of')} 3</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="containedRounded"
              size="medium"
              color="success"
              onClick={() => dispatch(setOnBoardingStep(2))}
            >
              {t('common.next')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
