import * as React from 'react'
import PhoneInput from 'react-phone-number-input'
import { FormControl, FormLabel, OutlinedInput } from '@mui/material'
import { useDispatch } from '../../store'
import { setUsernameInputHelper } from '../../store/auth/actionCreator'
import { InputMode } from '../../page/AuthLoginPage'
import { TypeFormError } from '../../function/validation'
import { FormError } from '../../component/FormError'

const inputExcludes = ['Backspace', 'Delete']

const ForwardedInput = React.forwardRef<
  any,
  React.InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
  const dispatch = useDispatch()
  const { onChange, value } = props

  const [input, setInput] = React.useState('')

  if (value === '') {
    dispatch(setUsernameInputHelper(input))
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (
      e.altKey ||
      e.shiftKey ||
      (e.ctrlKey && e.key === 'v') ||
      !inputExcludes.includes(e.key)
    ) {
      setInput(e.key)
    } else {
      setInput('')
      dispatch(setUsernameInputHelper(''))
    }
  }

  return (
    <input
      type="text"
      ref={ref}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      onPaste={(e) => setInput(e.clipboardData.getData('Text'))}
      value={value}
    />
  )
})

interface InputUsernameProps {
  label: string
  inputMode: InputMode
  username: string
  error: TypeFormError
  onChange: (value: string) => void
}

export function InputUsername({
  label,
  inputMode,
  username,
  error,
  onChange,
}: InputUsernameProps) {
  const phoneInput = React.useRef<any>(null)

  React.useEffect(() => {
    phoneInput.current?.focus()
  }, [inputMode])

  return (
    <FormControl fullWidth>
      <FormLabel required>{label}</FormLabel>
      {inputMode === 'phone' ? (
        <PhoneInput
          ref={phoneInput}
          className={error ? 'form__phone_error' : 'form__phone'}
          value={username}
          inputComponent={ForwardedInput}
          international
          onChange={onChange}
        />
      ) : (
        <OutlinedInput
          placeholder={label}
          value={username}
          name="username"
          onChange={(e) => onChange(e.target.value)}
          autoFocus
          autoComplete="on"
          error={Boolean(error)}
        />
      )}
      <FormError error={error} />
    </FormControl>
  )
}
