import {
  OAuthCredential,
  UserCredential,
  EmailAuthCredential,
} from 'firebase/auth'
import { AuthAction } from './actionType'
import { Provider } from '../../enum/provider'
import { AuthStep } from './reducer'

export const flushState: AuthAction = { type: 'auth/FLUSH_STATE' }

export const fetchProviderUser = (provider: Provider): AuthAction => ({
  type: 'auth/GET_PROVIDER_USER',
  provider,
})

export const setUserCredential = (
  userCredential: UserCredential,
): AuthAction => ({
  type: 'auth/SET_USER_CREDENTIAL',
  userCredential,
})

export const setAuthCredential = (
  credential: OAuthCredential | EmailAuthCredential | null,
): AuthAction => ({
  type: 'auth/SET_AUTH_CREDENTIAL',
  credential,
})

export const setRegisterStep = (mode: AuthStep): AuthAction => ({
  type: 'auth/SET_REGISTER_STEP',
  mode,
})

export const setRegisterInput = (data: object): AuthAction => ({
  type: 'auth/SET_REGISTER_INPUT',
  data,
})

export const setRegisterInputEmpty: AuthAction = {
  type: 'auth/SET_REGISTER_INPUT_EMPTY',
}

export const setUsernameInputHelper = (value: string): AuthAction => ({
  type: 'auth/SET_USERNAME_INPUT_HELPER',
  value,
})

export const fetchPhoneCode = (
  phone: string,
  mode?: 'register' | 'login',
): AuthAction => ({
  type: 'auth/GET_PHONE_CODE',
  phone,
  mode,
})

export const setAuthPhoneVerify = (value: boolean): AuthAction => ({
  type: 'auth/SET_AUTH_PHONE_VERIFY',
  value,
})

export const signupByProvider = (
  code: string,
  provider: Provider,
): AuthAction => ({
  type: 'auth/SIGNUP_BY_PROVIDER',
  code,
  provider,
})

export const sendEmailVerification = (
  email: string,
  password: string,
): AuthAction => ({
  type: 'auth/SEND_EMAIL_VERIFICATION',
  email,
  password,
})

export const verifyEmail = (
  oobCode: string,
  continueUrl: string | null,
): AuthAction => ({
  type: 'auth/VERIFY_EMAIL',
  oobCode,
  continueUrl,
})

export const checkEmailVerify = (
  email: string,
  password: string,
): AuthAction => ({
  type: 'auth/CHECK_EMAIL_VERIFY',
  email,
  password,
})

export const signupByEmail = (code: string): AuthAction => ({
  type: 'auth/SIGNUP_BY_EMAIL',
  code,
})

export const signInByProvider = (provider: Provider): AuthAction => ({
  type: 'auth/SIGN_IN_BY_PROVIDER',
  provider,
})

export const signInByPhone = (code: string): AuthAction => ({
  type: 'auth/SIGN_IN_BY_PHONE',
  code,
})

export const signInEmail = (email: string, password: string): AuthAction => ({
  type: 'auth/SIGN_IN_EMAIL',
  email,
  password,
})

export const signIn2Fa = (code: string): AuthAction => ({
  type: 'auth/SIGN_IN_2FA',
  code,
})

export const requestForgot = (email: string): AuthAction => ({
  type: 'auth/REQUEST_FORGOT',
  email,
})

export const updatePassword = (
  obbCode: string,
  password: string,
): AuthAction => ({
  type: 'auth/UPDATE_PASSWORD',
  obbCode,
  password,
})

export const verifyAndChangeEmail = (
  oobCode: string,
  continueUrl: string,
): AuthAction => ({
  type: 'auth/VERIFY_AND_CHANGE_EMAIL',
  oobCode,
  continueUrl,
})

export const refreshToken = (
  token?: string,
  redirect?: string,
): AuthAction => ({
  type: 'auth/REFRESH_TOKEN',
  token,
  redirect,
})

export const setRefreshedToken = (token: string): AuthAction => ({
  type: 'auth/SET_REFRESHED_TOKEN',
  token,
})

export const signOut: AuthAction = { type: 'auth/SIGN_OUT' }
