import { AuthAction } from './actionType'
import { LocalStorage } from '../../enum/storage'
import {
  EmailAuthCredential,
  OAuthCredential,
  UserCredential,
} from 'firebase/auth'
import { Country } from 'react-phone-number-input'
import { getCryptLocalStorage } from '../../function/storage'
import { Provider } from '../../enum/provider'
import { E164Number } from '../../component/InputPhone'

export type AuthStep =
  | 'PROVIDER_FORM'
  | 'INPUT_PROVIDER_PHONE'
  | 'VERIFY_PROVIDER_PHONE'
  | 'EMAIL_FORM'
  | 'CHECK_EMAIL'
  | 'VERIFY_EMAIL_PHONE'
  | 'INPUT_EMAIL_PHONE'

  type AdditionalInformation = {
    utmCampaign: string,
    utmSource: string,
    utmTerm: string,
    utmContent: string,
    utmMedium: string,
    handlCustom1: string,
    gaClientId: string,
    handlIP: string
    isIpPhoneMatches: boolean
  }

export interface AuthStorage {
  type: AuthStep
  provider?: Provider
  signInProvider?: string
  photoURL?: string
  phone?: E164Number
  firstname?: string
  lastname?: string
  email?: string
  password?: string
  utmInfo?: AdditionalInformation
}

interface RegisterInput {
  firstname: string
  lastname: string
  email: string
  password: string
  phone: E164Number
  country?: Country
  signInProvider: string
  photoURL: string
  phoneCode: string
  utmInfo: AdditionalInformation
}

export type AuthState = {
  userCredential: UserCredential | null
  authCredential: OAuthCredential | EmailAuthCredential | null
  registerStep: AuthStep
  registerInput: RegisterInput
  usernameInputHelper: string
  authPhoneVerify: boolean
  refreshedToken: string | null
}

const storageSignup: AuthStorage =
  getCryptLocalStorage(LocalStorage.SIGNUP_STATE) || {}

const initialState: AuthState = {
  userCredential: null,
  authCredential: null,
  registerStep: storageSignup.type || 'EMAIL_FORM',
  registerInput: {
    firstname: storageSignup.firstname || '',
    lastname: storageSignup.lastname || '',
    email: storageSignup.email || '',
    password: storageSignup.password || '',
    phone: storageSignup.phone || '',
    signInProvider: storageSignup.signInProvider || '',
    photoURL: storageSignup.photoURL || '',
    phoneCode: '',
    utmInfo: {
      gaClientId: storageSignup.utmInfo?.gaClientId || "",
      handlCustom1: storageSignup.utmInfo?.handlCustom1 || "",
      handlIP: storageSignup.utmInfo?.handlIP || "",
      utmCampaign: storageSignup.utmInfo?.utmCampaign || "",
      utmContent: storageSignup.utmInfo?.utmContent || "",
      utmMedium: storageSignup.utmInfo?.utmMedium || "",
      utmSource: storageSignup.utmInfo?.utmSource || "",
      utmTerm: storageSignup.utmInfo?.utmTerm || "",
      isIpPhoneMatches: storageSignup.utmInfo?.isIpPhoneMatches || false,
    },
  },
  usernameInputHelper: '',
  authPhoneVerify: false,
  refreshedToken: null,
}

export function authReducer(state = initialState, action: AuthAction) {
  switch (action.type) {
    case 'auth/FLUSH_STATE':
      return {
        ...state,
        ...initialState,
      }
    case 'auth/SET_USER_CREDENTIAL':
      return {
        ...state,
        userCredential: action.userCredential,
      }
    case 'auth/SET_AUTH_CREDENTIAL':
      return {
        ...state,
        authCredential: action.credential,
      }
    case 'auth/SET_REGISTER_STEP':
      return {
        ...state,
        registerStep: action.mode,
      }
    case 'auth/SET_REGISTER_INPUT':
      return {
        ...state,
        registerInput: {
          ...state.registerInput,
          ...action.data,
        },
      }
    case 'auth/SET_REGISTER_INPUT_EMPTY':
      const newState: Record<string, string> = {}

      Object.keys(state.registerInput).forEach((key) => {
        newState[key] = ''
      })

      return {
        ...state,
        registerInput: newState,
      }
    case 'auth/SET_USERNAME_INPUT_HELPER':
      return {
        ...state,
        usernameInputHelper: action.value,
      }
    case 'auth/SET_AUTH_PHONE_VERIFY':
      return {
        ...state,
        authPhoneVerify: action.value,
      }
    case 'auth/SET_REFRESHED_TOKEN':
      return {
        ...state,
        refreshedToken: action.token,
      }
    default:
      return state
  }
}
