import { getLocaleJson } from '../../i18n'
import { AlertColor } from '@mui/material/Alert/Alert'
import { LocalStorage } from '../../enum/storage'
import { CommonAction } from './actionType'
import { SearchResult, SourcePercentage } from '../searcher/reducer'
import { Apikey } from '../apikey/reducer'
import { Ticket } from '../support/reducer'
import { getAuthLocalStorageArray } from '../../function/storage'

export interface Notification {
  id: number
  requestMonitorId: number
  type: 'admin' | 'search'
  title: string
  text: string
  isRed?: boolean
}

export type Translation = {
  message: {
    error: {
      emailAlreadyInUse: string
    }
  }
  settings: {
    twoFATurnedOn: string
    twoFATurnedOff: string
    pictureHint2: string
    pictureHint3: string
  }
}

export type TableBodyRow = SearchResult & Apikey & Ticket

export type TableBodyData = {
  list: TableBodyRow[]
  count: number | null
}

const localLang = localStorage.getItem(LocalStorage.LANG) || 'en'

export type CommonState = {
  loading: boolean
  // language: string
  timeFormat: string
  timeFormatWithSeconds: string
  translation: Translation
  isRtl: boolean
  snackbar: {
    isOpen: boolean
    severity: undefined | AlertColor
    message: undefined | string
  }
  notifications: Notification[]
  fieldErrors: Record<string, string | undefined>
  tables: Record<string, TableBodyData | undefined>
  isMenuAnimation: boolean
}

const redNotifications = getAuthLocalStorageArray(
  LocalStorage.READ_NOTIFICATION,
)

const initialState: CommonState = {
  loading: false,
  // language: localLang,
  timeFormat: 'HH:mm',
  timeFormatWithSeconds: 'HH:mm:ss',
  translation: getLocaleJson(localLang),
  isRtl: localStorage.getItem(LocalStorage.LANG) === 'he',
  snackbar: {
    isOpen: false,
    severity: undefined,
    message: undefined,
  },
  notifications: [],
  fieldErrors: {},
  tables: {},
  isMenuAnimation: false,
}

export function commonReducer(state = initialState, action: CommonAction) {
  switch (action.type) {
    case 'common/SET_LOADING':
      return {
        ...state,
        loading: action.status,
      }
    case 'common/SET_LANGUAGE':
      localStorage.setItem(LocalStorage.LANG, action.language)
      return {
        ...state,
        language: action.language,
        isRtl: action.language === 'he',
      }
    case 'common/SHOW_SNACKBAR':
      return {
        ...state,
        snackbar: {
          isOpen: true,
          severity: action.severity,
          message: action.message,
        },
      }
    case 'common/HIDE_SNACKBAR':
      return {
        ...state,
        snackbar: { ...state.snackbar, isOpen: false },
      }
    case 'common/SET_FIELD_ERROR':
      return {
        ...state,
        fieldErrors: {
          ...state.fieldErrors,
          [action.key]: action.error,
        },
      }
    case 'common/CLEAR_FIELD_ERROR':
      return {
        ...state,
        fieldErrors: {},
      }
    case 'common/SET_NOTIFICATION':
      return {
        ...state,
        notifications: action.data.map((el) => ({
          ...el,
          isRed: redNotifications.includes(el.id),
        })),
      }
    case 'common/ADD_NOTIFICATION':
      return {
        ...state,
        notifications: [...state.notifications, ...action.data],
      }
    case 'common/ADD_NEW_NOTIFICATION':
      return {
        ...state,
        notifications: [...action.data, ...state.notifications],
      }
    case 'common/READ_NOTIFICATION':
      return {
        ...state,
        notifications: state.notifications.map((el) =>
          el.id === action.id ? { ...el, isRed: true } : el,
        ),
      }
    case 'common/CLEAR_NOTIFICATIONS_SUCCESS':
      return {
        ...state,
        notifications: [],
      }
    case 'common/SET_TABLE':
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.tableId]: action.data,
        },
      }
    case 'common/ADD_TABLE_ROW': {
      const tableData = state.tables[action.tableId]

      if (tableData === undefined) return state

      const pageItems =
        tableData.list.length > action.tableLimit - 1
          ? tableData.list.slice(0, -1)
          : tableData.list

      return {
        ...state,
        tables: {
          ...state.tables,
          [action.tableId]: {
            ...tableData,
            list: [action.row, ...pageItems],
          },
        },
      }
    }
    case 'common/REPLACE_SEARCHER_TABLE_ROW': {
      const tableData: TableBodyData | undefined = state.tables.searcher

      if (!tableData) return state

      const newList = (tableData.list as SearchResult[]).map((el) => {
        if (el.id === action.row.id) return action.row
        else return el
      })

      return {
        ...state,
        tables: {
          ...state.tables,
          searcher: {
            ...tableData,
            list: newList,
          },
        },
      }
    }
    case 'common/UPDATE_SEARCHER_TABLE_ROW_PERCENTAGE': {
      const tableData: TableBodyData | undefined | null = state.tables.searcher

      if (!tableData || tableData.list === null) return state

      const newList = (tableData.list as SearchResult[]).map((el) => {
        if (action.name === 'average percentage') {
          return {
            ...el,
            percentage: el.id === action.id ? action.progress : el.percentage,
          }
        } else {
          return {
            ...el,
            sources: el.sources.map((source: SourcePercentage) =>
              action.name === source.name && el.id === action.id
                ? {
                    ...source,
                    percentage: action.progress,
                  }
                : source,
            ),
          }
        }
      })

      return {
        ...state,
        tables: {
          ...state.tables,
          searcher: {
            ...tableData,
            list: newList,
          },
        },
      }
    }
    case 'common/TOGGLE_MENU_ANIMATION':
      return {
        ...state,
        isMenuAnimation: !state.isMenuAnimation,
      }
    default:
      return state
  }
}

  // backendalive
  export interface BackendAliveState {
    backendAlive: boolean
  }
  const initialBackendAliveState: BackendAliveState = {
    backendAlive: false,
  }
  export const backendAliveReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case 'backend/SET_BACKEND_ALIVE':
        return {
          ...state,
          backendAlive: action.backendAlive,
        }
      default:
        return state
    }
  }