export function isNumeric(str: string) {
  return /^-?\d+$/.test(str)
}

export function getRandomNumber(minCount: number, maxCount: number) {
  return Math.floor(Math.random() * (maxCount - minCount + 1) + minCount)
}

export function pad(num: number, size: number) {
  let strNum = num.toString()
  while (strNum.length < size) strNum = '0' + strNum
  return strNum
}

export function addLeadingZeros(num: number, totalLength: number) {
  return String(num).padStart(totalLength, '0')
}
