import { SettingsAction } from './actionType'
import { Provider } from '../../enum/provider'
import { IPackage } from '../package/reducer'

export type CampaignType = 'bottom-right' | 'center-overlay'
export type CampaignFrequency = 'once-a-day' | 'every-login'
export type CampaignPage =
  | 'all'
  | '/'
  | '/search'
  | '/packages'
  | '/developer'
  | '/apikey'
  | '/support'

export interface Campaign {
  id: number
  name: string
  title: string
  paragraph: string
  actionName: string
  actionUrl: string
  type: CampaignType
  showCountdown: boolean
  duration: { hours: number; minutes: number }
  finalDuration: { hours: number; minutes: number }
  subscribers: string[]
  packages: string[]
  pages: CampaignPage[]
  ltvRange: { from: number; to: number } | null
  balanceRange: { from: number; to: number } | null
  lastActive: number | null
  frequency: CampaignFrequency
  createdTime: string
}

export interface Profile {
  avatar: string | null
  email: string | null
  firstname: string | null
  is2FAEnabled: boolean | null
  isAutoRenewal: boolean | null
  isAutoRefill: boolean | null
  isOnBoarding: boolean
  RulesAcknowledgement: boolean
  lastname: string | null
  marketingCampaigns: Campaign[] | null
  orderId: number | null
  userPackage: IPackage | null
  packageBalance: number | null
  packageEndDate: Date | null
  // annualBonus: number | null
  packageStartBalance: number | null
  packageStartDate: string | null
  phone: string | null
  signInProvider: Provider | null
  uid: string | null
  package?: IPackage | null
  additionalPackageId : number | null
  additionalPackageEndDate: Date | null
  additionalPackageStartDate: Date | null
  irbisproUpgrade: boolean | null
  webIntisAutoRenewal: boolean | null
  additionalPackageType: string | null
  id?: number | null
}

export type BillingAddress = {
  country: string
  city: string
  state: string
  address: string
  postCode: string
}

export interface SettingsState extends Profile {
  onBoardingStep: number
  twoFactorQr: string | null
  accountingEmail: string | null
  billingAddress: BillingAddress | null
  password: string
}

const initialState: SettingsState = {
  avatar: null,
  email: null,
  firstname: null,
  is2FAEnabled: null,
  isAutoRenewal: null,
  isAutoRefill: false,
  isOnBoarding: false,
  RulesAcknowledgement: false,
  lastname: '',
  marketingCampaigns: null,
  orderId: null,
  userPackage: null,
  packageBalance: null,
  packageEndDate: null,
  packageStartBalance: null,
  packageStartDate: null,
  phone: '',
  signInProvider: null,
  uid: null,
  // annualBonus: null,
  password: '',
  onBoardingStep: 1,
  twoFactorQr: null,
  accountingEmail: null,
  billingAddress: null,
  additionalPackageId: null,
  additionalPackageEndDate: null,
  additionalPackageStartDate: null,
  irbisproUpgrade: null,
  webIntisAutoRenewal: null,
  additionalPackageType: null,
}

export function settingsReducer(state = initialState, action: SettingsAction) {
  switch (action.type) {
    case 'settings/SET_STATE':
      return {
        ...state,
        [action.key]: action.value,
      }
    case 'settings/FLUSH_STATE':
      return {
        ...state,
        ...initialState,
      }
    case 'settings/SET_PROFILE':
      return {
        ...state,
        ...action.data,
        userPackage: action.data.package,
      }
    case 'settings/SET_ON_BOARDING_STEP':
      return {
        ...state,
        onBoardingStep: action.step,
      }
    case 'settings/SET_ON_BOARDING_STATUS':
      return {
        ...state,
        isOnBoarding: action.value,
      }
    case 'settings/SET_RULES_ACKNOWLEDGEMENT_STATUS':
      return {
        ...state,
        RulesAcknowledgement: action.value,
      }
    case 'settings/SET_QR':
      return {
        ...state,
        twoFactorQr: action.qr,
      }
      case 'settings/UPDATE_BALANCE':
        let balance = state.packageBalance || 0
            balance = balance + action.value
      return {
        ...state,
        packageBalance: balance,
        //annualBonus: bonus,
      }
    case 'settings/SET_EMAIL_ACCOUNTING':
      return {
        ...state,
        accountingEmail: action.email,
      }
    case 'settings/SET_BILLING_ADDRESS':
      return {
        ...state,
        billingAddress: action.data,
      }
    default:
      return state
  }
}
