import { call, put, takeEvery } from 'redux-saga/effects'
import * as actionType from './actionType'
import { setMonitor } from './actionCreator'
import { api, Response } from '../../function/axios'
import { handleError } from '../../function/error'
import { GetChart } from './actionType'

function* getChart(action: GetChart) {
  try {
    const response: Response = yield call(api, {
      method: 'GET',
      url: `/request-monitor/chart?dateType=${action.dateType}`,
    })

    yield put(setMonitor(response.data))
  } catch (e) {
    yield call(handleError, e)
  }
}

export function* watchDashboard() {
  yield takeEvery(actionType.GET_CHART, getChart)
}
