import * as React from 'react'
import { useDispatch, useSelector } from '../../store'
import { TableBodyData, TableBodyRow } from '../../store/common/reducer'
import { fetchTable } from '../../store/common/actionCreator'
import { Table, TableContainer } from '@mui/material'
import { Pagination, Navigation, Header, Body } from './component'
import {
  Filter,
  FilterState,
  HeaderData,
  SortDirections,
  TableRef,
} from './types'
import { useTableStyles } from './hook/useStyles'
import { useTable } from './hook/useTable'
import { TableSize } from '../../type/mui'
import { getRefreshedToken } from '../../store/auth/selector'
import { setPage } from '../../store/searcher/actionCreator'

export const DEFAULT_OFFSET = 0
export const DEFAULT_LIMIT = 7
export const DEFAULT_ORDER = 'DESC'

type Props = {
  id: string
  url: string
  headerData: HeaderData[]
  renderBody: (row: TableBodyRow, idx: number) => React.ReactNode
  className?: string
  title?: string
  filters?: Filter[]
  search?: boolean
  searchPlaceholder?: string
  pagination?: boolean
  limit?: number
  refreshPerSeconds?: number
  selectable?: boolean
  selectNav?: React.ReactNode
  onRowClick?: (rows: TableBodyRow) => void
  onSelectCallback?: (rows: TableBodyRow[]) => void
  onUnselectCallback?: (rows: TableBodyRow[]) => void
  emptyText?: React.ReactNode
  size?: TableSize
}

export const DataTable = React.forwardRef<TableRef, Props>(
  (
    {
      id,
      url: initialUrl,
      headerData,
      renderBody,
      className,
      title,
      filters,
      search,
      searchPlaceholder,
      pagination = false,
      limit = DEFAULT_LIMIT,
      refreshPerSeconds,
      selectable,
      selectNav,
      onRowClick,
      onSelectCallback,
      onUnselectCallback,
      emptyText,
      size,
    },
    ref,
  ) => {
    const dispatch = useDispatch()
    const classes = useTableStyles()

    const { tables } = useSelector((state) => state.common)
    const refreshedToken = useSelector(getRefreshedToken)
    const bodyData: TableBodyData | undefined = tables ? tables[id] : undefined

    const [params, setParams] = React.useState<Record<string, any>>({
      offset: DEFAULT_OFFSET,
      limit,
    })

    const {
      sortKey,
      setSortKey,
      selectedRowIds,
      setSelectedRowIds,

      cellCount,
      pageCount,
      isPagesRowsSelected,
      handleSelect,
      handleSelectAll,
    } = useTable({
      headerData,
      bodyData,
      params,
      selectable,
      onSelectCallback,
      onUnselectCallback,
    })

    const page = useSelector((state) => state.searcher.page)

    const initialSortDirections = React.useMemo(() => {
      const initObj: SortDirections = {}

      headerData.forEach((el) => {
        initObj[el.key] = DEFAULT_ORDER

        if (el.defaultSort) setSortKey(el.key)
      })

      return initObj
    }, [headerData, setSortKey])

    const [lastSearch, setLastSearch] = React.useState('')
    const [sortDirections, setSortDirections] = React.useState<SortDirections>(
      initialSortDirections,
    )

    const url = React.useMemo(() => {
      if (sortKey === '' || sortDirections[sortKey] === undefined)
        return initialUrl

      return initialUrl + `?sort=${sortKey + sortDirections[sortKey]}`
    }, [initialUrl, sortKey, sortDirections])

    React.useEffect(() => {
      if (refreshedToken === null) return

      dispatch(fetchTable(url, params, id))

      if (refreshPerSeconds === undefined) return

      const intervalId = setInterval(() => {
        dispatch(fetchTable(url, params, id))
      }, refreshPerSeconds * 1000)

      return function cleanup() {
        clearInterval(intervalId)
      }
    }, [refreshedToken, params, dispatch, url, id, refreshPerSeconds])

    const handleSort = (key: string) => {
      const newDirection = sortDirections[key] === 'ASC' ? 'DESC' : 'ASC'

      setSortKey(key)
      setSortDirections((state) => ({ ...state, [key]: newDirection }))
    }

    const handleSearch = (value: string) => {
      if (lastSearch === value) return

      if (value.length > 2) {
        setParams((state) => ({
          ...state,
          offset: DEFAULT_OFFSET,
          search: value,
        }))
        dispatch({ type: 'searcher/SET_PAGE', number: 1 })
      } else if (value.length === 0) {
        setParams((state) => {
          const newState = { ...state }
          delete newState.search
          return newState
        })
      }

      setLastSearch(value)
    }

    const handleFilter = React.useCallback(
      (filterState: FilterState[]) => {
        const activeFilters = filterState.filter((el) => el.value !== 'all')

        const filterParams: Record<string, any> = {}

        activeFilters.forEach((el) => {
          filterParams[el.key] = el.value
        })

        setParams({
          offset: DEFAULT_OFFSET,
          limit: params.limit,
          ...filterParams,
        })
        dispatch({ type: 'searcher/SET_PAGE', number: 1 })
        setLastSearch('')
      },
      [params.limit, dispatch],
    )

    React.useImperativeHandle(ref, () => ({
      refresh() {
        const isLastRow = bodyData?.list.length === 1

        if (isLastRow && params.offet !== 0) {
          setParams((state) => ({
            ...state,
            offset: state.offset - state.limit,
          }))
          setSelectedRowIds([])
          dispatch({ type: 'searcher/SET_PAGE', number: page - 1 })
          return
        }

        dispatch(fetchTable(url, params, id))
        setSelectedRowIds([])
      },
      getSelectedIds() {
        return selectedRowIds
      },
    }))

    return (
      <>
        <TableContainer classes={classes} className={className}>
          <Navigation
            selectedRowIds={selectedRowIds}
            selectNav={selectNav}
            title={title}
            search={search}
            onSearch={handleSearch}
            filters={filters}
            onFilter={handleFilter}
          />

          <Table size={size}>
            <Header
              headerData={headerData}
              sortKey={sortKey}
              sortDirections={sortDirections}
              selectable={selectable}
              isPagesRowsSelected={isPagesRowsSelected}
              onSort={handleSort}
              onSelectAll={handleSelectAll}
            />

            <Body
              bodyData={bodyData}
              renderBody={renderBody}
              cellCount={cellCount}
              selectable={selectable}
              selectedRowIds={selectedRowIds}
              onRowClick={onRowClick}
              onSelect={handleSelect}
              emptyText={emptyText}
            />
          </Table>
        </TableContainer>

        {pagination && (
          <Pagination
            pageCount={pageCount}
            setParams={setParams}
            limit={params.limit}
          />
        )}
      </>
    )
  },
)
