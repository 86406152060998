import * as React from 'react'
import { makeStyles } from '@mui/styles'
import { Close as CloseIcon } from '@mui/icons-material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  Button,
  IconButton,
  Stack,
  Alert,
} from '@mui/material'
import { FormState } from '../function/validation'
import { Campaign } from '../store/settings/reducer'
import { useDispatch, useSelector } from '../store'
import { patchCampaign } from '../store/common/actionCreator'
import { pad } from '../function/number'
import { useCountdown } from '../hook/useCountdown'

const useStyles = makeStyles(() => {
  return {
    paper: {
      position: (props: FormState) =>
        props.type === 'bottom-right' ? 'fixed' : 'relative',
      right: (props: FormState) => (props.type === 'bottom-right' ? 0 : 'none'),
      bottom: (props: FormState) =>
        props.type === 'bottom-right' ? 0 : 'none',
      width: 500,
    },
  }
})

interface Props {
  data: Campaign
  onClose: () => void
}

export function PopupCampaign({ data, onClose }: Props) {
  const classes = useStyles(data)
  const dispatch = useDispatch()

  const [date, setDate] = React.useState<Record<string, string>>({})

  const hours = Number(data.finalDuration.hours)
  const minutes = Number(data.finalDuration.minutes)
  const seconds = hours * 60 * 60 + minutes * 60

  const dateStarted = new Date(data.createdTime)
  const unixEnd = dateStarted.setSeconds(dateStarted.getSeconds() + seconds)

  const countdown = useCountdown(unixEnd, true)

  const user = useSelector((state) => state.settings)

  React.useEffect(() => {
    setDate({
      days: pad(countdown.days, 2),
      hours: pad(countdown.hours, 2),
      minutes: pad(countdown.minutes, 2),
      seconds: pad(countdown.seconds, 2),
    })
  }, [countdown.days, countdown.hours, countdown.minutes, countdown.seconds])

  React.useEffect(() => {
    dispatch(patchCampaign(data.id, 'view'))
  }, [dispatch, data.id])

  function onActionUrlClick() {
    dispatch(patchCampaign(data.id, 'action'))
    let url = data.actionUrl + "&x-uid=" + user.uid
     if (process.env.REACT_APP_PAYPRO_TEST_MODE === 'true') {
      url = url + `&use-test-mode=${process.env.REACT_APP_PAYPRO_TEST_MODE}&secret-key=${process.env.REACT_APP_PAYPRO_TEST_SECRET}` 
     }
    window.open(url, '_blank')?.focus()
  }

  return (
    <Dialog open={true} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle>
        {data.name}
        <IconButton onClick={onClose} className="button__close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Typography
        color="primary"
        fontFamily="Montserrat-Bold, sans-serif"
        fontSize={16}
        textAlign="center"
      >
        {data.title}
      </Typography>

      <Divider variant="popup" color="primary" />

      <DialogContent sx={{ textAlign: 'center' }}>
        {countdown.isExpired ? (
          <Alert severity="info">
            <Typography color="inherit">Campaign is expired</Typography>
          </Alert>
        ) : (
          <Stack>
            {data.showCountdown && (
              <Stack
                direction="row"
                justifyContent="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
                my={3}
              >
                <Stack alignItems="center">
                  <Typography fontSize={36}>{date.days}</Typography>
                  <Typography fontSize={12}>Days</Typography>
                </Stack>
                <Stack alignItems="center">
                  <Typography fontSize={36}>{date.hours}</Typography>
                  <Typography fontSize={12}>Hours</Typography>
                </Stack>
                <Stack alignItems="center">
                  <Typography fontSize={36}>{date.minutes}</Typography>
                  <Typography fontSize={12}>Minutes</Typography>
                </Stack>
                <Stack alignItems="center">
                  <Typography fontSize={36}>{date.seconds}</Typography>
                  <Typography fontSize={12}>Seconds</Typography>
                </Stack>
              </Stack>
            )}
            <Typography variant="light" fontSize={16} minHeight={80}>
              {data.paragraph}
            </Typography>
          </Stack>
        )}
      </DialogContent>

      {!countdown.isExpired && (
        <>
          <Divider variant="popup" color="primary" />

          <DialogActions>
            <Button
              variant="contained"
              size="medium"
              color="success"
              onClick={onActionUrlClick}
              sx={{ borderRadius: 20 }}
            >
              {data.actionName}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
