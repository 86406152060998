import React from 'react'
import { useTranslation } from 'react-i18next'
import { TableStatusType } from '../types'
import { Box } from '@mui/material'
import table_ from '../../../sass/table.module.sass'
import { Body } from './Body'
import { Header } from './Header'
import { Navigation } from './Navigation'
import { Pagination } from './Pagination'

export interface TableStatusProps {
  status: TableStatusType
}

export function TableStatus({ status }: TableStatusProps) {
  const { t } = useTranslation()

  return (
    <>
      {status === 'done' && (
        <Box className={table_.status_success}>{t('common.done')}</Box>
      )}
      {status === 'open' && (
        <Box className={table_.status_error}>{t('common.open')}</Box>
      )}
      {status === 'in-progress' && (
        <Box className={table_.status_info}>{t('common.inProgress')}</Box>
      )}
      {status === 'closed' && <Box className={table_.status_close}>CLOSED</Box>}
    </>
  )
}

export { Body, Header, Navigation, Pagination }
